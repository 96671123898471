import React from 'react'
import styled from 'styled-components'
import { decimalToSexagesimal } from 'geolib'

const MonoFont = styled.span`
  font-family: monospace;
`

export const FormattedCoordinate = ({ value, sexagesimal }) =>
  !value ? null : sexagesimal ? (
    <MonoFont>
      {decimalToSexagesimal(value.latitude)} {value.latitude > 0 ? 'N' : 'S'}
      <br />
      {decimalToSexagesimal(value.longitude)} {value.longitude > 0 ? 'E' : 'W'}
    </MonoFont>
  ) : (
    <MonoFont>
      {value.latitude.toFixed(6)}, {value.longitude.toFixed(6)}
    </MonoFont>
  )
