import React from 'react'
import { Field, Form } from 'react-final-form'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { Button, ButtonLink } from '../../components/controls'
import { Heading } from '../../components/typography'
import { Input, required } from '../../components/input'
import { AuthPage } from './components/AuthPage'
import { useAuth } from '../../containers/auth'

export const MultiFactorAuth = () => {
  const { user, signOut } = useAuth()
  const history = useHistory()
  const { state } = useLocation()
  if (!user) {
    return <Redirect to="/sign-in" />
  }
  const onSubmit = () => history.push(state ? state.location : '/')
  return (
    <AuthPage>
      <Heading>Multi-factor authentication</Heading>
      <p style={{ width: '85%' }}>
        Your account is secured using multi-factor authentication (MFA). To finish signing in, enter
        the one-time PIN sent to your mobile device.
      </p>
      <br />
      <p>
        <b>Employee No:</b> {user.username}
      </p>
      <br />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form>
            <Field name="code" label="MFA Code" validate={required} component={Input} />
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <ButtonLink onClick={signOut}>Cancel</ButtonLink>
          </form>
        )}
      </Form>
    </AuthPage>
  )
}
