import React from 'react'
import styled from 'styled-components'

export const SummaryBlockRow = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.medium}px;
`

const Block = styled.div`
  flex: 1 0 1px;
  height: 150px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colors.light5};
  background-color: ${props => props.theme.colors.contentBackground};
  padding: ${props => props.theme.spacing.xsmall + 2}px ${props => props.theme.spacing.xsmall + 4}px;
  margin-right: ${props => props.theme.spacing.medium}px;
`

const Label = styled.div`
  color: ${props => props.theme.colors.dark2};
  font-size: ${props => props.theme.fontSizes.medium}px;
  text-transform: uppercase;
  flex-grow: 0;
`

const Value = styled.div`
  margin-top: ${props => props.theme.spacing.smedium}px;
  color: ${props => props.theme.colors[props.color || 'dark2']};
  font-size: ${props => props.theme.fontSizes.xxlarge * 1.7}px;
  font-weight: 600;
  text-align: center;
`

export const SummaryBlock = ({ label, value, color }) => (
  <Block>
    <Label>{label}</Label>
    <Value color={color}>{value}</Value>
  </Block>
)
