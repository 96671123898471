import React from 'react'
import { GridThemeProvider } from 'styled-bootstrap-grid'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { NormalizeGlobalStyle } from './NormalizeGlobalStyle'
import { gridTheme } from './gridTheme'
import { theme } from './theme'

export const ThemeProvider = ({ children }) => (
  <SCThemeProvider theme={theme}>
    <NormalizeGlobalStyle />
    <GridThemeProvider gridTheme={gridTheme}>{children}</GridThemeProvider>
  </SCThemeProvider>
)
