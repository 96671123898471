import React from 'react'
import styled from 'styled-components'
import { lowerCase, upperFirst } from 'lodash'

const Dot = styled.div`
  background-color: ${props => props.theme.colors[props.color]};
  display: inline-block;
  margin-right: 3px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
`

export const FormattedOrderStatus = ({ value }) => (
  <div>
    <Dot color={value} /> {upperFirst(lowerCase(value))}
  </div>
)
