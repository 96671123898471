const componentTypeMap = {
  premise: 'premise',
  subpremise: 'subpremise',
  street_number: 'streetNumber',
  route: 'route',
  sublocality: 'sublocality',
  locality: 'locality',
  administrative_area_level_1: 'adminAreaLevel1',
  administrative_area_level_2: 'adminAreaLevel2',
  administrative_area_level_3: 'adminAreaLevel3',
  administrative_area_level_4: 'adminAreaLevel4',
  administrative_area_level_5: 'adminAreaLevel5',
  postal_code: 'postalCode',
  formatted_address: 'formattedAddress',
}

export const geocodeResultToGeoAddress = ({
  geometry,
  formatted_address: formattedAddress,
  place_id: googlePlaceId,
  address_components: components,
}) => {
  const { lat, lng } = geometry.location
  const geoAddress = {
    formattedAddress,
    googlePlaceId,
    lat: typeof lat === 'function' ? lat() : lat,
    lng: typeof lng === 'function' ? lng() : lng,
  }

  return components.reduce(
    (address, { short_name: shortName, long_name: longName, types }) =>
      types.reduce((address, type) => {
        const mappedType = componentTypeMap[type]
        if (mappedType) {
          address[mappedType] = longName
        } else if (type === 'country') {
          address.country = shortName
          address.countryName = longName
        } else if (type === 'postal_town' && !address.locality) {
          address.locality = longName
        }
        return address
      }, address),
    geoAddress,
  )
}
