export const roles = {
  admin: {
    id: 'admin',
    name: 'Admin',
  },
  executive: {
    id: 'executive',
    name: 'Executive',
  },
  cityManager: {
    id: 'cityManager',
    name: 'City Manager',
  },
  flightController: {
    id: 'flightController',
    name: 'Flight Controller',
  },
  stockManager: {
    id: 'stockManager',
    name: 'Stock Manager',
  },
}

export const roleName = role => roles[role].name
