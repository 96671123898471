import styled from 'styled-components'

export const SelectInput = styled.select`
  border: 1px solid ${props => props.theme.colors[props.error ? 'error' : 'light4']};
  font-size: ${props => props.theme.fontSizes.medium}px;
  color: ${props => props.theme.colors.foreground};
  border-radius: 5px;
  font-weight: 400;
  padding: 8px;
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.xsmall}px;

  ::placeholder {
    color: ${props => props.theme.colors.gray};
  }
`
