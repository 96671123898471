import React from 'react'
import { useSelector } from 'react-redux'
import { Heading } from '../../components/typography'
import { DD, DL, DT } from '../../components/tables'
import { selectFlightTotalsByCity } from '../../containers/simulation/schedule/scheduleSelectors'

export const OrdersInfo = ({ city }) => {
  const info = useSelector(selectFlightTotalsByCity(city))
  return (
    <>
      <Heading margin="xsmall">Orders</Heading>
      <DL isHorizontal dtPercentage={48}>
        <DT>Alerts</DT>
        <DD>0</DD>
        <DT>Active Flights</DT>
        <DD>{(info.outbound ?? 0) + (info.inbound ?? 0)}</DD>
        <DT>Outbound</DT>
        <DD>{info.outbound ?? 0}</DD>
        <DT>Inbound</DT>
        <DD>{info.inbound ?? 0}</DD>
        <DT>Completed</DT>
        <DD>{info.completed ?? 0}</DD>
      </DL>
    </>
  )
}
