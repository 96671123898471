import produce from 'immer'
import delay from 'delay'
import { pick, mapValues } from 'lodash/fp'
import * as citySpecs from './citySpecs'
import { continents, byId } from './activeCities'
import { selectDroneSpecs } from '../drones/dronesReducer'

export const CITY_INITIALIZED = 'CITY_INITIALIZED'
export const CITY_SAVE_SETUP = 'CITY_SAVE_SETUP'
export const CITY_ADD_NO_FLY_ZONE = 'CITY_ADD_NO_FLY_ZONE'
export const CITY_SET_HUB_ACTIVE = 'CITY_SET_HUB_ACTIVE'

const initialState = {
  continents,
  byId: { ...byId, ...citySpecs },
  initializedCities: {},
}

export const citiesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CITY_INITIALIZED: {
        const { cityId } = action.payload
        draft.initializedCities[cityId] = true
        break
      }

      case CITY_SAVE_SETUP: {
        const { cityId, data } = action.payload
        Object.assign(draft.byId[cityId], data)
        break
      }

      case CITY_ADD_NO_FLY_ZONE: {
        const { cityId, zone } = action.payload
        draft.byId[cityId].noFlyZones.unshift(zone)
        break
      }

      case CITY_SET_HUB_ACTIVE: {
        const { cityId, hubId, active } = action.payload
        draft.byId[cityId].hubs[hubId].active = active
        break
      }

      // no default
    }
  })

// Use a function so the return value is empty and Final Form doesn't see validation errors
export const saveCitySetup = (cityId, values) => async (dispatch, getState) => {
  await delay(700)
  const droneOptions = selectDroneSpecs()(getState())
  const activeDroneId = values.droneSpec
  const droneSpec = droneOptions.find(d => d.id === activeDroneId)
  dispatch({
    type: CITY_SAVE_SETUP,
    payload: {
      cityId,
      data: {
        droneSpec,
        operatingHours: values.operatingHours,
        ...mapValues(Number, values.flightLevels),
      },
    },
  })
}

// Use a function so the return value is empty and Final Form doesn't see validation errors
export const addCityNoFlyZone = (cityId, values) => dispatch => {
  dispatch({
    type: CITY_ADD_NO_FLY_ZONE,
    payload: {
      cityId,
      zone: {
        name: values.name,
        radius: Number(values.radius),
        center: {
          latitude: Number(values.latitude),
          longitude: Number(values.longitude),
        },
      },
    },
  })
}

export const setCityHubActive = (cityId, hubId, active) => ({
  type: CITY_SET_HUB_ACTIVE,
  payload: { cityId, hubId, active },
})

export const selectContinents = () => ({ cities }) => cities.continents

export const selectActiveCities = () => ({ cities }) =>
  Object.values(cities.byId).map(pick(['id', 'name']))

export const selectActiveCitiesObject = () => state =>
  selectActiveCities()(state).reduce((acc, city) => {
    acc[city.name] = true
    return acc
  }, {})

export const selectCitySpec = cityId => ({ cities }) => cities.byId[cityId]

export const selectIsCityInitialized = cityId => ({ cities }) => cities.initializedCities[cityId]

export const cityInitialized = cityId => ({
  type: CITY_INITIALIZED,
  payload: { cityId },
})
