import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { UsersList } from './UsersList'
import { UserManagement } from './UserManagement'

export const UsersAndRoles = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/users`} component={UsersList} />
    <Route path={`${match.path}/users/:userId`} component={UserManagement} />
    <Redirect to={`${match.path}/users`} />
  </Switch>
)
