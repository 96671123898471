import React from 'react'
import styled from 'styled-components'
import { TextInput } from './TextInput'

const InputGroup = styled.div`
  position: relative;
  margin-right: ${props => (props.isInline ? props.theme.spacing.small : 0)}px;
  margin-bottom: ${props =>
    props.isInline ? 0 : props.theme.spacing[props.margin || 'smedium']}px;
  ${props => props.isInline && `flex: 1`};
  ${props => props.width && `flex: 0 1 ${`${props.width}px`}`};
  ${props => props.isBlocked && 'width: 100%;'};
`

const Label = styled.label`
  color: ${props => props.theme.colors.dark2};
  font-size: ${props => props.theme.fontSizes.medium}px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: ${props => props.theme.spacing.xsmall}px;
`

const Error = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: ${props => props.theme.fontSizes.xsmall}px;
  margin: 0 0 ${props => props.theme.spacing.xsmall}px ${props => props.theme.spacing.xxsmall}px;
`

export const Input = React.forwardRef(
  (
    { input = {}, meta = {}, name, label, as, block, width, inline, margin, children, ...props },
    ref,
  ) => {
    const { dirty, touched, submitFailed, error: clientError, submitError } = meta
    const error = (dirty || submitFailed) && touched && (submitError || clientError)
    const Component = as || TextInput

    return (
      <InputGroup isInline={inline} isBlocked={block} margin={margin} width={width}>
        {label && <Label htmlFor={input.name || name}>{label}</Label>}
        <Component
          {...props}
          style={{ marginBottom: 0, ...props.style }}
          {...input}
          error={error}
          ref={ref}
        >
          {children}
        </Component>
        {error && <Error>{error}</Error>}
      </InputGroup>
    )
  },
)
