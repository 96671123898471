import { useContext, useEffect, useRef } from 'react'
import { MapContext } from '../MapContext'
import { mapCoordinate } from '../Map'

const defaultStyle = {
  strokeColor: 'black',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: 'black',
  fillOpacity: 0.35,
}

export const MapCircle = ({ center, radius, style }) => {
  const { maps, map } = useContext(MapContext)
  const circle = useRef()

  useEffect(() => {
    if (map) {
      if (!circle.current) {
        circle.current = new maps.Circle({
          ...defaultStyle,
          ...style,
          center: mapCoordinate(center),
          radius,
          map,
        })
      } else {
        circle.current.setOptions({
          ...defaultStyle,
          ...style,
          center: mapCoordinate(center),
          radius,
          map,
        })
      }
    }
    return () => circle.current && circle.current.setMap(null)
  }, [center, radius, style, map, maps])

  return null
}
