import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { formatISO9075WithOptions } from 'date-fns/fp'
import { useTime } from '../../containers/simulation/time/useTime'
import { Button } from '../controls'

const formatTime = formatISO9075WithOptions({ representation: 'time' })

const Container = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: monospace;
  font-size: ${props => props.theme.fontSizes.xlarge}px;
`

const Time = styled.div`
  margin-left: ${props => props.theme.spacing.small}px;
`

const TimeControl = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: -${props => props.theme.spacing.xsmall}px;
`

const TimeBtn = styled(Button)`
  border-radius: 2px;
  background: ${props => props.theme.colors.light5};
  color: ${props => props.theme.colors.black};
  padding: 0 6px;
  margin: 0 0 2px;
`

export const Clock = () => {
  const { getTime, timeEventEmitter, multiplier, speedUp, slowDown } = useTime()
  const [timestamp, setTime] = useState(getTime())

  useEffect(() => {
    const updateCurrentTime = ev => setTime(ev.detail.time)
    timeEventEmitter.addEventListener('tick', updateCurrentTime)
    return () => timeEventEmitter.removeEventListener('tick', updateCurrentTime)
  }, [timeEventEmitter, setTime])

  return (
    <Container>
      <TimeControl>
        <TimeBtn onClick={speedUp}>
          <i className="fas fa-caret-up" />
        </TimeBtn>
        <TimeBtn onClick={slowDown}>
          <i className="fas fa-caret-down" />
        </TimeBtn>
      </TimeControl>
      <Time>x{multiplier.toString().padStart(2, '0')}</Time>
      <Time>{formatTime(timestamp)}</Time>
    </Container>
  )
}
