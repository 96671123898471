import { get, groupBy, mapValues, compose, sum } from 'lodash/fp'
import memoizeOne from 'memoize-one'
import { selectOrderById } from '../orders/ordersReducer'
import { selectCitySpec } from '../cities/citiesReducer'

// A single list of viable flights in a city, active and queued
export const selectFlightsByCity = cityId => {
  const getCitySpec = selectCitySpec(cityId)
  return state =>
    Object.values(state.schedule.byCity[cityId] ?? {}).flatMap(({ drones }) =>
      Object.values(drones)
        .flatMap(droneQueue => {
          const flights = [...droneQueue.queuedFlights]
          if (droneQueue.activeFlight) {
            flights.unshift(droneQueue.activeFlight)
          }
          return flights
        })
        .map(flight => ({
          ...flight,
          drone: getCitySpec(state).droneSpec,
          order: selectOrderById(flight.orderId)(state),
        })),
    )
}

// A single list of all flights in a city, active, queued, on-hold, completed, and cancelled
export const selectAllFlightsByCity = cityId => {
  const getCitySpec = selectCitySpec(cityId)
  return state =>
    Object.values(state.schedule.byCity[cityId] ?? {}).flatMap(
      ({ drones, completedFlights, cancelledFlights, delayedFlights }) =>
        [
          ...completedFlights,
          ...cancelledFlights,
          ...delayedFlights,
          ...Object.values(drones).flatMap(droneQueue => {
            const flights = [...droneQueue.queuedFlights]
            if (droneQueue.activeFlight) {
              flights.unshift(droneQueue.activeFlight)
            }
            return flights
          }),
        ].map(flight => ({
          ...flight,
          drone: getCitySpec(state).droneSpec,
          order: selectOrderById(flight.orderId)(state),
        })),
    )
}

// A single list of queued and on-hold flights in a city
export const selectQueuedFlightsByCity = cityId => {
  const getCitySpec = selectCitySpec(cityId)
  return state =>
    Object.values(state.schedule.byCity[cityId] ?? {}).flatMap(({ drones, delayedFlights }) =>
      [
        ...delayedFlights,
        ...Object.values(drones).flatMap(droneQueue => {
          const flights = [...droneQueue.queuedFlights]
          if (droneQueue.activeFlight) {
            flights.unshift(droneQueue.activeFlight)
          }
          return flights
        }),
      ].map(flight => ({
        ...flight,
        drone: getCitySpec(state).droneSpec,
        order: selectOrderById(flight.orderId)(state),
      })),
    )
}

const compileActiveFlights = memoizeOne((getCitySpec, cityId, state) =>
  Object.values(state.schedule.byCity[cityId] ?? {}).flatMap(({ drones }) =>
    Object.values(drones)
      .flatMap(droneQueue => droneQueue.activeFlight)
      .filter(Boolean)
      .map(flight => ({
        ...flight,
        drone: getCitySpec(state).droneSpec,
        order: selectOrderById(flight.orderId)(state),
      })),
  ),
)

// Only the active flights, not queued or completed
export const selectActiveFlightsByCity = cityId => {
  const getCitySpec = selectCitySpec(cityId)
  return state => compileActiveFlights(getCitySpec, cityId, state)
}

export const selectCompletedFlightCountByCity = cityId => state =>
  sum(Object.values(state.schedule.byCity[cityId] ?? {}).map(hub => hub.completedFlights.length))

const getFlightStats = compose(
  mapValues(get('length')),
  groupBy(flight => flight.flightPath.status),
)

export const selectFlightTotalsByCity = cityId => {
  const selectActive = selectActiveFlightsByCity(cityId)
  const selectCompletedCount = selectCompletedFlightCountByCity(cityId)
  return state => ({
    ...getFlightStats(selectActive(state)),
    completed: selectCompletedCount(state),
  })
}

// A single list of drone schedules across all hubs
export const selectDroneSchedulesByCity = cityId => state =>
  Object.values(state.schedule.byCity[cityId] ?? {}).flatMap(hub => Object.values(hub.drones))

export const selectFlightByDroneId = (cityId, hubId, droneId) => state => {
  const flight = state.schedule.byCity[cityId][hubId].drones[droneId].activeFlight
  return {
    flight,
    order: flight && selectOrderById(flight.orderId)(state),
  }
}
