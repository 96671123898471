import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { createReducer } from '../../containers/reducers'

export const configureStore = () => {
  const middleware = applyMiddleware(thunk)
  // Middleware execution order is top-to-bottom

  let store
  let composeEnhancers = compose
  if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  }
  store = createStore(createReducer(), undefined, composeEnhancers(middleware))

  // Extensions
  store.injectedReducers = {}

  // Make reducers hot reloadable
  if (module.hot) {
    module.hot.accept('../../containers/reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers))
    })
  }

  return store
}
