import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'styled-bootstrap-grid'
import { Page } from '../../components/app'
import { DD, DL, DT, Pagination, Table, TD, TH, TR } from '../../components/tables'
import { Input, SelectInput } from '../../components/input'
import { UserForm } from '../usersAndRoles/forms/UserForm'
import { B, Heading } from '../../components/typography'
import { Switch } from '../../components/input/Switch'
import { Button } from '../../components/controls'
import { usePagination } from '../../containers/router'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'
import {
  addUserCityDefaultRole,
  addUserWithInitialRole,
  changeUserCityRole,
  selectAllUsers,
  selectCityUsers,
  toggleCityRole,
} from '../../containers/users/usersReducer'
import { roles } from '../../containers/users/roles'
import { selectIndexedHubsByCity } from '../../containers/locations/hubSetup'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

const TDClick = styled(TD)`
  cursor: pointer;
`

const ContentSection = styled.div`
  margin-bottom: ${props => props.theme.spacing.xlarge}px;
`

export const UserSetup = ({ history, match }) => {
  const location = getCitySlug(match)
  const dispatch = useDispatch()
  const citySpec = useSelector(selectCitySpec(location))
  const cityUsers = useSelector(selectCityUsers(location))
  const allUsers = useSelector(selectAllUsers())
  const hubs = useSelector(selectIndexedHubsByCity(location))
  const { page, limit } = usePagination(6)

  const [userSearch, setUserSearch] = useState('')
  const filteredUsers = cityUsers.filter(
    user => user.name.includes(userSearch) || user.email.includes(userSearch),
  )

  const [allUsersSearch, setAllUsersSearch] = useState('')
  const filteredAllUsers = allUsers.filter(
    user => user.name.includes(allUsersSearch) || user.email.includes(allUsersSearch),
  )

  return (
    <Page heading={`${citySpec.name} - User Setup`}>
      <Row>
        <Col sm={8}>
          <Table
            head={
              <TR>
                <TH width="140px">Employee No.</TH>
                <TH>User</TH>
                <TH>Role</TH>
                <TH width="25%">Hubs</TH>
              </TR>
            }
            items={filteredUsers}
            page={page}
            limit={limit}
            emptyMsg="No users"
          >
            {user => (
              <TR key={user.id}>
                <TD>{user.id}</TD>
                <TDClick onClick={() => history.push(`/manage-access/users/${user.id}`)}>
                  <B>{user.name}</B>
                  <br />
                  {user.email}
                  <br />
                  {user.phone}
                </TDClick>
                <TD>
                  <SelectInput
                    inline
                    value={user.role.role}
                    onChange={ev =>
                      dispatch(changeUserCityRole(user.id, location, ev.target.value))
                    }
                  >
                    {Object.values(roles).map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </SelectInput>
                </TD>
                <TD>
                  {Object.entries(hubs).map(([hubId, hub]) => (
                    <Switch
                      key={hubId}
                      label={hub.locality}
                      labelWidth={130}
                      checked={user.role.hubs[hubId] ?? false}
                      onChange={() => dispatch(toggleCityRole(user.id, user.roleIdx, hubId))}
                    />
                  ))}
                </TD>
              </TR>
            )}
          </Table>
          <Pagination total={filteredUsers.length} limit={limit} />
        </Col>

        <Col sm={4}>
          <ContentSection>
            <Heading>Find a user with access</Heading>
            <Input
              placeholder="Search users..."
              value={userSearch}
              onChange={ev => setUserSearch(ev.target.value)}
            />
          </ContentSection>

          <ContentSection>
            <Heading>Add an existing user</Heading>
            <Input
              placeholder="Search all users..."
              value={allUsersSearch}
              onChange={ev => setAllUsersSearch(ev.target.value)}
            />
            {allUsersSearch && (
              <DL isHorizontal dtPercentage={60}>
                {filteredAllUsers.slice(0, 6).map(user => (
                  <React.Fragment key={user.id}>
                    <DT compact>{user.name}</DT>
                    <DD compact>
                      <Button
                        small
                        block={false}
                        onClick={() => dispatch(addUserCityDefaultRole(user.id, location))}
                      >
                        Add
                      </Button>
                    </DD>
                  </React.Fragment>
                ))}
              </DL>
            )}
          </ContentSection>

          <ContentSection>
            <Heading>Add a new user</Heading>
            <UserForm onSubmit={values => dispatch(addUserWithInitialRole(values, location))} />
          </ContentSection>
        </Col>
      </Row>
    </Page>
  )
}
