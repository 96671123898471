import produce from 'immer'
import { specs } from './droneSpecs'

export const DRONES_ADD_MANY = 'DRONES_ADD_MANY'

const initialState = {
  byId: {},
  specs,
}

export const dronesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DRONES_ADD_MANY: {
        const { drones } = action.payload
        drones.forEach(drone => {
          draft.byId[drone.id] = drone
        })
        break
      }

      // no default
    }
  })

export const addDrones = (cityId, cityHubs, drones) => ({
  type: DRONES_ADD_MANY,
  payload: { cityId, cityHubs, drones },
})

export const selectDroneSpecs = () => ({ drones }) => drones.specs
