import { random } from 'lodash'
import { geocodeResultToGeoAddress } from '../googleMaps/helpers/geocodeResultToGeoAddress'

// Use this one for client-side geocoding
export const googleMapsJs = {
  geocode(maps, address) {
    const geocoder = new maps.Geocoder()
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        status === 'OK'
          ? resolve(results[0])
          : reject(new Error('Address look-up was not successful: ' + status))
      })
    }).then(geocodeResultToGeoAddress)
  },

  reverseGeocode(maps, coordinate) {
    const geocoder = new maps.Geocoder()
    const latlng = {
      lat: coordinate.lat || coordinate.latitude,
      lng: coordinate.lng || coordinate.longitude,
    }
    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status !== 'OK') {
          reject(new Error('Address look-up was not successful: ' + status))
        }
        const streetAddresses = results.filter(res => res.types.includes('street_address'))
        if (streetAddresses.length) {
          return resolve(streetAddresses[0])
        }
        const premiseAddresses = results.filter(res => res.types.includes('premise'))
        if (premiseAddresses.length) {
          return resolve(premiseAddresses[0])
        }
        const routeAddresses = results.filter(res => res.types.includes('route'))
        if (routeAddresses.length && routeAddresses[0].components) {
          const streetNumber = random(5, 150).toString()
          return resolve({
            ...routeAddresses[0],
            components: [
              ...routeAddresses[0].components,
              { long_name: streetNumber, short_name: streetNumber, types: ['street_number'] },
            ],
          })
        }
        return resolve(results[0])
      })
    }).then(geocodeResultToGeoAddress)
  },
}
