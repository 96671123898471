import React from 'react'
import styled, { css } from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { times } from 'lodash'

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const activeBlockStyle = css`
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.primary};
`

const disabledBlockStyle = css`
  cursor: default;
  pointer-events: none;
  color: ${props => props.theme.colors.dark3};
`

const PageBlock = styled(({ active, ...props }) => <Link {...props} />)`
  border-radius: 0;
  border: 1px solid ${props => props.theme.colors.light4};
  border-left: none;
  padding: 8px;
  min-width: 34px;
  margin: 0;
  background-color: ${props => props.theme.colors.contentBackground};
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.medium}px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;

  ${props => props.active && activeBlockStyle};
  ${props => props.disabled && disabledBlockStyle};
`

const PrevBlock = styled(PageBlock)`
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: 1px solid ${props => props.theme.colors.light4};
`

const NextBlock = styled(PageBlock)`
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`

const pageNo = page => ({ search: `?page=${page}` })

export const Pagination = ({ total, limit = 30 }) => {
  const location = useLocation()
  if (total < limit) return null

  const query = new URLSearchParams(location.search)
  const page = Number(query.get('page') ?? 1)
  const pages = Math.ceil(total / limit)

  const listedPages =
    pages <= 5
      ? times(pages, n => ({ target: n + 1 }))
      : [
          ...times(3, n => ({ target: n + 1 })),
          { target: 4, label: '...' },
          { target: pages - 1 },
          { target: pages },
        ]

  return (
    <PaginationContainer>
      <PrevBlock to={pageNo(page - 1)} disabled={page === 1}>
        Previous
      </PrevBlock>
      {listedPages.map(({ target, label }) => (
        <PageBlock key={target} to={pageNo(target)} active={target === page}>
          {label || target}
        </PageBlock>
      ))}
      <NextBlock to={pageNo(page + 1)} disabled={page === pages}>
        Next
      </NextBlock>
    </PaginationContainer>
  )
}
