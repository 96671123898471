import React from 'react'
import delay from 'delay'
import styled from 'styled-components'
import { Field, Form } from 'react-final-form'
import { Button } from '../../../components/controls'
import { Input, required } from '../../../components/input'

const HForm = styled.form`
  display: flex;
  flex-direction: ${props => (props.isInline ? 'row' : 'column')};
  justify-content: stretch;
  margin-bottom: ${props => props.theme.spacing.medium}px;
`

export const UserForm = ({ onSubmit, inline }) => {
  return (
    <Form
      subscription={{ submitting: true }}
      onSubmit={async values => {
        await delay(700)
        onSubmit(values)
      }}
    >
      {({ handleSubmit, submitting, form }) => (
        <HForm
          isInline={inline}
          onSubmit={async event => {
            await handleSubmit(event)
            form.getState().valid && form.reset()
          }}
        >
          <Field
            name="id"
            placeholder="Employee No."
            validate={required}
            component={Input}
            inline={inline}
          />
          <Field
            name="name"
            placeholder="Full name"
            validate={required}
            component={Input}
            inline={inline}
          />
          <Field
            name="email"
            placeholder="Email"
            validate={required}
            component={Input}
            inline={inline}
          />
          <Field name="phone" placeholder="Phone No." component={Input} inline={inline} />
          <Button type="submit" disabled={submitting} inline block={false} align="flex-end">
            {submitting ? 'Adding...' : 'Add User'}
          </Button>
        </HForm>
      )}
    </Form>
  )
}
