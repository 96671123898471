import React from 'react'
import styled from 'styled-components'

const FooterSection = styled.footer`
  position: relative;
  text-align: center;
  color: ${props => props.theme.colors.dark5};
  font-size: ${props => props.theme.fontSizes.small}px;
  padding: ${props => props.theme.spacing.small}px 0;
  margin-top: ${props => props.theme.spacing.medium}px;
`

export const Footer = () => (
  <FooterSection>
    &copy; {new Date().getFullYear()} Parcel Fly Ltd. All rights reserved
  </FooterSection>
)
