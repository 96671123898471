import { theme as baseTheme } from '../../../../theme/theme'

export const mobileTheme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    primary: '#fcbb6a',
    accent: '#0066c0',
    background: '#fff',
    foreground: '#000',
  },
}
