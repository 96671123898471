import React from 'react'
import styled from 'styled-components'
import { PageContainer, PageContent } from '../../components/app'
import { Image } from '../../components/images'
import logo from '../../components/app/logo.svg'
import { Heading } from '../../components/typography'
import { Link } from 'react-router-dom'

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: -${props => props.theme.spacing.xlarge}px;
  max-width: 400px;
`

const Logo = styled(Image)`
  margin-bottom: ${props => props.theme.spacing.medium}px;
`

const Demo = styled.p`
  margin-top: ${props => props.theme.spacing.medium}px;
`

export const MobileBlockingPage = () => {
  return (
    <PageContainer>
      <PageContent justify="center" align="center">
        <Section>
          <Logo src={logo} width="85%" alt="Parcel Fly" />
          <Heading>Available on Desktops & Laptops</Heading>
          <p style={{ width: '85%' }}>
            The Parcel Fly enterprise management solution is available on desktop and laptop
            devices, supporting all modern browsers.
          </p>
          <Demo>
            <Link to="/mobile">Pick location demo</Link>
          </Demo>
        </Section>
      </PageContent>
    </PageContainer>
  )
}
