export const timeConfig = {
  initialMultiplier: 5,
}

export const orderConfig = {
  initialOrders: 200,
  newOrderInterval: {
    interval: 5000,
    delta: 1000,
  },
  weight: { min: 20 },
}

export const flightConfig = {
  loadingTimeInterval: {
    interval: 60,
    delta: 20,
  },
  deliveryTimeInterval: {
    interval: 12,
    delta: 3,
  },
}
