/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { decimalToSexagesimal } from 'geolib'
import { BgImgPage } from './components/layout/BgImgPage'
import { Overlay } from './components/layout/Overlay'
import { B, Heading } from '../../components/typography'
import { AddAddressButton } from './components/address/AddAddressButton'
import { AddressBlock } from './components/address/AddressItem'
import { useAirDeliverySpots } from './containers/AirDeliverySpots'
import { MobileModal } from './components/layout/MobileModal'
import { MobileButton } from './components/controls/MobileButton'

const Section = styled.div`
  margin-bottom: ${props => props.theme.spacing.small}px;
`

const P = styled.p`
  color: ${props => props.theme.colors.dark3};
  margin-bottom: ${props => props.theme.spacing.medium}px;
`

const H2 = styled(Heading)`
  font-size: ${props => props.theme.fontSizes.large}px;
`

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const MobileSettings = ({ pickLocation, history, location }) => {
  const { state = {} } = location
  const { deliverySpots, removeDeliverySpot } = useAirDeliverySpots()
  const [open, setOpen] = useState(state.success)
  useEffect(() => {
    if (state.success) {
      history.replace({ ...location, state: undefined })
    }
  }, [state, location, history])
  return (
    <BgImgPage>
      <Overlay>
        <Overlay.Content>
          <Section>
            <Heading margin="xsmall">Your Addresses</Heading>
            <AddAddressButton>Add a new Street Address</AddAddressButton>
            <Link to={pickLocation}>
              <AddAddressButton>Add a new Air Delivery Spot</AddAddressButton>
            </Link>
          </Section>

          <Section>
            <H2 margin="xsmall">Air Delivery Spots</H2>
            {!deliverySpots.length ? (
              <P>Add delivery spots to enable Air Delivery during checkout.</P>
            ) : (
              deliverySpots.map((spot, index) => (
                <AddressBlock key={spot.name} remove={() => removeDeliverySpot(index)}>
                  <B>{spot.name}</B>
                  <br />
                  Latitude: {decimalToSexagesimal(spot.latitude)} {spot.latitude >= 0 ? 'N' : 'S'}
                  <br />
                  Longitude: {decimalToSexagesimal(spot.longitude)}{' '}
                  {spot.longitude >= 0 ? 'E' : 'W'}
                  <br />
                  {spot.formattedAddress}
                  <br />
                </AddressBlock>
              ))
            )}
          </Section>

          <Section>
            <H2 margin="xsmall">Street Addresses</H2>
            <AddressBlock edit>
              <B>Jonathan Thatcher</B>
              <br />
              27 Tulip Tree Close
              <br />
              Tonbridge, Kent TN9 2SJ
              <br />
              United Kingdom
              <br />
              Phone number: +447688457891
              <br />
              <a>Add delivery instructions</a>
            </AddressBlock>
            <AddressBlock edit>
              <B>Susan Thatcher</B>
              <br />
              47 Chichester Rd
              <br />
              Tonbridge, Kent TN9 2TJ
              <br />
              United Kingdom
              <br />
              Phone number: +448476889157
              <br />
              <a>Add delivery instructions</a>
            </AddressBlock>
          </Section>
        </Overlay.Content>
      </Overlay>

      <MobileModal show={open}>
        <Centered>
          <Heading>Air Delivery Spot Added</Heading>
          <P>
            Your new Air Delivery Spot was successfully added. We will verify your spot and if we
            have any questions we will come back to you.
          </P>
          <MobileButton onClick={() => setOpen(false)}>Close</MobileButton>
        </Centered>
      </MobileModal>
    </BgImgPage>
  )
}
