import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns/fp'
import { DateInput, Input, TimeInput } from '../input'

const formatDate = format('P')

export const FilterBlock = styled.div`
  display: flex;
  flex-direction: ${props => (props.isInline ? 'row' : 'column')};
  margin-bottom: ${props => props.theme.spacing.xsmall}px;
  margin-left: auto;
  max-width: 900px;
  justify-content: ${props => props.justify || 'flex-start'};
`

export const FilterRow = styled.div`
  display: flex;
  margin-right: ${props => (props.isInline ? props.theme.spacing.small : 0)}px;
`

export const Dash = styled.div`
  padding: 8px;
  color: ${props => props.theme.colors.dark3};
`

export const Filter = ({ inline }) => (
  <FilterBlock isInline={inline}>
    <FilterRow isInline={inline}>
      <Input
        name="dateFrom"
        as={DateInput}
        input={{ defaultValue: formatDate(new Date()) }}
        margin="xsmall"
      />
      <Dash>-</Dash>
      <Input
        name="dateTo"
        as={DateInput}
        input={{ defaultValue: formatDate(new Date()) }}
        margin="xsmall"
      />
    </FilterRow>
    <FilterRow isInline={inline}>
      <Input name="timeFrom" as={TimeInput} input={{ defaultValue: '9:00 AM' }} margin="xsmall" />
      <Dash>-</Dash>
      <Input name="timeTo" as={TimeInput} input={{ defaultValue: '6:00 PM' }} margin="xsmall" />
    </FilterRow>
    <FilterRow>
      <Input name="search" placeholder="Search..." block margin="xsmall" />
    </FilterRow>
  </FilterBlock>
)
