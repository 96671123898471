import React from 'react'
import { orderBy } from 'lodash'
import { sortedUniqBy } from 'lodash/fp'
import { useSelector } from 'react-redux'
import { FormattedTime } from 'react-intl'
import { Page } from '../../components/app'
import { Table, TH, TD, TR, Filter, Pagination } from '../../components/tables'
import {
  FormattedCoordinate,
  FormattedMinutes,
  FormattedOrderStatus,
  FormattedWeight,
} from '../../components/formatters'
import { selectAllFlightsByCity } from '../../containers/simulation/schedule/scheduleSelectors'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'
import { usePagination } from '../../containers/router'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

const uniqById = sortedUniqBy(x => x.orderId)

export const CustomerOrders = ({ match, history }) => {
  const city = getCitySlug(match)
  const { page, limit } = usePagination(12)
  const { name } = useSelector(selectCitySpec(city))
  const flights = useSelector(selectAllFlightsByCity(city))
  const orderedFlights = uniqById(orderBy(flights, ['orderId'], ['desc']))
  return (
    <Page heading={`${name} - Customer Orders`}>
      <Filter inline />
      <Table
        head={
          <TR>
            <TH>Order ID</TH>
            <TH>Time</TH>
            <TH width="200">Suburb</TH>
            <TH>Coordinates</TH>
            <TH>Weight</TH>
            <TH>Time out</TH>
            <TH>Duration</TH>
            <TH center>Drone ID</TH>
            <TH center>Hub</TH>
            <TH>Status</TH>
          </TR>
        }
        page={page}
        limit={limit}
        items={orderedFlights}
        emptyMsg="No customer orders"
      >
        {({ order, ...flight }) => (
          <TR key={flight.orderId} onClick={() => history.push(`${match.url}/${flight.orderId}`)}>
            <TD>{flight.orderId}</TD>
            <TD>
              <FormattedTime value={order.date} hour="numeric" minute="numeric" second="numeric" />
            </TD>
            <TD>{order.destination.suburb}</TD>
            <TD>
              <FormattedCoordinate value={order.destination.location} />
            </TD>
            <TD>
              <FormattedWeight value={order.payload.weight} />
            </TD>
            <TD>
              <FormattedTime
                value={flight.schedule.departedAt ?? flight.schedule.scheduledDeparture}
              />
            </TD>
            <TD>
              <FormattedMinutes value={flight.flightPath.duration} />
            </TD>
            <TD center>{flight.droneId}</TD>
            <TD center>{flight.flightPath.hubId}</TD>
            <TD>
              <FormattedOrderStatus value={flight.schedule.status} />
            </TD>
          </TR>
        )}
      </Table>
      <Pagination total={orderedFlights.length} limit={limit} />
    </Page>
  )
}
