import React from 'react'
import styled from 'styled-components'
import { Field, useFormState } from 'react-final-form'
import { TD, TR } from '../../../components/tables'
import { Button } from '../../../components/controls'
import { Input, required } from '../../../components/input'

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const CityNoFlyZoneForm = () => {
  const { submitting } = useFormState()
  return (
    <TR>
      <TD>
        <Field
          name="name"
          placeholder="Zone description"
          validate={required}
          component={Input}
          inline
        />
      </TD>
      <TD>
        <FlexRow>
          <Field
            name="latitude"
            placeholder="Latitude"
            validate={required}
            component={Input}
            inline
          />
          <Field
            name="longitude"
            placeholder="Longitude"
            validate={required}
            component={Input}
            inline
          />
        </FlexRow>
      </TD>
      <TD>
        <FlexRow>
          <Field
            name="radius"
            placeholder="Radius (m)"
            validate={required}
            component={Input}
            inline
          />
          <Button type="submit" disabled={submitting} inline width="45%">
            Add
          </Button>
        </FlexRow>
      </TD>
    </TR>
  )
}
