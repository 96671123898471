import React from 'react'
import { Field, useFormState } from 'react-final-form'
import { Input, required, SelectInput } from '../../../components/input'
import { useSelector } from 'react-redux'
import { selectDroneSpecs } from '../../../containers/simulation/drones/dronesReducer'
import { DD, DL, DT } from '../../../components/tables'
import { FormattedDistance, FormattedWeight } from '../../../components/formatters'

export const DroneTypeForm = () => {
  const { values } = useFormState()
  const droneOptions = useSelector(selectDroneSpecs())
  const activeDroneId = values.droneSpec
  const activeDrone = droneOptions.find(d => d.id === activeDroneId)
  return (
    <>
      <Field name="droneSpec" validate={required} component={Input} as={SelectInput}>
        <option>Choose a drone type…</option>
        {droneOptions.map(({ id, model }) => (
          <option key={id} value={id}>
            {model}
          </option>
        ))}
      </Field>
      {activeDrone && (
        <DL isHorizontal dtPercentage={45}>
          <DT>Top speed</DT>
          <DD>{(activeDrone.topSpeed * 2.237).toFixed(1)} mph</DD>
          <DT>Range</DT>
          <DD>
            <FormattedDistance value={activeDrone.range} />
          </DD>
          <DT>Radius</DT>
          <DD>
            <FormattedDistance value={activeDrone.radius} />
          </DD>
          <DT>Max payload</DT>
          <DD>
            <FormattedWeight value={activeDrone.maxWeight} precision={1} />
          </DD>
        </DL>
      )}
    </>
  )
}
