import React from 'react'
import styled from 'styled-components'

const Addon = styled.div`
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;
`

const Icon = styled.i`
  color: ${props => props.theme.colors.light5};
  cursor: pointer;
`

export const AddonIcon = ({ className }) => (
  <Addon>
    <Icon className={className} />
  </Addon>
)
