import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { Tooltip } from './Tooltip/Tooltip'
import { PinMarker } from '../../map'

export const DestinationMarker = ({ orderId, isChosen, ...props }) => {
  const theme = useTheme()
  const [hover, setHover] = useState(false)
  return (
    <PinMarker
      {...props}
      color={theme.colors.destinationMarker}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {(isChosen || hover) && <Tooltip bgColor="destinationMarker">{orderId}</Tooltip>}
    </PinMarker>
  )
}
