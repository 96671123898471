import React from 'react'
import { useTheme } from 'styled-components'
import { MapCircle, PinMarker } from '../../map'
import { useFlightMapStyles } from './styles'

export const HubSetupMarker = ({ center, radius, enabled = true }) => {
  const { hubSetupCircleStyle } = useFlightMapStyles()
  const theme = useTheme()
  return (
    <>
      {enabled && <MapCircle center={center} radius={radius} style={hubSetupCircleStyle} />}
      <PinMarker color={theme.colors[enabled ? 'hubMarker' : 'dark3']} />
    </>
  )
}
