import {
  computeDestinationPoint,
  getDistance,
  getRhumbLineBearing,
  isPointWithinRadius,
} from 'geolib'
import { byShortestDistance } from '../helpers/sort'

const DISTANCE_INTERVAL = 100 // meters
const DISTANCE_FLY_AROUND = 3000 // meters
const BEARING_OFFSET = 5

// Check the path between two points at fixed intervals, and if the path intercepts
// a no-fly zone return the point where this occurs with the distance.
const findNoFlyZoneIntercept = (noFlyZone, origin, destination, intervals = DISTANCE_INTERVAL) => {
  const { center, radius } = noFlyZone
  const bearing = getRhumbLineBearing(origin, destination)
  let point = origin
  while (getDistance(point, destination) > intervals) {
    point = computeDestinationPoint(point, intervals, bearing)
    if (isPointWithinRadius(point, center, radius)) {
      return { point, noFlyZone, distance: getDistance(origin, point) }
    }
  }
}

const bearingWithOffset = (bearing, offset) => (360 + bearing + offset) % 360

const findPointOutsideNoFlyZone = ({ point, noFlyZone }, origin) => {
  const { center, radius } = noFlyZone
  const distance = DISTANCE_FLY_AROUND
  const bearing = getRhumbLineBearing(origin, point)
  let offset = 1
  do {
    const pointCW = computeDestinationPoint(point, distance, bearingWithOffset(bearing, offset))
    if (!isPointWithinRadius(pointCW, center, radius)) {
      return computeDestinationPoint(
        point,
        distance,
        bearingWithOffset(bearing, offset + BEARING_OFFSET),
      )
    }
    const pointCCW = computeDestinationPoint(point, distance, bearingWithOffset(bearing, -offset))
    if (!isPointWithinRadius(pointCCW, center, radius)) {
      return computeDestinationPoint(
        point,
        distance,
        bearingWithOffset(bearing, -offset - BEARING_OFFSET),
      )
    }
  } while (++offset < 180)
}

const calculateNextLeg = (noFlyZones, origin, destination) => {
  const noFlyIntercept = noFlyZones
    .map(noFlyZone => findNoFlyZoneIntercept(noFlyZone, origin, destination))
    .sort(byShortestDistance)
    .shift()

  return noFlyIntercept ? findPointOutsideNoFlyZone(noFlyIntercept, origin) : destination
}

const calculateOneWayPath = (noFlyZones, altitude, origin, destination) => {
  const flightPath = [{ ...origin, altitude }]
  let nextPoint = origin
  do {
    nextPoint = calculateNextLeg(noFlyZones, nextPoint, destination)
    flightPath.push({ ...nextPoint, altitude })
  } while (nextPoint !== destination)
  return flightPath
}

export const calculateFlightPathWayPoints = (
  { flightFloor, flightCeiling, noFlyZones },
  origin,
  destination,
) => {
  const noFlyZoneList = Object.values(noFlyZones)
  const altitude = flightFloor + Math.floor((flightCeiling - flightFloor) * Math.random())
  const pathOut = calculateOneWayPath(noFlyZoneList, altitude, origin, destination)
  return {
    wayPointsOut: [...pathOut, { ...destination, altitude: 0 }],
    wayPointsIn: [...pathOut.reverse(), { ...origin, altitude: 0 }],
  }
}
