import React from 'react'
import { useSelector } from 'react-redux'
import { Page } from '../../components/app'
import { ButtonBlockNavList } from '../../components/nav'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

export const LocationHome = ({ navLinks, match }) => {
  const location = getCitySlug(match)
  const { name } = useSelector(selectCitySpec(location))
  return (
    <Page heading={name}>
      <ButtonBlockNavList items={navLinks} />
    </Page>
  )
}
