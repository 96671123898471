import React from 'react'
import styled from 'styled-components'
import { Svg } from '../../images'
import { MapMarker } from './MapMarker'

const OffsetMarker = styled(MapMarker)`
  top: -${props => props.height}px;
`

export const PinMarker = ({ color = 'black', size = 22, children, ...props }) => (
  <OffsetMarker {...props} height={size} width={size}>
    <PinMarkerSvg color={color} size={size} />
    {children}
  </OffsetMarker>
)

export const PinMarkerSvg = ({ size, color }) => (
  <Svg viewBox="0 0 384 512" height={size} width={size}>
    <path
      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"
      fill={color}
    />
  </Svg>
)
