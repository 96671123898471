import React from 'react'
import styled from 'styled-components'
import { PageContainer, PageContent } from '../../../components/app'
import { Image } from '../../../components/images'
import logo from '../../../components/app/logo.svg'
import { BackgroundImage } from '../../../components/app/Page'

const Section = styled.div`
  background-color: ${props => props.theme.colors.contentBackground};
  margin-top: -${props => props.theme.spacing.xlarge * 1.5}px;
  padding: ${props => props.theme.spacing.large}px;
  border-radius: 10px;
  max-width: 400px;
`

const Logo = styled(Image)`
  margin-bottom: ${props => props.theme.spacing.medium}px;
`

export const AuthPage = ({ children }) => {
  return (
    <>
      <BackgroundImage src="/images/blurred-city.jpg" />
      <PageContainer>
        <PageContent justify="center" align="center">
          <Section>
            <Logo src={logo} width="100%" alt="Parcel Fly" />
            {children}
          </Section>
        </PageContent>
      </PageContainer>
    </>
  )
}
