import { subDays } from 'date-fns'
import { times, random, shuffle } from 'lodash/fp'
import { newDroneId } from '../../../containers/simulation/drones/generateDrone'

const makeDroneMaintenance = times(() => ({
  id: newDroneId(),
  serviceDate: subDays(new Date(), random(90, 180)),
  flightTimeHrs: random(50, 599),
  batteryCycle: random(20, 89),
  status: random(1, 2),
}))

export const drones = shuffle(makeDroneMaintenance(230))
