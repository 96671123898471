import React, { useState } from 'react'
import { sumBy } from 'lodash'
import styled from 'styled-components'
import { FormattedNumber } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Page } from '../../components/app'
import { DD, DL, DT, Table, TD, TH, TR } from '../../components/tables'
import { Heading } from '../../components/typography'
import { HubSetupMap } from '../../components/flightMap/HubSetupMap'
import { Switch } from '../../components/input/Switch'
import { HubLocationForm } from './forms/HubLocationForm'
import { FormattedCoordinate } from '../../components/formatters'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'
import {
  addLocationHub,
  selectHubsByCity,
  toggleLocationHub,
} from '../../containers/locations/hubSetup'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

const SQ_FT_PER_GATE = 150
const DRONES_PER_GATE = 2.5

const calcNumGates = area => Math.floor(area / SQ_FT_PER_GATE)

const mapSize = { width: 750, height: 600 }

const ContentRow = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.medium}px;
`

const ContentCol = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoCol = styled.div`
  flex-grow: 1;
  margin-left: ${props => props.theme.spacing.medium}px;
`

const CoverageInfo = ({ hubs }) => {
  const enabledHubs = hubs.filter(hub => hub.enabled)
  const numGates = calcNumGates(sumBy(enabledHubs, hub => hub.area))
  return (
    <>
      <Heading margin="xsmall">Coverage Info</Heading>
      <DL margin="large" isHorizontal dtPercentage={45}>
        <DT>Hubs</DT>
        <DD>{enabledHubs.length}</DD>
        <DT>Gates</DT>
        <DD>
          <FormattedNumber value={numGates} />
        </DD>
        <DT>Drone Capacity</DT>
        <DD>{Math.round(numGates * DRONES_PER_GATE)}</DD>
      </DL>
    </>
  )
}

export const HubSetup = ({ match }) => {
  const location = getCitySlug(match)
  const dispatch = useDispatch()
  const citySpec = useSelector(selectCitySpec(location))
  const hubLocations = useSelector(selectHubsByCity(location))
  const [maps, setMaps] = useState(null)
  return (
    <Page heading={`${citySpec.name} - Hub Setup`}>
      <ContentRow>
        <HubSetupMap
          size={mapSize}
          center={citySpec.center}
          hubs={hubLocations}
          noFlyZones={citySpec.noFlyZones}
          setMaps={setMaps}
        />

        <InfoCol>
          <CoverageInfo hubs={hubLocations} />
          <HubLocationForm onAddHub={addLocationHub(maps, location)} />
        </InfoCol>
      </ContentRow>

      <ContentCol>
        <Table
          head={
            <TR>
              <TH>Street Address</TH>
              <TH>Coordinates</TH>
              <TH>Landing Strip Area</TH>
              <TH>Gates</TH>
              <TH>Enabled</TH>
            </TR>
          }
          items={hubLocations}
          emptyMsg="No potential hub locations have been added"
        >
          {hub => (
            <TR key={hub.id}>
              <TD>
                {hub.streetNumber} {hub.route}, {hub.locality}, {hub.postalCode}
              </TD>
              <TD>
                <FormattedCoordinate value={hub.center} />
              </TD>
              <TD>
                <FormattedNumber value={hub.area} /> sq. ft.
              </TD>
              <TD>
                <FormattedNumber value={calcNumGates(hub.area)} />
              </TD>
              <TD>
                <Switch
                  checked={hub.enabled}
                  onChange={() => dispatch(toggleLocationHub(location, hub.id))}
                />
              </TD>
            </TR>
          )}
        </Table>
      </ContentCol>
    </Page>
  )
}
