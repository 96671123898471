import styled, { css } from 'styled-components'

const dataCSS = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${props => props.theme.spacing.small * (props.compact ? 0.6 : 0.6)}px 0;
  border-bottom: 1px solid ${props => (props.compact ? 'transparent' : props.theme.colors.light4)};
`

export const DT = styled.dt`
  ${dataCSS};
  padding-left: ${props => props.theme.spacing.xsmall}px;
  padding-right: ${props => props.theme.spacing.xsmall}px;
`

export const DD = styled.dd`
  ${dataCSS};
  padding-right: ${props => props.theme.spacing.xsmall}px;
`

export const DL = styled.dl`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${props => (props.isHorizontal ? 'row' : 'column')};
  margin-bottom: ${props => props.theme.spacing[props.margin || 'smedium']}px;
  background-color: ${props => props.theme.colors.contentBackground};
  border: 1px solid ${props => (props.compact ? 'transparent' : props.theme.colors.light4)};
  border-bottom: none;
  border-radius: 5px;

  ${DT} {
    flex: 1 0 ${props => props.dtPercentage || 35}%;
  }

  ${DD} {
    flex: 1 0 ${props => 100 - (props.dtPercentage || 35)}%;
  }
`
