import React, { useContext, useState } from 'react'

/**
 * Delivery spot item:
 * {
 *   name: 'Front lawn, 47 Chichester',
 *   latitude: '51° 11\' 24.5" N',
 *   longitude: '0° 15\' 52.3" E',
 * },
 */

const AirDeliverySpots = React.createContext({})

const useAirDeliverySpotsState = () => {
  const [deliverySpots, setSpots] = useState([])

  const addDeliverySpot = async spot => {
    setSpots([spot, ...deliverySpots])
  }

  const removeDeliverySpot = index =>
    setSpots([...deliverySpots.slice(0, index), ...deliverySpots.slice(index + 1)])

  return { deliverySpots, addDeliverySpot, removeDeliverySpot }
}

export const useAirDeliverySpots = () => useContext(AirDeliverySpots)

export const AirDeliverySpotsProvider = ({ children }) => {
  const value = useAirDeliverySpotsState()
  return <AirDeliverySpots.Provider value={value}>{children}</AirDeliverySpots.Provider>
}
