const baseSpacing = 28

export const theme = {
  colors: {
    primary: '#e22b44',
    primaryLight: '#ff584b',

    destinationMarker: '#e40d12aa',
    droneMarker: '#ff00e7',
    hubMarker: '#34bfef',
    hubMarkerInactive: '#55555599',
    noFlyZones: '#ffb288',
    flightPath: '#000000',

    ok: '#43a047',
    cancelled: '#c62828',
    onHold: '#c6671d',
    rescheduled: '#FFAA15',

    black: '#000000',
    dark1: '#333333',
    dark2: '#484848',
    dark3: '#777777',
    dark4: '#999999',
    dark5: '#b6b6b6',

    white: '#FFFFFF',
    light1: '#F7F7F7',
    light2: '#F2F2F2',
    light3: '#E1E1E1',
    light4: '#DADADA',
    light5: '#C7C7C7',

    error: '#c62828',
    warning: '#FFAA15',
    disabled: '#CCCCCC',

    placeholder: '#AAAAAA',

    background: '#EEEEEE',
    contentBackground: '#FDFDFD',

    foreground: '#333333',
  },

  spacing: {
    none: 0,
    hair: 1, // 1
    xxsmall: baseSpacing / 8, // 3
    xsmall: baseSpacing / 4, // 6
    small: baseSpacing / 2, // 12
    smedium: baseSpacing * 0.75, // 18
    medium: baseSpacing, // 24
    large: baseSpacing * 1.5, // 36
    xlarge: baseSpacing * 2, // 48
  },

  fontSizes: {
    xsmall: 12,
    small: 13,
    medium: 14,
    large: 16,
    xlarge: 19,
    xxlarge: 28,
  },

  fontFamily: 'Poppins, Helvetica, sans-serif',

  lineHeight: 1.3,
  paragraphLineHeight: 1.4,
}
