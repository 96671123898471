import React from 'react'
import { useTheme } from 'styled-components'
import { MapPolyline } from '../../map'
import { useFlightMapStyles } from './styles'

const icons = maps => [{ icon: { path: maps.SymbolPath.FORWARD_CLOSED_ARROW }, offset: '55%' }]

export const FlightPath = ({ path }) => {
  const { flightPathStyle } = useFlightMapStyles()
  return <MapPolyline path={path} icons={icons} style={flightPathStyle} />
}

export const MainFlightPath = ({ path }) => {
  const theme = useTheme()
  return <MapPolyline path={path} icons={icons} style={{ strokeColor: theme.colors.black }} />
}

export const AltFlightPath = ({ path }) => {
  const theme = useTheme()
  return <MapPolyline path={path} icons={icons} style={{ strokeColor: theme.colors.dark3 }} />
}
