import React from 'react'
import styled from 'styled-components'
import { Container as SBGContainer } from 'styled-bootstrap-grid'
import { Header } from './Header'
import { Footer } from './Footer'
import { Simulation } from '../simulation/Simulation'

export const BackgroundImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
`

export const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

export const PageContent = styled.div`
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
`

export const Container = styled(SBGContainer)`
  position: relative;
`

const PageHeading = styled.h1`
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.xxlarge * 0.95}px;
  margin-bottom: ${props => props.theme.spacing[props.margin || 'small']}px;
  color: ${props => (props.invertedColor ? props.theme.colors.white : undefined)};
`

export const Page = ({
  heading,
  fluid,
  justify = 'flex-start',
  align = 'flex-start',
  backgroundImage,
  children,
}) => (
  <>
    {backgroundImage && <BackgroundImage src={backgroundImage} />}
    <PageContainer>
      <Header />
      <PageContent justify={justify} align={align}>
        <Container fluid={fluid}>
          {heading && <PageHeading invertedColor={!!backgroundImage}>{heading}</PageHeading>}
          {children}
          <Simulation />
        </Container>
      </PageContent>
      <Footer />
    </PageContainer>
  </>
)
