import { useEffect, useRef, useState } from 'react'
import { getDistance } from 'geolib'
import pick from 'lodash/fp/pick'

const pickLatLng = pick(['latitude', 'longitude'])

const ACCURACY_DELAY = 5000
const ACCURACY_STEPS = 10
export const ACCURACY_INTERVAL = ACCURACY_DELAY / ACCURACY_STEPS

// Returns the current GeoLocationPosition
// https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPosition
export const useGeoPosition = () => {
  const [coords, setCoords] = useState({})
  const prevCoords = useRef(coords)

  useEffect(() => {
    const watcher = navigator.geolocation.watchPosition(
      ({ coords }) => setCoords(coords),
      undefined,
      {
        enableHighAccuracy: true,
        maximumAge: 0,
      },
    )

    return () => {
      navigator.geolocation.clearWatch(watcher)
    }
  }, [])

  const [accuracyMultiplier, setAccuracy] = useState(1)

  useEffect(() => {
    const distance = getDistance(pickLatLng(coords), pickLatLng(prevCoords.current))
    prevCoords.current = coords

    const timeout = setTimeout(() => {
      const newMultiplier = accuracyMultiplier - 1 / ACCURACY_STEPS
      setAccuracy(newMultiplier < 0 ? 0 : newMultiplier)
    }, ACCURACY_INTERVAL)

    if (distance > 1) {
      clearTimeout(timeout)
      setAccuracy(1)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [coords, accuracyMultiplier])

  return { coords, accuracyMultiplier }
}
