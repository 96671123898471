import React from 'react'
import delay from 'delay'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { Button } from '../../../components/controls'
import { Heading } from '../../../components/typography'
import { Input, required } from '../../../components/input'

const ColForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacing.large}px;
`

export const HubLocationForm = ({ onAddHub }) => {
  const dispatch = useDispatch()
  return (
    <Form
      subscription={{ submitting: true }}
      onSubmit={async values => {
        await delay(700)
        dispatch(onAddHub(values))
      }}
    >
      {({ handleSubmit, form }) => (
        <ColForm
          onSubmit={async event => {
            await handleSubmit(event)
            form.getState().valid && form.reset()
          }}
        >
          <Heading>Add a Hub Location</Heading>
          <Field
            name="address"
            placeholder="Street Address"
            validate={required}
            component={Input}
          />
          <Field
            name="area"
            placeholder="Landing Strip Area (sq. ft.)"
            validate={required}
            component={Input}
          />
          <Button type="submit" block={false} align="flex-end">
            Add Hub
          </Button>
        </ColForm>
      )}
    </Form>
  )
}
