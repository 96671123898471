import React from 'react'
import styled from 'styled-components'
import { navLink, navLinkActive } from '../app/Header'

const List = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.light5};
  margin-bottom: ${props => props.theme.spacing.smedium}px;
`

export const Tab = styled.div`
  cursor: pointer;
  ${navLink};
  ${props => props.isActive && navLinkActive};
  &:first-child {
    margin-left: 2px;
  }
`

export const TabsList = ({ items, active, onClick }) => (
  <List>
    {items.map(({ label, value }) => (
      <Tab key={value} isActive={value === active} onClick={() => onClick(value)}>
        {label}
      </Tab>
    ))}
  </List>
)
