import React from 'react'
import { orderBy } from 'lodash'
import { useSelector } from 'react-redux'
import { FormattedTime } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'styled-bootstrap-grid'
import { Page } from '../../components/app'
import { ExportReportLink } from '../../components/misc/ExportReportLink'
import { Filter, Pagination, Table, TD, TH, TR } from '../../components/tables'
import {
  FormattedCoordinate,
  FormattedMinutes,
  FormattedOrderStatus,
} from '../../components/formatters'
import { SummaryBlock, SummaryBlockRow } from '../../components/misc/SummaryBlock'
import { selectAllFlightsByCity } from '../../containers/simulation/schedule/scheduleSelectors'
import { usePagination } from '../../containers/router'
import { Heading } from '../../components/typography'

const summaryDefault = {
  sent: '6,306',
  delivered: '6,179',
  rate: '98%',
}

export const ExecutiveReports = ({ pageHeading, city = 'london', summary = summaryDefault }) => {
  const history = useHistory()
  const { page, limit } = usePagination(8)
  const flights = useSelector(selectAllFlightsByCity(city))
  const orderByKey = 'order.date'
  const orderedFlights = orderBy(flights, [orderByKey], ['desc'])
  return (
    <Page heading={pageHeading || 'Executive Reports'}>
      <ExportReportLink />

      <Row>
        <Col sm={8}>
          <SummaryBlockRow>
            <SummaryBlock label="Sent" value={summary.sent} />
            <SummaryBlock label="Delivered" value={summary.delivered} />
            <SummaryBlock label="Delivery Rate" value={summary.rate} color="ok" />
          </SummaryBlockRow>
        </Col>
        <Col sm={3} smOffset={1}>
          <Heading as="h3" size="large" margin="xsmall">
            Filter
          </Heading>
          <Filter />
        </Col>
      </Row>

      <Table
        head={
          <TR>
            <TH>Order ID</TH>
            <TH>Destination</TH>
            <TH>Location</TH>
            <TH>Drone ID</TH>
            <TH>Dispatched</TH>
            <TH>Delivered</TH>
            <TH>Returned</TH>
            <TH>Flight Time</TH>
            <TH>Status</TH>
          </TR>
        }
        page={page}
        limit={limit}
        items={orderedFlights}
        emptyMsg=""
      >
        {({ order, ...flight }) => (
          <TR
            key={order.id}
            onClick={() => history.push(`/cities/${city}/orders/${flight.orderId}`)}
          >
            <TD>{order.id}</TD>
            <TD>
              <FormattedCoordinate value={order.destination.location} />
            </TD>
            <TD>{order.destination.suburb}</TD>
            <TD>{flight.droneId}</TD>
            <TD>
              <FormattedTime value={flight.departedAt ?? flight.scheduledDeparture} />
            </TD>
            <TD>
              <FormattedTime value={flight.departedAt ?? flight.scheduledDeparture} />
            </TD>
            <TD>
              <FormattedTime value={flight.departedAt ?? flight.scheduledDeparture} />
            </TD>
            <TD>
              <FormattedMinutes value={flight.flightPath.duration} />
            </TD>
            <TD>
              <FormattedOrderStatus value={flight.schedule.status} />
            </TD>
          </TR>
        )}
      </Table>
      <Pagination total={orderedFlights.length} limit={limit} />
    </Page>
  )
}
