import React from 'react'
import { useSelector } from 'react-redux'
import { Heading } from '../../components/typography'
import { DD, DL, DT } from '../../components/tables'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'

export const WeatherInfo = ({ city }) => {
  const { weather } = useSelector(selectCitySpec(city))
  return (
    <>
      <Heading margin="xsmall">Weather</Heading>
      <DL isHorizontal dtPercentage={48}>
        <DT>Temperature</DT>
        <DD>{weather.temperature} &deg;C</DD>
        <DT>Precipitation</DT>
        <DD>{weather.precipitation}%</DD>
        <DT>Humidity</DT>
        <DD>{weather.humidity}%</DD>
        <DT>Wind</DT>
        <DD>{weather.wind} mph</DD>
      </DL>
    </>
  )
}
