import produce from 'immer'
import { update, mapValues } from 'lodash'
import * as citySpecs from '../simulation/cities/citySpecs'
import { googleMapsJs } from '../../services/googleMapsJs'
import { CITY_INVENTORY_SUBMIT } from '../../pages/cities/reducers/importInventory'

let lastId = 104

const newLocationHubId = () => (++lastId).toString()

const LOCATION_HUB_ADD = 'LOCATION_HUB_ADD'
const LOCATION_HUB_TOGGLE = 'LOCATION_HUB_TOGGLE'

const initialState = {
  byCity: mapValues(citySpecs, citySpec => ({
    droneSpec: citySpec.droneSpec,
    hubs: mapValues(citySpec.hubs, hub => ({
      ...hub,
      droneCounts: {
        ...hub.droneCounts,
        ordered: 0,
      },
      enabled: true,
    })),
  })),
}

export const hubSetupReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOCATION_HUB_ADD: {
        const { city, hub } = action.payload
        draft.byCity[city].hubs = {
          [hub.id]: {
            ...hub,
            radius: draft.byCity[city].droneSpec.radius,
            enabled: true,
            droneCounts: {
              active: 0,
              reserve: 0,
              ordered: 0,
            },
          },
          ...draft.byCity[city].hubs,
        }
        break
      }

      case LOCATION_HUB_TOGGLE: {
        const { city, hubId } = action.payload
        update(draft.byCity, [city, 'hubs', hubId, 'enabled'], enabled => !enabled)
        break
      }

      case CITY_INVENTORY_SUBMIT: {
        const { city, ordersByCity } = action.payload
        const hubs = draft.byCity[city].hubs
        Object.values(ordersByCity).forEach(order => {
          order.forEach((drones, hubIdx) => {
            hubs[hubIdx].droneCounts.ordered += drones ?? 0
          })
        })
        break
      }

      // no default
    }
  })

const getCity = (hubSetup, city) => hubSetup.byCity[city]

export const selectHubsByCity = city => ({ hubSetup }) =>
  Object.values(getCity(hubSetup, city).hubs)

export const selectIndexedHubsByCity = city => ({ hubSetup }) => getCity(hubSetup, city).hubs

export const addLocationHub = (maps, city) => ({ address, area }) => dispatch => {
  googleMapsJs
    .geocode(maps, address)
    .then(geoAddress => {
      dispatch({
        type: LOCATION_HUB_ADD,
        payload: {
          city,
          hub: {
            id: newLocationHubId(),
            streetNumber: geoAddress.streetNumber,
            route: geoAddress.route,
            locality: geoAddress.locality,
            postalCode: geoAddress.postalCode,
            formattedAddress: geoAddress.formattedAddress,
            center: {
              latitude: geoAddress.lat,
              longitude: geoAddress.lng,
            },
            area: Number(area),
            droneCounts: {
              active: 0,
              reserve: 0,
              ordered: 0,
            },
          },
        },
      })
    })
    .catch(error => window.alert(error.message))
}

export const toggleLocationHub = (city, hubId) => ({
  type: LOCATION_HUB_TOGGLE,
  payload: { city, hubId },
})
