import React, { useState } from 'react'
import styled from 'styled-components'
import GoogleMap from 'google-map-react'
import { config } from '../../config'
import { MapContext } from './MapContext'

export const mapCoordinate = location => ({
  lat: location.latitude ?? location.lat,
  lng: location.longitude ?? location.lng,
})

const MapWrapper = styled.div`
  flex: 0 0 auto;
  width: ${props => props.width}px;
  height: ${props => props.height || props.width}px;
  border: 1px solid ${props => props.theme.colors.dark4};
  overflow: hidden;
`

const mapDefaults = {
  defaultCenter: {
    latitude: 51.5074,
    longitude: -0.1278,
  },
  defaultZoom: 10,
}

export const Map = ({ width, height, onGoogleApiLoaded, children, ...props }) => {
  const [mapInstance, setMapInstance] = useState({})
  return (
    <MapWrapper width={width} height={height}>
      <MapContext.Provider value={mapInstance}>
        <GoogleMap
          {...mapDefaults}
          {...props}
          options={getMapOptions}
          bootstrapURLKeys={config.googleMaps}
          onGoogleApiLoaded={mapsApi => {
            setMapInstance(mapsApi)
            onGoogleApiLoaded && onGoogleApiLoaded(mapsApi)
          }}
          yesIWantToUseGoogleMapApiInternals
        >
          {children}
        </GoogleMap>
      </MapContext.Provider>
    </MapWrapper>
  )
}

const getMapOptions = () => ({
  streetViewControl: false,
  fullscreenControl: false,
  scaleControl: true,
  // draggable: false,
  // todo specify bounds if possible
  zoomControl: true,
  clickableIcons: false,
  disableDoubleClickZoom: true,
  mapTypeControl: false,
  maxZoom: 20,
  minZoom: 9,
})
