import styled from 'styled-components'
import { Button } from './Button'

export const ButtonHead = styled(Button).attrs({
  block: false,
})`
  position: absolute;
  top: 0;
  right: 15px;
`
