import { createGlobalStyle } from 'styled-components'

export const MobileGlobalStyle = createGlobalStyle`
  body {
    background: ${props => props.theme.colors.background};
  
    &, h1, h2, h3, h4, h5, h6, p {
      font-weight: 400;
      font-size: ${props => props.theme.fontSizes.medium}px;
      font-family: ${props => props.theme.fontFamily};
      line-height: ${props => props.theme.lineHeight};
      color: ${props => props.theme.colors.foreground};
      margin: 0;
    }

    p {
      line-height: ${props => props.theme.paragraphLineHeight};
      margin-bottom: ${props => props.theme.spacing.xsmall}px;
    }
    
    a {
      color: ${props => props.theme.colors.accent};
      text-decoration: none;
    }
  }
`
