import React from 'react'
import styled, { css } from 'styled-components'

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: ${props => props.theme.fontSizes.medium}px;
  margin-bottom: ${props => props.theme.spacing.small}px;
  background-color: ${props => props.theme.colors.contentBackground};

  td,
  th {
    padding: ${props => props.theme.spacing.small * 0.8}px;
    text-align: left;
  }

  thead th {
    background-color: ${props => props.theme.colors.light5};
    font-size: ${props => props.theme.fontSizes.large}px;
    font-weight: 600;
    &:last-child {
      border-right: 1px solid ${props => props.theme.colors.light5};
    }
  }

  tbody td {
    border-left: 1px solid ${props => props.theme.colors.light5};
    border-right: 1px solid ${props => props.theme.colors.light5};
  }

  tbody tr:last-child td {
    border-bottom: 1px solid ${props => props.theme.colors.light5};
  }

  tbody tr:nth-of-type(even) {
    background-color: ${props => props.theme.colors.light2};
  }
`

const EmptyTR = styled.tr`
  background-color: ${props => props.theme.colors.background} !important;
  td {
    padding: ${props => props.theme.spacing.xlarge}px;
    font-size: ${props => props.theme.fontSizes.large}px;
    text-align: center;
  }
`

export const TR = styled.tr`
  ${props => props.onClick && 'cursor: pointer;'};
`

const alignment = css`
  ${props => props.center && 'text-align: center;'};
  ${props => props.right && 'text-align: right;'};
  ${props => props.vAlign && `vertical-align: ${props.vAlign};`};
`

export const TH = styled.th`
  ${StyledTable} & {
    ${alignment}
  }
`
export const TD = styled.td`
  ${StyledTable} & {
    ${alignment}
  }
`

export const Table = ({
  head,
  firstRow,
  footer,
  items,
  page = 1,
  limit,
  emptyMsg,
  children,
  ...props
}) => {
  const pageItems = !limit ? items : items.slice((page - 1) * limit, page * limit)
  return (
    <StyledTable {...props}>
      {head && (
        <thead>
          {!Array.isArray(head) ? (
            head
          ) : (
            <TR>
              {head.map((heading, i) => (
                <th key={i}>{heading}</th>
              ))}
            </TR>
          )}
        </thead>
      )}
      <tbody>
        {firstRow}
        {typeof children !== 'function' ? (
          children
        ) : (
          <>
            {pageItems.map(children)}
            {!pageItems.length && (
              <EmptyTR>
                <td colSpan={100}>{emptyMsg}</td>
              </EmptyTR>
            )}
          </>
        )}
        {footer}
      </tbody>
    </StyledTable>
  )
}
