import { useMemo } from 'react'
import { darken } from 'polished'
import { useTheme } from 'styled-components'

export const useFlightMapStyles = () => {
  const theme = useTheme()
  return useMemo(
    () => ({
      flightPathStyle: {
        strokeWeight: 1,
        strokeColor: theme.colors.flightPath,
      },
      hubCircleStyle: {
        strokeColor: darken(0.2, theme.colors.hubMarker),
        fillOpacity: 0,
      },
      hubDisabledCircleStyle: {
        strokeColor: darken(0.2, theme.colors.hubMarkerInactive),
        fillOpacity: 0,
      },
      hubSetupCircleStyle: {
        strokeColor: darken(0.2, theme.colors.hubMarker),
        fillColor: darken(0.2, theme.colors.hubMarker),
        fillOpacity: 0.2,
      },
      noFlyZonesStyle: {
        strokeColor: theme.colors.noFlyZones,
        fillColor: theme.colors.noFlyZones,
        fillOpacity: 0.35,
      },
    }),
    [theme],
  )
}
