import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Page } from '../../components/app'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'
import { selectAllFlightsByCity } from '../../containers/simulation/schedule/scheduleSelectors'
import { Map, mapCoordinate } from '../../components/map'
import { HubMarker } from '../../components/flightMap/Markers/HubMarker'
import { NoFlyZoneArea } from '../../components/flightMap/Markers/NoFlyZoneArea'
import { DestinationMarker } from '../../components/flightMap/Markers/DestinationMarker'
import { AltFlightPath, MainFlightPath } from '../../components/flightMap/Markers/FlightPath'
import { Heading } from '../../components/typography'
import { DD, DL, DT } from '../../components/tables'
import {
  FormattedCoordinate,
  FormattedMinutes,
  FormattedOrderStatus,
  FormattedWeight,
} from '../../components/formatters'
import { Icon } from '../../components/map-info/DroneInfo'
import { FormattedTime } from 'react-intl'
import { upperFirst } from 'lodash'
import { FlightMapLegend } from '../../components/flightMap/FlightMapLegend'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

const mapSize = { width: 850, height: 600 }

const Content = styled.div`
  display: flex;
`

const InfoCol = styled.div`
  flex-grow: 1;
  margin-left: ${props => props.theme.spacing.medium}px;
`

export const CustomerOrdersMap = ({ match, history }) => {
  const city = getCitySlug(match)
  const { orderId } = match.params
  const citySpec = useSelector(selectCitySpec(city))
  const flights = useSelector(selectAllFlightsByCity(city))
  const flight = flights.find(f => f.orderId === orderId)
  const { order = { flightPaths: [] } } = flight || {}
  const { destination, payload } = order
  const hubs = Object.values(citySpec.hubs)
  const otherPaths = order.flightPaths.filter(path => path.hubId !== flight.flightPath.hubId)
  const departure = flight.flightPath.departedAt || flight.schedule.originalDeparture
  const delivered = flight.flightPath.deliveredAt
  return (
    <Page heading={`${citySpec.name} - Customer Orders`}>
      {!flight ? null : (
        <Content>
          <div>
            <Map {...mapSize} center={mapCoordinate(citySpec.center)}>
              {hubs.map(({ id, center, radius }) => (
                <HubMarker
                  key={id}
                  radius={radius}
                  center={center}
                  {...mapCoordinate(center)}
                  active
                />
              ))}

              <DestinationMarker {...mapCoordinate(destination.location)} orderId={orderId} />
              <MainFlightPath path={flight.flightPath.originalWayPoints.wayPointsOut} />

              {otherPaths.map(path => (
                <AltFlightPath
                  key={path.hubId}
                  path={path.originalWayPoints.wayPointsOut}
                  color="#777777"
                />
              ))}

              {citySpec.noFlyZones.map(({ name, center, radius }) => (
                <NoFlyZoneArea key={name} center={center} radius={radius} />
              ))}
            </Map>
            <FlightMapLegend drone={false} />
          </div>

          <InfoCol>
            <Heading margin="xsmall" onClick={history.goBack}>
              <Icon className="fas fa-arrow-left" /> Order
            </Heading>
            <DL isHorizontal dtPercentage={50}>
              <DT style={{ flexBasis: '100%' }}>
                {destination.streetNumber} {destination.route}
                <br />
                {destination.suburb}
                <br />
                {destination.postalCode}
                <br />
                <FormattedCoordinate value={order.destination.location} sexagesimal />
              </DT>
              <DT>Order No</DT>
              <DD>{order.id}</DD>
              <DT>Payload</DT>
              <DD>
                <FormattedWeight value={payload.weight} />
              </DD>
              <DT>Ordered at</DT>
              <DD>
                <FormattedTime
                  value={order.date}
                  hour="numeric"
                  minute="numeric"
                  second="numeric"
                />
              </DD>
              <DT>{flight.flightPath.status === 'queued' ? 'Scheduled for' : 'Departed at'}</DT>
              <DD>
                <FormattedTime value={departure} hour="numeric" minute="numeric" second="numeric" />
              </DD>
              <DT>Flight duration</DT>
              <DD>
                <FormattedMinutes value={flight.flightPath.duration} seconds />
              </DD>
              <DT>{delivered ? 'Delivered at' : 'Currently'}</DT>
              <DD>
                {delivered ? (
                  <FormattedTime
                    value={flight.schedule.deliveredAt}
                    hour="numeric"
                    minute="numeric"
                    second="numeric"
                  />
                ) : (
                  upperFirst(flight.flightPath.status)
                )}
              </DD>
              <DT>Status</DT>
              <DD>
                <FormattedOrderStatus value={flight.schedule.status} />
              </DD>
              <DT>Hub</DT>
              <DD>{flight.flightPath.hubId}</DD>
              <DT>Drone ID</DT>
              <DD>{flight.droneId}</DD>
            </DL>
          </InfoCol>
        </Content>
      )}
    </Page>
  )
}
