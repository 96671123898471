import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { camelCase } from 'lodash'
import { useSelector } from 'react-redux'
import { Col, Row } from 'styled-bootstrap-grid'
import { Page } from '../../components/app'
import { Pagination, Table, TD, TH, TR } from '../../components/tables'
import { usePagination } from '../../containers/router'
import { Button } from '../../components/controls'
import { Input } from '../../components/input'
import { selectActiveCitiesObject } from '../../containers/simulation/cities/citiesReducer'
import { cities } from './data/cities'

const CheckIcon = styled.i.attrs({
  className: 'fas fa-check',
})`
  color: ${props => props.theme.colors.ok};
`

const ConfigButton = styled(Button)`
  background: ${props => props.theme.colors.dark5};
  color: ${props => props.theme.colors.black};
  text-transform: none;
`

export const AddLocation = ({ history }) => {
  const searchInput = useRef()
  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.focus()
    }
  }, [])
  const activeCities = useSelector(selectActiveCitiesObject())
  const [citySearch, setCitySearch] = useState('')
  const { page, limit } = usePagination(600)
  const filteredCities = cities.filter(
    city => city.lCity.includes(citySearch) || city.lCountry.includes(citySearch),
  )
  return (
    <Page heading="Add a Location">
      <Row>
        <Col sm={9}>
          <Input
            placeholder="Search by city or country..."
            value={citySearch}
            onChange={ev => setCitySearch(ev.target.value.toLowerCase())}
            ref={searchInput}
          />

          <Table
            head={
              <TR>
                <TH center width="60px">
                  Active
                </TH>
                <TH>City</TH>
                <TH>Country</TH>
                <TH width="100px" />
              </TR>
            }
            items={filteredCities}
            page={page}
            limit={limit}
            emptyMsg="No cities match your search"
          >
            {item => (
              <TR key={item.city} onClick={() => history.push(`locations/${camelCase(item.city)}`)}>
                <TD center>{activeCities[item.city] ? <CheckIcon /> : ''}</TD>
                <TD>{item.city}</TD>
                <TD>{item.country}</TD>
                <TD>
                  <ConfigButton small block={false}>
                    {activeCities[item.city] ? 'Reconfigure' : 'Configure'}
                  </ConfigButton>
                </TD>
              </TR>
            )}
          </Table>
          <Pagination total={filteredCities.length} limit={limit} />
        </Col>
      </Row>
    </Page>
  )
}
