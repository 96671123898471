import styled from 'styled-components'

export const Overlay = styled.div`
  background-color: ${props => props.theme.colors.background};
  max-height: ${props => props.maxHeight || '70vh'};
  margin-top: auto;
  overflow-y: auto;
  width: 100%;
`

export const Content = styled.div`
  padding: ${props => props.theme.spacing.small}px;
`

Overlay.Content = Content
