const EventTargetPolyfill = function() {
  this.listeners = {}
}

EventTargetPolyfill.prototype.listeners = null
EventTargetPolyfill.prototype.addEventListener = function(type, callback) {
  if (!(type in this.listeners)) {
    this.listeners[type] = []
  }
  this.listeners[type].push(callback)
}

EventTargetPolyfill.prototype.removeEventListener = function(type, callback) {
  if (!(type in this.listeners)) {
    return
  }
  const stack = this.listeners[type]
  for (let i = 0, l = stack.length; i < l; i++) {
    if (stack[i] === callback) {
      stack.splice(i, 1)
      return
    }
  }
}

EventTargetPolyfill.prototype.dispatchEvent = function(event) {
  if (!(event.type in this.listeners)) {
    return true
  }
  const stack = this.listeners[event.type].slice()

  for (let i = 0, l = stack.length; i < l; i++) {
    stack[i].call(this, event)
  }
  return !event.defaultPrevented
}

export const EventTarget = EventTargetPolyfill
