export const specs = [
  {
    id: '1',
    model: 'DCargo 730',
    topSpeed: 27, // m/s === ~60 mph
    groundSpeed: 26, // m/s === ~60 mph
    verticalSpeed: 22, // m/s
    batteryPerSecond: 100 / ((27000 * 2) / 26 / 0.9),
    range: 55000, // meter
    radius: 27000,
    maxWeight: 3200, // gram
  },
  {
    id: '2',
    model: 'APT 70',
    topSpeed: 33, // m/s === ~70 mph
    groundSpeed: 31, // m/s === ~70 mph
    verticalSpeed: 25, // m/s
    batteryPerSecond: 100 / ((30000 * 2) / 33 / 0.9),
    range: 55000, // meter
    radius: 27000,
    maxWeight: 31000, // gram
  },
  {
    id: '3',
    model: 'AR Pro 420',
    topSpeed: 22, // m/s === ~50 mph
    groundSpeed: 22, // m/s === ~50 mph
    verticalSpeed: 20, // m/s
    batteryPerSecond: 100 / ((30000 * 2) / 22 / 0.9),
    range: 58000, // meter
    radius: 30000,
    maxWeight: 1500, // gram
  },
]
