import React from 'react'
import styled from 'styled-components'
import { useFormState } from 'react-final-form'

const Error = styled.div`
  margin: -${props => props.theme.spacing.xsmall}px 0 ${props => props.theme.spacing.smedium}px;
  color: ${props => props.theme.colors.error};
  text-align: center;
  width: 100%;
`

export const FormError = () => {
  const formState = useFormState()
  return !formState.submitError ? null : <Error>{formState.submitError}</Error>
}
