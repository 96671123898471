import React from 'react'
import { ThemeProvider } from 'styled-components'
import { MobileGlobalStyle } from './MobileGlobalStyle'
import { mobileTheme } from './mobileTheme'

export const MobileThemeProvider = ({ children }) => (
  <ThemeProvider theme={mobileTheme}>
    <MobileGlobalStyle />
    {children}
  </ThemeProvider>
)
