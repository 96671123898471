import React from 'react'
import { Page } from '../components/app'
import { ButtonBlockNavList } from '../components/nav'

export const Home = ({ navLinks }) => {
  return (
    <Page heading="Welcome" backgroundImage="/images/blurred-city.jpg">
      <ButtonBlockNavList items={navLinks} />
    </Page>
  )
}
