import React from 'react'
import { useSelector } from 'react-redux'
import { Heading } from '../../components/typography'
import { DD, DL, DT } from '../../components/tables'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'

export const CityInfo = ({ city }) => {
  const citySpec = useSelector(selectCitySpec(city))
  const hubs = Object.values(citySpec.hubs)
  return (
    <>
      <Heading margin="xsmall">Details</Heading>
      <DL isHorizontal dtPercentage={48}>
        <DT>Hubs</DT>
        <DD>{hubs.length}</DD>
        <DT>Active</DT>
        <DD>{hubs.some(hub => hub.active) ? 'Yes' : 'No'}</DD>
        <DT>Flight Ceiling</DT>
        <DD>{citySpec.flightCeiling} m</DD>
        <DT>Flight Floor</DT>
        <DD>{citySpec.flightFloor} m</DD>
        <DT>Flight Separation</DT>
        <DD>{citySpec.flightSeparation} m</DD>
        <DT>Takeoff Separation</DT>
        <DD>{citySpec.takeoffSeparation} seconds</DD>
      </DL>
    </>
  )
}
