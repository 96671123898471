export const cities = [
  { city: 'Tokyo', country: 'Japan', lCity: 'tokyo', lCountry: 'japan' },
  { city: 'Jakarta', country: 'Indonesia', lCity: 'jakarta', lCountry: 'indonesia' },
  { city: 'New York', country: 'United States', lCity: 'new york', lCountry: 'united states' },
  { city: 'Seoul', country: 'South Korea', lCity: 'seoul', lCountry: 'south korea' },
  { city: 'Manila', country: 'Philippines', lCity: 'manila', lCountry: 'philippines' },
  { city: 'Mumbai', country: 'India', lCity: 'mumbai', lCountry: 'india' },
  { city: 'Sao Paulo', country: 'Brazil', lCity: 'sao paulo', lCountry: 'brazil' },
  { city: 'Mexico City', country: 'Mexico', lCity: 'mexico city', lCountry: 'mexico' },
  { city: 'Delhi', country: 'India', lCity: 'delhi', lCountry: 'india' },
  { city: 'Osaka', country: 'Japan', lCity: 'osaka', lCountry: 'japan' },
  { city: 'Cairo', country: 'Egypt', lCity: 'cairo', lCountry: 'egypt' },
  { city: 'Kolkata', country: 'India', lCity: 'kolkata', lCountry: 'india' },
  {
    city: 'Los Angeles',
    country: 'United States',
    lCity: 'los angeles',
    lCountry: 'united states',
  },
  { city: 'Shanghai', country: 'China', lCity: 'shanghai', lCountry: 'china' },
  { city: 'Moscow', country: 'Russia', lCity: 'moscow', lCountry: 'russia' },
  { city: 'Beijing', country: 'China', lCity: 'beijing', lCountry: 'china' },
  { city: 'Buenos Aires', country: 'Argentina', lCity: 'buenos aires', lCountry: 'argentina' },
  { city: 'Guangzhou', country: 'China', lCity: 'guangzhou', lCountry: 'china' },
  { city: 'Shenzhen', country: 'China', lCity: 'shenzhen', lCountry: 'china' },
  { city: 'Istanbul', country: 'Turkey', lCity: 'istanbul', lCountry: 'turkey' },
  { city: 'Rio De Janeiro', country: 'Brazil', lCity: 'rio de janeiro', lCountry: 'brazil' },
  { city: 'Paris', country: 'France', lCity: 'paris', lCountry: 'france' },
  { city: 'Karachi', country: 'Pakistan', lCity: 'karachi', lCountry: 'pakistan' },
  { city: 'Nagoya', country: 'Japan', lCity: 'nagoya', lCountry: 'japan' },
  { city: 'Chicago', country: 'United States', lCity: 'chicago', lCountry: 'united states' },
  { city: 'Lagos', country: 'Nigeria', lCity: 'lagos', lCountry: 'nigeria' },
  { city: 'London', country: 'United Kingdom', lCity: 'london', lCountry: 'united kingdom' },
  { city: 'Bangkok', country: 'Thailand', lCity: 'bangkok', lCountry: 'thailand' },
  {
    city: 'Kinshasa',
    country: 'Dem Rep Of Congo',
    lCity: 'kinshasa',
    lCountry: 'dem rep of congo',
  },
  { city: 'Tehran', country: 'Iran', lCity: 'tehran', lCountry: 'iran' },
  { city: 'Lima', country: 'Peru', lCity: 'lima', lCountry: 'peru' },
  { city: 'Dongguan', country: 'China', lCity: 'dongguan', lCountry: 'china' },
  { city: 'Bogota', country: 'Colombia', lCity: 'bogota', lCountry: 'colombia' },
  { city: 'Chennai', country: 'India', lCity: 'chennai', lCountry: 'india' },
  { city: 'Dhaka', country: 'Bangladesh', lCity: 'dhaka', lCountry: 'bangladesh' },
  { city: 'Essen', country: 'Germany', lCity: 'essen', lCountry: 'germany' },
  { city: 'Tianjin', country: 'China', lCity: 'tianjin', lCountry: 'china' },
  {
    city: 'Hong Kong',
    country: 'China Hong Kong',
    lCity: 'hong kong',
    lCountry: 'china hong kong',
  },
  { city: 'Taipei', country: 'Taiwan', lCity: 'taipei', lCountry: 'taiwan' },
  { city: 'Lahore', country: 'Pakistan', lCity: 'lahore', lCountry: 'pakistan' },
  {
    city: 'Ho Chi Minh City',
    country: 'Viet Nam',
    lCity: 'ho chi minh city',
    lCountry: 'viet nam',
  },
  { city: 'Bangalore', country: 'India', lCity: 'bangalore', lCountry: 'india' },
  { city: 'Hyderabad', country: 'India', lCity: 'hyderabad', lCountry: 'india' },
  {
    city: 'Johannesburg',
    country: 'South Africa',
    lCity: 'johannesburg',
    lCountry: 'south africa',
  },
  { city: 'Baghdad', country: 'Iraq', lCity: 'baghdad', lCountry: 'iraq' },
  { city: 'Toronto', country: 'Canada', lCity: 'toronto', lCountry: 'canada' },
  { city: 'Santiago', country: 'Chile', lCity: 'santiago', lCountry: 'chile' },
  { city: 'Kuala Lumpur', country: 'Malaysia', lCity: 'kuala lumpur', lCountry: 'malaysia' },
  {
    city: 'San Francisco',
    country: 'United States',
    lCity: 'san francisco',
    lCountry: 'united states',
  },
  {
    city: 'Philadelphia',
    country: 'United States',
    lCity: 'philadelphia',
    lCountry: 'united states',
  },
  { city: 'Wuhan', country: 'China', lCity: 'wuhan', lCountry: 'china' },
  { city: 'Miami', country: 'United States', lCity: 'miami', lCountry: 'united states' },
  { city: 'Dallas', country: 'United States', lCity: 'dallas', lCountry: 'united states' },
  { city: 'Madrid', country: 'Spain', lCity: 'madrid', lCountry: 'spain' },
  { city: 'Ahmedabad', country: 'India', lCity: 'ahmedabad', lCountry: 'india' },
  { city: 'Boston', country: 'United States', lCity: 'boston', lCountry: 'united states' },
  { city: 'Belo Horizonte', country: 'Brazil', lCity: 'belo horizonte', lCountry: 'brazil' },
  { city: 'Khartoum', country: 'Sudan', lCity: 'khartoum', lCountry: 'sudan' },
  { city: 'Saint Petersburg', country: 'Russia', lCity: 'saint petersburg', lCountry: 'russia' },
  { city: 'Shenyang', country: 'China', lCity: 'shenyang', lCountry: 'china' },
  { city: 'Houston', country: 'United States', lCity: 'houston', lCountry: 'united states' },
  { city: 'Pune', country: 'India', lCity: 'pune', lCountry: 'india' },
  { city: 'Riyadh', country: 'Saudi Arabia', lCity: 'riyadh', lCountry: 'saudi arabia' },
  { city: 'Singapore', country: 'Singapore', lCity: 'singapore', lCountry: 'singapore' },
  { city: 'Washington', country: 'United States', lCity: 'washington', lCountry: 'united states' },
  { city: 'Yangon', country: 'Myanmar', lCity: 'yangon', lCountry: 'myanmar' },
  { city: 'Milan', country: 'Italy', lCity: 'milan', lCountry: 'italy' },
  { city: 'Atlanta', country: 'United States', lCity: 'atlanta', lCountry: 'united states' },
  { city: 'Chongqing', country: 'China', lCity: 'chongqing', lCountry: 'china' },
  { city: 'Alexandria', country: 'Egypt', lCity: 'alexandria', lCountry: 'egypt' },
  { city: 'Nanjing', country: 'China', lCity: 'nanjing', lCountry: 'china' },
  { city: 'Guadalajara', country: 'Mexico', lCity: 'guadalajara', lCountry: 'mexico' },
  { city: 'Barcelona', country: 'Spain', lCity: 'barcelona', lCountry: 'spain' },
  { city: 'Chengdu', country: 'China', lCity: 'chengdu', lCountry: 'china' },
  { city: 'Detroit', country: 'United States', lCity: 'detroit', lCountry: 'united states' },
  { city: 'Ankara', country: 'Turkey', lCity: 'ankara', lCountry: 'turkey' },
  { city: 'Abidjan', country: 'Cote D Ivoire', lCity: 'abidjan', lCountry: 'cote d ivoire' },
  { city: 'Athens', country: 'Greece', lCity: 'athens', lCountry: 'greece' },
  { city: 'Berlin', country: 'Germany', lCity: 'berlin', lCountry: 'germany' },
  { city: 'Sydney', country: 'Australia', lCity: 'sydney', lCountry: 'australia' },
  { city: 'Monterrey', country: 'Mexico', lCity: 'monterrey', lCountry: 'mexico' },
  { city: 'Phoenix', country: 'United States', lCity: 'phoenix', lCountry: 'united states' },
  { city: 'Busan', country: 'South Korea', lCity: 'busan', lCountry: 'south korea' },
  { city: 'Recife', country: 'Brazil', lCity: 'recife', lCountry: 'brazil' },
  { city: 'Bandung', country: 'Indonesia', lCity: 'bandung', lCountry: 'indonesia' },
  { city: 'Porto Alegre', country: 'Brazil', lCity: 'porto alegre', lCountry: 'brazil' },
  { city: 'Melbourne', country: 'Australia', lCity: 'melbourne', lCountry: 'australia' },
  { city: 'Luanda', country: 'Angola', lCity: 'luanda', lCountry: 'angola' },
  { city: 'Hangzhou', country: 'China', lCity: 'hangzhou', lCountry: 'china' },
  { city: 'Algiers', country: 'Algeria', lCity: 'algiers', lCountry: 'algeria' },
  { city: 'Ha Noi', country: 'Viet Nam', lCity: 'ha noi', lCountry: 'viet nam' },
  { city: 'Montreal', country: 'Canada', lCity: 'montreal', lCountry: 'canada' },
  { city: 'Xian', country: 'China', lCity: 'xian', lCountry: 'china' },
  { city: 'Pyongyang', country: 'North Korea', lCity: 'pyongyang', lCountry: 'north korea' },
  { city: 'Qingdao', country: 'China', lCity: 'qingdao', lCountry: 'china' },
  { city: 'Surat', country: 'India', lCity: 'surat', lCountry: 'india' },
  { city: 'Fortaleza', country: 'Brazil', lCity: 'fortaleza', lCountry: 'brazil' },
  { city: 'Medellin', country: 'Colombia', lCity: 'medellin', lCountry: 'colombia' },
  { city: 'Durban', country: 'South Africa', lCity: 'durban', lCountry: 'south africa' },
  { city: 'Kanpur', country: 'India', lCity: 'kanpur', lCountry: 'india' },
  { city: 'Addis Ababa', country: 'Ethiopia', lCity: 'addis ababa', lCountry: 'ethiopia' },
  { city: 'Nairobi', country: 'Kenya', lCity: 'nairobi', lCountry: 'kenya' },
  { city: 'Jeddah', country: 'Saudi Arabia', lCity: 'jeddah', lCountry: 'saudi arabia' },
  { city: 'Naples', country: 'Italy', lCity: 'naples', lCountry: 'italy' },
  { city: 'Kabul', country: 'Afghanistan', lCity: 'kabul', lCountry: 'afghanistan' },
  { city: 'Salvador', country: 'Brazil', lCity: 'salvador', lCountry: 'brazil' },
  { city: 'Harbin', country: 'China', lCity: 'harbin', lCountry: 'china' },
  { city: 'Kano', country: 'Nigeria', lCity: 'kano', lCountry: 'nigeria' },
  { city: 'Casablanca', country: 'Morocco', lCity: 'casablanca', lCountry: 'morocco' },
  { city: 'Cape Town', country: 'South Africa', lCity: 'cape town', lCountry: 'south africa' },
  { city: 'Curitiba', country: 'Brazil', lCity: 'curitiba', lCountry: 'brazil' },
  { city: 'Surabaya', country: 'Indonesia', lCity: 'surabaya', lCountry: 'indonesia' },
  { city: 'San Diego', country: 'United States', lCity: 'san diego', lCountry: 'united states' },
  { city: 'Seattle', country: 'United States', lCity: 'seattle', lCountry: 'united states' },
  { city: 'Rome', country: 'Italy', lCity: 'rome', lCountry: 'italy' },
  { city: 'Dar Es Salaam', country: 'Tanzania', lCity: 'dar es salaam', lCountry: 'tanzania' },
  { city: 'Taichung', country: 'China', lCity: 'taichung', lCountry: 'china' },
  { city: 'Jaipur', country: 'India', lCity: 'jaipur', lCountry: 'india' },
  { city: 'Caracas', country: 'Venezuela', lCity: 'caracas', lCountry: 'venezuela' },
  { city: 'Dakar', country: 'Senegal', lCity: 'dakar', lCountry: 'senegal' },
  { city: 'Kaohsiung', country: 'China', lCity: 'kaohsiung', lCountry: 'china' },
  {
    city: 'Minneapolis',
    country: 'United States',
    lCity: 'minneapolis',
    lCountry: 'united states',
  },
  { city: 'Lucknow', country: 'India', lCity: 'lucknow', lCountry: 'india' },
  { city: 'Amman', country: 'Jordan', lCity: 'amman', lCountry: 'jordan' },
  { city: 'Tel Aviv Yafo', country: 'Israel', lCity: 'tel aviv yafo', lCountry: 'israel' },
  { city: 'Guayaquil', country: 'Ecuador', lCity: 'guayaquil', lCountry: 'ecuador' },
  { city: 'Kyiv', country: 'Ukraine', lCity: 'kyiv', lCountry: 'ukraine' },
  { city: 'Faisalabad', country: 'Pakistan', lCity: 'faisalabad', lCountry: 'pakistan' },
  { city: 'Mashhad', country: 'Iran', lCity: 'mashhad', lCountry: 'iran' },
  { city: 'Izmir', country: 'Turkey', lCity: 'izmir', lCountry: 'turkey' },
  { city: 'Rawalpindi', country: 'Pakistan', lCity: 'rawalpindi', lCountry: 'pakistan' },
  { city: 'Tashkent', country: 'Uzbekistan', lCity: 'tashkent', lCountry: 'uzbekistan' },
  { city: 'Katowice', country: 'Poland', lCity: 'katowice', lCountry: 'poland' },
  { city: 'Changchun', country: 'China', lCity: 'changchun', lCountry: 'china' },
  { city: 'Campinas', country: 'Brazil', lCity: 'campinas', lCountry: 'brazil' },
  { city: 'Daegu', country: 'South Korea', lCity: 'daegu', lCountry: 'south korea' },
  { city: 'Changsha', country: 'China', lCity: 'changsha', lCountry: 'china' },
  { city: 'Nagpur', country: 'India', lCity: 'nagpur', lCountry: 'india' },
  { city: 'San Juan', country: 'Philippines', lCity: 'san juan', lCountry: 'philippines' },
  { city: 'Aleppo', country: 'Syria', lCity: 'aleppo', lCountry: 'syria' },
  { city: 'Lisbon', country: 'Portugal', lCity: 'lisbon', lCountry: 'portugal' },
  {
    city: 'Frankfurt Am Main',
    country: 'Germany',
    lCity: 'frankfurt am main',
    lCountry: 'germany',
  },
  { city: 'Nanchang', country: 'China', lCity: 'nanchang', lCountry: 'china' },
  {
    city: 'Birmingham',
    country: 'United Kingdom',
    lCity: 'birmingham',
    lCountry: 'united kingdom',
  },
  { city: 'Tampa', country: 'United States', lCity: 'tampa', lCountry: 'united states' },
  { city: 'Medan', country: 'Indonesia', lCity: 'medan', lCountry: 'indonesia' },
  { city: 'Dalian', country: 'China', lCity: 'dalian', lCountry: 'china' },
  { city: 'Tunis', country: 'Tunisia', lCity: 'tunis', lCountry: 'tunisia' },
  { city: 'Shijiazhuang', country: 'China', lCity: 'shijiazhuang', lCountry: 'china' },
  {
    city: 'Manchester',
    country: 'United Kingdom',
    lCity: 'manchester',
    lCountry: 'united kingdom',
  },
  { city: 'Port Au Prince', country: 'Haiti', lCity: 'port au prince', lCountry: 'haiti' },
  { city: 'Damascus', country: 'Syria', lCity: 'damascus', lCountry: 'syria' },
  { city: 'Jinan', country: 'China', lCity: 'jinan', lCountry: 'china' },
  { city: 'Fukuoka', country: 'Japan', lCity: 'fukuoka', lCountry: 'japan' },
  {
    city: 'Santo Domingo',
    country: 'Dominican Republic',
    lCity: 'santo domingo',
    lCountry: 'dominican republic',
  },
  { city: 'Havana', country: 'Cuba', lCity: 'havana', lCountry: 'cuba' },
  { city: 'Cali', country: 'Colombia', lCity: 'cali', lCountry: 'colombia' },
  { city: 'Denver', country: 'United States', lCity: 'denver', lCountry: 'united states' },
  { city: 'St Louis', country: 'United States', lCity: 'st louis', lCountry: 'united states' },
  { city: 'Colombo', country: 'Brazil', lCity: 'colombo', lCountry: 'brazil' },
  {
    city: 'Dubai',
    country: 'United Arab Emirates',
    lCity: 'dubai',
    lCountry: 'united arab emirates',
  },
  { city: 'Baltimore', country: 'United States', lCity: 'baltimore', lCountry: 'united states' },
  { city: 'Sapporo', country: 'Japan', lCity: 'sapporo', lCountry: 'japan' },
  { city: 'Rotterdam', country: 'Netherlands', lCity: 'rotterdam', lCountry: 'netherlands' },
  { city: 'Vancouver', country: 'Canada', lCity: 'vancouver', lCountry: 'canada' },
  { city: 'Preston', country: 'United Kingdom', lCity: 'preston', lCountry: 'united kingdom' },
  { city: 'Patna', country: 'India', lCity: 'patna', lCountry: 'india' },
  { city: 'Sanaa', country: 'Yemen', lCity: 'sanaa', lCountry: 'yemen' },
  { city: 'Warsaw', country: 'Poland', lCity: 'warsaw', lCountry: 'poland' },
  { city: 'Bonn', country: 'Germany', lCity: 'bonn', lCountry: 'germany' },
  { city: 'Accra', country: 'Ghana', lCity: 'accra', lCountry: 'ghana' },
  { city: 'Bucharest', country: 'Romania', lCity: 'bucharest', lCountry: 'romania' },
  { city: 'Yokohama', country: 'Japan', lCity: 'yokohama', lCountry: 'japan' },
  { city: 'Kunming', country: 'China', lCity: 'kunming', lCountry: 'china' },
  { city: 'Guiyang', country: 'China', lCity: 'guiyang', lCountry: 'china' },
  { city: 'Zibo', country: 'China', lCity: 'zibo', lCountry: 'china' },
  { city: 'Incheon', country: 'South Korea', lCity: 'incheon', lCountry: 'south korea' },
  { city: 'Zhengzhou', country: 'China', lCity: 'zhengzhou', lCountry: 'china' },
  { city: 'Taiyuan', country: 'China', lCity: 'taiyuan', lCountry: 'china' },
  { city: 'Chaoyang', country: 'China', lCity: 'chaoyang', lCountry: 'china' },
  { city: 'Brasilia', country: 'Brazil', lCity: 'brasilia', lCountry: 'brazil' },
  { city: 'Zhongshan', country: 'China', lCity: 'zhongshan', lCountry: 'china' },
  {
    city: 'West Midlands',
    country: 'United Kingdom',
    lCity: 'west midlands',
    lCountry: 'united kingdom',
  },
  { city: 'Giza', country: 'Egypt', lCity: 'giza', lCountry: 'egypt' },
  { city: 'Quezon City', country: 'Philippines', lCity: 'quezon city', lCountry: 'philippines' },
  { city: 'Nanhai', country: 'China', lCity: 'nanhai', lCountry: 'china' },
  { city: 'Fuzhou', country: 'China', lCity: 'fuzhou', lCountry: 'china' },
  { city: 'Lanzhou', country: 'China', lCity: 'lanzhou', lCountry: 'china' },
  { city: 'Xiamen', country: 'China', lCity: 'xiamen', lCountry: 'china' },
  { city: 'Chittagong', country: 'Bangladesh', lCity: 'chittagong', lCountry: 'bangladesh' },
  { city: 'Zaozhuang', country: 'China', lCity: 'zaozhuang', lCountry: 'china' },
  { city: 'Jilin', country: 'China', lCity: 'jilin', lCountry: 'china' },
  { city: 'Linyi', country: 'China', lCity: 'linyi', lCountry: 'china' },
  { city: 'Wenzhou', country: 'China', lCity: 'wenzhou', lCountry: 'china' },
  { city: 'Stockholm', country: 'Sweden', lCity: 'stockholm', lCountry: 'sweden' },
  {
    city: 'Puebla De Zaragoza',
    country: 'Mexico',
    lCity: 'puebla de zaragoza',
    lCountry: 'mexico',
  },
  { city: 'Puning', country: 'China', lCity: 'puning', lCountry: 'china' },
  { city: 'Baku', country: 'Azerbaijan', lCity: 'baku', lCountry: 'azerbaijan' },
  { city: 'Ibadan', country: 'Nigeria', lCity: 'ibadan', lCountry: 'nigeria' },
  { city: 'Brisbane', country: 'Australia', lCity: 'brisbane', lCountry: 'australia' },
  { city: 'Minsk', country: 'Belarus', lCity: 'minsk', lCountry: 'belarus' },
  { city: 'Sikasso', country: 'Mali', lCity: 'sikasso', lCountry: 'mali' },
  { city: 'Nanchong', country: 'China', lCity: 'nanchong', lCountry: 'china' },
  { city: 'Nanning', country: 'China', lCity: 'nanning', lCountry: 'china' },
  { city: 'Urumqi', country: 'China', lCity: 'urumqi', lCountry: 'china' },
  { city: 'Yantai', country: 'China', lCity: 'yantai', lCountry: 'china' },
  { city: 'Fuyang', country: 'China', lCity: 'fuyang', lCountry: 'china' },
  { city: 'Tangshan', country: 'China', lCity: 'tangshan', lCountry: 'china' },
  { city: 'Maracaibo', country: 'Venezuela', lCity: 'maracaibo', lCountry: 'venezuela' },
  { city: 'Hamburg', country: 'Germany', lCity: 'hamburg', lCountry: 'germany' },
  { city: 'Budapest', country: 'Hungary', lCity: 'budapest', lCountry: 'hungary' },
  { city: 'Shunde', country: 'China', lCity: 'shunde', lCountry: 'china' },
  { city: 'Manaus', country: 'Brazil', lCity: 'manaus', lCountry: 'brazil' },
  { city: 'Xuzhou', country: 'China', lCity: 'xuzhou', lCountry: 'china' },
  { city: 'Segou', country: 'Mali', lCity: 'segou', lCountry: 'mali' },
  { city: 'Baotou', country: 'China', lCity: 'baotou', lCountry: 'china' },
  { city: 'Hefei', country: 'China', lCity: 'hefei', lCountry: 'china' },
  { city: 'Vienna', country: 'Austria', lCity: 'vienna', lCountry: 'austria' },
  { city: 'Indore', country: 'India', lCity: 'indore', lCountry: 'india' },
  { city: 'Asuncion', country: 'Paraguay', lCity: 'asuncion', lCountry: 'paraguay' },
  { city: 'Tianmen', country: 'China', lCity: 'tianmen', lCountry: 'china' },
  { city: 'Belgrade', country: 'Serbia', lCity: 'belgrade', lCountry: 'serbia' },
  { city: 'Suzhou', country: 'China', lCity: 'suzhou', lCountry: 'china' },
  { city: 'Suizhou', country: 'China', lCity: 'suizhou', lCountry: 'china' },
  { city: 'Nanyang', country: 'China', lCity: 'nanyang', lCountry: 'china' },
  { city: 'Nakuru', country: 'Kenya', lCity: 'nakuru', lCountry: 'kenya' },
  { city: 'Koulikoro', country: 'Mali', lCity: 'koulikoro', lCountry: 'mali' },
  { city: 'Ningbo', country: 'China', lCity: 'ningbo', lCountry: 'china' },
  { city: 'Liuan', country: 'China', lCity: 'liuan', lCountry: 'china' },
  { city: 'Anshan', country: 'China', lCity: 'anshan', lCountry: 'china' },
  { city: 'Tengzhou', country: 'China', lCity: 'tengzhou', lCountry: 'china' },
  { city: 'Qiqihaer', country: 'China', lCity: 'qiqihaer', lCountry: 'china' },
  { city: 'Pizhou', country: 'China', lCity: 'pizhou', lCountry: 'china' },
  { city: 'Taian', country: 'China', lCity: 'taian', lCountry: 'china' },
  { city: 'Datong', country: 'China', lCity: 'datong', lCountry: 'china' },
  { city: 'Kobe', country: 'Japan', lCity: 'kobe', lCountry: 'japan' },
  { city: 'Hama', country: 'Syria', lCity: 'hama', lCountry: 'syria' },
  { city: 'Esfahan', country: 'Iran', lCity: 'esfahan', lCountry: 'iran' },
  { city: 'Tripoli', country: 'Libya', lCity: 'tripoli', lCountry: 'libya' },
  {
    city: 'West Yorkshire',
    country: 'United Kingdom',
    lCity: 'west yorkshire',
    lCountry: 'united kingdom',
  },
  { city: 'Vadodara', country: 'India', lCity: 'vadodara', lCountry: 'india' },
  { city: 'Taizhou', country: 'China', lCity: 'taizhou', lCountry: 'china' },
  { city: 'Luoyang', country: 'China', lCity: 'luoyang', lCountry: 'china' },
  { city: 'Quito', country: 'Ecuador', lCity: 'quito', lCountry: 'ecuador' },
  { city: 'Jinjiang', country: 'China', lCity: 'jinjiang', lCountry: 'china' },
  { city: 'Mopti', country: 'Mali', lCity: 'mopti', lCountry: 'mali' },
  { city: 'Perth', country: 'Australia', lCity: 'perth', lCountry: 'australia' },
  { city: 'Daejeon', country: 'South Korea', lCity: 'daejeon', lCountry: 'south korea' },
  { city: 'Kyoto', country: 'Japan', lCity: 'kyoto', lCountry: 'japan' },
  { city: 'Xiantao', country: 'China', lCity: 'xiantao', lCountry: 'china' },
  { city: 'Tangerang', country: 'Indonesia', lCity: 'tangerang', lCountry: 'indonesia' },
  { city: 'Bhopal', country: 'India', lCity: 'bhopal', lCountry: 'india' },
  { city: 'Coimbatore', country: 'India', lCity: 'coimbatore', lCountry: 'india' },
  { city: 'Kharkiv', country: 'Ukraine', lCity: 'kharkiv', lCountry: 'ukraine' },
  { city: 'Gwangju', country: 'South Korea', lCity: 'gwangju', lCountry: 'south korea' },
  { city: 'Xinghua', country: 'China', lCity: 'xinghua', lCountry: 'china' },
  { city: 'Harare', country: 'Zimbabwe', lCity: 'harare', lCountry: 'zimbabwe' },
  { city: 'Fushun', country: 'China', lCity: 'fushun', lCountry: 'china' },
  { city: 'Shangqiu', country: 'China', lCity: 'shangqiu', lCountry: 'china' },
  { city: 'Belem', country: 'Brazil', lCity: 'belem', lCountry: 'brazil' },
  { city: 'Wuxi', country: 'China', lCity: 'wuxi', lCountry: 'china' },
  { city: 'Hechuan', country: 'China', lCity: 'hechuan', lCountry: 'china' },
  { city: 'Wujin', country: 'China', lCity: 'wujin', lCountry: 'china' },
  { city: 'Guigang', country: 'China', lCity: 'guigang', lCountry: 'china' },
  { city: 'Jianyang', country: 'China', lCity: 'jianyang', lCountry: 'china' },
  { city: 'Huhehaote', country: 'China', lCity: 'huhehaote', lCountry: 'china' },
  { city: 'Santa Cruz', country: 'Bolivia', lCity: 'santa cruz', lCountry: 'bolivia' },
  { city: 'Semarang', country: 'Indonesia', lCity: 'semarang', lCountry: 'indonesia' },
  { city: 'Ludhiana', country: 'India', lCity: 'ludhiana', lCountry: 'india' },
  { city: 'Novosibirsk', country: 'Russia', lCity: 'novosibirsk', lCountry: 'russia' },
  { city: 'Neijiang', country: 'China', lCity: 'neijiang', lCountry: 'china' },
  { city: 'Maputo', country: 'Mozambique', lCity: 'maputo', lCountry: 'mozambique' },
  { city: 'Nanan', country: 'China', lCity: 'nanan', lCountry: 'china' },
  { city: 'Douala', country: 'Cameroon', lCity: 'douala', lCountry: 'cameroon' },
  { city: 'Weifang', country: 'China', lCity: 'weifang', lCountry: 'china' },
  { city: 'Daqing', country: 'China', lCity: 'daqing', lCountry: 'china' },
  { city: 'Kayes', country: 'Mali', lCity: 'kayes', lCountry: 'mali' },
  { city: 'Tongzhou', country: 'China', lCity: 'tongzhou', lCountry: 'china' },
  { city: 'Tabriz', country: 'Iran', lCity: 'tabriz', lCountry: 'iran' },
  { city: 'Homs', country: 'Syria', lCity: 'homs', lCountry: 'syria' },
  { city: 'Rugao', country: 'China', lCity: 'rugao', lCountry: 'china' },
  { city: 'Guiping', country: 'China', lCity: 'guiping', lCountry: 'china' },
  { city: 'Huainan', country: 'China', lCity: 'huainan', lCountry: 'china' },
  { city: 'Kochi', country: 'India', lCity: 'kochi', lCountry: 'india' },
  { city: 'Suining', country: 'China', lCity: 'suining', lCountry: 'china' },
  { city: 'Bozhou', country: 'China', lCity: 'bozhou', lCountry: 'china' },
  { city: 'Zhanjiang', country: 'China', lCity: 'zhanjiang', lCountry: 'china' },
  { city: 'Changde', country: 'China', lCity: 'changde', lCountry: 'china' },
  { city: 'Montevideo', country: 'Uruguay', lCity: 'montevideo', lCountry: 'uruguay' },
  { city: 'Xintai', country: 'China', lCity: 'xintai', lCountry: 'china' },
  { city: 'Ekaterinoburg', country: 'Russia', lCity: 'ekaterinoburg', lCountry: 'russia' },
  { city: 'Juarez', country: 'Mexico', lCity: 'juarez', lCountry: 'mexico' },
  { city: 'Handan', country: 'China', lCity: 'handan', lCountry: 'china' },
  { city: 'Visakhapatnam', country: 'India', lCity: 'visakhapatnam', lCountry: 'india' },
  { city: 'Kawasaki', country: 'Japan', lCity: 'kawasaki', lCountry: 'japan' },
  { city: 'Jiangjin', country: 'China', lCity: 'jiangjin', lCountry: 'china' },
  { city: 'Pingdu', country: 'China', lCity: 'pingdu', lCountry: 'china' },
  { city: 'Agra', country: 'India', lCity: 'agra', lCountry: 'india' },
  { city: 'Jiangyin', country: 'China', lCity: 'jiangyin', lCountry: 'china' },
  { city: 'Tijuana', country: 'Mexico', lCity: 'tijuana', lCountry: 'mexico' },
  { city: 'Liuyang', country: 'China', lCity: 'liuyang', lCountry: 'china' },
  { city: 'Bursa', country: 'Turkey', lCity: 'bursa', lCountry: 'turkey' },
  { city: 'Al Hasakeh', country: 'Syria', lCity: 'al hasakeh', lCountry: 'syria' },
  { city: 'Makkah', country: 'Saudi Arabia', lCity: 'makkah', lCountry: 'saudi arabia' },
  { city: 'Yaounde', country: 'Cameroon', lCity: 'yaounde', lCountry: 'cameroon' },
  { city: 'Xuanwei', country: 'China', lCity: 'xuanwei', lCountry: 'china' },
  { city: 'Dengzhou', country: 'China', lCity: 'dengzhou', lCountry: 'china' },
  { city: 'Palembang', country: 'Indonesia', lCity: 'palembang', lCountry: 'indonesia' },
  { city: 'Nizhny Novgorod', country: 'Russia', lCity: 'nizhny novgorod', lCountry: 'russia' },
  { city: 'Leon', country: 'Mexico', lCity: 'leon', lCountry: 'mexico' },
  { city: 'Guarulhos', country: 'Brazil', lCity: 'guarulhos', lCountry: 'brazil' },
  { city: 'Heze', country: 'China', lCity: 'heze', lCountry: 'china' },
  { city: 'Auckland', country: 'New Zealand', lCity: 'auckland', lCountry: 'new zealand' },
  { city: 'Omdurman', country: 'Sudan', lCity: 'omdurman', lCountry: 'sudan' },
  { city: 'Shantou', country: 'China', lCity: 'shantou', lCountry: 'china' },
  { city: 'Leizhou', country: 'China', lCity: 'leizhou', lCountry: 'china' },
  { city: 'Yongcheng', country: 'China', lCity: 'yongcheng', lCountry: 'china' },
  { city: 'Valencia', country: 'Venezuela', lCity: 'valencia', lCountry: 'venezuela' },
  { city: 'Thane', country: 'India', lCity: 'thane', lCountry: 'india' },
  {
    city: 'San Antonio',
    country: 'United States',
    lCity: 'san antonio',
    lCountry: 'united states',
  },
  { city: 'Xinyang', country: 'China', lCity: 'xinyang', lCountry: 'china' },
  { city: 'Luzhou', country: 'China', lCity: 'luzhou', lCountry: 'china' },
  { city: 'Almaty', country: 'Kazakhstan', lCity: 'almaty', lCountry: 'kazakhstan' },
  { city: 'Changshu', country: 'China', lCity: 'changshu', lCountry: 'china' },
  { city: 'Taixing', country: 'China', lCity: 'taixing', lCountry: 'china' },
  { city: 'Phnom Penh', country: 'Cambodia', lCity: 'phnom penh', lCountry: 'cambodia' },
  { city: 'Laiwu', country: 'China', lCity: 'laiwu', lCountry: 'china' },
  { city: 'Xiaoshan', country: 'China', lCity: 'xiaoshan', lCountry: 'china' },
  { city: 'Yiyang', country: 'China', lCity: 'yiyang', lCountry: 'china' },
  { city: 'Goiania', country: 'Brazil', lCity: 'goiania', lCountry: 'brazil' },
  { city: 'Liuzhou', country: 'China', lCity: 'liuzhou', lCountry: 'china' },
  { city: 'Gaozhou', country: 'China', lCity: 'gaozhou', lCountry: 'china' },
  { city: 'Fengcheng', country: 'China', lCity: 'fengcheng', lCountry: 'china' },
  { city: 'Cixi', country: 'China', lCity: 'cixi', lCountry: 'china' },
  { city: 'Karaj', country: 'Iran', lCity: 'karaj', lCountry: 'iran' },
  { city: 'Mogadishu', country: 'Somalia', lCity: 'mogadishu', lCountry: 'somalia' },
  { city: 'Varanasi', country: 'India', lCity: 'varanasi', lCountry: 'india' },
  { city: 'Cordoba', country: 'Argentina', lCity: 'cordoba', lCountry: 'argentina' },
  { city: 'Kampala', country: 'Uganda', lCity: 'kampala', lCountry: 'uganda' },
  { city: 'Ruian', country: 'China', lCity: 'ruian', lCountry: 'china' },
  { city: 'Lianjiang', country: 'China', lCity: 'lianjiang', lCountry: 'china' },
  { city: 'Huaian', country: 'China', lCity: 'huaian', lCountry: 'china' },
  { city: 'Shiraz', country: 'Iran', lCity: 'shiraz', lCountry: 'iran' },
  { city: 'Multan', country: 'Pakistan', lCity: 'multan', lCountry: 'pakistan' },
  { city: 'Madurai', country: 'India', lCity: 'madurai', lCountry: 'india' },
  { city: 'Munchen', country: 'Germany', lCity: 'munchen', lCountry: 'germany' },
  { city: 'Kalyan', country: 'India', lCity: 'kalyan', lCountry: 'india' },
  { city: 'Quanzhou', country: 'China', lCity: 'quanzhou', lCountry: 'china' },
  { city: 'Adana', country: 'Turkey', lCity: 'adana', lCountry: 'turkey' },
  { city: 'Bazhong', country: 'China', lCity: 'bazhong', lCountry: 'china' },
  { city: 'Fes', country: 'Morocco', lCity: 'fes', lCountry: 'morocco' },
  { city: 'Ouagadougou', country: 'Burkina Faso', lCity: 'ouagadougou', lCountry: 'burkina faso' },
  { city: 'Haicheng', country: 'China', lCity: 'haicheng', lCountry: 'china' },
  { city: 'Xishan', country: 'China', lCity: 'xishan', lCountry: 'china' },
  { city: 'Leiyang', country: 'China', lCity: 'leiyang', lCountry: 'china' },
  { city: 'Caloocan', country: 'Philippines', lCity: 'caloocan', lCountry: 'philippines' },
  { city: 'Kalookan', country: 'Philippines', lCity: 'kalookan', lCountry: 'philippines' },
  { city: 'Jingzhou', country: 'China', lCity: 'jingzhou', lCountry: 'china' },
  { city: 'Saitama', country: 'Japan', lCity: 'saitama', lCountry: 'japan' },
  { city: 'Prague', country: 'Czech Republic', lCity: 'prague', lCountry: 'czech republic' },
  { city: 'Fuqing', country: 'China', lCity: 'fuqing', lCountry: 'china' },
  { city: 'Kumasi', country: 'Ghana', lCity: 'kumasi', lCountry: 'ghana' },
  { city: 'Meerut', country: 'India', lCity: 'meerut', lCountry: 'india' },
  { city: 'Lufeng', country: 'China', lCity: 'lufeng', lCountry: 'china' },
  { city: 'Dongtai', country: 'China', lCity: 'dongtai', lCountry: 'china' },
  { city: 'Yixing', country: 'China', lCity: 'yixing', lCountry: 'china' },
  { city: 'Mianyang', country: 'China', lCity: 'mianyang', lCountry: 'china' },
  { city: 'Wenling', country: 'China', lCity: 'wenling', lCountry: 'china' },
  { city: 'Leqing', country: 'China', lCity: 'leqing', lCountry: 'china' },
  { city: 'Ottawa', country: 'Canada', lCity: 'ottawa', lCountry: 'canada' },
  { city: 'Yushu', country: 'China', lCity: 'yushu', lCountry: 'china' },
  { city: 'Barranquilla', country: 'Colombia', lCity: 'barranquilla', lCountry: 'colombia' },
  { city: 'Hiroshima', country: 'Japan', lCity: 'hiroshima', lCountry: 'japan' },
  { city: 'Chifeng', country: 'China', lCity: 'chifeng', lCountry: 'china' },
  { city: 'Nashik', country: 'India', lCity: 'nashik', lCountry: 'india' },
  { city: 'Makasar', country: 'Indonesia', lCity: 'makasar', lCountry: 'indonesia' },
  { city: 'Sofia', country: 'Bulgaria', lCity: 'sofia', lCountry: 'bulgaria' },
  { city: 'Rizhao', country: 'China', lCity: 'rizhao', lCountry: 'china' },
  { city: 'Davao', country: 'Philippines', lCity: 'davao', lCountry: 'philippines' },
  { city: 'Tianshui', country: 'China', lCity: 'tianshui', lCountry: 'china' },
  { city: 'Huzhou', country: 'China', lCity: 'huzhou', lCountry: 'china' },
  { city: 'Samara', country: 'Russia', lCity: 'samara', lCountry: 'russia' },
  { city: 'Omsk', country: 'Russia', lCity: 'omsk', lCountry: 'russia' },
  { city: 'Gujranwala', country: 'Pakistan', lCity: 'gujranwala', lCountry: 'pakistan' },
  { city: 'Adelaide', country: 'Australia', lCity: 'adelaide', lCountry: 'australia' },
  { city: 'Macheng', country: 'China', lCity: 'macheng', lCountry: 'china' },
  { city: 'Wuxian', country: 'China', lCity: 'wuxian', lCountry: 'china' },
  { city: 'Bijie', country: 'China', lCity: 'bijie', lCountry: 'china' },
  { city: 'Yuzhou', country: 'China', lCity: 'yuzhou', lCountry: 'china' },
  { city: 'Leshan', country: 'China', lCity: 'leshan', lCountry: 'china' },
  { city: 'La Matanza', country: 'Argentina', lCity: 'la matanza', lCountry: 'argentina' },
  { city: 'Rosario', country: 'Argentina', lCity: 'rosario', lCountry: 'argentina' },
  { city: 'Jabalpur', country: 'India', lCity: 'jabalpur', lCountry: 'india' },
  { city: 'Kazan', country: 'Russia', lCity: 'kazan', lCountry: 'russia' },
  { city: 'Jimo', country: 'China', lCity: 'jimo', lCountry: 'china' },
  { city: 'Dingzhou', country: 'China', lCity: 'dingzhou', lCountry: 'china' },
  { city: 'Calgary', country: 'Canada', lCity: 'calgary', lCountry: 'canada' },
  { city: 'Yerevan', country: 'Armenia', lCity: 'yerevan', lCountry: 'armenia' },
  { city: 'El Jadida', country: 'Morocco', lCity: 'el jadida', lCountry: 'morocco' },
  { city: 'Jamshedpur', country: 'India', lCity: 'jamshedpur', lCountry: 'india' },
  { city: 'Zurich', country: 'Switzerland', lCity: 'zurich', lCountry: 'switzerland' },
  { city: 'Zoucheng', country: 'China', lCity: 'zoucheng', lCountry: 'china' },
  {
    city: 'Pikine Guediawaye',
    country: 'Senegal',
    lCity: 'pikine guediawaye',
    lCountry: 'senegal',
  },
  { city: 'Anqiu', country: 'China', lCity: 'anqiu', lCountry: 'china' },
  { city: 'Guangan', country: 'China', lCity: 'guangan', lCountry: 'china' },
  { city: 'Chelyabinsk', country: 'Russia', lCity: 'chelyabinsk', lCountry: 'russia' },
  { city: 'Conakry', country: 'Guinea', lCity: 'conakry', lCountry: 'guinea' },
  { city: 'Asansol', country: 'India', lCity: 'asansol', lCountry: 'india' },
  { city: 'Shouguang', country: 'China', lCity: 'shouguang', lCountry: 'china' },
  { city: 'Changzhou', country: 'China', lCity: 'changzhou', lCountry: 'china' },
  { city: 'Ulsan', country: 'South Korea', lCity: 'ulsan', lCountry: 'south korea' },
  { city: 'Zhuji', country: 'China', lCity: 'zhuji', lCountry: 'china' },
  { city: 'Toluca', country: 'Mexico', lCity: 'toluca', lCountry: 'mexico' },
  { city: 'Marrakech', country: 'Morocco', lCity: 'marrakech', lCountry: 'morocco' },
  { city: 'Dhanbad', country: 'India', lCity: 'dhanbad', lCountry: 'india' },
  { city: 'Tbilisi', country: 'Georgia', lCity: 'tbilisi', lCountry: 'georgia' },
  { city: 'Hanchuan', country: 'China', lCity: 'hanchuan', lCountry: 'china' },
  { city: 'Lusaka', country: 'Zambia', lCity: 'lusaka', lCountry: 'zambia' },
  { city: 'Qidong', country: 'China', lCity: 'qidong', lCountry: 'china' },
  { city: 'Faridabad', country: 'India', lCity: 'faridabad', lCountry: 'india' },
  { city: 'Zaoyang', country: 'China', lCity: 'zaoyang', lCountry: 'china' },
  { city: 'Zhucheng', country: 'China', lCity: 'zhucheng', lCountry: 'china' },
  { city: 'Rostov Na Donu', country: 'Russia', lCity: 'rostov na donu', lCountry: 'russia' },
  { city: 'Jiangdu', country: 'China', lCity: 'jiangdu', lCountry: 'china' },
  { city: 'Xiangcheng', country: 'China', lCity: 'xiangcheng', lCountry: 'china' },
  { city: 'Zigong', country: 'China', lCity: 'zigong', lCountry: 'china' },
  { city: 'Jining', country: 'China', lCity: 'jining', lCountry: 'china' },
  { city: 'Edmonton', country: 'Canada', lCity: 'edmonton', lCountry: 'canada' },
  { city: 'Allahabad', country: 'India', lCity: 'allahabad', lCountry: 'india' },
  { city: 'Beiliu', country: 'China', lCity: 'beiliu', lCountry: 'china' },
  { city: 'Dnipropetrovsk', country: 'Ukraine', lCity: 'dnipropetrovsk', lCountry: 'ukraine' },
  { city: 'Gongzhuling', country: 'China', lCity: 'gongzhuling', lCountry: 'china' },
  { city: 'Qinzhou', country: 'China', lCity: 'qinzhou', lCountry: 'china' },
  { city: 'Ufa', country: 'Russia', lCity: 'ufa', lCountry: 'russia' },
  { city: 'Sendai', country: 'Japan', lCity: 'sendai', lCountry: 'japan' },
  { city: 'Volgograd', country: 'Russia', lCity: 'volgograd', lCountry: 'russia' },
  { city: 'Ezhou', country: 'China', lCity: 'ezhou', lCountry: 'china' },
  { city: 'Guatemala City', country: 'Guatemala', lCity: 'guatemala city', lCountry: 'guatemala' },
  { city: 'Zhongxiang', country: 'China', lCity: 'zhongxiang', lCountry: 'china' },
  { city: 'Amsterdam', country: 'Netherlands', lCity: 'amsterdam', lCountry: 'netherlands' },
  { city: 'Brussels', country: 'Belgium', lCity: 'brussels', lCountry: 'belgium' },
  { city: 'Bamako', country: 'Mali', lCity: 'bamako', lCountry: 'mali' },
  { city: 'Ziyang', country: 'China', lCity: 'ziyang', lCountry: 'china' },
  { city: 'Antananarivo', country: 'Madagascar', lCity: 'antananarivo', lCountry: 'madagascar' },
  { city: 'Mudanjiang', country: 'China', lCity: 'mudanjiang', lCountry: 'china' },
  { city: 'Amritsar', country: 'India', lCity: 'amritsar', lCountry: 'india' },
  { city: 'Vijayawada', country: 'India', lCity: 'vijayawada', lCountry: 'india' },
  { city: 'Haora', country: 'India', lCity: 'haora', lCountry: 'india' },
  { city: 'Donetsk', country: 'Ukraine', lCity: 'donetsk', lCountry: 'ukraine' },
  { city: 'Huazhou', country: 'China', lCity: 'huazhou', lCountry: 'china' },
  { city: 'Pimpri Chinchwad', country: 'India', lCity: 'pimpri chinchwad', lCountry: 'india' },
  { city: 'Dublin', country: 'Ireland', lCity: 'dublin', lCountry: 'ireland' },
  { city: 'Rajkot', country: 'India', lCity: 'rajkot', lCountry: 'india' },
  { city: 'Sao Luis', country: 'Brazil', lCity: 'sao luis', lCountry: 'brazil' },
  { city: 'Beni Mellal', country: 'Morocco', lCity: 'beni mellal', lCountry: 'morocco' },
  { city: 'Lianyuan', country: 'China', lCity: 'lianyuan', lCountry: 'china' },
  { city: 'Liupanshui', country: 'China', lCity: 'liupanshui', lCountry: 'china' },
  { city: 'Kaduna', country: 'Nigeria', lCity: 'kaduna', lCountry: 'nigeria' },
  { city: 'Kitakyushu', country: 'Japan', lCity: 'kitakyushu', lCountry: 'japan' },
  { city: 'Qianjiang', country: 'China', lCity: 'qianjiang', lCountry: 'china' },
  { city: 'Perm', country: 'Russia', lCity: 'perm', lCountry: 'russia' },
  { city: 'Odessa', country: 'Ukraine', lCity: 'odessa', lCountry: 'ukraine' },
  { city: 'Qom', country: 'Iran', lCity: 'qom', lCountry: 'iran' },
  { city: 'Yongchuan', country: 'China', lCity: 'yongchuan', lCountry: 'china' },
  { city: 'Peshawar', country: 'Pakistan', lCity: 'peshawar', lCountry: 'pakistan' },
  { city: 'Linzhou', country: 'China', lCity: 'linzhou', lCountry: 'china' },
  { city: 'Benxi', country: 'China', lCity: 'benxi', lCountry: 'china' },
  { city: 'Ulaanbaatar', country: 'Mongolia', lCity: 'ulaanbaatar', lCountry: 'mongolia' },
  { city: 'Zhangqiu', country: 'China', lCity: 'zhangqiu', lCountry: 'china' },
  { city: 'Yongzhou', country: 'China', lCity: 'yongzhou', lCountry: 'china' },
  { city: 'Sao Goncalo', country: 'Brazil', lCity: 'sao goncalo', lCountry: 'brazil' },
  { city: 'Srinagar', country: 'India', lCity: 'srinagar', lCountry: 'india' },
  { city: 'Ghaziabad', country: 'India', lCity: 'ghaziabad', lCountry: 'india' },
  { city: 'Xinyi', country: 'China', lCity: 'xinyi', lCountry: 'china' },
  { city: 'Koln', country: 'Germany', lCity: 'koln', lCountry: 'germany' },
  { city: 'Zhangjiagang', country: 'China', lCity: 'zhangjiagang', lCountry: 'china' },
  { city: 'Wafangdian', country: 'China', lCity: 'wafangdian', lCountry: 'china' },
  { city: 'Xianyang', country: 'China', lCity: 'xianyang', lCountry: 'china' },
  { city: 'Liaocheng', country: 'China', lCity: 'liaocheng', lCountry: 'china' },
  { city: 'Ahwaz', country: 'Iran', lCity: 'ahwaz', lCountry: 'iran' },
  { city: 'Taishan', country: 'China', lCity: 'taishan', lCountry: 'china' },
  { city: 'Linhai', country: 'China', lCity: 'linhai', lCountry: 'china' },
  { city: 'Feicheng', country: 'China', lCity: 'feicheng', lCountry: 'china' },
  { city: 'Suwon', country: 'South Korea', lCity: 'suwon', lCountry: 'south korea' },
  { city: 'Wuwei', country: 'China', lCity: 'wuwei', lCountry: 'china' },
  { city: 'Haimen', country: 'China', lCity: 'haimen', lCountry: 'china' },
  { city: 'San Luis Potosi', country: 'Mexico', lCity: 'san luis potosi', lCountry: 'mexico' },
  { city: 'Liling', country: 'China', lCity: 'liling', lCountry: 'china' },
  { city: 'Xinhui', country: 'China', lCity: 'xinhui', lCountry: 'china' },
  { city: 'Gaziantep', country: 'Turkey', lCity: 'gaziantep', lCountry: 'turkey' },
  { city: 'Krasnoyarsk', country: 'Russia', lCity: 'krasnoyarsk', lCountry: 'russia' },
  { city: 'Chiba', country: 'Japan', lCity: 'chiba', lCountry: 'japan' },
  { city: 'Voronezh', country: 'Russia', lCity: 'voronezh', lCountry: 'russia' },
  { city: 'Durg Bhilai Nagar', country: 'India', lCity: 'durg bhilai nagar', lCountry: 'india' },
  { city: 'Ruzhou', country: 'China', lCity: 'ruzhou', lCountry: 'china' },
  { city: 'Maceio', country: 'Brazil', lCity: 'maceio', lCountry: 'brazil' },
  { city: 'Yichun', country: 'China', lCity: 'yichun', lCountry: 'china' },
  { city: 'Al Madinah', country: 'Saudi Arabia', lCity: 'al madinah', lCountry: 'saudi arabia' },
  { city: 'Yulin', country: 'China', lCity: 'yulin', lCountry: 'china' },
  { city: 'Seongnam', country: 'South Korea', lCity: 'seongnam', lCountry: 'south korea' },
  { city: 'Yueyang', country: 'China', lCity: 'yueyang', lCountry: 'china' },
  { city: 'Yiwu', country: 'China', lCity: 'yiwu', lCountry: 'china' },
  { city: 'San Jose', country: 'United States', lCity: 'san jose', lCountry: 'united states' },
  { city: 'Jixi', country: 'China', lCity: 'jixi', lCountry: 'china' },
  { city: 'Managua', country: 'Nicaragua', lCity: 'managua', lCountry: 'nicaragua' },
  { city: 'Safi', country: 'Morocco', lCity: 'safi', lCountry: 'morocco' },
  { city: 'Guangyuan', country: 'China', lCity: 'guangyuan', lCountry: 'china' },
  { city: 'Soweto', country: 'South Africa', lCity: 'soweto', lCountry: 'south africa' },
  { city: 'Zhangjiakou', country: 'China', lCity: 'zhangjiakou', lCountry: 'china' },
  { city: 'Baoding', country: 'China', lCity: 'baoding', lCountry: 'china' },
  { city: 'Cartagena', country: 'Colombia', lCity: 'cartagena', lCountry: 'colombia' },
  { city: 'Huludao', country: 'China', lCity: 'huludao', lCountry: 'china' },
  { city: 'Pingdingshan', country: 'China', lCity: 'pingdingshan', lCountry: 'china' },
  { city: 'Torino', country: 'Italy', lCity: 'torino', lCountry: 'italy' },
  { city: 'Zengcheng', country: 'China', lCity: 'zengcheng', lCountry: 'china' },
  { city: 'Laiyang', country: 'China', lCity: 'laiyang', lCountry: 'china' },
  { city: 'Qingzhou', country: 'China', lCity: 'qingzhou', lCountry: 'china' },
  { city: 'Aurangabad', country: 'India', lCity: 'aurangabad', lCountry: 'india' },
  { city: 'Lattakia', country: 'Syria', lCity: 'lattakia', lCountry: 'syria' },
  { city: 'Merida', country: 'Mexico', lCity: 'merida', lCountry: 'mexico' },
  { city: 'Laizhou', country: 'China', lCity: 'laizhou', lCountry: 'china' },
  { city: 'Thiruvananthapuram', country: 'India', lCity: 'thiruvananthapuram', lCountry: 'india' },
  { city: 'Weinan', country: 'China', lCity: 'weinan', lCountry: 'china' },
  { city: 'Wuchang', country: 'China', lCity: 'wuchang', lCountry: 'china' },
  { city: 'Guangshui', country: 'China', lCity: 'guangshui', lCountry: 'china' },
  { city: 'Gaizhou', country: 'China', lCity: 'gaizhou', lCountry: 'china' },
  { city: 'Goteborg', country: 'Sweden', lCity: 'goteborg', lCountry: 'sweden' },
  { city: 'Xiaogan', country: 'China', lCity: 'xiaogan', lCountry: 'china' },
  { city: 'Torreon', country: 'Mexico', lCity: 'torreon', lCountry: 'mexico' },
  { city: 'Jiaxing', country: 'China', lCity: 'jiaxing', lCountry: 'china' },
  { city: 'Kozhikode', country: 'India', lCity: 'kozhikode', lCountry: 'india' },
  { city: 'Sale', country: 'Morocco', lCity: 'sale', lCountry: 'morocco' },
  { city: 'Zhuzhou', country: 'China', lCity: 'zhuzhou', lCountry: 'china' },
  { city: 'Tyneside', country: 'United Kingdom', lCity: 'tyneside', lCountry: 'united kingdom' },
  { city: 'Hengyang', country: 'China', lCity: 'hengyang', lCountry: 'china' },
  { city: 'Dehui', country: 'China', lCity: 'dehui', lCountry: 'china' },
  { city: 'Honghu', country: 'China', lCity: 'honghu', lCountry: 'china' },
  { city: 'Danyang', country: 'China', lCity: 'danyang', lCountry: 'china' },
  { city: 'Daye', country: 'China', lCity: 'daye', lCountry: 'china' },
  { city: 'Solapur', country: 'India', lCity: 'solapur', lCountry: 'india' },
  { city: 'Xingning', country: 'China', lCity: 'xingning', lCountry: 'china' },
  { city: 'Xiangfan', country: 'China', lCity: 'xiangfan', lCountry: 'china' },
  { city: 'Shubra El Khema', country: 'Egypt', lCity: 'shubra el khema', lCountry: 'egypt' },
  { city: 'Luoding', country: 'China', lCity: 'luoding', lCountry: 'china' },
  { city: 'Gwalior', country: 'India', lCity: 'gwalior', lCountry: 'india' },
  { city: 'Ranchi', country: 'India', lCity: 'ranchi', lCountry: 'india' },
  { city: 'Huiyang', country: 'China', lCity: 'huiyang', lCountry: 'china' },
  { city: 'Mombasa', country: 'Kenya', lCity: 'mombasa', lCountry: 'kenya' },
  { city: 'Jinzhou', country: 'China', lCity: 'jinzhou', lCountry: 'china' },
  { city: 'Jiangyan', country: 'China', lCity: 'jiangyan', lCountry: 'china' },
  { city: 'Chenghai', country: 'China', lCity: 'chenghai', lCountry: 'china' },
  { city: 'Jiamusi', country: 'China', lCity: 'jiamusi', lCountry: 'china' },
  { city: 'Songzi', country: 'China', lCity: 'songzi', lCountry: 'china' },
  { city: 'Tegucigalpa', country: 'Honduras', lCity: 'tegucigalpa', lCountry: 'honduras' },
  { city: 'Wujiang', country: 'China', lCity: 'wujiang', lCountry: 'china' },
  { city: 'Jodhpur', country: 'India', lCity: 'jodhpur', lCountry: 'india' },
  { city: 'Duque De Caxias', country: 'Brazil', lCity: 'duque de caxias', lCountry: 'brazil' },
  { city: 'Xining', country: 'China', lCity: 'xining', lCountry: 'china' },
  { city: 'Yuyao', country: 'China', lCity: 'yuyao', lCountry: 'china' },
  { city: 'Hezhou', country: 'China', lCity: 'hezhou', lCountry: 'china' },
  { city: 'Jiangyou', country: 'China', lCity: 'jiangyou', lCountry: 'china' },
  { city: 'Tiruchchirappalli', country: 'India', lCity: 'tiruchchirappalli', lCountry: 'india' },
  { city: 'Baoshan', country: 'China', lCity: 'baoshan', lCountry: 'china' },
  { city: 'Saratov', country: 'Russia', lCity: 'saratov', lCountry: 'russia' },
  { city: 'Nova Iguacu', country: 'Brazil', lCity: 'nova iguacu', lCountry: 'brazil' },
  { city: 'Ankang', country: 'China', lCity: 'ankang', lCountry: 'china' },
  { city: 'Gaomi', country: 'China', lCity: 'gaomi', lCountry: 'china' },
  { city: 'Yangchun', country: 'China', lCity: 'yangchun', lCountry: 'china' },
  {
    city: 'Santiago De Los Caballeros',
    country: 'Dominican Republic',
    lCity: 'santiago de los caballeros',
    lCountry: 'dominican republic',
  },
  { city: 'Danzhou', country: 'China', lCity: 'danzhou', lCountry: 'china' },
  { city: 'La Paz', country: 'Bolivia', lCity: 'la paz', lCountry: 'bolivia' },
  { city: 'Zhuanghe', country: 'China', lCity: 'zhuanghe', lCountry: 'china' },
  { city: 'Zhuhai', country: 'China', lCity: 'zhuhai', lCountry: 'china' },
  { city: 'Zhaodong', country: 'China', lCity: 'zhaodong', lCountry: 'china' },
  { city: 'Sakai', country: 'Japan', lCity: 'sakai', lCountry: 'japan' },
  { city: 'Haikou', country: 'China', lCity: 'haikou', lCountry: 'china' },
  { city: 'Jiaonan', country: 'China', lCity: 'jiaonan', lCountry: 'china' },
  { city: 'El Alto', country: 'Bolivia', lCity: 'el alto', lCountry: 'bolivia' },
  { city: 'Xuancheng', country: 'China', lCity: 'xuancheng', lCountry: 'china' },
  { city: 'Wuchuan', country: 'China', lCity: 'wuchuan', lCountry: 'china' },
  { city: 'Yuhang', country: 'China', lCity: 'yuhang', lCountry: 'china' },
  { city: 'Qinhuangdao', country: 'China', lCity: 'qinhuangdao', lCountry: 'china' },
  { city: 'Bogor', country: 'Indonesia', lCity: 'bogor', lCountry: 'indonesia' },
  { city: 'Kermanshah', country: 'Iran', lCity: 'kermanshah', lCountry: 'iran' },
  { city: 'Longhai', country: 'China', lCity: 'longhai', lCountry: 'china' },
  { city: 'Liverpool', country: 'United Kingdom', lCity: 'liverpool', lCountry: 'united kingdom' },
  { city: 'Yanshi', country: 'China', lCity: 'yanshi', lCountry: 'china' },
  { city: 'Guwahati', country: 'India', lCity: 'guwahati', lCountry: 'india' },
  { city: 'Konya', country: 'Turkey', lCity: 'konya', lCountry: 'turkey' },
  { city: 'Barquisimeto', country: 'Venezuela', lCity: 'barquisimeto', lCountry: 'venezuela' },
  { city: 'Yingde', country: 'China', lCity: 'yingde', lCountry: 'china' },
  { city: 'Bengbu', country: 'China', lCity: 'bengbu', lCountry: 'china' },
  { city: 'Yibin', country: 'China', lCity: 'yibin', lCountry: 'china' },
  { city: 'Chandigarh', country: 'India', lCity: 'chandigarh', lCountry: 'india' },
  { city: 'Xiangxiang', country: 'China', lCity: 'xiangxiang', lCountry: 'china' },
  { city: 'Yinchuan', country: 'China', lCity: 'yinchuan', lCountry: 'china' },
  { city: 'Guilin', country: 'China', lCity: 'guilin', lCountry: 'china' },
  { city: 'Hamamatsu', country: 'Japan', lCity: 'hamamatsu', lCountry: 'japan' },
  {
    city: 'Sao Bernardo Do Campo',
    country: 'Brazil',
    lCity: 'sao bernardo do campo',
    lCountry: 'brazil',
  },
  { city: 'Deir El Zor', country: 'Syria', lCity: 'deir el zor', lCountry: 'syria' },
  { city: 'Bishkek', country: 'Kyrgyzstan', lCity: 'bishkek', lCountry: 'kyrgyzstan' },
  { city: 'Teresina', country: 'Brazil', lCity: 'teresina', lCountry: 'brazil' },
  { city: 'Suihua', country: 'China', lCity: 'suihua', lCountry: 'china' },
  { city: 'Benghazi', country: 'Libya', lCity: 'benghazi', lCountry: 'libya' },
  { city: 'Jiutai', country: 'China', lCity: 'jiutai', lCountry: 'china' },
  { city: 'Meishan', country: 'China', lCity: 'meishan', lCountry: 'china' },
  { city: 'Zaporizhya', country: 'Ukraine', lCity: 'zaporizhya', lCountry: 'ukraine' },
  { city: 'Gaoyou', country: 'China', lCity: 'gaoyou', lCountry: 'china' },
  { city: 'Marseille', country: 'France', lCity: 'marseille', lCountry: 'france' },
  { city: 'Kaifeng', country: 'China', lCity: 'kaifeng', lCountry: 'china' },
  { city: 'Changning', country: 'China', lCity: 'changning', lCountry: 'china' },
  { city: 'Tongliao', country: 'China', lCity: 'tongliao', lCountry: 'china' },
  { city: 'Natal', country: 'Brazil', lCity: 'natal', lCountry: 'brazil' },
  { city: 'Bandar Lampung', country: 'Indonesia', lCity: 'bandar lampung', lCountry: 'indonesia' },
  { city: 'Dongying', country: 'China', lCity: 'dongying', lCountry: 'china' },
  { city: 'Gaoan', country: 'China', lCity: 'gaoan', lCountry: 'china' },
  { city: 'Langzhong', country: 'China', lCity: 'langzhong', lCountry: 'china' },
  { city: 'Lichuan', country: 'China', lCity: 'lichuan', lCountry: 'china' },
  { city: 'Hubli Dharwad', country: 'India', lCity: 'hubli dharwad', lCountry: 'india' },
  { city: 'Mysore', country: 'India', lCity: 'mysore', lCountry: 'india' },
  { city: 'Niigata', country: 'Japan', lCity: 'niigata', lCountry: 'japan' },
  {
    city: 'Indianapolis',
    country: 'United States',
    lCity: 'indianapolis',
    lCountry: 'united states',
  },
  { city: 'Jiaozhou', country: 'China', lCity: 'jiaozhou', lCountry: 'china' },
  { city: 'Pingxiang', country: 'China', lCity: 'pingxiang', lCountry: 'china' },
  { city: 'Haiphong', country: 'Viet Nam', lCity: 'haiphong', lCountry: 'viet nam' },
  { city: 'Arequipa', country: 'Peru', lCity: 'arequipa', lCountry: 'peru' },
  {
    city: 'Jacksonville',
    country: 'United States',
    lCity: 'jacksonville',
    lCountry: 'united states',
  },
  { city: 'Tanger', country: 'Morocco', lCity: 'tanger', lCountry: 'morocco' },
  { city: 'Dandong', country: 'China', lCity: 'dandong', lCountry: 'china' },
  { city: 'Kishinev', country: 'Moldova', lCity: 'kishinev', lCountry: 'moldova' },
  { city: 'Krasnodar', country: 'Russia', lCity: 'krasnodar', lCountry: 'russia' },
  { city: 'Zagreb', country: 'Croatia', lCity: 'zagreb', lCountry: 'croatia' },
  { city: 'Xinmi', country: 'China', lCity: 'xinmi', lCountry: 'china' },
  { city: 'Chaohu', country: 'China', lCity: 'chaohu', lCountry: 'china' },
  { city: 'Xinyu', country: 'China', lCity: 'xinyu', lCountry: 'china' },
  { city: 'Gongyi', country: 'China', lCity: 'gongyi', lCountry: 'china' },
  { city: 'Huixian', country: 'China', lCity: 'huixian', lCountry: 'china' },
  { city: 'Xinxiang', country: 'China', lCity: 'xinxiang', lCountry: 'china' },
  {
    city: 'Port Elizabeth',
    country: 'South Africa',
    lCity: 'port elizabeth',
    lCountry: 'south africa',
  },
  { city: 'Mendoza', country: 'Argentina', lCity: 'mendoza', lCountry: 'argentina' },
  { city: 'Nantong', country: 'China', lCity: 'nantong', lCountry: 'china' },
  { city: 'Pengzhou', country: 'China', lCity: 'pengzhou', lCountry: 'china' },
  { city: 'Khulna', country: 'Bangladesh', lCity: 'khulna', lCountry: 'bangladesh' },
  { city: 'Malang', country: 'Indonesia', lCity: 'malang', lCountry: 'indonesia' },
  { city: 'Padang', country: 'Indonesia', lCity: 'padang', lCountry: 'indonesia' },
  { city: 'Anyang', country: 'China', lCity: 'anyang', lCountry: 'china' },
  { city: 'Renqiu', country: 'China', lCity: 'renqiu', lCountry: 'china' },
  { city: 'Foshan', country: 'China', lCity: 'foshan', lCountry: 'china' },
  { city: 'Anshun', country: 'China', lCity: 'anshun', lCountry: 'china' },
  { city: 'Chihuahua', country: 'Mexico', lCity: 'chihuahua', lCountry: 'mexico' },
  { city: 'Campo Grande', country: 'Brazil', lCity: 'campo grande', lCountry: 'brazil' },
  { city: 'Lodz', country: 'Poland', lCity: 'lodz', lCountry: 'poland' },
  { city: 'Goyang', country: 'South Korea', lCity: 'goyang', lCountry: 'south korea' },
  { city: 'Benin City', country: 'Nigeria', lCity: 'benin city', lCountry: 'nigeria' },
  { city: 'Bucheon', country: 'South Korea', lCity: 'bucheon', lCountry: 'south korea' },
  { city: 'Gaocheng', country: 'China', lCity: 'gaocheng', lCountry: 'china' },
  { city: 'Pulandian', country: 'China', lCity: 'pulandian', lCountry: 'china' },
  { city: 'Hejian', country: 'China', lCity: 'hejian', lCountry: 'china' },
  { city: 'Dafeng', country: 'China', lCity: 'dafeng', lCountry: 'china' },
  { city: 'Krakow', country: 'Poland', lCity: 'krakow', lCountry: 'poland' },
  { city: 'Enshi', country: 'China', lCity: 'enshi', lCountry: 'china' },
  { city: 'Dongyang', country: 'China', lCity: 'dongyang', lCountry: 'china' },
  { city: 'Lviv', country: 'Ukraine', lCity: 'lviv', lCountry: 'ukraine' },
  { city: 'Kunshan', country: 'China', lCity: 'kunshan', lCountry: 'china' },
  { city: 'Shuangcheng', country: 'China', lCity: 'shuangcheng', lCountry: 'china' },
  { city: 'Salem', country: 'India', lCity: 'salem', lCountry: 'india' },
  { city: 'Jiaozuo', country: 'China', lCity: 'jiaozuo', lCountry: 'china' },
  { city: 'Ad Dammam', country: 'Saudi Arabia', lCity: 'ad dammam', lCountry: 'saudi arabia' },
  { city: 'Huaibei', country: 'China', lCity: 'huaibei', lCountry: 'china' },
  { city: 'Liyang', country: 'China', lCity: 'liyang', lCountry: 'china' },
  { city: 'Samut Prakan', country: 'Thailand', lCity: 'samut prakan', lCountry: 'thailand' },
  { city: 'Rongcheng', country: 'China', lCity: 'rongcheng', lCountry: 'china' },
  { city: 'Cenxi', country: 'China', lCity: 'cenxi', lCountry: 'china' },
  { city: 'Nampho', country: 'North Korea', lCity: 'nampho', lCountry: 'north korea' },
  { city: 'Columbus', country: 'United States', lCity: 'columbus', lCountry: 'united states' },
  { city: 'Bareilly', country: 'India', lCity: 'bareilly', lCountry: 'india' },
  { city: 'Leping', country: 'China', lCity: 'leping', lCountry: 'china' },
  { city: 'Laixi', country: 'China', lCity: 'laixi', lCountry: 'china' },
  { city: 'Liaoyang', country: 'China', lCity: 'liaoyang', lCountry: 'china' },
  { city: 'Zhaotong', country: 'China', lCity: 'zhaotong', lCountry: 'china' },
  { city: 'Jerusalem', country: 'Israel', lCity: 'jerusalem', lCountry: 'israel' },
  { city: 'Tainan', country: 'China', lCity: 'tainan', lCountry: 'china' },
  { city: 'Cuernavaca', country: 'Mexico', lCity: 'cuernavaca', lCountry: 'mexico' },
  { city: 'Riga', country: 'Latvia', lCity: 'riga', lCountry: 'latvia' },
  { city: 'Linfen', country: 'China', lCity: 'linfen', lCountry: 'china' },
  { city: 'Quebec', country: 'Canada', lCity: 'quebec', lCountry: 'canada' },
  { city: 'Lingbao', country: 'China', lCity: 'lingbao', lCountry: 'china' },
  { city: 'Shangyu', country: 'China', lCity: 'shangyu', lCountry: 'china' },
  { city: 'Wuan', country: 'China', lCity: 'wuan', lCountry: 'china' },
  { city: 'Hailun', country: 'China', lCity: 'hailun', lCountry: 'china' },
  { city: 'Xingyi', country: 'China', lCity: 'xingyi', lCountry: 'china' },
  { city: 'Wuxue', country: 'China', lCity: 'wuxue', lCountry: 'china' },
  { city: 'Cebu', country: 'Philippines', lCity: 'cebu', lCountry: 'philippines' },
  { city: 'Aguascalientes', country: 'Mexico', lCity: 'aguascalientes', lCountry: 'mexico' },
  { city: 'Tolyatti', country: 'Russia', lCity: 'tolyatti', lCountry: 'russia' },
  { city: 'Hamilton', country: 'Canada', lCity: 'hamilton', lCountry: 'canada' },
  { city: 'Zhoushan', country: 'China', lCity: 'zhoushan', lCountry: 'china' },
  { city: 'Langfang', country: 'China', lCity: 'langfang', lCountry: 'china' },
  { city: 'Osasco', country: 'Brazil', lCity: 'osasco', lCountry: 'brazil' },
  { city: 'Nonthaburi', country: 'Thailand', lCity: 'nonthaburi', lCountry: 'thailand' },
  { city: 'Dashiqiao', country: 'China', lCity: 'dashiqiao', lCountry: 'china' },
  { city: 'Tongxiang', country: 'China', lCity: 'tongxiang', lCountry: 'china' },
  { city: 'Yichang', country: 'China', lCity: 'yichang', lCountry: 'china' },
  { city: 'Yangzhou', country: 'China', lCity: 'yangzhou', lCountry: 'china' },
  { city: 'Blantyre City', country: 'Malawi', lCity: 'blantyre city', lCountry: 'malawi' },
  { city: 'Hamhung', country: 'North Korea', lCity: 'hamhung', lCountry: 'north korea' },
  { city: 'Jalandhar', country: 'India', lCity: 'jalandhar', lCountry: 'india' },
  { city: 'Al Rakka', country: 'Syria', lCity: 'al rakka', lCountry: 'syria' },
  { city: 'Niamey', country: 'Niger', lCity: 'niamey', lCountry: 'niger' },
  { city: 'Xiangtan', country: 'China', lCity: 'xiangtan', lCountry: 'china' },
  { city: 'Winnipeg', country: 'Canada', lCity: 'winnipeg', lCountry: 'canada' },
  { city: 'Oran', country: 'Algeria', lCity: 'oran', lCountry: 'algeria' },
  { city: 'Kota', country: 'India', lCity: 'kota', lCountry: 'india' },
  { city: 'Sevilla', country: 'Spain', lCity: 'sevilla', lCountry: 'spain' },
  { city: 'Navi Mumbai', country: 'India', lCity: 'navi mumbai', lCountry: 'india' },
  { city: 'Port Harcourt', country: 'Nigeria', lCity: 'port harcourt', lCountry: 'nigeria' },
  { city: 'Saltillo', country: 'Mexico', lCity: 'saltillo', lCountry: 'mexico' },
  { city: 'Khartoum North', country: 'Sudan', lCity: 'khartoum north', lCountry: 'sudan' },
  { city: 'Shizuoka', country: 'Japan', lCity: 'shizuoka', lCountry: 'japan' },
  { city: 'Yuanjiang', country: 'China', lCity: 'yuanjiang', lCountry: 'china' },
  { city: 'Raipur', country: 'India', lCity: 'raipur', lCountry: 'india' },
  { city: 'Kryviy Rig', country: 'Ukraine', lCity: 'kryviy rig', lCountry: 'ukraine' },
  { city: 'Yingkou', country: 'China', lCity: 'yingkou', lCountry: 'china' },
  { city: 'Wuhu', country: 'China', lCity: 'wuhu', lCountry: 'china' },
  { city: 'Zhenjiang', country: 'China', lCity: 'zhenjiang', lCountry: 'china' },
  { city: 'Queretaro', country: 'Mexico', lCity: 'queretaro', lCountry: 'mexico' },
  { city: 'Nankang', country: 'China', lCity: 'nankang', lCountry: 'china' },
  { city: 'Wugang', country: 'China', lCity: 'wugang', lCountry: 'china' },
  { city: 'Hegang', country: 'China', lCity: 'hegang', lCountry: 'china' },
  { city: 'Linqing', country: 'China', lCity: 'linqing', lCountry: 'china' },
  { city: 'Pretoria', country: 'South Africa', lCity: 'pretoria', lCountry: 'south africa' },
  { city: 'Zunyi', country: 'China', lCity: 'zunyi', lCountry: 'china' },
  { city: 'Panzhihua', country: 'China', lCity: 'panzhihua', lCountry: 'china' },
  { city: 'Austin', country: 'United States', lCity: 'austin', lCountry: 'united states' },
  { city: 'Changle', country: 'China', lCity: 'changle', lCountry: 'china' },
  { city: 'Lianyungang', country: 'China', lCity: 'lianyungang', lCountry: 'china' },
  { city: 'Yancheng', country: 'China', lCity: 'yancheng', lCountry: 'china' },
  { city: 'Zunhua', country: 'China', lCity: 'zunhua', lCountry: 'china' },
  { city: 'Changyi', country: 'China', lCity: 'changyi', lCountry: 'china' },
  { city: 'Meknes', country: 'Morocco', lCity: 'meknes', lCountry: 'morocco' },
  { city: 'Qiongshan', country: 'China', lCity: 'qiongshan', lCountry: 'china' },
  { city: 'Bulawayo', country: 'Zimbabwe', lCity: 'bulawayo', lCountry: 'zimbabwe' },
  { city: 'Wendeng', country: 'China', lCity: 'wendeng', lCountry: 'china' },
  { city: 'Okayama', country: 'Japan', lCity: 'okayama', lCountry: 'japan' },
  { city: 'Santo Andre', country: 'Brazil', lCity: 'santo andre', lCountry: 'brazil' },
  { city: 'Rabat', country: 'Morocco', lCity: 'rabat', lCountry: 'morocco' },
  { city: 'Pakanbaru', country: 'Indonesia', lCity: 'pakanbaru', lCountry: 'indonesia' },
  { city: 'Nehe', country: 'China', lCity: 'nehe', lCountry: 'china' },
  { city: 'Memphis', country: 'United States', lCity: 'memphis', lCountry: 'united states' },
  { city: 'Joao Pessoa', country: 'Brazil', lCity: 'joao pessoa', lCountry: 'brazil' },
  { city: 'Kathmandu', country: 'Nepal', lCity: 'kathmandu', lCountry: 'nepal' },
  { city: 'Longkou', country: 'China', lCity: 'longkou', lCountry: 'china' },
  { city: 'Shengzhou', country: 'China', lCity: 'shengzhou', lCountry: 'china' },
  { city: 'Antalya', country: 'Turkey', lCity: 'antalya', lCountry: 'turkey' },
  { city: 'Kumamoto', country: 'Japan', lCity: 'kumamoto', lCountry: 'japan' },
  { city: 'Lilongwe', country: 'Malawi', lCity: 'lilongwe', lCountry: 'malawi' },
  { city: 'Mexicali', country: 'Mexico', lCity: 'mexicali', lCountry: 'mexico' },
  { city: 'Kaiping', country: 'China', lCity: 'kaiping', lCountry: 'china' },
  { city: 'Palermo', country: 'Italy', lCity: 'palermo', lCountry: 'italy' },
  { city: 'Aligarh', country: 'India', lCity: 'aligarh', lCountry: 'india' },
  {
    city: 'Nottingham',
    country: 'United Kingdom',
    lCity: 'nottingham',
    lCountry: 'united kingdom',
  },
  { city: 'Haining', country: 'China', lCity: 'haining', lCountry: 'china' },
  { city: 'Mosul', country: 'Iraq', lCity: 'mosul', lCountry: 'iraq' },
  { city: 'Hermosillo', country: 'Mexico', lCity: 'hermosillo', lCountry: 'mexico' },
  { city: 'Tongcheng', country: 'China', lCity: 'tongcheng', lCountry: 'china' },
  { city: 'Shulan', country: 'China', lCity: 'shulan', lCountry: 'china' },
  { city: 'Miluo', country: 'China', lCity: 'miluo', lCountry: 'china' },
  { city: 'Bhubaneswar', country: 'India', lCity: 'bhubaneswar', lCountry: 'india' },
  { city: 'Yangquan', country: 'China', lCity: 'yangquan', lCountry: 'china' },
  { city: 'Chenzhou', country: 'China', lCity: 'chenzhou', lCountry: 'china' },
  { city: 'Haiyang', country: 'China', lCity: 'haiyang', lCountry: 'china' },
  { city: 'Morelia', country: 'Mexico', lCity: 'morelia', lCountry: 'mexico' },
  { city: 'Huangshi', country: 'China', lCity: 'huangshi', lCountry: 'china' },
  { city: 'Xinmin', country: 'China', lCity: 'xinmin', lCountry: 'china' },
  { city: 'Tetouan', country: 'Morocco', lCity: 'tetouan', lCountry: 'morocco' },
  { city: 'Barnaul', country: 'Russia', lCity: 'barnaul', lCountry: 'russia' },
  { city: 'Qixia', country: 'China', lCity: 'qixia', lCountry: 'china' },
  {
    city: 'Jaboatao Dos Guarapes',
    country: 'Brazil',
    lCity: 'jaboatao dos guarapes',
    lCountry: 'brazil',
  },
  { city: 'Chongzhou', country: 'China', lCity: 'chongzhou', lCountry: 'china' },
  { city: 'Cotonou', country: 'Benin', lCity: 'cotonou', lCountry: 'benin' },
  { city: 'Yingcheng', country: 'China', lCity: 'yingcheng', lCountry: 'china' },
  { city: 'Zaragoza', country: 'Spain', lCity: 'zaragoza', lCountry: 'spain' },
  { city: 'Changzhi', country: 'China', lCity: 'changzhi', lCountry: 'china' },
  { city: 'Qujing', country: 'China', lCity: 'qujing', lCountry: 'china' },
  { city: 'Linghai', country: 'China', lCity: 'linghai', lCountry: 'china' },
  { city: 'Changge', country: 'China', lCity: 'changge', lCountry: 'china' },
  { city: 'Trujillo', country: 'Peru', lCity: 'trujillo', lCountry: 'peru' },
  { city: 'Tampico', country: 'Mexico', lCity: 'tampico', lCountry: 'mexico' },
  { city: 'Maoming', country: 'China', lCity: 'maoming', lCountry: 'china' },
  { city: 'Moron', country: 'Argentina', lCity: 'moron', lCountry: 'argentina' },
  { city: 'La Plata', country: 'Argentina', lCity: 'la plata', lCountry: 'argentina' },
  { city: 'Ciudad Guayana', country: 'Venezuela', lCity: 'ciudad guayana', lCountry: 'venezuela' },
  { city: 'Moradabad', country: 'India', lCity: 'moradabad', lCountry: 'india' },
  { city: 'Jieshou', country: 'China', lCity: 'jieshou', lCountry: 'china' },
  { city: 'Sheffield', country: 'United Kingdom', lCity: 'sheffield', lCountry: 'united kingdom' },
  { city: 'Donggang', country: 'China', lCity: 'donggang', lCountry: 'china' },
  { city: 'Jingjiang', country: 'China', lCity: 'jingjiang', lCountry: 'china' },
  { city: 'Acheng', country: 'China', lCity: 'acheng', lCountry: 'china' },
  { city: 'Acapulco', country: 'Mexico', lCity: 'acapulco', lCountry: 'mexico' },
  { city: 'Veracruz', country: 'Mexico', lCity: 'veracruz', lCountry: 'mexico' },
  { city: 'Ulyanovsk', country: 'Russia', lCity: 'ulyanovsk', lCountry: 'russia' },
  { city: 'Wroclaw', country: 'Poland', lCity: 'wroclaw', lCountry: 'poland' },
  { city: 'Jieyang', country: 'China', lCity: 'jieyang', lCountry: 'china' },
  { city: 'Shaoxing', country: 'China', lCity: 'shaoxing', lCountry: 'china' },
  { city: 'Qianan', country: 'China', lCity: 'qianan', lCountry: 'china' },
  { city: 'Nanchuan', country: 'China', lCity: 'nanchuan', lCountry: 'china' },
  { city: 'Qionglai', country: 'China', lCity: 'qionglai', lCountry: 'china' },
  { city: 'Deyang', country: 'China', lCity: 'deyang', lCountry: 'china' },
  { city: 'Sagamihara', country: 'Japan', lCity: 'sagamihara', lCountry: 'japan' },
  { city: 'Fuxin', country: 'China', lCity: 'fuxin', lCountry: 'china' },
  { city: 'Jiyuan', country: 'China', lCity: 'jiyuan', lCountry: 'china' },
  { city: 'Puente Alto', country: 'Chile', lCity: 'puente alto', lCountry: 'chile' },
  { city: 'Qufu', country: 'China', lCity: 'qufu', lCountry: 'china' },
  { city: 'Gaoyao', country: 'China', lCity: 'gaoyao', lCountry: 'china' },
  { city: 'Gorakhpur', country: 'India', lCity: 'gorakhpur', lCountry: 'india' },
  { city: 'Fort Worth', country: 'United States', lCity: 'fort worth', lCountry: 'united states' },
  { city: 'Xinji', country: 'China', lCity: 'xinji', lCountry: 'china' },
  {
    city: 'San Miguel De Tucuman',
    country: 'Argentina',
    lCity: 'san miguel de tucuman',
    lCountry: 'argentina',
  },
  { city: 'Dujiangyan', country: 'China', lCity: 'dujiangyan', lCountry: 'china' },
  { city: 'The Hague', country: 'Netherlands', lCity: 'the hague', lCountry: 'netherlands' },
  { city: 'Bhiwandi', country: 'India', lCity: 'bhiwandi', lCountry: 'india' },
  { city: 'Culiacan Rosales', country: 'Mexico', lCity: 'culiacan rosales', lCountry: 'mexico' },
  { city: 'Lingyuan', country: 'China', lCity: 'lingyuan', lCountry: 'china' },
  { city: 'Xingyang', country: 'China', lCity: 'xingyang', lCountry: 'china' },
  { city: 'Maiduguri', country: 'Nigeria', lCity: 'maiduguri', lCountry: 'nigeria' },
  { city: 'Genova', country: 'Italy', lCity: 'genova', lCountry: 'italy' },
  { city: 'Meihekou', country: 'China', lCity: 'meihekou', lCountry: 'china' },
  { city: 'Izhevsk', country: 'Russia', lCity: 'izhevsk', lCountry: 'russia' },
  { city: 'Jeonju', country: 'South Korea', lCity: 'jeonju', lCountry: 'south korea' },
  { city: 'Leling', country: 'China', lCity: 'leling', lCountry: 'china' },
  { city: 'Xichang', country: 'China', lCity: 'xichang', lCountry: 'china' },
  { city: 'Zaria', country: 'Nigeria', lCity: 'zaria', lCountry: 'nigeria' },
  { city: 'Anlu', country: 'China', lCity: 'anlu', lCountry: 'china' },
  {
    city: 'Sao Jose Dos Campos',
    country: 'Brazil',
    lCity: 'sao jose dos campos',
    lCountry: 'brazil',
  },
  { city: 'Charlotte', country: 'United States', lCity: 'charlotte', lCountry: 'united states' },
  { city: 'Yizheng', country: 'China', lCity: 'yizheng', lCountry: 'china' },
  { city: 'Malmo', country: 'Sweden', lCity: 'malmo', lCountry: 'sweden' },
  { city: 'Weihai', country: 'China', lCity: 'weihai', lCountry: 'china' },
  { city: 'Xinzheng', country: 'China', lCity: 'xinzheng', lCountry: 'china' },
  { city: 'Dengfeng', country: 'China', lCity: 'dengfeng', lCountry: 'china' },
  { city: 'Vladivostok', country: 'Russia', lCity: 'vladivostok', lCountry: 'russia' },
  { city: 'Shaoyang', country: 'China', lCity: 'shaoyang', lCountry: 'china' },
  { city: 'Jammu', country: 'India', lCity: 'jammu', lCountry: 'india' },
  { city: 'Lanxi', country: 'China', lCity: 'lanxi', lCountry: 'china' },
  { city: 'Yuncheng', country: 'China', lCity: 'yuncheng', lCountry: 'china' },
  { city: 'Kagoshima', country: 'Japan', lCity: 'kagoshima', lCountry: 'japan' },
  { city: 'Yaroslave', country: 'Russia', lCity: 'yaroslave', lCountry: 'russia' },
  { city: 'Contagem', country: 'Brazil', lCity: 'contagem', lCountry: 'brazil' },
  { city: 'Shishou', country: 'China', lCity: 'shishou', lCountry: 'china' },
  { city: 'Panjin', country: 'China', lCity: 'panjin', lCountry: 'china' },
  { city: 'Zamboanga', country: 'Philippines', lCity: 'zamboanga', lCountry: 'philippines' },
  { city: 'Orumiyeh', country: 'Iran', lCity: 'orumiyeh', lCountry: 'iran' },
  { city: 'Binzhou', country: 'China', lCity: 'binzhou', lCountry: 'china' },
  { city: 'Kisumu', country: 'Kenya', lCity: 'kisumu', lCountry: 'kenya' },
  { city: 'Baoji', country: 'China', lCity: 'baoji', lCountry: 'china' },
  { city: 'Uberlandia', country: 'Brazil', lCity: 'uberlandia', lCountry: 'brazil' },
  { city: 'El Paso', country: 'United States', lCity: 'el paso', lCountry: 'united states' },
  { city: 'Yunzhou', country: 'China', lCity: 'yunzhou', lCountry: 'china' },
  { city: 'Kenitra', country: 'Morocco', lCity: 'kenitra', lCountry: 'morocco' },
  { city: 'Diyarbakir', country: 'Turkey', lCity: 'diyarbakir', lCountry: 'turkey' },
  { city: 'Jurong', country: 'China', lCity: 'jurong', lCountry: 'china' },
  { city: 'Cucuta', country: 'Colombia', lCity: 'cucuta', lCountry: 'colombia' },
  { city: 'Zhaoyuan', country: 'China', lCity: 'zhaoyuan', lCountry: 'china' },
  { city: 'Huizhou', country: 'China', lCity: 'huizhou', lCountry: 'china' },
  { city: 'Tianchang', country: 'China', lCity: 'tianchang', lCountry: 'china' },
  { city: 'Dortmund', country: 'Germany', lCity: 'dortmund', lCountry: 'germany' },
  { city: 'Shihezi', country: 'China', lCity: 'shihezi', lCountry: 'china' },
  { city: 'Shiyan', country: 'China', lCity: 'shiyan', lCountry: 'china' },
  { city: 'Cuttack', country: 'India', lCity: 'cuttack', lCountry: 'india' },
  { city: 'Cochabamba', country: 'Bolivia', lCity: 'cochabamba', lCountry: 'bolivia' },
  { city: 'Cheongju', country: 'South Korea', lCity: 'cheongju', lCountry: 'south korea' },
  { city: 'Jingmen', country: 'China', lCity: 'jingmen', lCountry: 'china' },
  { city: 'Shangzhi', country: 'China', lCity: 'shangzhi', lCountry: 'china' },
  { city: 'Anqing', country: 'China', lCity: 'anqing', lCountry: 'china' },
  { city: 'Chongjin', country: 'North Korea', lCity: 'chongjin', lCountry: 'north korea' },
  { city: 'Stuttgart', country: 'Germany', lCity: 'stuttgart', lCountry: 'germany' },
  { city: 'Rushan', country: 'China', lCity: 'rushan', lCountry: 'china' },
  { city: 'Kingston', country: 'Jamaica', lCity: 'kingston', lCountry: 'jamaica' },
  { city: 'Milwaukee', country: 'United States', lCity: 'milwaukee', lCountry: 'united states' },
  { city: 'Sorocaba', country: 'Brazil', lCity: 'sorocaba', lCountry: 'brazil' },
  { city: 'Glasgow', country: 'United Kingdom', lCity: 'glasgow', lCountry: 'united kingdom' },
  { city: 'Khabarovsk', country: 'Russia', lCity: 'khabarovsk', lCountry: 'russia' },
  { city: 'Guanghan', country: 'China', lCity: 'guanghan', lCountry: 'china' },
  { city: 'Warangal', country: 'India', lCity: 'warangal', lCountry: 'india' },
  { city: 'Irkutsk', country: 'Russia', lCity: 'irkutsk', lCountry: 'russia' },
  { city: 'Tyumen', country: 'Russia', lCity: 'tyumen', lCountry: 'russia' },
  {
    city: 'Lomas De Zamora',
    country: 'Argentina',
    lCity: 'lomas de zamora',
    lCountry: 'argentina',
  },
  { city: 'Beipiao', country: 'China', lCity: 'beipiao', lCountry: 'china' },
  { city: 'Funabashi', country: 'Japan', lCity: 'funabashi', lCountry: 'japan' },
  { city: 'Mingguang', country: 'China', lCity: 'mingguang', lCountry: 'china' },
  { city: 'Dusseldorf', country: 'Germany', lCity: 'dusseldorf', lCountry: 'germany' },
  { city: 'Shenzhou', country: 'China', lCity: 'shenzhou', lCountry: 'china' },
  { city: 'Icel', country: 'Turkey', lCity: 'icel', lCountry: 'turkey' },
  { city: 'Zhangzhou', country: 'China', lCity: 'zhangzhou', lCountry: 'china' },
  { city: 'Xianning', country: 'China', lCity: 'xianning', lCountry: 'china' },
  { city: 'Maanshan', country: 'China', lCity: 'maanshan', lCountry: 'china' },
  { city: 'Bandjarmasin', country: 'Indonesia', lCity: 'bandjarmasin', lCountry: 'indonesia' },
  { city: 'Callao', country: 'Peru', lCity: 'callao', lCountry: 'peru' },
  { city: 'Poznan', country: 'Poland', lCity: 'poznan', lCountry: 'poland' },
  { city: 'Kayseri', country: 'Turkey', lCity: 'kayseri', lCountry: 'turkey' },
  { city: 'Chon Buri', country: 'Thailand', lCity: 'chon buri', lCountry: 'thailand' },
  { city: 'Quetta', country: 'Pakistan', lCity: 'quetta', lCountry: 'pakistan' },
  { city: 'Shuozhou', country: 'China', lCity: 'shuozhou', lCountry: 'china' },
  { city: 'Samarinda', country: 'Indonesia', lCity: 'samarinda', lCountry: 'indonesia' },
  { city: 'Helsinki', country: 'Finland', lCity: 'helsinki', lCountry: 'finland' },
  { city: 'Akesu', country: 'China', lCity: 'akesu', lCountry: 'china' },
  { city: 'Novokuznetsk', country: 'Russia', lCity: 'novokuznetsk', lCountry: 'russia' },
  { city: 'Malaga', country: 'Spain', lCity: 'malaga', lCountry: 'spain' },
  { city: 'Hachioji', country: 'Japan', lCity: 'hachioji', lCountry: 'japan' },
  { city: 'Ribeirao Preto', country: 'Brazil', lCity: 'ribeirao preto', lCountry: 'brazil' },
  { city: 'Beihai', country: 'China', lCity: 'beihai', lCountry: 'china' },
  { city: 'Jamnagar', country: 'India', lCity: 'jamnagar', lCountry: 'india' },
  { city: 'Nouakchott', country: 'Mauritania', lCity: 'nouakchott', lCountry: 'mauritania' },
  { city: 'Bazhou', country: 'China', lCity: 'bazhou', lCountry: 'china' },
  { city: 'Yongkang', country: 'China', lCity: 'yongkang', lCountry: 'china' },
  { city: 'Louisville', country: 'United States', lCity: 'louisville', lCountry: 'united states' },
  { city: 'Chizhou', country: 'China', lCity: 'chizhou', lCountry: 'china' },
  { city: 'Huaiyin', country: 'China', lCity: 'huaiyin', lCountry: 'china' },
  { city: 'Fuan', country: 'China', lCity: 'fuan', lCountry: 'china' },
  { city: 'Bhilai Nagar', country: 'India', lCity: 'bhilai nagar', lCountry: 'india' },
  { city: 'Dezhou', country: 'China', lCity: 'dezhou', lCountry: 'china' },
  { city: 'Makhachkala', country: 'Russia', lCity: 'makhachkala', lCountry: 'russia' },
  { city: 'Xingping', country: 'China', lCity: 'xingping', lCountry: 'china' },
  { city: 'Jiujiang', country: 'China', lCity: 'jiujiang', lCountry: 'china' },
  { city: 'Bristol', country: 'United Kingdom', lCity: 'bristol', lCountry: 'united kingdom' },
  { city: 'Botou', country: 'China', lCity: 'botou', lCountry: 'china' },
  { city: 'Fengnan', country: 'China', lCity: 'fengnan', lCountry: 'china' },
  { city: 'Astana', country: 'Kazakhstan', lCity: 'astana', lCountry: 'kazakhstan' },
  { city: 'Yizhou', country: 'China', lCity: 'yizhou', lCountry: 'china' },
  { city: 'Amravati', country: 'India', lCity: 'amravati', lCountry: 'india' },
  {
    city: 'Nashville Davidson',
    country: 'United States',
    lCity: 'nashville davidson',
    lCountry: 'united states',
  },
  { city: 'Batam', country: 'Indonesia', lCity: 'batam', lCountry: 'indonesia' },
  { city: 'Orenburg', country: 'Russia', lCity: 'orenburg', lCountry: 'russia' },
  { city: 'Zhuozhou', country: 'China', lCity: 'zhuozhou', lCountry: 'china' },
  { city: 'Las Vegas', country: 'United States', lCity: 'las vegas', lCountry: 'united states' },
  { city: 'Cancun', country: 'Mexico', lCity: 'cancun', lCountry: 'mexico' },
  { city: 'Longyan', country: 'China', lCity: 'longyan', lCountry: 'china' },
  { city: 'Oslo', country: 'Norway', lCity: 'oslo', lCountry: 'norway' },
  { city: 'Cuiaba', country: 'Brazil', lCity: 'cuiaba', lCountry: 'brazil' },
  { city: 'Tiruppur', country: 'India', lCity: 'tiruppur', lCountry: 'india' },
  { city: 'Vilnius', country: 'Lithuania', lCity: 'vilnius', lCountry: 'lithuania' },
  { city: 'Bremen', country: 'Germany', lCity: 'bremen', lCountry: 'germany' },
  {
    city: 'Gold Coast Tweed',
    country: 'Australia',
    lCity: 'gold coast tweed',
    lCountry: 'australia',
  },
  { city: 'Gaobeidian', country: 'China', lCity: 'gaobeidian', lCountry: 'china' },
  { city: 'Mangalore', country: 'India', lCity: 'mangalore', lCountry: 'india' },
  { city: 'Songyuan', country: 'China', lCity: 'songyuan', lCountry: 'china' },
  { city: 'Yangjiang', country: 'China', lCity: 'yangjiang', lCountry: 'china' },
  { city: 'Wanyuan', country: 'China', lCity: 'wanyuan', lCountry: 'china' },
  { city: 'Jiangmen', country: 'China', lCity: 'jiangmen', lCountry: 'china' },
  { city: 'Xingtai', country: 'China', lCity: 'xingtai', lCountry: 'china' },
  { city: 'Shaoguan', country: 'China', lCity: 'shaoguan', lCountry: 'china' },
  { city: 'Feira De Santana', country: 'Brazil', lCity: 'feira de santana', lCountry: 'brazil' },
  { city: 'Guixi', country: 'China', lCity: 'guixi', lCountry: 'china' },
  { city: 'Ruijin', country: 'China', lCity: 'ruijin', lCountry: 'china' },
  { city: 'Zahedan', country: 'Iran', lCity: 'zahedan', lCountry: 'iran' },
  { city: 'Jinzhong', country: 'China', lCity: 'jinzhong', lCountry: 'china' },
  { city: 'Portland', country: 'United States', lCity: 'portland', lCountry: 'united states' },
  { city: 'Jintan', country: 'China', lCity: 'jintan', lCountry: 'china' },
  { city: 'Reynosa', country: 'Mexico', lCity: 'reynosa', lCountry: 'mexico' },
  { city: 'Ilorin', country: 'Nigeria', lCity: 'ilorin', lCountry: 'nigeria' },
  {
    city: 'Oklahoma City',
    country: 'United States',
    lCity: 'oklahoma city',
    lCountry: 'united states',
  },
  {
    city: 'Nakhon Ratchasima',
    country: 'Thailand',
    lCity: 'nakhon ratchasima',
    lCountry: 'thailand',
  },
  { city: 'Ndjamena', country: 'Chad', lCity: 'ndjamena', lCountry: 'chad' },
  { city: 'Shangzhou', country: 'China', lCity: 'shangzhou', lCountry: 'china' },
  { city: 'Panshi', country: 'China', lCity: 'panshi', lCountry: 'china' },
  { city: 'Kerman', country: 'Iran', lCity: 'kerman', lCountry: 'iran' },
  { city: 'Kaiyuan', country: 'China', lCity: 'kaiyuan', lCountry: 'china' },
  { city: 'Islamabad', country: 'Pakistan', lCity: 'islamabad', lCountry: 'pakistan' },
  {
    city: 'Sarajevo',
    country: 'Bosnia And Herzegovina',
    lCity: 'sarajevo',
    lCountry: 'bosnia and herzegovina',
  },
  { city: 'Bikaner', country: 'India', lCity: 'bikaner', lCountry: 'india' },
  { city: 'Dushanbe', country: 'Tajikistan', lCity: 'dushanbe', lCountry: 'tajikistan' },
  { city: 'Vientiane', country: 'Laos', lCity: 'vientiane', lCountry: 'laos' },
  { city: 'Dehradun', country: 'India', lCity: 'dehradun', lCountry: 'india' },
  { city: 'Zhangshu', country: 'China', lCity: 'zhangshu', lCountry: 'china' },
  { city: 'Beining', country: 'China', lCity: 'beining', lCountry: 'china' },
  {
    city: 'Abu Dhabi',
    country: 'United Arab Emirates',
    lCity: 'abu dhabi',
    lCountry: 'united arab emirates',
  },
  { city: 'Shimkent', country: 'Kazakhstan', lCity: 'shimkent', lCountry: 'kazakhstan' },
  { city: 'Xingcheng', country: 'China', lCity: 'xingcheng', lCountry: 'china' },
  { city: 'Imbaba', country: 'Egypt', lCity: 'imbaba', lCountry: 'egypt' },
  { city: 'Yicheng', country: 'China', lCity: 'yicheng', lCountry: 'china' },
  { city: 'Skoplje', country: 'Macedonia', lCity: 'skoplje', lCountry: 'macedonia' },
  { city: 'Kadhimain', country: 'Iraq', lCity: 'kadhimain', lCountry: 'iraq' },
  { city: 'At Taif', country: 'Saudi Arabia', lCity: 'at taif', lCountry: 'saudi arabia' },
  { city: 'Dali', country: 'China', lCity: 'dali', lCountry: 'china' },
  { city: 'Fuding', country: 'China', lCity: 'fuding', lCountry: 'china' },
  { city: 'Renhuai', country: 'China', lCity: 'renhuai', lCountry: 'china' },
  { city: 'Mira Bhayandar', country: 'India', lCity: 'mira bhayandar', lCountry: 'india' },
  { city: 'Kemerovo', country: 'Russia', lCity: 'kemerovo', lCountry: 'russia' },
  { city: 'Duisburg', country: 'Germany', lCity: 'duisburg', lCountry: 'germany' },
  { city: 'Rasht', country: 'Iran', lCity: 'rasht', lCountry: 'iran' },
  { city: 'San Pedro Sula', country: 'Honduras', lCity: 'san pedro sula', lCountry: 'honduras' },
  { city: 'Bucaramanga', country: 'Colombia', lCity: 'bucaramanga', lCountry: 'colombia' },
  { city: 'Bhavnagar', country: 'India', lCity: 'bhavnagar', lCountry: 'india' },
  { city: 'Conghua', country: 'China', lCity: 'conghua', lCountry: 'china' },
  { city: 'Changwon', country: 'South Korea', lCity: 'changwon', lCountry: 'south korea' },
  { city: 'Mianzhu', country: 'China', lCity: 'mianzhu', lCountry: 'china' },
  { city: 'Pohang', country: 'South Korea', lCity: 'pohang', lCountry: 'south korea' },
  { city: 'Tucson', country: 'United States', lCity: 'tucson', lCountry: 'united states' },
  { city: 'Taicang', country: 'China', lCity: 'taicang', lCountry: 'china' },
  { city: 'Matsuyama', country: 'Japan', lCity: 'matsuyama', lCountry: 'japan' },
  { city: 'Hannover', country: 'Germany', lCity: 'hannover', lCountry: 'germany' },
  { city: 'Guntur', country: 'India', lCity: 'guntur', lCountry: 'india' },
  { city: 'Linan', country: 'China', lCity: 'linan', lCountry: 'china' },
  { city: 'Higashiosaka', country: 'Japan', lCity: 'higashiosaka', lCountry: 'japan' },
  { city: 'Wanning', country: 'China', lCity: 'wanning', lCountry: 'china' },
  { city: 'Mar Del Plata', country: 'Argentina', lCity: 'mar del plata', lCountry: 'argentina' },
  { city: 'Ryazan', country: 'Russia', lCity: 'ryazan', lCountry: 'russia' },
  { city: 'Quilmes', country: 'Argentina', lCity: 'quilmes', lCountry: 'argentina' },
  { city: 'Tomsk', country: 'Russia', lCity: 'tomsk', lCountry: 'russia' },
  { city: 'Linhe', country: 'China', lCity: 'linhe', lCountry: 'china' },
  { city: 'Chibi', country: 'China', lCity: 'chibi', lCountry: 'china' },
  { city: 'Jos', country: 'Nigeria', lCity: 'jos', lCountry: 'nigeria' },
  { city: 'Penza', country: 'Russia', lCity: 'penza', lCountry: 'russia' },
  { city: 'Laohekou', country: 'China', lCity: 'laohekou', lCountry: 'china' },
  { city: 'Wenchang', country: 'China', lCity: 'wenchang', lCountry: 'china' },
  { city: 'Juiz De Fora', country: 'Brazil', lCity: 'juiz de fora', lCountry: 'brazil' },
  { city: 'Zhijiang', country: 'China', lCity: 'zhijiang', lCountry: 'china' },
  { city: 'Newcastle', country: 'Australia', lCity: 'newcastle', lCountry: 'australia' },
  { city: 'Pinghu', country: 'China', lCity: 'pinghu', lCountry: 'china' },
  { city: 'Zhaoqing', country: 'China', lCity: 'zhaoqing', lCountry: 'china' },
  {
    city: 'Naberezhnye Tchelny',
    country: 'Russia',
    lCity: 'naberezhnye tchelny',
    lCountry: 'russia',
  },
  { city: 'Qingyuan', country: 'China', lCity: 'qingyuan', lCountry: 'china' },
  { city: 'Tula', country: 'Russia', lCity: 'tula', lCountry: 'russia' },
  { city: 'Belgaum', country: 'India', lCity: 'belgaum', lCountry: 'india' },
  { city: 'Pondicherry', country: 'India', lCity: 'pondicherry', lCountry: 'india' },
  { city: 'Aracaju', country: 'Brazil', lCity: 'aracaju', lCountry: 'brazil' },
  { city: 'Pasig', country: 'Philippines', lCity: 'pasig', lCountry: 'philippines' },
  { city: 'Mykolayiv', country: 'Ukraine', lCity: 'mykolayiv', lCountry: 'ukraine' },
  { city: 'Ibague', country: 'Colombia', lCity: 'ibague', lCountry: 'colombia' },
  { city: 'Hanzhong', country: 'China', lCity: 'hanzhong', lCountry: 'china' },
  { city: 'Tlaxcala', country: 'Mexico', lCity: 'tlaxcala', lCountry: 'mexico' },
  { city: 'Chuxiong', country: 'China', lCity: 'chuxiong', lCountry: 'china' },
  { city: 'Denpasar', country: 'Indonesia', lCity: 'denpasar', lCountry: 'indonesia' },
  { city: 'Lipetsk', country: 'Russia', lCity: 'lipetsk', lCountry: 'russia' },
  { city: 'Dengta', country: 'China', lCity: 'dengta', lCountry: 'china' },
  { city: 'Tuxtla Gutierrez', country: 'Mexico', lCity: 'tuxtla gutierrez', lCountry: 'mexico' },
  { city: 'Songkhla', country: 'Thailand', lCity: 'songkhla', lCountry: 'thailand' },
  { city: 'Mariupol', country: 'Ukraine', lCity: 'mariupol', lCountry: 'ukraine' },
  { city: 'Danjiangkou', country: 'China', lCity: 'danjiangkou', lCountry: 'china' },
  { city: 'Chiclayo', country: 'Peru', lCity: 'chiclayo', lCountry: 'peru' },
  { city: 'Copenhagen', country: 'Denmark', lCity: 'copenhagen', lCountry: 'denmark' },
  { city: 'Penglai', country: 'China', lCity: 'penglai', lCountry: 'china' },
  { city: 'Oaxaca De Juarez', country: 'Mexico', lCity: 'oaxaca de juarez', lCountry: 'mexico' },
  { city: 'Aba', country: 'Nigeria', lCity: 'aba', lCountry: 'nigeria' },
  { city: 'Astrakhan', country: 'Russia', lCity: 'astrakhan', lCountry: 'russia' },
  { city: 'Macao', country: 'China Macao Sar', lCity: 'macao', lCountry: 'china macao sar' },
  { city: 'Eskisehir', country: 'Turkey', lCity: 'eskisehir', lCountry: 'turkey' },
  { city: 'Shishi', country: 'China', lCity: 'shishi', lCountry: 'china' },
  { city: 'Kannur', country: 'India', lCity: 'kannur', lCountry: 'india' },
  { city: 'Ananindeua', country: 'Brazil', lCity: 'ananindeua', lCountry: 'brazil' },
  { city: 'Bokaro Steel City', country: 'India', lCity: 'bokaro steel city', lCountry: 'india' },
  { city: 'San Salvador', country: 'El Salvador', lCity: 'san salvador', lCountry: 'el salvador' },
  { city: 'Kolhapur', country: 'India', lCity: 'kolhapur', lCountry: 'india' },
  { city: 'Xinzhou', country: 'China', lCity: 'xinzhou', lCountry: 'china' },
  { city: 'Joinville', country: 'Brazil', lCity: 'joinville', lCountry: 'brazil' },
  { city: 'Dangyang', country: 'China', lCity: 'dangyang', lCountry: 'china' },
  { city: 'Londrina', country: 'Brazil', lCity: 'londrina', lCountry: 'brazil' },
  { city: 'Hebi', country: 'China', lCity: 'hebi', lCountry: 'china' },
  { city: 'Ganzhou', country: 'China', lCity: 'ganzhou', lCountry: 'china' },
  { city: 'Hamadan', country: 'Iran', lCity: 'hamadan', lCountry: 'iran' },
  { city: 'Yucheng', country: 'China', lCity: 'yucheng', lCountry: 'china' },
  {
    city: 'Albuquerque',
    country: 'United States',
    lCity: 'albuquerque',
    lCountry: 'united states',
  },
  { city: 'Agadir', country: 'Morocco', lCity: 'agadir', lCountry: 'morocco' },
  { city: 'Chuzhou', country: 'China', lCity: 'chuzhou', lCountry: 'china' },
  { city: 'Geneve', country: 'Switzerland', lCity: 'geneve', lCountry: 'switzerland' },
  { city: 'Durgapur', country: 'India', lCity: 'durgapur', lCountry: 'india' },
  { city: 'Siping', country: 'China', lCity: 'siping', lCountry: 'china' },
  { city: 'Surakarta', country: 'Indonesia', lCity: 'surakarta', lCountry: 'indonesia' },
  { city: 'Gomel', country: 'Belarus', lCity: 'gomel', lCountry: 'belarus' },
  { city: 'Ajmer', country: 'India', lCity: 'ajmer', lCountry: 'india' },
  { city: 'Leipzig', country: 'Germany', lCity: 'leipzig', lCountry: 'germany' },
  { city: 'Kirov', country: 'Russia', lCity: 'kirov', lCountry: 'russia' },
  { city: 'Belford Roxo', country: 'Brazil', lCity: 'belford roxo', lCountry: 'brazil' },
  { city: 'Nanping', country: 'China', lCity: 'nanping', lCountry: 'china' },
  { city: 'Luhansk', country: 'Ukraine', lCity: 'luhansk', lCountry: 'ukraine' },
  {
    city: 'Al Sharjah',
    country: 'United Arab Emirates',
    lCity: 'al sharjah',
    lCountry: 'united arab emirates',
  },
  { city: 'Shuangyashan', country: 'China', lCity: 'shuangyashan', lCountry: 'china' },
  { city: 'Qitaihe', country: 'China', lCity: 'qitaihe', lCountry: 'china' },
  { city: 'Zhangye', country: 'China', lCity: 'zhangye', lCountry: 'china' },
  { city: 'Nurnberg', country: 'Germany', lCity: 'nurnberg', lCountry: 'germany' },
  { city: 'Bale', country: 'Switzerland', lCity: 'bale', lCountry: 'switzerland' },
  { city: 'Oujda', country: 'Morocco', lCity: 'oujda', lCountry: 'morocco' },
  { city: 'Erbil', country: 'Iraq', lCity: 'erbil', lCountry: 'iraq' },
  { city: 'Valenzuela', country: 'Philippines', lCity: 'valenzuela', lCountry: 'philippines' },
  { city: 'Hongjiang', country: 'China', lCity: 'hongjiang', lCountry: 'china' },
  { city: 'Baicheng', country: 'China', lCity: 'baicheng', lCountry: 'china' },
  { city: 'Raurkela', country: 'India', lCity: 'raurkela', lCountry: 'india' },
  { city: 'Panama City', country: 'Panama', lCity: 'panama city', lCountry: 'panama' },
  {
    city: 'Barcelona Puerto La Cruz',
    country: 'Venezuela',
    lCity: 'barcelona puerto la cruz',
    lCountry: 'venezuela',
  },
  { city: 'Huanghua', country: 'China', lCity: 'huanghua', lCountry: 'china' },
  { city: 'Pontianak', country: 'Indonesia', lCity: 'pontianak', lCountry: 'indonesia' },
  { city: 'Himeji', country: 'Japan', lCity: 'himeji', lCountry: 'japan' },
  { city: 'Sanya', country: 'China', lCity: 'sanya', lCountry: 'china' },
  { city: 'Orizaba', country: 'Mexico', lCity: 'orizaba', lCountry: 'mexico' },
  { city: 'Dunhua', country: 'China', lCity: 'dunhua', lCountry: 'china' },
  { city: 'Kawaguchi', country: 'Japan', lCity: 'kawaguchi', lCountry: 'japan' },
  { city: 'Durango', country: 'Mexico', lCity: 'durango', lCountry: 'mexico' },
  { city: 'Jianou', country: 'China', lCity: 'jianou', lCountry: 'china' },
  { city: 'Tombouctou', country: 'Mali', lCity: 'tombouctou', lCountry: 'mali' },
  { city: 'Niteroi', country: 'Brazil', lCity: 'niteroi', lCountry: 'brazil' },
  { city: 'Dresden', country: 'Germany', lCity: 'dresden', lCountry: 'germany' },
  { city: 'Arak', country: 'Iran', lCity: 'arak', lCountry: 'iran' },
  { city: 'Soledad', country: 'Colombia', lCity: 'soledad', lCountry: 'colombia' },
  { city: 'Shahe', country: 'China', lCity: 'shahe', lCountry: 'china' },
  { city: 'Jiaohe', country: 'China', lCity: 'jiaohe', lCountry: 'china' },
  { city: 'Long Beach', country: 'United States', lCity: 'long beach', lCountry: 'united states' },
  { city: 'Jiangshan', country: 'China', lCity: 'jiangshan', lCountry: 'china' },
  { city: 'Jian', country: 'China', lCity: 'jian', lCountry: 'china' },
  { city: 'Anda', country: 'China', lCity: 'anda', lCountry: 'china' },
  { city: 'Jiande', country: 'China', lCity: 'jiande', lCountry: 'china' },
  { city: 'Ulhasnagar', country: 'India', lCity: 'ulhasnagar', lCountry: 'india' },
  { city: 'Las Pinas', country: 'Philippines', lCity: 'las pinas', lCountry: 'philippines' },
  { city: 'Matsudo', country: 'Japan', lCity: 'matsudo', lCountry: 'japan' },
  { city: 'Port Said', country: 'Egypt', lCity: 'port said', lCountry: 'egypt' },
  { city: 'Yuanping', country: 'China', lCity: 'yuanping', lCountry: 'china' },
  { city: 'Gaoping', country: 'China', lCity: 'gaoping', lCountry: 'china' },
  { city: 'Fenghua', country: 'China', lCity: 'fenghua', lCountry: 'china' },
  { city: 'Makati', country: 'Philippines', lCity: 'makati', lCountry: 'philippines' },
  { city: 'Qingzhen', country: 'China', lCity: 'qingzhen', lCountry: 'china' },
  { city: 'Siliguri', country: 'India', lCity: 'siliguri', lCountry: 'india' },
  { city: 'Kurashiki', country: 'Japan', lCity: 'kurashiki', lCountry: 'japan' },
  { city: 'Ipoh', country: 'Malaysia', lCity: 'ipoh', lCountry: 'malaysia' },
  { city: 'Lanus', country: 'Argentina', lCity: 'lanus', lCountry: 'argentina' },
  { city: 'Taguig', country: 'Philippines', lCity: 'taguig', lCountry: 'philippines' },
  { city: 'Nangong', country: 'China', lCity: 'nangong', lCountry: 'china' },
  { city: 'Al Kamishli', country: 'Syria', lCity: 'al kamishli', lCountry: 'syria' },
  {
    city: 'Sao Joao De Meriti',
    country: 'Brazil',
    lCity: 'sao joao de meriti',
    lCountry: 'brazil',
  },
  { city: 'Ichikawa', country: 'Japan', lCity: 'ichikawa', lCountry: 'japan' },
  { city: 'Nishinomiya', country: 'Japan', lCity: 'nishinomiya', lCountry: 'japan' },
  { city: 'Constantine', country: 'Algeria', lCity: 'constantine', lCountry: 'algeria' },
  { city: 'Enping', country: 'China', lCity: 'enping', lCountry: 'china' },
  { city: 'Weihui', country: 'China', lCity: 'weihui', lCountry: 'china' },
  { city: 'Adhamiyah', country: 'Iraq', lCity: 'adhamiyah', lCountry: 'iraq' },
  { city: 'Kitchener', country: 'Canada', lCity: 'kitchener', lCountry: 'canada' },
  { city: 'Duyun', country: 'China', lCity: 'duyun', lCountry: 'china' },
  { city: 'Jhansi', country: 'India', lCity: 'jhansi', lCountry: 'india' },
  { city: 'Amagasaki', country: 'Japan', lCity: 'amagasaki', lCountry: 'japan' },
  { city: 'Oita', country: 'Japan', lCity: 'oita', lCountry: 'japan' },
  { city: 'Liaoyuan', country: 'China', lCity: 'liaoyuan', lCountry: 'china' },
  {
    city: 'Cagayan De Oro',
    country: 'Philippines',
    lCity: 'cagayan de oro',
    lCountry: 'philippines',
  },
  { city: 'Antwerpen', country: 'Belgium', lCity: 'antwerpen', lCountry: 'belgium' },
  {
    city: 'Brighton Worthing Littlehampton',
    country: 'United Kingdom',
    lCity: 'brighton worthing littlehampton',
    lCountry: 'united kingdom',
  },
  { city: 'Fresno', country: 'United States', lCity: 'fresno', lCountry: 'united states' },
  { city: 'Baiyin', country: 'China', lCity: 'baiyin', lCountry: 'china' },
  { city: 'Tonghua', country: 'China', lCity: 'tonghua', lCountry: 'china' },
  { city: 'Maracay', country: 'Venezuela', lCity: 'maracay', lCountry: 'venezuela' },
  { city: 'Sargodha', country: 'Pakistan', lCity: 'sargodha', lCountry: 'pakistan' },
  { city: 'Utsunomiya', country: 'Japan', lCity: 'utsunomiya', lCountry: 'japan' },
  { city: 'Gdansk', country: 'Poland', lCity: 'gdansk', lCountry: 'poland' },
  { city: 'Sanhe', country: 'China', lCity: 'sanhe', lCountry: 'china' },
  { city: 'Sacramento', country: 'United States', lCity: 'sacramento', lCountry: 'united states' },
  { city: 'Pingliang', country: 'China', lCity: 'pingliang', lCountry: 'china' },
  {
    city: 'New Orleans',
    country: 'United States',
    lCity: 'new orleans',
    lCountry: 'united states',
  },
  { city: 'Kanazawa', country: 'Japan', lCity: 'kanazawa', lCountry: 'japan' },
  { city: 'Zhangjiajie', country: 'China', lCity: 'zhangjiajie', lCountry: 'china' },
  { city: 'Cheboksary', country: 'Russia', lCity: 'cheboksary', lCountry: 'russia' },
  { city: 'Gejiu', country: 'China', lCity: 'gejiu', lCountry: 'china' },
  {
    city: 'Aparecida De Goiania',
    country: 'Brazil',
    lCity: 'aparecida de goiania',
    lCountry: 'brazil',
  },
  { city: 'Saharanpur', country: 'India', lCity: 'saharanpur', lCountry: 'india' },
  { city: 'Cleveland', country: 'United States', lCity: 'cleveland', lCountry: 'united states' },
  { city: 'Mixco', country: 'Guatemala', lCity: 'mixco', lCountry: 'guatemala' },
  {
    city: 'Bangui',
    country: 'Central African Republic',
    lCity: 'bangui',
    lCountry: 'central african republic',
  },
  { city: 'Lome', country: 'Togo', lCity: 'lome', lCountry: 'togo' },
  { city: 'Qionghai', country: 'China', lCity: 'qionghai', lCountry: 'china' },
  { city: 'Paranaque', country: 'Philippines', lCity: 'paranaque', lCountry: 'philippines' },
  { city: 'Edinburgh', country: 'United Kingdom', lCity: 'edinburgh', lCountry: 'united kingdom' },
  { city: 'Linxiang', country: 'China', lCity: 'linxiang', lCountry: 'china' },
  { city: 'Puyang', country: 'China', lCity: 'puyang', lCountry: 'china' },
  {
    city: 'Sangli Miraj Kupwad',
    country: 'India',
    lCity: 'sangli miraj kupwad',
    lCountry: 'india',
  },
  { city: 'Qinyang', country: 'China', lCity: 'qinyang', lCountry: 'china' },
  { city: 'Pereira', country: 'Colombia', lCity: 'pereira', lCountry: 'colombia' },
  { city: 'Karaganda', country: 'Kazakhstan', lCity: 'karaganda', lCountry: 'kazakhstan' },
  {
    city: 'Kansas City',
    country: 'United States',
    lCity: 'kansas city',
    lCountry: 'united states',
  },
  { city: 'Lyon', country: 'France', lCity: 'lyon', lCountry: 'france' },
  { city: 'Jingdezhen', country: 'China', lCity: 'jingdezhen', lCountry: 'china' },
  { city: 'Jambi', country: 'Indonesia', lCity: 'jambi', lCountry: 'indonesia' },
  { city: 'Huadian', country: 'China', lCity: 'huadian', lCountry: 'china' },
  { city: 'Putian', country: 'China', lCity: 'putian', lCountry: 'china' },
  { city: 'Jinghong', country: 'China', lCity: 'jinghong', lCountry: 'china' },
  { city: 'Cangzhou', country: 'China', lCity: 'cangzhou', lCountry: 'china' },
  { city: 'Mesa', country: 'United States', lCity: 'mesa', lCountry: 'united states' },
  { city: 'Nagasaki', country: 'Japan', lCity: 'nagasaki', lCountry: 'japan' },
  { city: 'Beian', country: 'China', lCity: 'beian', lCountry: 'china' },
  {
    city: 'Portsmouth',
    country: 'United Kingdom',
    lCity: 'portsmouth',
    lCountry: 'united kingdom',
  },
  { city: 'Bhatpara', country: 'India', lCity: 'bhatpara', lCountry: 'india' },
  { city: 'Johore Bharu', country: 'Malaysia', lCity: 'johore bharu', lCountry: 'malaysia' },
  { city: 'Tabuk', country: 'Saudi Arabia', lCity: 'tabuk', lCountry: 'saudi arabia' },
  { city: 'Leicester', country: 'United Kingdom', lCity: 'leicester', lCountry: 'united kingdom' },
  { city: 'Taonan', country: 'China', lCity: 'taonan', lCountry: 'china' },
  { city: 'Sanshui', country: 'China', lCity: 'sanshui', lCountry: 'china' },
  { city: 'Xinle', country: 'China', lCity: 'xinle', lCountry: 'china' },
  {
    city: 'Virginia Beach',
    country: 'United States',
    lCity: 'virginia beach',
    lCountry: 'united states',
  },
  { city: 'Mishan', country: 'China', lCity: 'mishan', lCountry: 'china' },
  { city: 'Ningan', country: 'China', lCity: 'ningan', lCountry: 'china' },
  { city: 'Chengde', country: 'China', lCity: 'chengde', lCountry: 'china' },
  { city: 'Bryansk', country: 'Russia', lCity: 'bryansk', lCountry: 'russia' },
  { city: 'Hailin', country: 'China', lCity: 'hailin', lCountry: 'china' },
  { city: 'Gulbarga', country: 'India', lCity: 'gulbarga', lCountry: 'india' },
  {
    city: 'Bobo Dioulasso',
    country: 'Burkina Faso',
    lCity: 'bobo dioulasso',
    lCountry: 'burkina faso',
  },
  { city: 'Masan', country: 'South Korea', lCity: 'masan', lCountry: 'south korea' },
  { city: 'Tieling', country: 'China', lCity: 'tieling', lCountry: 'china' },
  { city: 'Kaili', country: 'China', lCity: 'kaili', lCountry: 'china' },
  { city: 'Ogbomosho', country: 'Nigeria', lCity: 'ogbomosho', lCountry: 'nigeria' },
  { city: 'Shifang', country: 'China', lCity: 'shifang', lCountry: 'china' },
  { city: 'Yanji', country: 'China', lCity: 'yanji', lCountry: 'china' },
  { city: 'Yazd', country: 'Iran', lCity: 'yazd', lCountry: 'iran' },
  { city: 'Firozabad', country: 'India', lCity: 'firozabad', lCountry: 'india' },
  { city: 'Tirunelveli', country: 'India', lCity: 'tirunelveli', lCountry: 'india' },
  { city: 'Zarqa', country: 'Jordan', lCity: 'zarqa', lCountry: 'jordan' },
  { city: 'Matamoros', country: 'Mexico', lCity: 'matamoros', lCountry: 'mexico' },
  { city: 'Ujjain', country: 'India', lCity: 'ujjain', lCountry: 'india' },
  { city: 'Nanded', country: 'India', lCity: 'nanded', lCountry: 'india' },
  { city: 'Daan', country: 'China', lCity: 'daan', lCountry: 'china' },
  {
    city: 'Campos Dos Goytacazes',
    country: 'Brazil',
    lCity: 'campos dos goytacazes',
    lCountry: 'brazil',
  },
  { city: 'Balikpapan', country: 'Indonesia', lCity: 'balikpapan', lCountry: 'indonesia' },
  { city: 'Bacolod', country: 'Philippines', lCity: 'bacolod', lCountry: 'philippines' },
  { city: 'Wuhai', country: 'China', lCity: 'wuhai', lCountry: 'china' },
  { city: 'Yokosuka', country: 'Japan', lCity: 'yokosuka', lCountry: 'japan' },
  { city: 'Santiago De Cuba', country: 'Cuba', lCity: 'santiago de cuba', lCountry: 'cuba' },
  { city: 'Bratislava', country: 'Slovakia', lCity: 'bratislava', lCountry: 'slovakia' },
  { city: 'Jinhua', country: 'China', lCity: 'jinhua', lCountry: 'china' },
  { city: 'Matola', country: 'Mozambique', lCity: 'matola', lCountry: 'mozambique' },
  { city: 'Lechang', country: 'China', lCity: 'lechang', lCountry: 'china' },
  { city: 'Coatzacoalcos', country: 'Mexico', lCity: 'coatzacoalcos', lCountry: 'mexico' },
  { city: 'Emeishan', country: 'China', lCity: 'emeishan', lCountry: 'china' },
  { city: 'Kaliningrad', country: 'Russia', lCity: 'kaliningrad', lCountry: 'russia' },
  { city: 'Hengshui', country: 'China', lCity: 'hengshui', lCountry: 'china' },
  { city: 'Fangchenggang', country: 'China', lCity: 'fangchenggang', lCountry: 'china' },
  { city: 'Santa Marta', country: 'Colombia', lCity: 'santa marta', lCountry: 'colombia' },
  { city: 'Sialkote', country: 'Pakistan', lCity: 'sialkote', lCountry: 'pakistan' },
  { city: 'Yongji', country: 'China', lCity: 'yongji', lCountry: 'china' },
  { city: 'Toyama', country: 'Japan', lCity: 'toyama', lCountry: 'japan' },
  { city: 'Doha', country: 'Qatar', lCity: 'doha', lCountry: 'qatar' },
  { city: 'Fujin', country: 'China', lCity: 'fujin', lCountry: 'china' },
  { city: 'Kirkuk', country: 'Iraq', lCity: 'kirkuk', lCountry: 'iraq' },
  { city: 'Libreville', country: 'Gabon', lCity: 'libreville', lCountry: 'gabon' },
  { city: 'Fukuyama', country: 'Japan', lCity: 'fukuyama', lCountry: 'japan' },
  { city: 'Santos', country: 'Brazil', lCity: 'santos', lCountry: 'brazil' },
  { city: 'Cheonan', country: 'South Korea', lCity: 'cheonan', lCountry: 'south korea' },
  { city: 'Suez', country: 'Egypt', lCity: 'suez', lCountry: 'egypt' },
  { city: 'Murcia', country: 'Spain', lCity: 'murcia', lCountry: 'spain' },
  { city: 'Utrecht', country: 'Netherlands', lCity: 'utrecht', lCountry: 'netherlands' },
  { city: 'Makyivka', country: 'Ukraine', lCity: 'makyivka', lCountry: 'ukraine' },
  {
    city: 'Sao Jose Do Rio Preto',
    country: 'Brazil',
    lCity: 'sao jose do rio preto',
    lCountry: 'brazil',
  },
  { city: 'Omaha', country: 'United States', lCity: 'omaha', lCountry: 'united states' },
  { city: 'Xiaoyi', country: 'China', lCity: 'xiaoyi', lCountry: 'china' },
  { city: 'Maua', country: 'Brazil', lCity: 'maua', lCountry: 'brazil' },
  { city: 'Soacha', country: 'Colombia', lCity: 'soacha', lCountry: 'colombia' },
  { city: 'Malatya', country: 'Turkey', lCity: 'malatya', lCountry: 'turkey' },
  { city: 'Manado', country: 'Indonesia', lCity: 'manado', lCountry: 'indonesia' },
  { city: 'Toyota', country: 'Japan', lCity: 'toyota', lCountry: 'japan' },
  { city: 'Caxias Do Sul', country: 'Brazil', lCity: 'caxias do sul', lCountry: 'brazil' },
  { city: 'Magnitogorsk', country: 'Russia', lCity: 'magnitogorsk', lCountry: 'russia' },
  {
    city: 'General Santos',
    country: 'Philippines',
    lCity: 'general santos',
    lCountry: 'philippines',
  },
  { city: 'Sanliurfa', country: 'Turkey', lCity: 'sanliurfa', lCountry: 'turkey' },
  { city: 'Ivanovo', country: 'Russia', lCity: 'ivanovo', lCountry: 'russia' },
  { city: 'Szczecin', country: 'Poland', lCity: 'szczecin', lCountry: 'poland' },
  { city: 'Sihui', country: 'China', lCity: 'sihui', lCountry: 'china' },
  { city: 'Gaza', country: 'Palestine', lCity: 'gaza', lCountry: 'palestine' },
  { city: 'Shanwei', country: 'China', lCity: 'shanwei', lCountry: 'china' },
  { city: 'Lianzhou', country: 'China', lCity: 'lianzhou', lCountry: 'china' },
  { city: 'Malegaon', country: 'India', lCity: 'malegaon', lCountry: 'india' },
  { city: 'Zhalantun', country: 'China', lCity: 'zhalantun', lCountry: 'china' },
  { city: 'Yuxi', country: 'China', lCity: 'yuxi', lCountry: 'china' },
  { city: 'Bahawalpur', country: 'Pakistan', lCity: 'bahawalpur', lCountry: 'pakistan' },
  { city: 'Rajahmundry', country: 'India', lCity: 'rajahmundry', lCountry: 'india' },
  { city: 'Enugu', country: 'Nigeria', lCity: 'enugu', lCountry: 'nigeria' },
  { city: 'Betim', country: 'Brazil', lCity: 'betim', lCountry: 'brazil' },
  { city: 'Ashgabat', country: 'Turkmenistan', lCity: 'ashgabat', lCountry: 'turkmenistan' },
  {
    city: 'General San Martin',
    country: 'Argentina',
    lCity: 'general san martin',
    lCountry: 'argentina',
  },
  { city: 'Florianopolis', country: 'Brazil', lCity: 'florianopolis', lCountry: 'brazil' },
  { city: 'Santa Fe', country: 'Argentina', lCity: 'santa fe', lCountry: 'argentina' },
  { city: 'Basra', country: 'Iraq', lCity: 'basra', lCountry: 'iraq' },
  { city: 'Huangshan', country: 'China', lCity: 'huangshan', lCountry: 'china' },
  { city: 'Yakeshi', country: 'China', lCity: 'yakeshi', lCountry: 'china' },
  { city: 'Heshan', country: 'China', lCity: 'heshan', lCountry: 'china' },
  { city: 'Kursk', country: 'Russia', lCity: 'kursk', lCountry: 'russia' },
  { city: 'Tver', country: 'Russia', lCity: 'tver', lCountry: 'russia' },
  { city: 'Machida', country: 'Japan', lCity: 'machida', lCountry: 'japan' },
  {
    city: 'Pietermaritzburg',
    country: 'South Africa',
    lCity: 'pietermaritzburg',
    lCountry: 'south africa',
  },
  { city: 'Vila Velha', country: 'Brazil', lCity: 'vila velha', lCountry: 'brazil' },
  { city: 'Nellore', country: 'India', lCity: 'nellore', lCountry: 'india' },
  { city: 'Shuangliao', country: 'China', lCity: 'shuangliao', lCountry: 'china' },
  { city: 'Tongchuan', country: 'China', lCity: 'tongchuan', lCountry: 'china' },
  { city: 'Hirakata', country: 'Japan', lCity: 'hirakata', lCountry: 'japan' },
  { city: 'Yanan', country: 'China', lCity: 'yanan', lCountry: 'china' },
  { city: 'Ningde', country: 'China', lCity: 'ningde', lCountry: 'china' },
  { city: 'Sochi', country: 'Russia', lCity: 'sochi', lCountry: 'russia' },
  { city: 'Huambo', country: 'Angola', lCity: 'huambo', lCountry: 'angola' },
  { city: 'Akola', country: 'India', lCity: 'akola', lCountry: 'india' },
  { city: 'Gifu', country: 'Japan', lCity: 'gifu', lCountry: 'japan' },
  { city: 'Ruichang', country: 'China', lCity: 'ruichang', lCountry: 'china' },
  { city: 'Loudi', country: 'China', lCity: 'loudi', lCountry: 'china' },
  { city: 'Adan', country: 'Yemen', lCity: 'adan', lCountry: 'yemen' },
  { city: 'Luquan', country: 'China', lCity: 'luquan', lCountry: 'china' },
  { city: 'Beira', country: 'Mozambique', lCity: 'beira', lCountry: 'mozambique' },
  { city: 'St Catharines', country: 'Canada', lCity: 'st catharines', lCountry: 'canada' },
  { city: 'Tallinn', country: 'Estonia', lCity: 'tallinn', lCountry: 'estonia' },
  { city: 'Erzurum', country: 'Turkey', lCity: 'erzurum', lCountry: 'turkey' },
  { city: 'Fujisawa', country: 'Japan', lCity: 'fujisawa', lCountry: 'japan' },
  { city: 'Diadema', country: 'Brazil', lCity: 'diadema', lCountry: 'brazil' },
  { city: 'Oakland', country: 'United States', lCity: 'oakland', lCountry: 'united states' },
  {
    city: 'El Mahalla El Kubra',
    country: 'Egypt',
    lCity: 'el mahalla el kubra',
    lCountry: 'egypt',
  },
  { city: 'Gao', country: 'Mali', lCity: 'gao', lCountry: 'mali' },
  { city: 'Serra', country: 'Brazil', lCity: 'serra', lCountry: 'brazil' },
  { city: 'Gaya', country: 'India', lCity: 'gaya', lCountry: 'india' },
  { city: 'Tirana', country: 'Albania', lCity: 'tirana', lCountry: 'albania' },
  { city: 'Bochum', country: 'Germany', lCity: 'bochum', lCountry: 'germany' },
  { city: 'Yogyakarta', country: 'Indonesia', lCity: 'yogyakarta', lCountry: 'indonesia' },
  { city: 'South Dum Dum', country: 'India', lCity: 'south dum dum', lCountry: 'india' },
  { city: 'Namangan', country: 'Uzbekistan', lCity: 'namangan', lCountry: 'uzbekistan' },
  { city: 'Marikina', country: 'Philippines', lCity: 'marikina', lCountry: 'philippines' },
  { city: 'Erode', country: 'India', lCity: 'erode', lCountry: 'india' },
  { city: 'Ardabil', country: 'Iran', lCity: 'ardabil', lCountry: 'iran' },
  { city: 'Villa Nueva', country: 'Guatemala', lCity: 'villa nueva', lCountry: 'guatemala' },
  { city: 'Villavicencio', country: 'Colombia', lCity: 'villavicencio', lCountry: 'colombia' },
  { city: 'Toulouse', country: 'France', lCity: 'toulouse', lCountry: 'france' },
  { city: 'Carapicuiba', country: 'Brazil', lCity: 'carapicuiba', lCountry: 'brazil' },
  { city: 'Udaipur', country: 'India', lCity: 'udaipur', lCountry: 'india' },
  { city: 'Maheshtala', country: 'India', lCity: 'maheshtala', lCountry: 'india' },
  { city: 'Rajshahi', country: 'Bangladesh', lCity: 'rajshahi', lCountry: 'bangladesh' },
  { city: 'Hami', country: 'China', lCity: 'hami', lCountry: 'china' },
  { city: 'Pasto', country: 'Colombia', lCity: 'pasto', lCountry: 'colombia' },
  { city: 'Vellore', country: 'India', lCity: 'vellore', lCountry: 'india' },
  { city: 'Olinda', country: 'Brazil', lCity: 'olinda', lCountry: 'brazil' },
  { city: 'Changji', country: 'China', lCity: 'changji', lCountry: 'china' },
  { city: 'Fenyang', country: 'China', lCity: 'fenyang', lCountry: 'china' },
  { city: 'Hancheng', country: 'China', lCity: 'hancheng', lCountry: 'china' },
  { city: 'Toyonaka', country: 'Japan', lCity: 'toyonaka', lCountry: 'japan' },
  { city: 'Yidu', country: 'China', lCity: 'yidu', lCountry: 'china' },
  { city: 'Thessaloniki', country: 'Greece', lCity: 'thessaloniki', lCountry: 'greece' },
  { city: 'Monteria', country: 'Colombia', lCity: 'monteria', lCountry: 'colombia' },
  { city: 'Dazhou', country: 'China', lCity: 'dazhou', lCountry: 'china' },
  {
    city: 'Bournemouth',
    country: 'United Kingdom',
    lCity: 'bournemouth',
    lCountry: 'united kingdom',
  },
  { city: 'Keelung', country: 'China', lCity: 'keelung', lCountry: 'china' },
  { city: 'Djibouti', country: 'Djibouti', lCity: 'djibouti', lCountry: 'djibouti' },
  { city: 'Da Nang', country: 'Viet Nam', lCity: 'da nang', lCountry: 'viet nam' },
  { city: 'Tulsa', country: 'United States', lCity: 'tulsa', lCountry: 'united states' },
  { city: 'Halifax', country: 'Canada', lCity: 'halifax', lCountry: 'canada' },
  { city: 'Kuerle', country: 'China', lCity: 'kuerle', lCountry: 'china' },
  { city: 'Ningguo', country: 'China', lCity: 'ningguo', lCountry: 'china' },
  { city: 'Manizales', country: 'Colombia', lCity: 'manizales', lCountry: 'colombia' },
  { city: 'Wuzhou', country: 'China', lCity: 'wuzhou', lCountry: 'china' },
  { city: 'Porto Velho', country: 'Brazil', lCity: 'porto velho', lCountry: 'brazil' },
  { city: 'Kashiwa', country: 'Japan', lCity: 'kashiwa', lCountry: 'japan' },
  { city: 'Bello', country: 'Colombia', lCity: 'bello', lCountry: 'colombia' },
  { city: 'Kollam', country: 'India', lCity: 'kollam', lCountry: 'india' },
  { city: 'Campina Grande', country: 'Brazil', lCity: 'campina grande', lCountry: 'brazil' },
  { city: 'Vereeniging', country: 'South Africa', lCity: 'vereeniging', lCountry: 'south africa' },
  { city: 'Chiang Mai', country: 'Thailand', lCity: 'chiang mai', lCountry: 'thailand' },
  { city: 'Muntinlupa', country: 'Philippines', lCity: 'muntinlupa', lCountry: 'philippines' },
  { city: 'Anguo', country: 'China', lCity: 'anguo', lCountry: 'china' },
  { city: 'Abuja', country: 'Nigeria', lCity: 'abuja', lCountry: 'nigeria' },
  { city: 'Nizhny Tagil', country: 'Russia', lCity: 'nizhny tagil', lCountry: 'russia' },
  { city: 'Villahermosa', country: 'Mexico', lCity: 'villahermosa', lCountry: 'mexico' },
  { city: 'Nagano', country: 'Japan', lCity: 'nagano', lCountry: 'japan' },
  { city: 'Buraydah', country: 'Saudi Arabia', lCity: 'buraydah', lCountry: 'saudi arabia' },
  { city: 'Samsun', country: 'Turkey', lCity: 'samsun', lCountry: 'turkey' },
  { city: 'Honolulu', country: 'United States', lCity: 'honolulu', lCountry: 'united states' },
  {
    city: 'Las Palmas De Gran Canaria',
    country: 'Spain',
    lCity: 'las palmas de gran canaria',
    lCountry: 'spain',
  },
  {
    city: 'Palmasde Gran Canaria',
    country: 'Spain',
    lCity: 'palmasde gran canaria',
    lCountry: 'spain',
  },
  { city: 'Sevastopol', country: 'Ukraine', lCity: 'sevastopol', lCountry: 'ukraine' },
  { city: 'Wellington', country: 'New Zealand', lCity: 'wellington', lCountry: 'new zealand' },
  { city: 'Wakayama', country: 'Japan', lCity: 'wakayama', lCountry: 'japan' },
  { city: 'Ulan Ude', country: 'Russia', lCity: 'ulan ude', lCountry: 'russia' },
  { city: 'Palma De Mallorca', country: 'Spain', lCity: 'palma de mallorca', lCountry: 'spain' },
  { city: 'Christchurch', country: 'New Zealand', lCity: 'christchurch', lCountry: 'new zealand' },
  { city: 'Jizhou', country: 'China', lCity: 'jizhou', lCountry: 'china' },
  { city: 'Huanggang', country: 'China', lCity: 'huanggang', lCountry: 'china' },
  { city: 'Xuchang', country: 'China', lCity: 'xuchang', lCountry: 'china' },
  { city: 'Bologna', country: 'Italy', lCity: 'bologna', lCountry: 'italy' },
  { city: 'Jiexiu', country: 'China', lCity: 'jiexiu', lCountry: 'china' },
  { city: 'Tanta', country: 'Egypt', lCity: 'tanta', lCountry: 'egypt' },
  { city: 'Nanxiong', country: 'China', lCity: 'nanxiong', lCountry: 'china' },
  {
    city: 'Khamis Mushayt',
    country: 'Saudi Arabia',
    lCity: 'khamis mushayt',
    lCountry: 'saudi arabia',
  },
  { city: 'Toyohashi', country: 'Japan', lCity: 'toyohashi', lCountry: 'japan' },
  { city: 'Moji Das Cruzes', country: 'Brazil', lCity: 'moji das cruzes', lCountry: 'brazil' },
  { city: 'Ichinomiya', country: 'Japan', lCity: 'ichinomiya', lCountry: 'japan' },
  { city: 'Ndola', country: 'Zambia', lCity: 'ndola', lCountry: 'zambia' },
  { city: 'Salta', country: 'Argentina', lCity: 'salta', lCountry: 'argentina' },
  { city: 'Nara', country: 'Japan', lCity: 'nara', lCountry: 'japan' },
  { city: 'Oyo', country: 'Nigeria', lCity: 'oyo', lCountry: 'nigeria' },
  {
    city: 'Colorado Springs',
    country: 'United States',
    lCity: 'colorado springs',
    lCountry: 'united states',
  },
  {
    city: 'Reading Wokingham',
    country: 'United Kingdom',
    lCity: 'reading wokingham',
    lCountry: 'united kingdom',
  },
  { city: 'Mansura', country: 'Egypt', lCity: 'mansura', lCountry: 'egypt' },
  { city: 'Wuppertal', country: 'Germany', lCity: 'wuppertal', lCountry: 'germany' },
  { city: 'Kakinada', country: 'India', lCity: 'kakinada', lCountry: 'india' },
  { city: 'Jalgaon', country: 'India', lCity: 'jalgaon', lCountry: 'india' },
  { city: 'Hejin', country: 'China', lCity: 'hejin', lCountry: 'china' },
  { city: 'Mogilev', country: 'Belarus', lCity: 'mogilev', lCountry: 'belarus' },
  { city: 'Klang', country: 'Malaysia', lCity: 'klang', lCountry: 'malaysia' },
  { city: 'Macapa', country: 'Brazil', lCity: 'macapa', lCountry: 'brazil' },
  { city: 'Brno', country: 'Czech Republic', lCity: 'brno', lCountry: 'czech republic' },
  { city: 'Piracicaba', country: 'Brazil', lCity: 'piracicaba', lCountry: 'brazil' },
  { city: 'Firenze', country: 'Italy', lCity: 'firenze', lCountry: 'italy' },
  { city: 'Iloilo', country: 'Philippines', lCity: 'iloilo', lCountry: 'philippines' },
  { city: 'Benoni', country: 'South Africa', lCity: 'benoni', lCountry: 'south africa' },
  { city: 'Udon Thani', country: 'Thailand', lCity: 'udon thani', lCountry: 'thailand' },
  { city: 'Teesside', country: 'United Kingdom', lCity: 'teesside', lCountry: 'united kingdom' },
  { city: 'Bydgoszcz', country: 'Poland', lCity: 'bydgoszcz', lCountry: 'poland' },
  { city: 'Valledupar', country: 'Colombia', lCity: 'valledupar', lCountry: 'colombia' },
  { city: 'Sulamaniya', country: 'Iraq', lCity: 'sulamaniya', lCountry: 'iraq' },
  { city: 'Davangere', country: 'India', lCity: 'davangere', lCountry: 'india' },
  { city: 'Chaozhou', country: 'China', lCity: 'chaozhou', lCountry: 'china' },
  { city: 'Warri', country: 'Nigeria', lCity: 'warri', lCountry: 'nigeria' },
  { city: 'Arlington', country: 'United States', lCity: 'arlington', lCountry: 'united states' },
  { city: 'Tongling', country: 'China', lCity: 'tongling', lCountry: 'china' },
  { city: 'Kitwe', country: 'Zambia', lCity: 'kitwe', lCountry: 'zambia' },
  {
    city: 'The Potteries',
    country: 'United Kingdom',
    lCity: 'the potteries',
    lCountry: 'united kingdom',
  },
  { city: 'Bouake', country: 'Cote D Ivoire', lCity: 'bouake', lCountry: 'cote d ivoire' },
  { city: 'Piura', country: 'Peru', lCity: 'piura', lCountry: 'peru' },
  { city: 'Beirut', country: 'Lebanon', lCity: 'beirut', lCountry: 'lebanon' },
  { city: 'Samarkand', country: 'Uzbekistan', lCity: 'samarkand', lCountry: 'uzbekistan' },
  { city: 'Cariacica', country: 'Brazil', lCity: 'cariacica', lCountry: 'brazil' },
  { city: 'Misurata', country: 'Libya', lCity: 'misurata', lCountry: 'libya' },
  { city: 'Kaunas', country: 'Lithuania', lCity: 'kaunas', lCountry: 'lithuania' },
  { city: 'Stavropol', country: 'Russia', lCity: 'stavropol', lCountry: 'russia' },
  { city: 'Hsinchu', country: 'China', lCity: 'hsinchu', lCountry: 'china' },
  { city: 'Vinnytsya', country: 'Ukraine', lCity: 'vinnytsya', lCountry: 'ukraine' },
  { city: 'Dongfang', country: 'China', lCity: 'dongfang', lCountry: 'china' },
  { city: 'Asmara', country: 'Eritrea', lCity: 'asmara', lCountry: 'eritrea' },
  { city: 'Simpheropol', country: 'Ukraine', lCity: 'simpheropol', lCountry: 'ukraine' },
  { city: 'Yining', country: 'China', lCity: 'yining', lCountry: 'china' },
  { city: 'Bauru', country: 'Brazil', lCity: 'bauru', lCountry: 'brazil' },
  { city: 'Iquitos', country: 'Peru', lCity: 'iquitos', lCountry: 'peru' },
  { city: 'Nuevo Laredo', country: 'Mexico', lCity: 'nuevo laredo', lCountry: 'mexico' },
  { city: 'Wuzhong', country: 'China', lCity: 'wuzhong', lCountry: 'china' },
  { city: 'Uijeongbu', country: 'South Korea', lCity: 'uijeongbu', lCountry: 'south korea' },
  { city: 'Arkhangelsk', country: 'Russia', lCity: 'arkhangelsk', lCountry: 'russia' },
  { city: 'Asahikawa', country: 'Japan', lCity: 'asahikawa', lCountry: 'japan' },
  { city: 'Pasay', country: 'Philippines', lCity: 'pasay', lCountry: 'philippines' },
  { city: 'Kahramanmaras', country: 'Turkey', lCity: 'kahramanmaras', lCountry: 'turkey' },
  { city: 'Wichita', country: 'United States', lCity: 'wichita', lCountry: 'united states' },
  { city: 'Okazaki', country: 'Japan', lCity: 'okazaki', lCountry: 'japan' },
  { city: 'Tieli', country: 'China', lCity: 'tieli', lCountry: 'china' },
  { city: 'Iwaki', country: 'Japan', lCity: 'iwaki', lCountry: 'japan' },
  { city: 'Meizhou', country: 'China', lCity: 'meizhou', lCountry: 'china' },
  { city: 'Lublin', country: 'Poland', lCity: 'lublin', lCountry: 'poland' },
  { city: 'Bilbao', country: 'Spain', lCity: 'bilbao', lCountry: 'spain' },
  { city: 'Panipat', country: 'India', lCity: 'panipat', lCountry: 'india' },
  { city: 'Suita', country: 'Japan', lCity: 'suita', lCountry: 'japan' },
  { city: 'Kherson', country: 'Ukraine', lCity: 'kherson', lCountry: 'ukraine' },
  { city: 'Itaquaquecetuba', country: 'Brazil', lCity: 'itaquaquecetuba', lCountry: 'brazil' },
  { city: 'Abeokuta', country: 'Nigeria', lCity: 'abeokuta', lCountry: 'nigeria' },
  { city: 'Annaba', country: 'Algeria', lCity: 'annaba', lCountry: 'algeria' },
  { city: 'Huaying', country: 'China', lCity: 'huaying', lCountry: 'china' },
  { city: 'Vitebsk', country: 'Belarus', lCity: 'vitebsk', lCountry: 'belarus' },
  { city: 'Takatsuki', country: 'Japan', lCity: 'takatsuki', lCountry: 'japan' },
  { city: 'Zixing', country: 'China', lCity: 'zixing', lCountry: 'china' },
  { city: 'Petaling Jaya', country: 'Malaysia', lCity: 'petaling jaya', lCountry: 'malaysia' },
  {
    city: 'Bloemfontein',
    country: 'South Africa',
    lCity: 'bloemfontein',
    lCountry: 'south africa',
  },
  { city: 'Onitsha', country: 'Nigeria', lCity: 'onitsha', lCountry: 'nigeria' },
  { city: 'Bhagalpur', country: 'India', lCity: 'bhagalpur', lCountry: 'india' },
  { city: 'Fife', country: 'United Kingdom', lCity: 'fife', lCountry: 'united kingdom' },
  { city: 'Al Rayyan', country: 'Qatar', lCity: 'al rayyan', lCountry: 'qatar' },
  { city: 'Montes Claros', country: 'Brazil', lCity: 'montes claros', lCountry: 'brazil' },
  { city: 'Jundiai', country: 'Brazil', lCity: 'jundiai', lCountry: 'brazil' },
  { city: 'Panihati', country: 'India', lCity: 'panihati', lCountry: 'india' },
  { city: 'Lishui', country: 'China', lCity: 'lishui', lCountry: 'china' },
  { city: 'Ahmednagar', country: 'India', lCity: 'ahmednagar', lCountry: 'india' },
  { city: 'Manukau', country: 'New Zealand', lCity: 'manukau', lCountry: 'new zealand' },
  { city: 'Irapuato', country: 'Mexico', lCity: 'irapuato', lCountry: 'mexico' },
  { city: 'Huaihua', country: 'China', lCity: 'huaihua', lCountry: 'china' },
  { city: 'Pelotas', country: 'Brazil', lCity: 'pelotas', lCountry: 'brazil' },
  { city: 'Jiuquan', country: 'China', lCity: 'jiuquan', lCountry: 'china' },
  { city: 'Zanjan', country: 'Iran', lCity: 'zanjan', lCountry: 'iran' },
  { city: 'Belgorod', country: 'Russia', lCity: 'belgorod', lCountry: 'russia' },
  { city: 'Kathlehong', country: 'South Africa', lCity: 'kathlehong', lCountry: 'south africa' },
  {
    city: 'Kempton Park',
    country: 'South Africa',
    lCity: 'kempton park',
    lCountry: 'south africa',
  },
  { city: 'Oshawa', country: 'Canada', lCity: 'oshawa', lCountry: 'canada' },
  { city: 'Avellaneda', country: 'Argentina', lCity: 'avellaneda', lCountry: 'argentina' },
  { city: 'Berne', country: 'Switzerland', lCity: 'berne', lCountry: 'switzerland' },
  { city: 'Assyut', country: 'Egypt', lCity: 'assyut', lCountry: 'egypt' },
  { city: 'Nice', country: 'France', lCity: 'nice', lCountry: 'france' },
  { city: 'Plovdiv', country: 'Bulgaria', lCity: 'plovdiv', lCountry: 'bulgaria' },
  { city: 'Kaluga', country: 'Russia', lCity: 'kaluga', lCountry: 'russia' },
  { city: 'Antofagasta', country: 'Chile', lCity: 'antofagasta', lCountry: 'chile' },
  { city: 'Mataram', country: 'Indonesia', lCity: 'mataram', lCountry: 'indonesia' },
  { city: 'Gumi', country: 'South Korea', lCity: 'gumi', lCountry: 'south korea' },
  { city: 'Raleigh', country: 'United States', lCity: 'raleigh', lCountry: 'united states' },
  { city: 'Dhule', country: 'India', lCity: 'dhule', lCountry: 'india' },
  { city: 'Kashi', country: 'China', lCity: 'kashi', lCountry: 'china' },
  { city: 'Baise', country: 'China', lCity: 'baise', lCountry: 'china' },
  { city: 'Santa Ana', country: 'United States', lCity: 'santa ana', lCountry: 'united states' },
  { city: 'Vladimir', country: 'Russia', lCity: 'vladimir', lCountry: 'russia' },
  { city: 'Jinju', country: 'South Korea', lCity: 'jinju', lCountry: 'south korea' },
  { city: 'Umlazi', country: 'South Africa', lCity: 'umlazi', lCountry: 'south africa' },
  { city: 'Lengshuijiang', country: 'China', lCity: 'lengshuijiang', lCountry: 'china' },
  { city: 'Malabon', country: 'Philippines', lCity: 'malabon', lCountry: 'philippines' },
  { city: 'Koriyama', country: 'Japan', lCity: 'koriyama', lCountry: 'japan' },
  { city: 'Wudalianchi', country: 'China', lCity: 'wudalianchi', lCountry: 'china' },
  { city: 'Andizhan', country: 'Uzbekistan', lCity: 'andizhan', lCountry: 'uzbekistan' },
  { city: 'Zhumadian', country: 'China', lCity: 'zhumadian', lCountry: 'china' },
  { city: 'Takamatsu', country: 'Japan', lCity: 'takamatsu', lCountry: 'japan' },
  { city: 'Luxi', country: 'China', lCity: 'luxi', lCountry: 'china' },
  { city: 'Sanming', country: 'China', lCity: 'sanming', lCountry: 'china' },
  {
    city: 'Coventry Bedworth',
    country: 'United Kingdom',
    lCity: 'coventry bedworth',
    lCountry: 'united kingdom',
  },
  { city: 'Rajpur Sonarpur', country: 'India', lCity: 'rajpur sonarpur', lCountry: 'india' },
  { city: 'Carrefour', country: 'Haiti', lCity: 'carrefour', lCountry: 'haiti' },
  { city: 'Tokorozawa', country: 'Japan', lCity: 'tokorozawa', lCountry: 'japan' },
  { city: 'Taraz', country: 'Kazakhstan', lCity: 'taraz', lCountry: 'kazakhstan' },
  { city: 'Khouribga', country: 'Morocco', lCity: 'khouribga', lCountry: 'morocco' },
  { city: 'Buenaventura', country: 'Colombia', lCity: 'buenaventura', lCountry: 'colombia' },
  { city: 'Sukkur', country: 'Pakistan', lCity: 'sukkur', lCountry: 'pakistan' },
  { city: 'Surat Thani', country: 'Thailand', lCity: 'surat thani', lCountry: 'thailand' },
  { city: 'Baishan', country: 'China', lCity: 'baishan', lCountry: 'china' },
  { city: 'Yongan', country: 'China', lCity: 'yongan', lCountry: 'china' },
  { city: 'Yaan', country: 'China', lCity: 'yaan', lCountry: 'china' },
  { city: 'Kaesong', country: 'North Korea', lCity: 'kaesong', lCountry: 'north korea' },
  { city: 'Victoria', country: 'Canada', lCity: 'victoria', lCountry: 'canada' },
  { city: 'Gwangmyeong', country: 'South Korea', lCity: 'gwangmyeong', lCountry: 'south korea' },
  { city: 'Kawagoe', country: 'Japan', lCity: 'kawagoe', lCountry: 'japan' },
  { city: 'Ciudad Del Este', country: 'Paraguay', lCity: 'ciudad del este', lCountry: 'paraguay' },
  { city: 'Canoas', country: 'Brazil', lCity: 'canoas', lCountry: 'brazil' },
  { city: 'Akita', country: 'Japan', lCity: 'akita', lCountry: 'japan' },
  { city: 'Basilan', country: 'Philippines', lCity: 'basilan', lCountry: 'philippines' },
  { city: 'Windsor', country: 'Canada', lCity: 'windsor', lCountry: 'canada' },
  { city: 'Gimhae', country: 'South Korea', lCity: 'gimhae', lCountry: 'south korea' },
  { city: 'Anaheim', country: 'United States', lCity: 'anaheim', lCountry: 'united states' },
  { city: 'San Miguelito', country: 'Panama', lCity: 'san miguelito', lCountry: 'panama' },
  { city: 'Muzaffarnagar', country: 'India', lCity: 'muzaffarnagar', lCountry: 'india' },
  { city: 'Vladikavkaz', country: 'Russia', lCity: 'vladikavkaz', lCountry: 'russia' },
  { city: 'Bilaspur', country: 'India', lCity: 'bilaspur', lCountry: 'india' },
  { city: 'Thrissur', country: 'India', lCity: 'thrissur', lCountry: 'india' },
  { city: 'Sokoto', country: 'Nigeria', lCity: 'sokoto', lCountry: 'nigeria' },
  { city: 'Sao Vicente', country: 'Brazil', lCity: 'sao vicente', lCountry: 'brazil' },
  { city: 'Chimbote', country: 'Peru', lCity: 'chimbote', lCountry: 'peru' },
  { city: 'Kurgan', country: 'Russia', lCity: 'kurgan', lCountry: 'russia' },
  { city: 'Franca', country: 'Brazil', lCity: 'franca', lCountry: 'brazil' },
  {
    city: 'Al Ayn',
    country: 'United Arab Emirates',
    lCity: 'al ayn',
    lCountry: 'united arab emirates',
  },
  { city: 'Sanandaj', country: 'Iran', lCity: 'sanandaj', lCountry: 'iran' },
  { city: 'Cardiff', country: 'United Kingdom', lCity: 'cardiff', lCountry: 'united kingdom' },
  { city: 'Shangrao', country: 'China', lCity: 'shangrao', lCountry: 'china' },
  { city: 'Khon Kaen', country: 'Thailand', lCity: 'khon kaen', lCountry: 'thailand' },
  { city: 'Cuzco', country: 'Peru', lCity: 'cuzco', lCountry: 'peru' },
  { city: 'Sinuiji', country: 'North Korea', lCity: 'sinuiji', lCountry: 'north korea' },
  { city: 'Bari', country: 'Italy', lCity: 'bari', lCountry: 'italy' },
  { city: 'Canberra', country: 'Australia', lCity: 'canberra', lCountry: 'australia' },
  { city: 'Orel', country: 'Russia', lCity: 'orel', lCountry: 'russia' },
  { city: 'Maringa', country: 'Brazil', lCity: 'maringa', lCountry: 'brazil' },
  { city: 'Van', country: 'Turkey', lCity: 'van', lCountry: 'turkey' },
  { city: 'Zhoukou', country: 'China', lCity: 'zhoukou', lCountry: 'china' },
  { city: 'Iksan', country: 'South Korea', lCity: 'iksan', lCountry: 'south korea' },
  { city: 'Saransk', country: 'Russia', lCity: 'saransk', lCountry: 'russia' },
  { city: 'Patiala', country: 'India', lCity: 'patiala', lCountry: 'india' },
  { city: 'Shahjahanpur', country: 'India', lCity: 'shahjahanpur', lCountry: 'india' },
  {
    city: 'Ribeirao Das Neves',
    country: 'Brazil',
    lCity: 'ribeirao das neves',
    lCountry: 'brazil',
  },
  { city: 'Alicante', country: 'Spain', lCity: 'alicante', lCountry: 'spain' },
  { city: 'Eindhoven', country: 'Netherlands', lCity: 'eindhoven', lCountry: 'netherlands' },
  { city: 'Pachuca', country: 'Mexico', lCity: 'pachuca', lCountry: 'mexico' },
  { city: 'Bielefeld', country: 'Germany', lCity: 'bielefeld', lCountry: 'germany' },
  {
    city: 'North Lanarkshire',
    country: 'United Kingdom',
    lCity: 'north lanarkshire',
    lCountry: 'united kingdom',
  },
  { city: 'Celaya', country: 'Mexico', lCity: 'celaya', lCountry: 'mexico' },
  { city: 'Kurnool', country: 'India', lCity: 'kurnool', lCountry: 'india' },
  { city: 'Grodno', country: 'Belarus', lCity: 'grodno', lCountry: 'belarus' },
  { city: 'Valladolid', country: 'Spain', lCity: 'valladolid', lCountry: 'spain' },
  {
    city: 'Birkenhead',
    country: 'United Kingdom',
    lCity: 'birkenhead',
    lCountry: 'united kingdom',
  },
  { city: 'Neiva', country: 'Colombia', lCity: 'neiva', lCountry: 'colombia' },
  { city: 'Murmansk', country: 'Russia', lCity: 'murmansk', lCountry: 'russia' },
  { city: 'Mathura', country: 'India', lCity: 'mathura', lCountry: 'india' },
  { city: 'Anapolis', country: 'Brazil', lCity: 'anapolis', lCountry: 'brazil' },
  { city: 'Volzhsky', country: 'Russia', lCity: 'volzhsky', lCountry: 'russia' },
  { city: 'Maebashi', country: 'Japan', lCity: 'maebashi', lCountry: 'japan' },
  { city: 'Hechi', country: 'China', lCity: 'hechi', lCountry: 'china' },
  { city: 'Smolensk', country: 'Russia', lCity: 'smolensk', lCountry: 'russia' },
  { city: 'Xifeng', country: 'China', lCity: 'xifeng', lCountry: 'china' },
  { city: 'Taizz', country: 'Yemen', lCity: 'taizz', lCountry: 'yemen' },
  { city: 'Vitoria', country: 'Brazil', lCity: 'vitoria', lCountry: 'brazil' },
  { city: 'Bellary', country: 'India', lCity: 'bellary', lCountry: 'india' },
  { city: 'Pittsburgh', country: 'United States', lCity: 'pittsburgh', lCountry: 'united states' },
  { city: 'Iasi', country: 'Romania', lCity: 'iasi', lCountry: 'romania' },
  { city: 'Koshigaya', country: 'Japan', lCity: 'koshigaya', lCountry: 'japan' },
  { city: 'Korba', country: 'India', lCity: 'korba', lCountry: 'india' },
  { city: 'Bandar E Abbas', country: 'Iran', lCity: 'bandar e abbas', lCountry: 'iran' },
  { city: 'Kamarhati', country: 'India', lCity: 'kamarhati', lCountry: 'india' },
  { city: 'Shizuishan', country: 'China', lCity: 'shizuishan', lCountry: 'china' },
  { city: 'Khayelitsa', country: 'South Africa', lCity: 'khayelitsa', lCountry: 'south africa' },
  { city: 'Novi Sad', country: 'Serbia', lCity: 'novi sad', lCountry: 'serbia' },
  { city: 'Rio Branco', country: 'Brazil', lCity: 'rio branco', lCountry: 'brazil' },
  { city: 'Meixian', country: 'China', lCity: 'meixian', lCountry: 'china' },
  { city: 'Caucaia', country: 'Brazil', lCity: 'caucaia', lCountry: 'brazil' },
  { city: 'Khoramabad', country: 'Iran', lCity: 'khoramabad', lCountry: 'iran' },
  { city: 'Okene', country: 'Nigeria', lCity: 'okene', lCountry: 'nigeria' },
  { city: 'Pathum Thani', country: 'Thailand', lCity: 'pathum thani', lCountry: 'thailand' },
  { city: 'Naha', country: 'Japan', lCity: 'naha', lCountry: 'japan' },
  { city: 'Varna', country: 'Bulgaria', lCity: 'varna', lCountry: 'bulgaria' },
  { city: 'Hulin', country: 'China', lCity: 'hulin', lCountry: 'china' },
  { city: 'Aomori', country: 'Japan', lCity: 'aomori', lCountry: 'japan' },
  { city: 'Calabar', country: 'Nigeria', lCity: 'calabar', lCountry: 'nigeria' },
  { city: 'Ostrava', country: 'Czech Republic', lCity: 'ostrava', lCountry: 'czech republic' },
  { city: 'Petropolis', country: 'Brazil', lCity: 'petropolis', lCountry: 'brazil' },
  { city: 'Miyazaki', country: 'Japan', lCity: 'miyazaki', lCountry: 'japan' },
  { city: 'Muling', country: 'China', lCity: 'muling', lCountry: 'china' },
  { city: 'Lausanne', country: 'Switzerland', lCity: 'lausanne', lCountry: 'switzerland' },
  { city: 'Sagar', country: 'India', lCity: 'sagar', lCountry: 'india' },
  { city: 'Foz Do Iguacu', country: 'Brazil', lCity: 'foz do iguacu', lCountry: 'brazil' },
  { city: 'Najaf', country: 'Iraq', lCity: 'najaf', lCountry: 'iraq' },
  { city: 'Cincinnati', country: 'United States', lCity: 'cincinnati', lCountry: 'united states' },
  { city: 'Tongren', country: 'China', lCity: 'tongren', lCountry: 'china' },
  { city: 'Port Sudan', country: 'Sudan', lCity: 'port sudan', lCountry: 'sudan' },
  { city: 'Cherepovets', country: 'Russia', lCity: 'cherepovets', lCountry: 'russia' },
  { city: 'Mannheim', country: 'Germany', lCity: 'mannheim', lCountry: 'germany' },
  { city: 'Qazvin', country: 'Iran', lCity: 'qazvin', lCountry: 'iran' },
  { city: 'Huancayo', country: 'Peru', lCity: 'huancayo', lCountry: 'peru' },
  { city: 'Camaguey', country: 'Cuba', lCity: 'camaguey', lCountry: 'cuba' },
  { city: 'Yamunanagar', country: 'India', lCity: 'yamunanagar', lCountry: 'india' },
  { city: 'Kurume', country: 'Japan', lCity: 'kurume', lCountry: 'japan' },
  { city: 'Chita', country: 'Russia', lCity: 'chita', lCountry: 'russia' },
  { city: 'Cluj Napoca', country: 'Romania', lCity: 'cluj napoca', lCountry: 'romania' },
  { city: 'Constanta', country: 'Romania', lCity: 'constanta', lCountry: 'romania' },
  { city: 'Muzaffarpur', country: 'India', lCity: 'muzaffarpur', lCountry: 'india' },
  { city: 'Guaruja', country: 'Brazil', lCity: 'guaruja', lCountry: 'brazil' },
  { city: 'Ponta Grossa', country: 'Brazil', lCity: 'ponta grossa', lCountry: 'brazil' },
  { city: 'Pavlodar', country: 'Kazakhstan', lCity: 'pavlodar', lCountry: 'kazakhstan' },
  {
    city: 'Southampton',
    country: 'United Kingdom',
    lCity: 'southampton',
    lCountry: 'united kingdom',
  },
  { city: 'Jincheng', country: 'China', lCity: 'jincheng', lCountry: 'china' },
  { city: 'Luohe', country: 'China', lCity: 'luohe', lCountry: 'china' },
  { city: 'Cuenca', country: 'Ecuador', lCity: 'cuenca', lCountry: 'ecuador' },
  { city: 'Yokkaichi', country: 'Japan', lCity: 'yokkaichi', lCountry: 'japan' },
  { city: 'Ganja', country: 'Azerbaijan', lCity: 'ganja', lCountry: 'azerbaijan' },
  { city: 'Timisoara', country: 'Romania', lCity: 'timisoara', lCountry: 'romania' },
  { city: 'Tepic', country: 'Mexico', lCity: 'tepic', lCountry: 'mexico' },
  { city: 'Nampula', country: 'Mozambique', lCity: 'nampula', lCountry: 'mozambique' },
  { city: 'Yeosu', country: 'South Korea', lCity: 'yeosu', lCountry: 'south korea' },
  { city: 'Poltava', country: 'Ukraine', lCity: 'poltava', lCountry: 'ukraine' },
  { city: 'Catania', country: 'Italy', lCity: 'catania', lCountry: 'italy' },
  { city: 'Tirupati', country: 'India', lCity: 'tirupati', lCountry: 'india' },
  { city: 'Ambattur', country: 'India', lCity: 'ambattur', lCountry: 'india' },
  { city: 'Brest', country: 'Belarus', lCity: 'brest', lCountry: 'belarus' },
  {
    city: 'South Lanarkshire',
    country: 'United Kingdom',
    lCity: 'south lanarkshire',
    lCountry: 'united kingdom',
  },
  { city: 'Otsu', country: 'Japan', lCity: 'otsu', lCountry: 'japan' },
  { city: 'Yangzhong', country: 'China', lCity: 'yangzhong', lCountry: 'china' },
  {
    city: 'Kingston Upon Hull',
    country: 'United Kingdom',
    lCity: 'kingston upon hull',
    lCountry: 'united kingdom',
  },
  { city: 'Toledo', country: 'United States', lCity: 'toledo', lCountry: 'united states' },
  { city: 'Gaoming', country: 'China', lCity: 'gaoming', lCountry: 'china' },
  { city: 'Craiova', country: 'Romania', lCity: 'craiova', lCountry: 'romania' },
  { city: 'Wonsan', country: 'North Korea', lCity: 'wonsan', lCountry: 'north korea' },
  { city: 'Latur', country: 'India', lCity: 'latur', lCountry: 'india' },
  { city: 'Paulista', country: 'Brazil', lCity: 'paulista', lCountry: 'brazil' },
  { city: 'San Isidro', country: 'Argentina', lCity: 'san isidro', lCountry: 'argentina' },
  { city: 'Blumenou', country: 'Brazil', lCity: 'blumenou', lCountry: 'brazil' },
  { city: 'Horlivka', country: 'Ukraine', lCity: 'horlivka', lCountry: 'ukraine' },
  { city: 'Al Hudaydah', country: 'Yemen', lCity: 'al hudaydah', lCountry: 'yemen' },
  { city: 'Dexing', country: 'China', lCity: 'dexing', lCountry: 'china' },
  { city: 'Chandrapur', country: 'India', lCity: 'chandrapur', lCountry: 'india' },
  { city: 'Aurora', country: 'United States', lCity: 'aurora', lCountry: 'united states' },
  { city: 'Kerbala', country: 'Iraq', lCity: 'kerbala', lCountry: 'iraq' },
  { city: 'Galati', country: 'Romania', lCity: 'galati', lCountry: 'romania' },
  { city: 'Naltchik', country: 'Russia', lCity: 'naltchik', lCountry: 'russia' },
  { city: 'Kharagpur', country: 'India', lCity: 'kharagpur', lCountry: 'india' },
  { city: 'Kasugai', country: 'Japan', lCity: 'kasugai', lCountry: 'japan' },
  {
    city: 'Bakersfield',
    country: 'United States',
    lCity: 'bakersfield',
    lCountry: 'united states',
  },
  { city: 'Denizli', country: 'Turkey', lCity: 'denizli', lCountry: 'turkey' },
  { city: 'Bialystok', country: 'Poland', lCity: 'bialystok', lCountry: 'poland' },
  { city: 'Anning', country: 'China', lCity: 'anning', lCountry: 'china' },
  { city: 'New Delhi', country: 'India', lCity: 'new delhi', lCountry: 'india' },
  { city: 'Chernihiv', country: 'Ukraine', lCity: 'chernihiv', lCountry: 'ukraine' },
  { city: 'Rohtak', country: 'India', lCity: 'rohtak', lCountry: 'india' },
  { city: 'Vologda', country: 'Russia', lCity: 'vologda', lCountry: 'russia' },
  { city: 'Jishou', country: 'China', lCity: 'jishou', lCountry: 'china' },
  { city: 'Arhus', country: 'Denmark', lCity: 'arhus', lCountry: 'denmark' },
  { city: 'Hakodate', country: 'Japan', lCity: 'hakodate', lCountry: 'japan' },
  { city: 'Noida', country: 'India', lCity: 'noida', lCountry: 'india' },
  { city: 'Jhang', country: 'Pakistan', lCity: 'jhang', lCountry: 'pakistan' },
  { city: 'Vigo', country: 'Spain', lCity: 'vigo', lCountry: 'spain' },
  { city: 'Garoua', country: 'Cameroon', lCity: 'garoua', lCountry: 'cameroon' },
  { city: 'Fuquan', country: 'China', lCity: 'fuquan', lCountry: 'china' },
  { city: 'Resistencia', country: 'Argentina', lCity: 'resistencia', lCountry: 'argentina' },
  { city: 'Vina Del Mar', country: 'Chile', lCity: 'vina del mar', lCountry: 'chile' },
  { city: 'Sumgayit', country: 'Azerbaijan', lCity: 'sumgayit', lCountry: 'azerbaijan' },
  { city: 'Cherkasy', country: 'Ukraine', lCity: 'cherkasy', lCountry: 'ukraine' },
  { city: 'Akashi', country: 'Japan', lCity: 'akashi', lCountry: 'japan' },
  { city: 'Fukushima', country: 'Japan', lCity: 'fukushima', lCountry: 'japan' },
  { city: 'Shimonoseki', country: 'Japan', lCity: 'shimonoseki', lCountry: 'japan' },
  { city: 'Kukatpalle', country: 'India', lCity: 'kukatpalle', lCountry: 'india' },
  { city: 'Soyapango', country: 'El Salvador', lCity: 'soyapango', lCountry: 'el salvador' },
  { city: 'Surgut', country: 'Russia', lCity: 'surgut', lCountry: 'russia' },
  { city: 'Riverside', country: 'United States', lCity: 'riverside', lCountry: 'united states' },
  { city: 'Kulti', country: 'India', lCity: 'kulti', lCountry: 'india' },
  {
    city: 'Vitoria Da Conquista',
    country: 'Brazil',
    lCity: 'vitoria da conquista',
    lCountry: 'brazil',
  },
  { city: 'Brahmapur', country: 'India', lCity: 'brahmapur', lCountry: 'india' },
  { city: 'Vicente Lopez', country: 'Argentina', lCity: 'vicente lopez', lCountry: 'argentina' },
  { city: 'Sanmenxia', country: 'China', lCity: 'sanmenxia', lCountry: 'china' },
  {
    city: 'Ust Kamenogorsk',
    country: 'Kazakhstan',
    lCity: 'ust kamenogorsk',
    lCountry: 'kazakhstan',
  },
  { city: 'Shaowu', country: 'China', lCity: 'shaowu', lCountry: 'china' },
  { city: 'Al Hufuf', country: 'Saudi Arabia', lCity: 'al hufuf', lCountry: 'saudi arabia' },
  { city: 'Kindia', country: 'Guinea', lCity: 'kindia', lCountry: 'guinea' },
  { city: 'Morioka', country: 'Japan', lCity: 'morioka', lCountry: 'japan' },
  { city: 'Nizamabad', country: 'India', lCity: 'nizamabad', lCountry: 'india' },
  { city: 'Stockton', country: 'United States', lCity: 'stockton', lCountry: 'united states' },
  { city: 'Palmira', country: 'Colombia', lCity: 'palmira', lCountry: 'colombia' },
  { city: 'Quzhou', country: 'China', lCity: 'quzhou', lCountry: 'china' },
  { city: 'Barddhaman', country: 'India', lCity: 'barddhaman', lCountry: 'india' },
  { city: 'Ichalakaranji', country: 'India', lCity: 'ichalakaranji', lCountry: 'india' },
  { city: 'Kuitun', country: 'China', lCity: 'kuitun', lCountry: 'china' },
  { city: 'Uberaba', country: 'Brazil', lCity: 'uberaba', lCountry: 'brazil' },
  { city: 'Al Mubarraz', country: 'Saudi Arabia', lCity: 'al mubarraz', lCountry: 'saudi arabia' },
  { city: 'Iligan', country: 'Philippines', lCity: 'iligan', lCountry: 'philippines' },
  { city: 'Sumy', country: 'Ukraine', lCity: 'sumy', lCountry: 'ukraine' },
  { city: 'Cascavel', country: 'Brazil', lCity: 'cascavel', lCountry: 'brazil' },
  { city: 'Delmas', country: 'Haiti', lCity: 'delmas', lCountry: 'haiti' },
  { city: 'Sakarya', country: 'Turkey', lCity: 'sakarya', lCountry: 'turkey' },
  {
    city: 'Corpus Christi',
    country: 'United States',
    lCity: 'corpus christi',
    lCountry: 'united states',
  },
  { city: 'Caruaru', country: 'Brazil', lCity: 'caruaru', lCountry: 'brazil' },
  { city: 'Tambov', country: 'Russia', lCity: 'tambov', lCountry: 'russia' },
  { city: 'Tongi', country: 'Bangladesh', lCity: 'tongi', lCountry: 'bangladesh' },
  { city: 'Gebze', country: 'Turkey', lCity: 'gebze', lCountry: 'turkey' },
  { city: 'Nzerekore', country: 'Guinea', lCity: 'nzerekore', lCountry: 'guinea' },
  { city: 'Alappuzha', country: 'India', lCity: 'alappuzha', lCountry: 'india' },
  { city: 'Armenia', country: 'Colombia', lCity: 'armenia', lCountry: 'colombia' },
  { city: 'Ciudad Victoria', country: 'Mexico', lCity: 'ciudad victoria', lCountry: 'mexico' },
  { city: 'Gaborone', country: 'Botswana', lCity: 'gaborone', lCountry: 'botswana' },
  { city: 'Buonmathuot', country: 'Viet Nam', lCity: 'buonmathuot', lCountry: 'viet nam' },
  { city: 'Gelsenkirchen', country: 'Germany', lCity: 'gelsenkirchen', lCountry: 'germany' },
  { city: 'Semipalatinsk', country: 'Kazakhstan', lCity: 'semipalatinsk', lCountry: 'kazakhstan' },
  { city: 'Elazig', country: 'Turkey', lCity: 'elazig', lCountry: 'turkey' },
  { city: 'Rampur', country: 'India', lCity: 'rampur', lCountry: 'india' },
  { city: 'Novorossiysk', country: 'Russia', lCity: 'novorossiysk', lCountry: 'russia' },
  { city: 'Brasov', country: 'Romania', lCity: 'brasov', lCountry: 'romania' },
  { city: 'Newark', country: 'United States', lCity: 'newark', lCountry: 'united states' },
  { city: 'Susano', country: 'Brazil', lCity: 'susano', lCountry: 'brazil' },
  { city: 'Sheikhu Pura', country: 'Pakistan', lCity: 'sheikhu pura', lCountry: 'pakistan' },
  { city: 'Ichihara', country: 'Japan', lCity: 'ichihara', lCountry: 'japan' },
  { city: 'Bhilwara', country: 'India', lCity: 'bhilwara', lCountry: 'india' },
  { city: 'Zuwarah', country: 'Libya', lCity: 'zuwarah', lCountry: 'libya' },
  { city: 'Georgetown', country: 'Guyana', lCity: 'georgetown', lCountry: 'guyana' },
  { city: 'Jeju', country: 'South Korea', lCity: 'jeju', lCountry: 'south korea' },
  { city: 'Buffalo', country: 'United States', lCity: 'buffalo', lCountry: 'united states' },
  { city: 'Limeira', country: 'Brazil', lCity: 'limeira', lCountry: 'brazil' },
  { city: 'Roodepoort', country: 'South Africa', lCity: 'roodepoort', lCountry: 'south africa' },
  { city: 'Ciudad Bolivar', country: 'Venezuela', lCity: 'ciudad bolivar', lCountry: 'venezuela' },
  { city: 'Mandaluyong', country: 'Philippines', lCity: 'mandaluyong', lCountry: 'philippines' },
  { city: 'Abadan', country: 'Iran', lCity: 'abadan', lCountry: 'iran' },
  { city: 'Kuching', country: 'Malaysia', lCity: 'kuching', lCountry: 'malaysia' },
  { city: 'Batman', country: 'Turkey', lCity: 'batman', lCountry: 'turkey' },
  { city: 'Karlsruhe', country: 'Germany', lCity: 'karlsruhe', lCountry: 'germany' },
  { city: 'Zhytomyr', country: 'Ukraine', lCity: 'zhytomyr', lCountry: 'ukraine' },
  { city: 'Belfast', country: 'United Kingdom', lCity: 'belfast', lCountry: 'united kingdom' },
  { city: 'Santarem', country: 'Brazil', lCity: 'santarem', lCountry: 'brazil' },
  { city: 'San Bernardo', country: 'Chile', lCity: 'san bernardo', lCountry: 'chile' },
  { city: 'Gyeongju', country: 'South Korea', lCity: 'gyeongju', lCountry: 'south korea' },
  { city: 'Yoshkar Ola', country: 'Russia', lCity: 'yoshkar ola', lCountry: 'russia' },
  { city: 'St Paul', country: 'United States', lCity: 'st paul', lCountry: 'united states' },
  { city: 'Valparaiso', country: 'Chile', lCity: 'valparaiso', lCountry: 'chile' },
  { city: 'Anchorage', country: 'United States', lCity: 'anchorage', lCountry: 'united states' },
  { city: 'Huozhou', country: 'China', lCity: 'huozhou', lCountry: 'china' },
  { city: 'Wollongong', country: 'Australia', lCity: 'wollongong', lCountry: 'australia' },
  { city: 'Holguin', country: 'Cuba', lCity: 'holguin', lCountry: 'cuba' },
  { city: 'Gijon', country: 'Spain', lCity: 'gijon', lCountry: 'spain' },
  { city: 'Shimoga', country: 'India', lCity: 'shimoga', lCountry: 'india' },
  { city: 'Kostroma', country: 'Russia', lCity: 'kostroma', lCountry: 'russia' },
  { city: 'Islam Shahr', country: 'Iran', lCity: 'islam shahr', lCountry: 'iran' },
  { city: 'Yao', country: 'Japan', lCity: 'yao', lCountry: 'japan' },
  { city: 'Cirebon', country: 'Indonesia', lCity: 'cirebon', lCountry: 'indonesia' },
  { city: 'Phyongsong', country: 'North Korea', lCity: 'phyongsong', lCountry: 'north korea' },
  { city: 'Gunsan', country: 'South Korea', lCity: 'gunsan', lCountry: 'south korea' },
  {
    city: 'Komsomolsk Na Amure',
    country: 'Russia',
    lCity: 'komsomolsk na amure',
    lCountry: 'russia',
  },
  { city: 'San Cristobal', country: 'Venezuela', lCity: 'san cristobal', lCountry: 'venezuela' },
  { city: 'Pekalongan', country: 'Indonesia', lCity: 'pekalongan', lCountry: 'indonesia' },
  { city: 'Rajarhat Gopalpur', country: 'India', lCity: 'rajarhat gopalpur', lCountry: 'india' },
  { city: 'Taubate', country: 'Brazil', lCity: 'taubate', lCountry: 'brazil' },
  { city: 'Gravatai', country: 'Brazil', lCity: 'gravatai', lCountry: 'brazil' },
  { city: 'Swansea', country: 'United Kingdom', lCity: 'swansea', lCountry: 'united kingdom' },
  { city: 'Nantes', country: 'France', lCity: 'nantes', lCountry: 'france' },
  { city: 'Larkana', country: 'Pakistan', lCity: 'larkana', lCountry: 'pakistan' },
  { city: 'Kelamayi', country: 'China', lCity: 'kelamayi', lCountry: 'china' },
  { city: 'Santa Maria', country: 'Brazil', lCity: 'santa maria', lCountry: 'brazil' },
  { city: 'Sukabumi', country: 'Indonesia', lCity: 'sukabumi', lCountry: 'indonesia' },
  { city: 'Banda Aceh', country: 'Indonesia', lCity: 'banda aceh', lCountry: 'indonesia' },
  {
    city: 'Nakhon Si Thammarat',
    country: 'Thailand',
    lCity: 'nakhon si thammarat',
    lCountry: 'thailand',
  },
  { city: 'Southend', country: 'United Kingdom', lCity: 'southend', lCountry: 'united kingdom' },
  { city: 'Venezia', country: 'Italy', lCity: 'venezia', lCountry: 'italy' },
  { city: 'Wulanhaote', country: 'China', lCity: 'wulanhaote', lCountry: 'china' },
  { city: 'Irbid', country: 'Jordan', lCity: 'irbid', lCountry: 'jordan' },
  { city: 'Hilla', country: 'Iraq', lCity: 'hilla', lCountry: 'iraq' },
  { city: 'Wiesbaden', country: 'Germany', lCity: 'wiesbaden', lCountry: 'germany' },
  { city: 'Wonju', country: 'South Korea', lCity: 'wonju', lCountry: 'south korea' },
  {
    city: 'Lexington Fayette',
    country: 'United States',
    lCity: 'lexington fayette',
    lCountry: 'united states',
  },
  { city: 'Ibaraki', country: 'Japan', lCity: 'ibaraki', lCountry: 'japan' },
  { city: 'Shillong', country: 'India', lCity: 'shillong', lCountry: 'india' },
  { city: 'Tokushima', country: 'Japan', lCity: 'tokushima', lCountry: 'japan' },
  { city: 'Angeles', country: 'Philippines', lCity: 'angeles', lCountry: 'philippines' },
  { city: 'Monclova', country: 'Mexico', lCity: 'monclova', lCountry: 'mexico' },
  { city: 'Zagazig', country: 'Egypt', lCity: 'zagazig', lCountry: 'egypt' },
  { city: 'Butuan', country: 'Philippines', lCity: 'butuan', lCountry: 'philippines' },
  { city: 'Kakogawa', country: 'Japan', lCity: 'kakogawa', lCountry: 'japan' },
  { city: 'Hail', country: 'Saudi Arabia', lCity: 'hail', lCountry: 'saudi arabia' },
  { city: 'Darbhanga', country: 'India', lCity: 'darbhanga', lCountry: 'india' },
  { city: 'Haifa', country: 'Israel', lCity: 'haifa', lCountry: 'israel' },
  {
    city: 'San Pedro De Macoris',
    country: 'Dominican Republic',
    lCity: 'san pedro de macoris',
    lCountry: 'dominican republic',
  },
  { city: 'Taganrog', country: 'Russia', lCity: 'taganrog', lCountry: 'russia' },
  { city: 'Nasariya', country: 'Iraq', lCity: 'nasariya', lCountry: 'iraq' },
  { city: 'Suncheon', country: 'South Korea', lCity: 'suncheon', lCountry: 'south korea' },
  { city: 'Sterlitamak', country: 'Russia', lCity: 'sterlitamak', lCountry: 'russia' },
  { city: 'Alwar', country: 'India', lCity: 'alwar', lCountry: 'india' },
  { city: 'Petrozavodsk', country: 'Russia', lCity: 'petrozavodsk', lCountry: 'russia' },
  { city: 'Cumana', country: 'Venezuela', lCity: 'cumana', lCountry: 'venezuela' },
  { city: 'Barueri', country: 'Brazil', lCity: 'barueri', lCountry: 'brazil' },
  { city: 'Zhangping', country: 'China', lCity: 'zhangping', lCountry: 'china' },
  { city: 'Munster', country: 'Germany', lCity: 'munster', lCountry: 'germany' },
  { city: 'Chiayi', country: 'China', lCity: 'chiayi', lCountry: 'china' },
  { city: 'Fengzhen', country: 'China', lCity: 'fengzhen', lCountry: 'china' },
  { city: 'Gunpo', country: 'South Korea', lCity: 'gunpo', lCountry: 'south korea' },
  { city: 'Monchengladbach', country: 'Germany', lCity: 'monchengladbach', lCountry: 'germany' },
  { city: 'Strasbourg', country: 'France', lCity: 'strasbourg', lCountry: 'france' },
  { city: 'Dasarahalli', country: 'India', lCity: 'dasarahalli', lCountry: 'india' },
  {
    city: 'Santiago Del Estero',
    country: 'Argentina',
    lCity: 'santiago del estero',
    lCountry: 'argentina',
  },
  { city: 'Yakutsk', country: 'Russia', lCity: 'yakutsk', lCountry: 'russia' },
  { city: 'Chemnitz', country: 'Germany', lCity: 'chemnitz', lCountry: 'germany' },
  { city: 'Boksburg', country: 'South Africa', lCity: 'boksburg', lCountry: 'south africa' },
  { city: 'Hisar', country: 'India', lCity: 'hisar', lCountry: 'india' },
  { city: 'Mito', country: 'Japan', lCity: 'mito', lCountry: 'japan' },
  { city: 'Tarlac', country: 'Philippines', lCity: 'tarlac', lCountry: 'philippines' },
  { city: 'Hailaer', country: 'China', lCity: 'hailaer', lCountry: 'china' },
  { city: 'Maturin', country: 'Venezuela', lCity: 'maturin', lCountry: 'venezuela' },
  { city: 'L B Nagar', country: 'India', lCity: 'l b nagar', lCountry: 'india' },
  { city: 'Viamao', country: 'Brazil', lCity: 'viamao', lCountry: 'brazil' },
  { city: 'Yunfu', country: 'China', lCity: 'yunfu', lCountry: 'china' },
  { city: 'Bally', country: 'India', lCity: 'bally', lCountry: 'india' },
  { city: 'Longjing', country: 'China', lCity: 'longjing', lCountry: 'china' },
  { city: 'Kankan', country: 'Guinea', lCity: 'kankan', lCountry: 'guinea' },
  {
    city: 'Sao Jose Dos Pinhais',
    country: 'Brazil',
    lCity: 'sao jose dos pinhais',
    lCountry: 'brazil',
  },
  { city: 'Blackpool', country: 'United Kingdom', lCity: 'blackpool', lCountry: 'united kingdom' },
  { city: 'Cuddapah', country: 'India', lCity: 'cuddapah', lCountry: 'india' },
  { city: 'Faiyum', country: 'Egypt', lCity: 'faiyum', lCountry: 'egypt' },
  { city: 'Bahia Blanca', country: 'Argentina', lCity: 'bahia blanca', lCountry: 'argentina' },
  { city: 'Verona', country: 'Italy', lCity: 'verona', lCountry: 'italy' },
  { city: 'Petrolina', country: 'Brazil', lCity: 'petrolina', lCountry: 'brazil' },
  { city: 'Dzerzhinsk', country: 'Russia', lCity: 'dzerzhinsk', lCountry: 'russia' },
  { city: 'Ratchaburi', country: 'Thailand', lCity: 'ratchaburi', lCountry: 'thailand' },
  { city: 'Mandaue', country: 'Philippines', lCity: 'mandaue', lCountry: 'philippines' },
  { city: 'Popayan', country: 'Colombia', lCity: 'popayan', lCountry: 'colombia' },
  {
    city: 'Governador Valadares',
    country: 'Brazil',
    lCity: 'governador valadares',
    lCountry: 'brazil',
  },
  { city: 'Katsina', country: 'Nigeria', lCity: 'katsina', lCountry: 'nigeria' },
  { city: 'San Miguel', country: 'El Salvador', lCity: 'san miguel', lCountry: 'el salvador' },
  { city: 'Parbhani', country: 'India', lCity: 'parbhani', lCountry: 'india' },
  { city: 'Hiratsuka', country: 'Japan', lCity: 'hiratsuka', lCountry: 'japan' },
  { city: 'Gajuwaka', country: 'India', lCity: 'gajuwaka', lCountry: 'india' },
  { city: 'Novo Hamburgo', country: 'Brazil', lCity: 'novo hamburgo', lCountry: 'brazil' },
  { city: 'Volta Redonda', country: 'Brazil', lCity: 'volta redonda', lCountry: 'brazil' },
  { city: 'Corrientes', country: 'Argentina', lCity: 'corrientes', lCountry: 'argentina' },
  { city: 'Aktobe', country: 'Kazakhstan', lCity: 'aktobe', lCountry: 'kazakhstan' },
  { city: 'Sivas', country: 'Turkey', lCity: 'sivas', lCountry: 'turkey' },
  { city: 'Floridablanca', country: 'Colombia', lCity: 'floridablanca', lCountry: 'colombia' },
  { city: 'Thies', country: 'Senegal', lCity: 'thies', lCountry: 'senegal' },
  { city: 'Yamagata', country: 'Japan', lCity: 'yamagata', lCountry: 'japan' },
  {
    city: 'Dnieprodzerzhynsk',
    country: 'Ukraine',
    lCity: 'dnieprodzerzhynsk',
    lCountry: 'ukraine',
  },
  { city: 'Ismailia', country: 'Egypt', lCity: 'ismailia', lCountry: 'egypt' },
  { city: 'Augsburg', country: 'Germany', lCity: 'augsburg', lCountry: 'germany' },
  { city: 'Varzea Grande', country: 'Brazil', lCity: 'varzea grande', lCountry: 'brazil' },
  { city: 'Bengkulu', country: 'Indonesia', lCity: 'bengkulu', lCountry: 'indonesia' },
  { city: 'Halle', country: 'Germany', lCity: 'halle', lCountry: 'germany' },
  { city: 'Nis', country: 'Serbia', lCity: 'nis', lCountry: 'serbia' },
  {
    city: 'Port Moresby',
    country: 'Papua New Guinea',
    lCity: 'port moresby',
    lCountry: 'papua new guinea',
  },
  { city: 'Sariwon', country: 'North Korea', lCity: 'sariwon', lCountry: 'north korea' },
  {
    city: 'Ubon Ratchathani',
    country: 'Thailand',
    lCity: 'ubon ratchathani',
    lCountry: 'thailand',
  },
  { city: 'Bratsk', country: 'Russia', lCity: 'bratsk', lCountry: 'russia' },
  { city: 'Bijapur', country: 'India', lCity: 'bijapur', lCountry: 'india' },
  { city: 'Kirovohrad', country: 'Ukraine', lCity: 'kirovohrad', lCountry: 'ukraine' },
  { city: 'Khmelnytskiy', country: 'Ukraine', lCity: 'khmelnytskiy', lCountry: 'ukraine' },
  { city: 'Sari', country: 'Iran', lCity: 'sari', lCountry: 'iran' },
  { city: 'Dongsheng', country: 'China', lCity: 'dongsheng', lCountry: 'china' },
  { city: 'Chuncheon', country: 'South Korea', lCity: 'chuncheon', lCountry: 'south korea' },
  { city: 'Gdynia', country: 'Poland', lCity: 'gdynia', lCountry: 'poland' },
  { city: 'Baguio', country: 'Philippines', lCity: 'baguio', lCountry: 'philippines' },
  { city: 'Fukui', country: 'Japan', lCity: 'fukui', lCountry: 'japan' },
  { city: 'Junagadh', country: 'India', lCity: 'junagadh', lCountry: 'india' },
  { city: 'Kediri', country: 'Indonesia', lCity: 'kediri', lCountry: 'indonesia' },
  { city: 'Bamenda', country: 'Cameroon', lCity: 'bamenda', lCountry: 'cameroon' },
  { city: 'Gujrat', country: 'Pakistan', lCity: 'gujrat', lCountry: 'pakistan' },
  { city: 'Chishui', country: 'China', lCity: 'chishui', lCountry: 'china' },
  { city: 'Tulufan', country: 'China', lCity: 'tulufan', lCountry: 'china' },
  { city: 'Orsk', country: 'Russia', lCity: 'orsk', lCountry: 'russia' },
  { city: 'Kure', country: 'Japan', lCity: 'kure', lCountry: 'japan' },
  { city: 'Oshogbo', country: 'Nigeria', lCity: 'oshogbo', lCountry: 'nigeria' },
  { city: 'Baranagar', country: 'India', lCity: 'baranagar', lCountry: 'india' },
  { city: 'Mokpo', country: 'South Korea', lCity: 'mokpo', lCountry: 'south korea' },
  { city: 'Longquan', country: 'China', lCity: 'longquan', lCountry: 'china' },
  { city: 'Plano', country: 'United States', lCity: 'plano', lCountry: 'united states' },
  { city: 'Osh', country: 'Kyrgyzstan', lCity: 'osh', lCountry: 'kyrgyzstan' },
  { city: 'Lingwu', country: 'China', lCity: 'lingwu', lCountry: 'china' },
  { city: 'Leiden', country: 'Netherlands', lCity: 'leiden', lCountry: 'netherlands' },
  { city: 'Boa Vista', country: 'Brazil', lCity: 'boa vista', lCountry: 'brazil' },
  { city: 'Labe', country: 'Guinea', lCity: 'labe', lCountry: 'guinea' },
  { city: 'Alajuela', country: 'Costa Rica', lCity: 'alajuela', lCountry: 'costa rica' },
  {
    city: 'St Petersburg',
    country: 'United States',
    lCity: 'st petersburg',
    lCountry: 'united states',
  },
  {
    city: 'Phra Nakhon Si Ayutthaya',
    country: 'Thailand',
    lCity: 'phra nakhon si ayutthaya',
    lCountry: 'thailand',
  },
  { city: 'Pucallpa', country: 'Peru', lCity: 'pucallpa', lCountry: 'peru' },
  { city: 'Sfax', country: 'Tunisia', lCity: 'sfax', lCountry: 'tunisia' },
  { city: 'Qingtongxia', country: 'China', lCity: 'qingtongxia', lCountry: 'china' },
  { city: 'Tumkur', country: 'India', lCity: 'tumkur', lCountry: 'india' },
  { city: 'Russiefa', country: 'Jordan', lCity: 'russiefa', lCountry: 'jordan' },
  {
    city: 'Hospitalet De Llobregat',
    country: 'Spain',
    lCity: 'hospitalet de llobregat',
    lCountry: 'spain',
  },
  { city: 'Ljubljana', country: 'Slovenia', lCity: 'ljubljana', lCountry: 'slovenia' },
  { city: 'Sasebo', country: 'Japan', lCity: 'sasebo', lCountry: 'japan' },
  { city: 'Batangas', country: 'Philippines', lCity: 'batangas', lCountry: 'philippines' },
  { city: 'Temuco', country: 'Chile', lCity: 'temuco', lCountry: 'chile' },
  { city: 'Sucre', country: 'Bolivia', lCity: 'sucre', lCountry: 'bolivia' },
  { city: 'Najran', country: 'Saudi Arabia', lCity: 'najran', lCountry: 'saudi arabia' },
  { city: 'Shakhty', country: 'Russia', lCity: 'shakhty', lCountry: 'russia' },
  { city: 'Batna', country: 'Algeria', lCity: 'batna', lCountry: 'algeria' },
  { city: 'Braunschweig', country: 'Germany', lCity: 'braunschweig', lCountry: 'germany' },
  { city: 'Saraburi', country: 'Thailand', lCity: 'saraburi', lCountry: 'thailand' },
  { city: 'Czestochowa', country: 'Poland', lCity: 'czestochowa', lCountry: 'poland' },
  { city: 'Imphal', country: 'India', lCity: 'imphal', lCountry: 'india' },
  { city: 'Mardan', country: 'Pakistan', lCity: 'mardan', lCountry: 'pakistan' },
  { city: 'Embu', country: 'Brazil', lCity: 'embu', lCountry: 'brazil' },
  { city: 'Messina', country: 'Italy', lCity: 'messina', lCountry: 'italy' },
  { city: 'Fuchu', country: 'Japan', lCity: 'fuchu', lCountry: 'japan' },
  { city: 'Dordrecht', country: 'Netherlands', lCity: 'dordrecht', lCountry: 'netherlands' },
  { city: 'Praia Grande', country: 'Brazil', lCity: 'praia grande', lCountry: 'brazil' },
  { city: 'Kasur', country: 'Pakistan', lCity: 'kasur', lCountry: 'pakistan' },
  { city: 'Takasaki', country: 'Japan', lCity: 'takasaki', lCountry: 'japan' },
  { city: 'Angarsk', country: 'Russia', lCity: 'angarsk', lCountry: 'russia' },
  { city: 'Kashan', country: 'Iran', lCity: 'kashan', lCountry: 'iran' },
  { city: 'Hachinohe', country: 'Japan', lCity: 'hachinohe', lCountry: 'japan' },
  { city: 'Suqian', country: 'China', lCity: 'suqian', lCountry: 'china' },
  { city: 'Syktivkar', country: 'Russia', lCity: 'syktivkar', lCountry: 'russia' },
  { city: 'Graz', country: 'Austria', lCity: 'graz', lCountry: 'austria' },
  { city: 'Majnoon', country: 'Iraq', lCity: 'majnoon', lCountry: 'iraq' },
  { city: 'Rivne', country: 'Ukraine', lCity: 'rivne', lCountry: 'ukraine' },
  { city: 'Borujerd', country: 'Iran', lCity: 'borujerd', lCountry: 'iran' },
  { city: 'Aachen', country: 'Germany', lCity: 'aachen', lCountry: 'germany' },
  { city: 'Plymouth', country: 'United Kingdom', lCity: 'plymouth', lCountry: 'united kingdom' },
  { city: 'Bergen', country: 'Norway', lCity: 'bergen', lCountry: 'norway' },
  { city: 'Anantapur', country: 'India', lCity: 'anantapur', lCountry: 'india' },
  { city: 'Aldershot', country: 'United Kingdom', lCity: 'aldershot', lCountry: 'united kingdom' },
  { city: 'Coruna', country: 'Spain', lCity: 'coruna', lCountry: 'spain' },
  { city: 'La Coruna', country: 'Spain', lCity: 'la coruna', lCountry: 'spain' },
  { city: 'Jinshi', country: 'China', lCity: 'jinshi', lCountry: 'china' },
  { city: 'Paramaribo', country: 'Suriname', lCity: 'paramaribo', lCountry: 'suriname' },
  { city: 'Thoothukkudi', country: 'India', lCity: 'thoothukkudi', lCountry: 'india' },
  {
    city: 'Farrukhabad Cum Fategarh',
    country: 'India',
    lCity: 'farrukhabad cum fategarh',
    lCountry: 'india',
  },
  { city: 'Huayin', country: 'China', lCity: 'huayin', lCountry: 'china' },
  { city: 'Tegal', country: 'Indonesia', lCity: 'tegal', lCountry: 'indonesia' },
  { city: 'Neyagawa', country: 'Japan', lCity: 'neyagawa', lCountry: 'japan' },
  { city: 'Krefeld', country: 'Germany', lCity: 'krefeld', lCountry: 'germany' },
  { city: 'Dezful', country: 'Iran', lCity: 'dezful', lCountry: 'iran' },
  { city: 'Sincelejo', country: 'Colombia', lCity: 'sincelejo', lCountry: 'colombia' },
  { city: 'Nizhenvartovsk', country: 'Russia', lCity: 'nizhenvartovsk', lCountry: 'russia' },
  { city: 'Narayanganj', country: 'Bangladesh', lCity: 'narayanganj', lCountry: 'bangladesh' },
  { city: 'Chiang Rai', country: 'Thailand', lCity: 'chiang rai', lCountry: 'thailand' },
  { city: 'Rangpur', country: 'Bangladesh', lCity: 'rangpur', lCountry: 'bangladesh' },
  { city: 'Gorgan', country: 'Iran', lCity: 'gorgan', lCountry: 'iran' },
  { city: 'Juazeiro Do Norte', country: 'Brazil', lCity: 'juazeiro do norte', lCountry: 'brazil' },
  { city: 'Tiefa', country: 'China', lCity: 'tiefa', lCountry: 'china' },
  {
    city: 'Jersey City',
    country: 'United States',
    lCity: 'jersey city',
    lCountry: 'united states',
  },
  { city: 'Glendale', country: 'United States', lCity: 'glendale', lCountry: 'united states' },
  { city: 'Lincoln', country: 'United States', lCity: 'lincoln', lCountry: 'united states' },
  { city: 'Habra', country: 'India', lCity: 'habra', lCountry: 'india' },
  { city: 'Akure', country: 'Nigeria', lCity: 'akure', lCountry: 'nigeria' },
  { city: 'Itagui', country: 'Colombia', lCity: 'itagui', lCountry: 'colombia' },
  { city: 'Kasukabe', country: 'Japan', lCity: 'kasukabe', lCountry: 'japan' },
  { city: 'Chernivtsy', country: 'Ukraine', lCity: 'chernivtsy', lCountry: 'ukraine' },
  { city: 'Granada', country: 'Spain', lCity: 'granada', lCountry: 'spain' },
  { city: 'Sumare', country: 'Brazil', lCity: 'sumare', lCountry: 'brazil' },
  { city: 'Tembisa', country: 'South Africa', lCity: 'tembisa', lCountry: 'south africa' },
  { city: 'Bukhara', country: 'Uzbekistan', lCity: 'bukhara', lCountry: 'uzbekistan' },
  { city: 'Dire Dawa', country: 'Ethiopia', lCity: 'dire dawa', lCountry: 'ethiopia' },
  { city: 'Mage', country: 'Brazil', lCity: 'mage', lCountry: 'brazil' },
  { city: 'Derby', country: 'United Kingdom', lCity: 'derby', lCountry: 'united kingdom' },
  { city: 'Ramagundam', country: 'India', lCity: 'ramagundam', lCountry: 'india' },
  { city: 'Fuji', country: 'Japan', lCity: 'fuji', lCountry: 'japan' },
  { city: 'Qods', country: 'Iran', lCity: 'qods', lCountry: 'iran' },
  { city: 'Ipatinga', country: 'Brazil', lCity: 'ipatinga', lCountry: 'brazil' },
  { city: 'Nagaoka', country: 'Japan', lCity: 'nagaoka', lCountry: 'japan' },
  {
    city: 'Luton Dunstable',
    country: 'United Kingdom',
    lCity: 'luton dunstable',
    lCountry: 'united kingdom',
  },
  { city: 'Soka', country: 'Japan', lCity: 'soka', lCountry: 'japan' },
  { city: 'Lishi', country: 'China', lCity: 'lishi', lCountry: 'china' },
  { city: 'Karradah Sharqiyah', country: 'Iraq', lCity: 'karradah sharqiyah', lCountry: 'iraq' },
  { city: 'Jalna', country: 'India', lCity: 'jalna', lCountry: 'india' },
  { city: 'Saskatoon', country: 'Canada', lCity: 'saskatoon', lCountry: 'canada' },
  { city: 'Bujumbura', country: 'Burundi', lCity: 'bujumbura', lCountry: 'burundi' },
  { city: 'Zacatecas', country: 'Mexico', lCity: 'zacatecas', lCountry: 'mexico' },
  { city: 'Nakhon Pathom', country: 'Thailand', lCity: 'nakhon pathom', lCountry: 'thailand' },
  { city: 'Magdeburg', country: 'Germany', lCity: 'magdeburg', lCountry: 'germany' },
  { city: 'Chandler', country: 'United States', lCity: 'chandler', lCountry: 'united states' },
  { city: 'Kosice', country: 'Slovakia', lCity: 'kosice', lCountry: 'slovakia' },
  { city: 'Kassala', country: 'Sudan', lCity: 'kassala', lCountry: 'sudan' },
  { city: 'Kota Bahru', country: 'Malaysia', lCity: 'kota bahru', lCountry: 'malaysia' },
  { city: 'Engels', country: 'Russia', lCity: 'engels', lCountry: 'russia' },
  { city: 'Kiel', country: 'Germany', lCity: 'kiel', lCountry: 'germany' },
  { city: 'Kigali', country: 'Rwanda', lCity: 'kigali', lCountry: 'rwanda' },
  { city: 'Rahimyar Khan', country: 'Pakistan', lCity: 'rahimyar khan', lCountry: 'pakistan' },
  { city: 'Windhoek', country: 'Namibia', lCity: 'windhoek', lCountry: 'namibia' },
  { city: 'Ratlam', country: 'India', lCity: 'ratlam', lCountry: 'india' },
  { city: 'Espoo', country: 'Finland', lCity: 'espoo', lCountry: 'finland' },
  { city: 'Kousseri', country: 'Cameroon', lCity: 'kousseri', lCountry: 'cameroon' },
  { city: 'Gent', country: 'Belgium', lCity: 'gent', lCountry: 'belgium' },
  { city: 'Porto Novo', country: 'Benin', lCity: 'porto novo', lCountry: 'benin' },
  { city: 'Imperatriz', country: 'Brazil', lCity: 'imperatriz', lCountry: 'brazil' },
  { city: 'Henderson', country: 'United States', lCity: 'henderson', lCountry: 'united states' },
  { city: 'Ivano Frankivsk', country: 'Ukraine', lCity: 'ivano frankivsk', lCountry: 'ukraine' },
  {
    city: 'Hafar Al Batin',
    country: 'Saudi Arabia',
    lCity: 'hafar al batin',
    lCountry: 'saudi arabia',
  },
  { city: 'Bihar', country: 'India', lCity: 'bihar', lCountry: 'india' },
  { city: 'Greensboro', country: 'United States', lCity: 'greensboro', lCountry: 'united states' },
  { city: 'Norfolk', country: 'United States', lCity: 'norfolk', lCountry: 'united states' },
  {
    city: 'The Medway Towns',
    country: 'United Kingdom',
    lCity: 'the medway towns',
    lCountry: 'united kingdom',
  },
  { city: 'Ploiesti', country: 'Romania', lCity: 'ploiesti', lCountry: 'romania' },
  { city: 'Barasat', country: 'India', lCity: 'barasat', lCountry: 'india' },
  { city: 'Durg', country: 'India', lCity: 'durg', lCountry: 'india' },
  { city: 'Avadi', country: 'India', lCity: 'avadi', lCountry: 'india' },
  { city: 'Simao', country: 'China', lCity: 'simao', lCountry: 'china' },
  { city: 'Dewas', country: 'India', lCity: 'dewas', lCountry: 'india' },
  { city: 'Porto', country: 'Portugal', lCity: 'porto', lCountry: 'portugal' },
  { city: 'Navotas', country: 'Philippines', lCity: 'navotas', lCountry: 'philippines' },
  { city: 'Manisa', country: 'Turkey', lCity: 'manisa', lCountry: 'turkey' },
  { city: 'Kremenchuh', country: 'Ukraine', lCity: 'kremenchuh', lCountry: 'ukraine' },
  { city: 'Tacna', country: 'Peru', lCity: 'tacna', lCountry: 'peru' },
  { city: 'Uralsk', country: 'Kazakhstan', lCity: 'uralsk', lCountry: 'kazakhstan' },
  { city: 'Blida', country: 'Algeria', lCity: 'blida', lCountry: 'algeria' },
  { city: 'Mossoro', country: 'Brazil', lCity: 'mossoro', lCountry: 'brazil' },
  { city: 'Aizawl', country: 'India', lCity: 'aizawl', lCountry: 'india' },
  { city: 'Al Obeid', country: 'Sudan', lCity: 'al obeid', lCountry: 'sudan' },
  { city: 'Navsari', country: 'India', lCity: 'navsari', lCountry: 'india' },
  { city: 'Satna', country: 'India', lCity: 'satna', lCountry: 'india' },
  { city: 'Gurgaon', country: 'India', lCity: 'gurgaon', lCountry: 'india' },
  { city: 'Haeju', country: 'North Korea', lCity: 'haeju', lCountry: 'north korea' },
  { city: 'Chigasaki', country: 'Japan', lCity: 'chigasaki', lCountry: 'japan' },
  { city: 'Gangneung', country: 'South Korea', lCity: 'gangneung', lCountry: 'south korea' },
  { city: 'Gyeongsan', country: 'South Korea', lCity: 'gyeongsan', lCountry: 'south korea' },
  {
    city: 'Kuala Terengganu',
    country: 'Malaysia',
    lCity: 'kuala terengganu',
    lCountry: 'malaysia',
  },
  { city: 'Trabzon', country: 'Turkey', lCity: 'trabzon', lCountry: 'turkey' },
  { city: 'Kaolack', country: 'Senegal', lCity: 'kaolack', lCountry: 'senegal' },
  { city: 'Heyuan', country: 'China', lCity: 'heyuan', lCountry: 'china' },
  {
    city: 'Acarigua Araure',
    country: 'Venezuela',
    lCity: 'acarigua araure',
    lCountry: 'venezuela',
  },
  { city: 'Matsumoto', country: 'Japan', lCity: 'matsumoto', lCountry: 'japan' },
  { city: 'Vitoria Gasteiz', country: 'Spain', lCity: 'vitoria gasteiz', lCountry: 'spain' },
  { city: 'Balikesir', country: 'Turkey', lCity: 'balikesir', lCountry: 'turkey' },
  { city: 'Mymensingh', country: 'Bangladesh', lCity: 'mymensingh', lCountry: 'bangladesh' },
  { city: 'Colimas', country: 'Mexico', lCity: 'colimas', lCountry: 'mexico' },
  { city: 'Nyala', country: 'Sudan', lCity: 'nyala', lCountry: 'sudan' },
  { city: 'Sambalpur', country: 'India', lCity: 'sambalpur', lCountry: 'india' },
  {
    city: 'Aberdeenshire',
    country: 'United Kingdom',
    lCity: 'aberdeenshire',
    lCountry: 'united kingdom',
  },
  { city: 'Rancagua', country: 'Chile', lCity: 'rancagua', lCountry: 'chile' },
  { city: 'Nizhnekamsk', country: 'Russia', lCity: 'nizhnekamsk', lCountry: 'russia' },
  { city: 'Binjai', country: 'Indonesia', lCity: 'binjai', lCountry: 'indonesia' },
  { city: 'Radom', country: 'Poland', lCity: 'radom', lCountry: 'poland' },
  { city: 'Scottsdale', country: 'United States', lCity: 'scottsdale', lCountry: 'united states' },
  { city: 'Quthbullapur', country: 'India', lCity: 'quthbullapur', lCountry: 'india' },
  { city: 'Montpellier', country: 'France', lCity: 'montpellier', lCountry: 'france' },
  { city: 'Biisk', country: 'Russia', lCity: 'biisk', lCountry: 'russia' },
  { city: 'Kandahar', country: 'Afghanistan', lCity: 'kandahar', lCountry: 'afghanistan' },
  { city: 'Maroua', country: 'Cameroon', lCity: 'maroua', lCountry: 'cameroon' },
  { city: 'Taboao Da Serra', country: 'Brazil', lCity: 'taboao da serra', lCountry: 'brazil' },
  { city: 'Sosnowiec', country: 'Poland', lCity: 'sosnowiec', lCountry: 'poland' },
  { city: 'Sonipat', country: 'India', lCity: 'sonipat', lCountry: 'india' },
  { city: 'Houma', country: 'China', lCity: 'houma', lCountry: 'china' },
  { city: 'Bole', country: 'China', lCity: 'bole', lCountry: 'china' },
  { city: 'Lefkosia', country: 'Cyprus', lCity: 'lefkosia', lCountry: 'cyprus' },
  {
    city: 'Pematang Siantar',
    country: 'Indonesia',
    lCity: 'pematang siantar',
    lCountry: 'indonesia',
  },
  { city: 'English Bazar', country: 'India', lCity: 'english bazar', lCountry: 'india' },
  { city: 'Ambon', country: 'Indonesia', lCity: 'ambon', lCountry: 'indonesia' },
  { city: 'Marilia', country: 'Brazil', lCity: 'marilia', lCountry: 'brazil' },
  { city: 'As Seeb', country: 'Oman', lCity: 'as seeb', lCountry: 'oman' },
  { city: 'Kanggye', country: 'North Korea', lCity: 'kanggye', lCountry: 'north korea' },
  { city: 'Fort Wayne', country: 'United States', lCity: 'fort wayne', lCountry: 'united states' },
  {
    city: 'Santa Cruz De Tenerife',
    country: 'Spain',
    lCity: 'santa cruz de tenerife',
    lCountry: 'spain',
  },
  { city: 'Mwanza', country: 'Tanzania', lCity: 'mwanza', lCountry: 'tanzania' },
  { city: 'Lasa', country: 'China', lCity: 'lasa', lCountry: 'china' },
  { city: 'Mohammedia', country: 'Morocco', lCity: 'mohammedia', lCountry: 'morocco' },
  { city: 'Cabanatuan', country: 'Philippines', lCity: 'cabanatuan', lCountry: 'philippines' },
  { city: 'Ganganagar', country: 'India', lCity: 'ganganagar', lCountry: 'india' },
  { city: 'Tarsus', country: 'Turkey', lCity: 'tarsus', lCountry: 'turkey' },
  { city: 'Al Jubayl', country: 'Saudi Arabia', lCity: 'al jubayl', lCountry: 'saudi arabia' },
  { city: 'Tilburg', country: 'Netherlands', lCity: 'tilburg', lCountry: 'netherlands' },
  { city: 'Atsugi', country: 'Japan', lCity: 'atsugi', lCountry: 'japan' },
  { city: 'Oberhausen', country: 'Germany', lCity: 'oberhausen', lCountry: 'germany' },
  {
    city: 'Baton Rouge',
    country: 'United States',
    lCity: 'baton rouge',
    lCountry: 'united states',
  },
  { city: 'Karnal', country: 'India', lCity: 'karnal', lCountry: 'india' },
  {
    city: 'San Fernando City',
    country: 'Philippines',
    lCity: 'san fernando city',
    lCountry: 'philippines',
  },
  { city: 'Barinas', country: 'Venezuela', lCity: 'barinas', lCountry: 'venezuela' },
  { city: 'Madison', country: 'United States', lCity: 'madison', lCountry: 'united states' },
  { city: 'Bayamon', country: 'Puerto Rico', lCity: 'bayamon', lCountry: 'puerto rico' },
  { city: 'Badalona', country: 'Spain', lCity: 'badalona', lCountry: 'spain' },
  { city: 'Nhatrang', country: 'Viet Nam', lCity: 'nhatrang', lCountry: 'viet nam' },
  { city: 'Yamato', country: 'Japan', lCity: 'yamato', lCountry: 'japan' },
  { city: 'Itaborai', country: 'Brazil', lCity: 'itaborai', lCountry: 'brazil' },
  { city: 'Ilheus', country: 'Brazil', lCity: 'ilheus', lCountry: 'brazil' },
  { city: 'Palmas', country: 'Brazil', lCity: 'palmas', lCountry: 'brazil' },
  { city: 'Rishon Leziyyon', country: 'Israel', lCity: 'rishon leziyyon', lCountry: 'israel' },
  { city: 'Hialeah', country: 'United States', lCity: 'hialeah', lCountry: 'united states' },
  { city: 'Hardwar', country: 'India', lCity: 'hardwar', lCountry: 'india' },
  { city: 'Ageo', country: 'Japan', lCity: 'ageo', lCountry: 'japan' },
  { city: 'Groznyi', country: 'Russia', lCity: 'groznyi', lCountry: 'russia' },
  { city: 'North Dumdum', country: 'India', lCity: 'north dumdum', lCountry: 'india' },
  { city: 'Concepcion', country: 'Chile', lCity: 'concepcion', lCountry: 'chile' },
  { city: 'Takarazuka', country: 'Japan', lCity: 'takarazuka', lCountry: 'japan' },
  { city: 'Wadhwan', country: 'India', lCity: 'wadhwan', lCountry: 'india' },
  { city: 'Santa Luzia', country: 'Brazil', lCity: 'santa luzia', lCountry: 'brazil' },
  { city: 'George Town', country: 'Malaysia', lCity: 'george town', lCountry: 'malaysia' },
  { city: 'Aswan', country: 'Egypt', lCity: 'aswan', lCountry: 'egypt' },
  { city: 'Ternopil', country: 'Ukraine', lCity: 'ternopil', lCountry: 'ukraine' },
  { city: 'Hue', country: 'Viet Nam', lCity: 'hue', lCountry: 'viet nam' },
  { city: 'Elche', country: 'Spain', lCity: 'elche', lCountry: 'spain' },
  { city: 'Chesapeake', country: 'United States', lCity: 'chesapeake', lCountry: 'united states' },
  { city: 'Bobruisk', country: 'Belarus', lCity: 'bobruisk', lCountry: 'belarus' },
  { city: 'Sao Carlo', country: 'Brazil', lCity: 'sao carlo', lCountry: 'brazil' },
  { city: 'Starsy Oskol', country: 'Russia', lCity: 'starsy oskol', lCountry: 'russia' },
  { city: 'Lipa', country: 'Philippines', lCity: 'lipa', lCountry: 'philippines' },
  { city: 'Anand', country: 'India', lCity: 'anand', lCountry: 'india' },
  { city: 'Chungju', country: 'South Korea', lCity: 'chungju', lCountry: 'south korea' },
  { city: 'Ozhukarai', country: 'India', lCity: 'ozhukarai', lCountry: 'india' },
  { city: 'Bathinda', country: 'India', lCity: 'bathinda', lCountry: 'india' },
  { city: 'Machala', country: 'Ecuador', lCity: 'machala', lCountry: 'ecuador' },
  { city: 'Velikiy Novgorod', country: 'Russia', lCity: 'velikiy novgorod', lCountry: 'russia' },
  { city: 'Lapu Lapu', country: 'Philippines', lCity: 'lapu lapu', lCountry: 'philippines' },
  { city: 'Lampang', country: 'Thailand', lCity: 'lampang', lCountry: 'thailand' },
  { city: 'Prokopyevsk', country: 'Russia', lCity: 'prokopyevsk', lCountry: 'russia' },
  { city: 'Northshore', country: 'New Zealand', lCity: 'northshore', lCountry: 'new zealand' },
  { city: 'Braila', country: 'Romania', lCity: 'braila', lCountry: 'romania' },
  { city: 'Oruro', country: 'Bolivia', lCity: 'oruro', lCountry: 'bolivia' },
  { city: 'Campeche', country: 'Mexico', lCity: 'campeche', lCountry: 'mexico' },
  { city: 'Garland', country: 'United States', lCity: 'garland', lCountry: 'united states' },
  { city: 'Chofu', country: 'Japan', lCity: 'chofu', lCountry: 'japan' },
  { city: 'Blagoveshchensk', country: 'Russia', lCity: 'blagoveshchensk', lCountry: 'russia' },
  { city: 'Karimnagar', country: 'India', lCity: 'karimnagar', lCountry: 'india' },
  { city: 'Thanjavur', country: 'India', lCity: 'thanjavur', lCountry: 'india' },
  { city: 'Cantho', country: 'Viet Nam', lCity: 'cantho', lCountry: 'viet nam' },
  { city: 'Naihati', country: 'India', lCity: 'naihati', lCountry: 'india' },
  { city: 'Eluru', country: 'India', lCity: 'eluru', lCountry: 'india' },
  { city: 'Sabzewar', country: 'Iran', lCity: 'sabzewar', lCountry: 'iran' },
  { city: 'Bordeaux', country: 'France', lCity: 'bordeaux', lCountry: 'france' },
  { city: 'Helong', country: 'China', lCity: 'helong', lCountry: 'china' },
  { city: 'Sete Lagoas', country: 'Brazil', lCity: 'sete lagoas', lCountry: 'brazil' },
  { city: 'Sunshine Coast', country: 'Australia', lCity: 'sunshine coast', lCountry: 'australia' },
  { city: 'Alvorada', country: 'Brazil', lCity: 'alvorada', lCountry: 'brazil' },
  { city: 'Oviedo', country: 'Spain', lCity: 'oviedo', lCountry: 'spain' },
  { city: 'Setif', country: 'Algeria', lCity: 'setif', lCountry: 'algeria' },
  { city: 'Rybinsk', country: 'Russia', lCity: 'rybinsk', lCountry: 'russia' },
  { city: 'Lucheng', country: 'China', lCity: 'lucheng', lCountry: 'china' },
  { city: 'Lubeck', country: 'Germany', lCity: 'lubeck', lCountry: 'germany' },
  { city: 'Ota', country: 'Japan', lCity: 'ota', lCountry: 'japan' },
  { city: 'Cabimas', country: 'Venezuela', lCity: 'cabimas', lCountry: 'venezuela' },
  { city: 'Orlando', country: 'United States', lCity: 'orlando', lCountry: 'united states' },
  { city: 'Kalasin', country: 'Thailand', lCity: 'kalasin', lCountry: 'thailand' },
  { city: 'Sao Leopoldo', country: 'Brazil', lCity: 'sao leopoldo', lCountry: 'brazil' },
  { city: 'Norilsk', country: 'Russia', lCity: 'norilsk', lCountry: 'russia' },
  { city: 'Wuyishan', country: 'China', lCity: 'wuyishan', lCountry: 'china' },
  { city: 'Aberdeen', country: 'United Kingdom', lCity: 'aberdeen', lCountry: 'united kingdom' },
  { city: 'Nukus', country: 'Uzbekistan', lCity: 'nukus', lCountry: 'uzbekistan' },
  { city: 'Hapur', country: 'India', lCity: 'hapur', lCountry: 'india' },
  { city: 'Parana', country: 'Argentina', lCity: 'parana', lCountry: 'argentina' },
  { city: 'Tiruvottiyur', country: 'India', lCity: 'tiruvottiyur', lCountry: 'india' },
  {
    city: 'Santo Domingo De Los Colorados',
    country: 'Ecuador',
    lCity: 'santo domingo de los colorados',
    lCountry: 'ecuador',
  },
  { city: 'Jacarei', country: 'Brazil', lCity: 'jacarei', lCountry: 'brazil' },
  { city: 'Etawah', country: 'India', lCity: 'etawah', lCountry: 'india' },
  { city: 'Al Gezira', country: 'Sudan', lCity: 'al gezira', lCountry: 'sudan' },
  { city: 'Hunchun', country: 'China', lCity: 'hunchun', lCountry: 'china' },
  { city: 'Rochester', country: 'United States', lCity: 'rochester', lCountry: 'united states' },
  { city: 'Nassau', country: 'Bahamas', lCity: 'nassau', lCountry: 'bahamas' },
  { city: 'Akron', country: 'United States', lCity: 'akron', lCountry: 'united states' },
  { city: 'Posadas', country: 'Argentina', lCity: 'posadas', lCountry: 'argentina' },
  { city: 'Padova', country: 'Italy', lCity: 'padova', lCountry: 'italy' },
  {
    city: 'Chula Vista',
    country: 'United States',
    lCity: 'chula vista',
    lCountry: 'united states',
  },
  { city: 'Maunath Bhanjan', country: 'India', lCity: 'maunath bhanjan', lCountry: 'india' },
  { city: 'Lubbock', country: 'United States', lCity: 'lubbock', lCountry: 'united states' },
  {
    city: 'Heerlen Kerkrade',
    country: 'Netherlands',
    lCity: 'heerlen kerkrade',
    lCountry: 'netherlands',
  },
  { city: 'Damanhur', country: 'Egypt', lCity: 'damanhur', lCountry: 'egypt' },
  { city: 'Campha', country: 'Viet Nam', lCity: 'campha', lCountry: 'viet nam' },
  { city: 'Highland', country: 'United Kingdom', lCity: 'highland', lCountry: 'united kingdom' },
  { city: 'Kramatorsk', country: 'Ukraine', lCity: 'kramatorsk', lCountry: 'ukraine' },
  { city: 'Armavir', country: 'Russia', lCity: 'armavir', lCountry: 'russia' },
  { city: 'Amara', country: 'Iraq', lCity: 'amara', lCountry: 'iraq' },
  { city: 'Sahiwal', country: 'Pakistan', lCity: 'sahiwal', lCountry: 'pakistan' },
  { city: 'Laredo', country: 'United States', lCity: 'laredo', lCountry: 'united states' },
  { city: 'Santa Clara', country: 'Cuba', lCity: 'santa clara', lCountry: 'cuba' },
  { city: 'Guantanamo', country: 'Cuba', lCity: 'guantanamo', lCountry: 'cuba' },
  { city: 'Juliaca', country: 'Peru', lCity: 'juliaca', lCountry: 'peru' },
  { city: 'Juazeiro', country: 'Brazil', lCity: 'juazeiro', lCountry: 'brazil' },
  { city: 'Faizabad', country: 'India', lCity: 'faizabad', lCountry: 'india' },
  { city: 'Nagercoil', country: 'India', lCity: 'nagercoil', lCountry: 'india' },
  { city: 'Kirikkale', country: 'Turkey', lCity: 'kirikkale', lCountry: 'turkey' },
  { city: 'Joetsu', country: 'Japan', lCity: 'joetsu', lCountry: 'japan' },
  { city: 'Numazu', country: 'Japan', lCity: 'numazu', lCountry: 'japan' },
  { city: 'Divinopolis', country: 'Brazil', lCity: 'divinopolis', lCountry: 'brazil' },
  { city: 'San Pablo', country: 'Philippines', lCity: 'san pablo', lCountry: 'philippines' },
  { city: 'Kustanai', country: 'Kazakhstan', lCity: 'kustanai', lCountry: 'kazakhstan' },
  {
    city: 'Dearne Valley',
    country: 'United Kingdom',
    lCity: 'dearne valley',
    lCountry: 'united kingdom',
  },
  { city: 'Kielce', country: 'Poland', lCity: 'kielce', lCountry: 'poland' },
  { city: 'Torun', country: 'Poland', lCity: 'torun', lCountry: 'poland' },
  { city: 'Modesto', country: 'United States', lCity: 'modesto', lCountry: 'united states' },
  { city: 'Saga', country: 'Japan', lCity: 'saga', lCountry: 'japan' },
  {
    city: 'Presidente Prudente',
    country: 'Brazil',
    lCity: 'presidente prudente',
    lCountry: 'brazil',
  },
  { city: 'Buri Ram', country: 'Thailand', lCity: 'buri ram', lCountry: 'thailand' },
  { city: 'Bauchi', country: 'Nigeria', lCity: 'bauchi', lCountry: 'nigeria' },
  { city: 'Mostoles', country: 'Spain', lCity: 'mostoles', lCountry: 'spain' },
  { city: 'Rennes', country: 'France', lCity: 'rennes', lCountry: 'france' },
  { city: 'Kimberley', country: 'South Africa', lCity: 'kimberley', lCountry: 'south africa' },
  { city: 'Oradea', country: 'Romania', lCity: 'oradea', lCountry: 'romania' },
  { city: 'Neyshabur', country: 'Iran', lCity: 'neyshabur', lCountry: 'iran' },
  { city: 'Ica', country: 'Peru', lCity: 'ica', lCountry: 'peru' },
  { city: 'Trieste', country: 'Italy', lCity: 'trieste', lCountry: 'italy' },
  { city: 'Gujiao', country: 'China', lCity: 'gujiao', lCountry: 'china' },
  { city: 'Raichur', country: 'India', lCity: 'raichur', lCountry: 'india' },
  { city: 'Bafoussam', country: 'Cameroon', lCity: 'bafoussam', lCountry: 'cameroon' },
  { city: 'Pyatigorsk', country: 'Russia', lCity: 'pyatigorsk', lCountry: 'russia' },
  { city: 'Tampere', country: 'Finland', lCity: 'tampere', lCountry: 'finland' },
  {
    city: 'Mirzapur Cum Vindhyachal',
    country: 'India',
    lCity: 'mirzapur cum vindhyachal',
    lCountry: 'india',
  },
  { city: 'Hagen', country: 'Germany', lCity: 'hagen', lCountry: 'germany' },
  { city: 'Bharatpur', country: 'India', lCity: 'bharatpur', lCountry: 'india' },
  { city: 'Itabuna', country: 'Brazil', lCity: 'itabuna', lCountry: 'brazil' },
  {
    city: 'Ajman',
    country: 'United Arab Emirates',
    lCity: 'ajman',
    lCountry: 'united arab emirates',
  },
  { city: 'Jinchang', country: 'China', lCity: 'jinchang', lCountry: 'china' },
  { city: 'Durham', country: 'United States', lCity: 'durham', lCountry: 'united states' },
  { city: 'Karshi', country: 'Uzbekistan', lCity: 'karshi', lCountry: 'uzbekistan' },
  { city: 'Najafabad', country: 'Iran', lCity: 'najafabad', lCountry: 'iran' },
  { city: 'Secunderabad', country: 'India', lCity: 'secunderabad', lCountry: 'india' },
  { city: 'Debrecen', country: 'Hungary', lCity: 'debrecen', lCountry: 'hungary' },
  { city: 'Talca', country: 'Chile', lCity: 'talca', lCountry: 'chile' },
  { city: 'Adiyaman', country: 'Turkey', lCity: 'adiyaman', lCountry: 'turkey' },
  { city: 'Americana', country: 'Brazil', lCity: 'americana', lCountry: 'brazil' },
  { city: 'Dodoma', country: 'Tanzania', lCity: 'dodoma', lCountry: 'tanzania' },
  { city: 'Ambarnath', country: 'India', lCity: 'ambarnath', lCountry: 'india' },
  { city: 'Reno', country: 'United States', lCity: 'reno', lCountry: 'united states' },
  { city: 'Greater Hobart', country: 'Australia', lCity: 'greater hobart', lCountry: 'australia' },
  { city: 'Toamasina', country: 'Madagascar', lCity: 'toamasina', lCountry: 'madagascar' },
  { city: 'Turmero', country: 'Venezuela', lCity: 'turmero', lCountry: 'venezuela' },
  { city: 'Arrah', country: 'India', lCity: 'arrah', lCountry: 'india' },
  { city: 'Rostock', country: 'Germany', lCity: 'rostock', lCountry: 'germany' },
  { city: 'San Lorenzo', country: 'Paraguay', lCity: 'san lorenzo', lCountry: 'paraguay' },
  { city: 'Itapevi', country: 'Brazil', lCity: 'itapevi', lCountry: 'brazil' },
  { city: 'Linxia', country: 'China', lCity: 'linxia', lCountry: 'china' },
  {
    city: 'Freiburg Im Breisgau',
    country: 'Germany',
    lCity: 'freiburg im breisgau',
    lCountry: 'germany',
  },
  { city: 'Isesaki', country: 'Japan', lCity: 'isesaki', lCountry: 'japan' },
  { city: 'Kuantan', country: 'Malaysia', lCity: 'kuantan', lCountry: 'malaysia' },
  { city: 'Arapiraca', country: 'Brazil', lCity: 'arapiraca', lCountry: 'brazil' },
  { city: 'Ashdod', country: 'Israel', lCity: 'ashdod', lCountry: 'israel' },
  { city: 'Tamale', country: 'Ghana', lCity: 'tamale', lCountry: 'ghana' },
  { city: 'Uluberia', country: 'India', lCity: 'uluberia', lCountry: 'india' },
  { city: 'Abha', country: 'Saudi Arabia', lCity: 'abha', lCountry: 'saudi arabia' },
  { city: 'Okara', country: 'Pakistan', lCity: 'okara', lCountry: 'pakistan' },
  { city: 'Hortolandia', country: 'Brazil', lCity: 'hortolandia', lCountry: 'brazil' },
  { city: 'Tottori', country: 'Japan', lCity: 'tottori', lCountry: 'japan' },
  { city: 'Menia', country: 'Egypt', lCity: 'menia', lCountry: 'egypt' },
  { city: 'Alcala De Henares', country: 'Spain', lCity: 'alcala de henares', lCountry: 'spain' },
  { city: 'Charleroi', country: 'Belgium', lCity: 'charleroi', lCountry: 'belgium' },
  { city: 'Erfurt', country: 'Germany', lCity: 'erfurt', lCountry: 'germany' },
  { city: 'Bommanahalli', country: 'India', lCity: 'bommanahalli', lCountry: 'india' },
  { city: 'Rayong', country: 'Thailand', lCity: 'rayong', lCountry: 'thailand' },
  { city: 'Sao Jose', country: 'Brazil', lCity: 'sao jose', lCountry: 'brazil' },
  { city: 'Kishiwada', country: 'Japan', lCity: 'kishiwada', lCountry: 'japan' },
  { city: 'Al Kharj', country: 'Saudi Arabia', lCity: 'al kharj', lCountry: 'saudi arabia' },
  { city: 'Mejicanos', country: 'El Salvador', lCity: 'mejicanos', lCountry: 'el salvador' },
  { city: 'Bila Tserkva', country: 'Ukraine', lCity: 'bila tserkva', lCountry: 'ukraine' },
  { city: 'Maraba', country: 'Brazil', lCity: 'maraba', lCountry: 'brazil' },
  { city: 'Sabadell', country: 'Spain', lCity: 'sabadell', lCountry: 'spain' },
  { city: 'Tsukuba', country: 'Japan', lCity: 'tsukuba', lCountry: 'japan' },
  { city: 'Fremont', country: 'United States', lCity: 'fremont', lCountry: 'united states' },
  { city: 'Probolinggo', country: 'Indonesia', lCity: 'probolinggo', lCountry: 'indonesia' },
  { city: 'Balakovo', country: 'Russia', lCity: 'balakovo', lCountry: 'russia' },
  { city: 'Atushi', country: 'China', lCity: 'atushi', lCountry: 'china' },
  { city: 'Taiping', country: 'Malaysia', lCity: 'taiping', lCountry: 'malaysia' },
  { city: 'Montgomery', country: 'United States', lCity: 'montgomery', lCountry: 'united states' },
  { city: 'Al Khums', country: 'Libya', lCity: 'al khums', lCountry: 'libya' },
  { city: 'Ziguinchor', country: 'Senegal', lCity: 'ziguinchor', lCountry: 'senegal' },
  { city: 'Groningen', country: 'Netherlands', lCity: 'groningen', lCountry: 'netherlands' },
  { city: 'Lutsk', country: 'Ukraine', lCity: 'lutsk', lCountry: 'ukraine' },
  { city: 'Terrassa', country: 'Spain', lCity: 'terrassa', lCountry: 'spain' },
  { city: 'Araraquara', country: 'Brazil', lCity: 'araraquara', lCountry: 'brazil' },
  {
    city: 'Jerez De La Frontera',
    country: 'Spain',
    lCity: 'jerez de la frontera',
    lCountry: 'spain',
  },
  { city: 'Hitachi', country: 'Japan', lCity: 'hitachi', lCountry: 'japan' },
  { city: 'Luzern', country: 'Switzerland', lCity: 'luzern', lCountry: 'switzerland' },
  { city: 'Gliwice', country: 'Poland', lCity: 'gliwice', lCountry: 'poland' },
  { city: 'Nakhon Sawan', country: 'Thailand', lCity: 'nakhon sawan', lCountry: 'thailand' },
  { city: 'Wah Cantonment', country: 'Pakistan', lCity: 'wah cantonment', lCountry: 'pakistan' },
  { city: 'Shreveport', country: 'United States', lCity: 'shreveport', lCountry: 'united states' },
  { city: 'Odawara', country: 'Japan', lCity: 'odawara', lCountry: 'japan' },
  {
    city: 'San Bernardino',
    country: 'United States',
    lCity: 'san bernardino',
    lCountry: 'united states',
  },
  { city: 'Regina', country: 'Canada', lCity: 'regina', lCountry: 'canada' },
  {
    city: 'Cachoeiro De Itapemirim',
    country: 'Brazil',
    lCity: 'cachoeiro de itapemirim',
    lCountry: 'brazil',
  },
  { city: 'Serampore', country: 'India', lCity: 'serampore', lCountry: 'india' },
  { city: 'Samut Sakhon', country: 'Thailand', lCity: 'samut sakhon', lCountry: 'thailand' },
  { city: 'Bissau', country: 'Guinea Bissau', lCity: 'bissau', lCountry: 'guinea bissau' },
  { city: 'Kokand', country: 'Uzbekistan', lCity: 'kokand', lCountry: 'uzbekistan' },
  { city: 'Porbandar', country: 'India', lCity: 'porbandar', lCountry: 'india' },
  { city: 'Sirjan', country: 'Iran', lCity: 'sirjan', lCountry: 'iran' },
  { city: 'Palakkad', country: 'India', lCity: 'palakkad', lCountry: 'india' },
  {
    city: 'Northampton',
    country: 'United Kingdom',
    lCity: 'northampton',
    lCountry: 'united kingdom',
  },
  { city: 'Salmiya', country: 'Kuwait', lCity: 'salmiya', lCountry: 'kuwait' },
  { city: 'Camacari', country: 'Brazil', lCity: 'camacari', lCountry: 'brazil' },
  { city: 'Rio Grande', country: 'Brazil', lCity: 'rio grande', lCountry: 'brazil' },
  { city: 'Taranto', country: 'Italy', lCity: 'taranto', lCountry: 'italy' },
  { city: 'Spokane', country: 'United States', lCity: 'spokane', lCountry: 'united states' },
  { city: 'Khammam', country: 'India', lCity: 'khammam', lCountry: 'india' },
  { city: 'Purnia', country: 'India', lCity: 'purnia', lCountry: 'india' },
  { city: 'Nadiad', country: 'India', lCity: 'nadiad', lCountry: 'india' },
  { city: 'Dindigul', country: 'India', lCity: 'dindigul', lCountry: 'india' },
  { city: 'Matsue', country: 'Japan', lCity: 'matsue', lCountry: 'japan' },
  { city: 'Babol', country: 'Iran', lCity: 'babol', lCountry: 'iran' },
  { city: 'Diwaniya', country: 'Iraq', lCity: 'diwaniya', lCountry: 'iraq' },
  { city: 'Yonkers', country: 'United States', lCity: 'yonkers', lCountry: 'united states' },
  { city: 'Maracanau', country: 'Brazil', lCity: 'maracanau', lCountry: 'brazil' },
  { city: 'Kassel', country: 'Germany', lCity: 'kassel', lCountry: 'germany' },
  { city: 'Severodvinsk', country: 'Russia', lCity: 'severodvinsk', lCountry: 'russia' },
  { city: 'Lucena City', country: 'Philippines', lCity: 'lucena city', lCountry: 'philippines' },
  {
    city: 'Dehiwala Mount Lavinia',
    country: 'Sri Lanka',
    lCity: 'dehiwala mount lavinia',
    lCountry: 'sri lanka',
  },
  {
    city: 'Banja Luka',
    country: 'Bosnia And Herzegovina',
    lCity: 'banja luka',
    lCountry: 'bosnia and herzegovina',
  },
  { city: 'Pskov', country: 'Russia', lCity: 'pskov', lCountry: 'russia' },
  { city: 'Tacoma', country: 'United States', lCity: 'tacoma', lCountry: 'united states' },
  { city: 'Gandhinagar', country: 'India', lCity: 'gandhinagar', lCountry: 'india' },
  { city: 'Pamplona', country: 'Spain', lCity: 'pamplona', lCountry: 'spain' },
  { city: 'Amol', country: 'Iran', lCity: 'amol', lCountry: 'iran' },
  { city: 'Vizianagarm', country: 'India', lCity: 'vizianagarm', lCountry: 'india' },
  { city: 'Waitakere', country: 'New Zealand', lCity: 'waitakere', lCountry: 'new zealand' },
  {
    city: 'Petropavlovsk Kamchatsky',
    country: 'Russia',
    lCity: 'petropavlovsk kamchatsky',
    lCountry: 'russia',
  },
  { city: 'Portoviejo', country: 'Ecuador', lCity: 'portoviejo', lCountry: 'ecuador' },
  { city: 'Norwich', country: 'United Kingdom', lCity: 'norwich', lCountry: 'united kingdom' },
  {
    city: 'Huntington Beach',
    country: 'United States',
    lCity: 'huntington beach',
    lCountry: 'united states',
  },
  { city: 'Burhanpur', country: 'India', lCity: 'burhanpur', lCountry: 'india' },
  { city: 'Olongapo', country: 'Philippines', lCity: 'olongapo', lCountry: 'philippines' },
  { city: 'Kofu', country: 'Japan', lCity: 'kofu', lCountry: 'japan' },
  { city: 'Des Moines', country: 'United States', lCity: 'des moines', lCountry: 'united states' },
  { city: 'Kocaeli', country: 'Turkey', lCity: 'kocaeli', lCountry: 'turkey' },
  {
    city: 'Grand Rapids',
    country: 'United States',
    lCity: 'grand rapids',
    lCountry: 'united states',
  },
  { city: 'Richmond', country: 'United States', lCity: 'richmond', lCountry: 'united states' },
  {
    city: 'Winston Salem',
    country: 'United States',
    lCity: 'winston salem',
    lCountry: 'united states',
  },
  { city: 'Fuenlabrada', country: 'Spain', lCity: 'fuenlabrada', lCountry: 'spain' },
  { city: 'Irving', country: 'United States', lCity: 'irving', lCountry: 'united states' },
  { city: 'Seremban', country: 'Malaysia', lCity: 'seremban', lCountry: 'malaysia' },
  { city: 'Manta', country: 'Ecuador', lCity: 'manta', lCountry: 'ecuador' },
  { city: 'Boise City', country: 'United States', lCity: 'boise city', lCountry: 'united states' },
  { city: 'Suzuka', country: 'Japan', lCity: 'suzuka', lCountry: 'japan' },
  { city: 'Jayapura', country: 'Indonesia', lCity: 'jayapura', lCountry: 'indonesia' },
  { city: 'Barisal', country: 'Bangladesh', lCity: 'barisal', lCountry: 'bangladesh' },
  { city: 'Heihe', country: 'China', lCity: 'heihe', lCountry: 'china' },
  { city: 'Apopa', country: 'El Salvador', lCity: 'apopa', lCountry: 'el salvador' },
  { city: 'Ramadi', country: 'Iraq', lCity: 'ramadi', lCountry: 'iraq' },
  { city: 'Zlatoust', country: 'Russia', lCity: 'zlatoust', lCountry: 'russia' },
  { city: 'Itami', country: 'Japan', lCity: 'itami', lCountry: 'japan' },
  { city: 'Yangsan', country: 'South Korea', lCity: 'yangsan', lCountry: 'south korea' },
  { city: 'Ath Thuqbah', country: 'Saudi Arabia', lCity: 'ath thuqbah', lCountry: 'saudi arabia' },
  { city: 'Yamaguchi', country: 'Japan', lCity: 'yamaguchi', lCountry: 'japan' },
  { city: 'Mobile', country: 'United States', lCity: 'mobile', lCountry: 'united states' },
  { city: 'Al Gadarif', country: 'Sudan', lCity: 'al gadarif', lCountry: 'sudan' },
  { city: 'Kumagaya', country: 'Japan', lCity: 'kumagaya', lCountry: 'japan' },
  { city: 'Katihar', country: 'India', lCity: 'katihar', lCountry: 'india' },
  { city: 'Le Havre', country: 'France', lCity: 'le havre', lCountry: 'france' },
  {
    city: 'Augusta Richmond',
    country: 'United States',
    lCity: 'augusta richmond',
    lCountry: 'united states',
  },
  { city: 'Zabrze', country: 'Poland', lCity: 'zabrze', lCountry: 'poland' },
  { city: 'Brescia', country: 'Italy', lCity: 'brescia', lCountry: 'italy' },
  { city: 'Barrancabermeja', country: 'Colombia', lCity: 'barrancabermeja', lCountry: 'colombia' },
  { city: 'Dera Ghazi Khan', country: 'Pakistan', lCity: 'dera ghazi khan', lCountry: 'pakistan' },
  { city: 'Rio Claro', country: 'Brazil', lCity: 'rio claro', lCountry: 'brazil' },
  { city: 'Wusu', country: 'China', lCity: 'wusu', lCountry: 'china' },
  { city: 'Petropavlovsk', country: 'Kazakhstan', lCity: 'petropavlovsk', lCountry: 'kazakhstan' },
  { city: 'Reykjavik', country: 'Iceland', lCity: 'reykjavik', lCountry: 'iceland' },
  { city: 'Tulua', country: 'Colombia', lCity: 'tulua', lCountry: 'colombia' },
  { city: 'Nishitokyo', country: 'Japan', lCity: 'nishitokyo', lCountry: 'japan' },
  { city: 'Mirpur Khas', country: 'Pakistan', lCity: 'mirpur khas', lCountry: 'pakistan' },
  { city: 'Haarlem', country: 'Netherlands', lCity: 'haarlem', lCountry: 'netherlands' },
  { city: 'Uji', country: 'Japan', lCity: 'uji', lCountry: 'japan' },
  { city: 'Nazareth', country: 'Ethiopia', lCity: 'nazareth', lCountry: 'ethiopia' },
  { city: 'Agartala', country: 'India', lCity: 'agartala', lCountry: 'india' },
  { city: 'Nawabshah', country: 'Pakistan', lCity: 'nawabshah', lCountry: 'pakistan' },
  { city: 'Osmaniye', country: 'Turkey', lCity: 'osmaniye', lCountry: 'turkey' },
  { city: 'Minna', country: 'Nigeria', lCity: 'minna', lCountry: 'nigeria' },
  { city: 'Bourgas', country: 'Bulgaria', lCity: 'bourgas', lCountry: 'bulgaria' },
  { city: 'Yumen', country: 'China', lCity: 'yumen', lCountry: 'china' },
  { city: 'Split', country: 'Croatia', lCity: 'split', lCountry: 'croatia' },
  { city: 'Vantaa', country: 'Finland', lCity: 'vantaa', lCountry: 'finland' },
  {
    city: 'Yanbu Al Bahr',
    country: 'Saudi Arabia',
    lCity: 'yanbu al bahr',
    lCountry: 'saudi arabia',
  },
  {
    city: 'Santa Barbara D Oeste',
    country: 'Brazil',
    lCity: 'santa barbara d oeste',
    lCountry: 'brazil',
  },
  { city: 'Linz', country: 'Austria', lCity: 'linz', lCountry: 'austria' },
  { city: 'Kancheepuram', country: 'India', lCity: 'kancheepuram', lCountry: 'india' },
  { city: 'Passo Fundo', country: 'Brazil', lCity: 'passo fundo', lCountry: 'brazil' },
  { city: 'Criciuma', country: 'Brazil', lCity: 'criciuma', lCountry: 'brazil' },
  { city: 'Dunhuang', country: 'China', lCity: 'dunhuang', lCountry: 'china' },
  { city: 'Carolina', country: 'Puerto Rico', lCity: 'carolina', lCountry: 'puerto rico' },
  { city: 'Pali', country: 'India', lCity: 'pali', lCountry: 'india' },
  { city: 'Bhusawal', country: 'India', lCity: 'bhusawal', lCountry: 'india' },
  { city: 'Tanga', country: 'Tanzania', lCity: 'tanga', lCountry: 'tanzania' },
  { city: 'Krishnarajapura', country: 'India', lCity: 'krishnarajapura', lCountry: 'india' },
  { city: 'Munger', country: 'India', lCity: 'munger', lCountry: 'india' },
  { city: 'Luziania', country: 'Brazil', lCity: 'luziania', lCountry: 'brazil' },
  { city: 'Bytom', country: 'Poland', lCity: 'bytom', lCountry: 'poland' },
  { city: 'Reims', country: 'France', lCity: 'reims', lCountry: 'france' },
  { city: 'Varamin', country: 'Iran', lCity: 'varamin', lCountry: 'iran' },
  { city: 'Liege', country: 'Belgium', lCity: 'liege', lCountry: 'belgium' },
  { city: 'Birjand', country: 'Iran', lCity: 'birjand', lCountry: 'iran' },
  { city: 'Ife', country: 'Nigeria', lCity: 'ife', lCountry: 'nigeria' },
  { city: 'Irvine', country: 'United States', lCity: 'irvine', lCountry: 'united states' },
  { city: 'Murwara', country: 'India', lCity: 'murwara', lCountry: 'india' },
  { city: 'Klaipeda', country: 'Lithuania', lCity: 'klaipeda', lCountry: 'lithuania' },
  { city: 'Odense', country: 'Denmark', lCity: 'odense', lCountry: 'denmark' },
  { city: 'Dourados', country: 'Brazil', lCity: 'dourados', lCountry: 'brazil' },
  { city: 'Cork', country: 'Ireland', lCity: 'cork', lCountry: 'ireland' },
  { city: 'Hetian', country: 'China', lCity: 'hetian', lCountry: 'china' },
  { city: 'Singrauli', country: 'India', lCity: 'singrauli', lCountry: 'india' },
  { city: 'Mesquita', country: 'Brazil', lCity: 'mesquita', lCountry: 'brazil' },
  { city: 'Sikar', country: 'India', lCity: 'sikar', lCountry: 'india' },
  { city: 'Almeria', country: 'Spain', lCity: 'almeria', lCountry: 'spain' },
  { city: 'Beer Sheva', country: 'Israel', lCity: 'beer sheva', lCountry: 'israel' },
  { city: 'Nong Khai', country: 'Thailand', lCity: 'nong khai', lCountry: 'thailand' },
  { city: 'Linjiang', country: 'China', lCity: 'linjiang', lCountry: 'china' },
  { city: 'Prato', country: 'Italy', lCity: 'prato', lCountry: 'italy' },
  { city: 'Ikorodu', country: 'Nigeria', lCity: 'ikorodu', lCountry: 'nigeria' },
  { city: 'Nala Sopara', country: 'India', lCity: 'nala sopara', lCountry: 'india' },
  {
    city: 'Little Rock',
    country: 'United States',
    lCity: 'little rock',
    lCountry: 'united states',
  },
  {
    city: 'Milton Keynes',
    country: 'United Kingdom',
    lCity: 'milton keynes',
    lCountry: 'united kingdom',
  },
  { city: 'Lille', country: 'France', lCity: 'lille', lCountry: 'france' },
  { city: 'Higashihiroshima', country: 'Japan', lCity: 'higashihiroshima', lCountry: 'japan' },
  { city: 'Silchar', country: 'India', lCity: 'silchar', lCountry: 'india' },
  { city: 'Reggio Di Calabria', country: 'Italy', lCity: 'reggio di calabria', lCountry: 'italy' },
  { city: 'Uppsala', country: 'Sweden', lCity: 'uppsala', lCountry: 'sweden' },
  { city: 'Sidi Bel Abbes', country: 'Algeria', lCity: 'sidi bel abbes', lCountry: 'algeria' },
  { city: 'Balashikha', country: 'Russia', lCity: 'balashikha', lCountry: 'russia' },
  { city: 'Saarbrucken', country: 'Germany', lCity: 'saarbrucken', lCountry: 'germany' },
  { city: 'Kutaisi', country: 'Georgia', lCity: 'kutaisi', lCountry: 'georgia' },
  { city: 'Kodaira', country: 'Japan', lCity: 'kodaira', lCountry: 'japan' },
  { city: 'Duran', country: 'Ecuador', lCity: 'duran', lCountry: 'ecuador' },
  { city: 'Kyzylorda', country: 'Kazakhstan', lCity: 'kyzylorda', lCountry: 'kazakhstan' },
  { city: 'Oxnard', country: 'United States', lCity: 'oxnard', lCountry: 'united states' },
  { city: 'Kamensk Uralsky', country: 'Russia', lCity: 'kamensk uralsky', lCountry: 'russia' },
  { city: 'Neuquen', country: 'Argentina', lCity: 'neuquen', lCountry: 'argentina' },
  { city: 'Machilipatnam', country: 'India', lCity: 'machilipatnam', lCountry: 'india' },
  {
    city: 'Donostia San Sebastian',
    country: 'Spain',
    lCity: 'donostia san sebastian',
    lCountry: 'spain',
  },
  { city: 'Rewa', country: 'India', lCity: 'rewa', lCountry: 'india' },
  { city: 'Kut', country: 'Iraq', lCity: 'kut', lCountry: 'iraq' },
  { city: 'Mainz', country: 'Germany', lCity: 'mainz', lCountry: 'germany' },
  { city: 'Fergana', country: 'Uzbekistan', lCity: 'fergana', lCountry: 'uzbekistan' },
  { city: 'Amarillo', country: 'United States', lCity: 'amarillo', lCountry: 'united states' },
  {
    city: 'Sunderland',
    country: 'United Kingdom',
    lCity: 'sunderland',
    lCountry: 'united kingdom',
  },
  { city: 'Sambhal', country: 'India', lCity: 'sambhal', lCountry: 'india' },
  { city: 'Santander', country: 'Spain', lCity: 'santander', lCountry: 'spain' },
  { city: 'Leganes', country: 'Spain', lCity: 'leganes', lCountry: 'spain' },
  { city: 'Bojnurd', country: 'Iran', lCity: 'bojnurd', lCountry: 'iran' },
  { city: 'Andong', country: 'South Korea', lCity: 'andong', lCountry: 'south korea' },
  { city: 'Pireas', country: 'Greece', lCity: 'pireas', lCountry: 'greece' },
  { city: 'Hamm', country: 'Germany', lCity: 'hamm', lCountry: 'germany' },
  { city: 'Petah Tiqwa', country: 'Israel', lCity: 'petah tiqwa', lCountry: 'israel' },
  { city: 'Narathiwat', country: 'Thailand', lCity: 'narathiwat', lCountry: 'thailand' },
  { city: 'Aracatuba', country: 'Brazil', lCity: 'aracatuba', lCountry: 'brazil' },
  { city: 'Kushiro', country: 'Japan', lCity: 'kushiro', lCountry: 'japan' },
  { city: 'Syzran', country: 'Russia', lCity: 'syzran', lCountry: 'russia' },
  { city: 'St Johns', country: 'Canada', lCity: 'st johns', lCountry: 'canada' },
  { city: 'Dos Quebradas', country: 'Colombia', lCity: 'dos quebradas', lCountry: 'colombia' },
  { city: 'Ponce', country: 'Puerto Rico', lCity: 'ponce', lCountry: 'puerto rico' },
  { city: 'Indaiatuba', country: 'Brazil', lCity: 'indaiatuba', lCountry: 'brazil' },
  { city: 'Manzhouli', country: 'China', lCity: 'manzhouli', lCountry: 'china' },
  { city: 'Khomeini Shahr', country: 'Iran', lCity: 'khomeini shahr', lCountry: 'iran' },
  { city: 'Miquan', country: 'China', lCity: 'miquan', lCountry: 'china' },
  { city: 'Byatarayanapura', country: 'India', lCity: 'byatarayanapura', lCountry: 'india' },
  { city: 'Yuzhno Sakhalinsk', country: 'Russia', lCity: 'yuzhno sakhalinsk', lCountry: 'russia' },
  { city: 'Asan', country: 'South Korea', lCity: 'asan', lCountry: 'south korea' },
  { city: 'Yachiyo', country: 'Japan', lCity: 'yachiyo', lCountry: 'japan' },
  { city: 'Khimki', country: 'Russia', lCity: 'khimki', lCountry: 'russia' },
  { city: 'Saint Etienne', country: 'France', lCity: 'saint etienne', lCountry: 'france' },
  { city: 'Modena', country: 'Italy', lCity: 'modena', lCountry: 'italy' },
  { city: 'Antsirabe', country: 'Madagascar', lCity: 'antsirabe', lCountry: 'madagascar' },
  { city: 'Crawley', country: 'United Kingdom', lCity: 'crawley', lCountry: 'united kingdom' },
  { city: 'Knoxville', country: 'United States', lCity: 'knoxville', lCountry: 'united states' },
  {
    city: 'San Salvador De Jujuy',
    country: 'Argentina',
    lCity: 'san salvador de jujuy',
    lCountry: 'argentina',
  },
  {
    city: 'Newport News',
    country: 'United States',
    lCity: 'newport news',
    lCountry: 'united states',
  },
  { city: 'Icheon', country: 'South Korea', lCity: 'icheon', lCountry: 'south korea' },
  { city: 'Cotia', country: 'Brazil', lCity: 'cotia', lCountry: 'brazil' },
  { city: 'Tagum', country: 'Philippines', lCity: 'tagum', lCountry: 'philippines' },
  { city: 'Bacau', country: 'Romania', lCity: 'bacau', lCountry: 'romania' },
  { city: 'Podolsk', country: 'Russia', lCity: 'podolsk', lCountry: 'russia' },
  {
    city: 'Nossa Senhora Do Socorro',
    country: 'Brazil',
    lCity: 'nossa senhora do socorro',
    lCountry: 'brazil',
  },
  { city: 'Ube', country: 'Japan', lCity: 'ube', lCountry: 'japan' },
  { city: 'Chapra', country: 'India', lCity: 'chapra', lCountry: 'india' },
  { city: 'Novocherkassk', country: 'Russia', lCity: 'novocherkassk', lCountry: 'russia' },
  { city: 'Envigado', country: 'Colombia', lCity: 'envigado', lCountry: 'colombia' },
  { city: 'Tacloban', country: 'Philippines', lCity: 'tacloban', lCountry: 'philippines' },
  { city: 'Aletai', country: 'China', lCity: 'aletai', lCountry: 'china' },
  { city: 'Almere', country: 'Netherlands', lCity: 'almere', lCountry: 'netherlands' },
  { city: 'Yingtan', country: 'China', lCity: 'yingtan', lCountry: 'china' },
  {
    city: 'Moreno Valley',
    country: 'United States',
    lCity: 'moreno valley',
    lCountry: 'united states',
  },
  { city: 'Iquique', country: 'Chile', lCity: 'iquique', lCountry: 'chile' },
  { city: 'Nova Friburgo', country: 'Brazil', lCity: 'nova friburgo', lCountry: 'brazil' },
  {
    city: 'Salt Lake City',
    country: 'United States',
    lCity: 'salt lake city',
    lCountry: 'united states',
  },
  { city: 'Hyesan', country: 'North Korea', lCity: 'hyesan', lCountry: 'north korea' },
  { city: 'Jackson', country: 'United States', lCity: 'jackson', lCountry: 'united states' },
  { city: 'Botshabelo', country: 'South Africa', lCity: 'botshabelo', lCountry: 'south africa' },
  { city: 'Izumi', country: 'Japan', lCity: 'izumi', lCountry: 'japan' },
  { city: 'Herat', country: 'Afghanistan', lCity: 'herat', lCountry: 'afghanistan' },
  { city: 'Biskra', country: 'Algeria', lCity: 'biskra', lCountry: 'algeria' },
  { city: 'Mitaka', country: 'Japan', lCity: 'mitaka', lCountry: 'japan' },
  { city: 'Pasuruan', country: 'Indonesia', lCity: 'pasuruan', lCountry: 'indonesia' },
  { city: 'Manama', country: 'Bahrain', lCity: 'manama', lCountry: 'bahrain' },
  { city: 'Lemesos', country: 'Cyprus', lCity: 'lemesos', lCountry: 'cyprus' },
  { city: 'Providence', country: 'United States', lCity: 'providence', lCountry: 'united states' },
  { city: 'Bielsko Biala', country: 'Poland', lCity: 'bielsko biala', lCountry: 'poland' },
  { city: 'Jessore', country: 'Bangladesh', lCity: 'jessore', lCountry: 'bangladesh' },
  {
    city: 'North Las Vegas',
    country: 'United States',
    lCity: 'north las vegas',
    lCountry: 'united states',
  },
  { city: 'Hino', country: 'Japan', lCity: 'hino', lCountry: 'japan' },
  {
    city: 'Ferraz De Vasconcelos',
    country: 'Brazil',
    lCity: 'ferraz de vasconcelos',
    lCountry: 'brazil',
  },
  { city: 'Bharuch', country: 'India', lCity: 'bharuch', lCountry: 'india' },
  { city: 'Mangaung', country: 'South Africa', lCity: 'mangaung', lCountry: 'south africa' },
  { city: 'Parma', country: 'Italy', lCity: 'parma', lCountry: 'italy' },
  { city: 'Puerto Cabello', country: 'Venezuela', lCity: 'puerto cabello', lCountry: 'venezuela' },
  { city: 'Los Teques', country: 'Venezuela', lCity: 'los teques', lCountry: 'venezuela' },
  { city: 'Bulandshahr', country: 'India', lCity: 'bulandshahr', lCountry: 'india' },
  { city: 'Riohacha', country: 'Colombia', lCity: 'riohacha', lCountry: 'colombia' },
  { city: 'Barra Mansa', country: 'Brazil', lCity: 'barra mansa', lCountry: 'brazil' },
  { city: 'Puerto Montt', country: 'Chile', lCity: 'puerto montt', lCountry: 'chile' },
  { city: 'Kutahya', country: 'Turkey', lCity: 'kutahya', lCountry: 'turkey' },
  { city: 'Worcester', country: 'United States', lCity: 'worcester', lCountry: 'united states' },
  { city: 'Sobral', country: 'Brazil', lCity: 'sobral', lCountry: 'brazil' },
  { city: 'Atirau', country: 'Kazakhstan', lCity: 'atirau', lCountry: 'kazakhstan' },
  { city: 'Herne', country: 'Germany', lCity: 'herne', lCountry: 'germany' },
  { city: 'Maikop', country: 'Russia', lCity: 'maikop', lCountry: 'russia' },
  { city: 'Takoradi', country: 'Ghana', lCity: 'takoradi', lCountry: 'ghana' },
  { city: 'Malolos', country: 'Philippines', lCity: 'malolos', lCountry: 'philippines' },
  {
    city: 'Nueva San Salvador',
    country: 'El Salvador',
    lCity: 'nueva san salvador',
    lCountry: 'el salvador',
  },
  { city: 'Turku', country: 'Finland', lCity: 'turku', lCountry: 'finland' },
  { city: 'Sullana', country: 'Peru', lCity: 'sullana', lCountry: 'peru' },
  { city: 'Raiganj', country: 'India', lCity: 'raiganj', lCountry: 'india' },
  { city: 'La Serena', country: 'Chile', lCity: 'la serena', lCountry: 'chile' },
  { city: 'Malkajgiri', country: 'India', lCity: 'malkajgiri', lCountry: 'india' },
  { city: 'Amadora', country: 'Portugal', lCity: 'amadora', lCountry: 'portugal' },
  { city: 'Kragujevac', country: 'Serbia', lCity: 'kragujevac', lCountry: 'serbia' },
  { city: 'Olsztyn', country: 'Poland', lCity: 'olsztyn', lCountry: 'poland' },
  { city: 'Vasai', country: 'India', lCity: 'vasai', lCountry: 'india' },
  { city: 'Phusro', country: 'India', lCity: 'phusro', lCountry: 'india' },
  { city: 'Chlef', country: 'Algeria', lCity: 'chlef', lCountry: 'algeria' },
  { city: 'Gilbert', country: 'United States', lCity: 'gilbert', lCountry: 'united states' },
  { city: 'Imabari', country: 'Japan', lCity: 'imabari', lCountry: 'japan' },
  { city: 'Corum', country: 'Turkey', lCity: 'corum', lCountry: 'turkey' },
  {
    city: 'Mulheim An Der Ruhr',
    country: 'Germany',
    lCity: 'mulheim an der ruhr',
    lCountry: 'germany',
  },
  { city: 'Mangora', country: 'Pakistan', lCity: 'mangora', lCountry: 'pakistan' },
  { city: 'Xilinhaote', country: 'China', lCity: 'xilinhaote', lCountry: 'china' },
  { city: 'Bidar', country: 'India', lCity: 'bidar', lCountry: 'india' },
  { city: 'Burgos', country: 'Spain', lCity: 'burgos', lCountry: 'spain' },
  { city: 'Ibirite', country: 'Brazil', lCity: 'ibirite', lCountry: 'brazil' },
  { city: 'Kosti', country: 'Sudan', lCity: 'kosti', lCountry: 'sudan' },
  { city: 'Miskolc', country: 'Hungary', lCity: 'miskolc', lCountry: 'hungary' },
  { city: 'Chapeco', country: 'Brazil', lCity: 'chapeco', lCountry: 'brazil' },
  { city: 'Arica', country: 'Chile', lCity: 'arica', lCountry: 'chile' },
  { city: 'Hirosaki', country: 'Japan', lCity: 'hirosaki', lCountry: 'japan' },
  { city: 'Bukand', country: 'Iran', lCity: 'bukand', lCountry: 'iran' },
  { city: 'Kottayam', country: 'India', lCity: 'kottayam', lCountry: 'india' },
  {
    city: 'Renfrewshire',
    country: 'United Kingdom',
    lCity: 'renfrewshire',
    lCountry: 'united kingdom',
  },
  { city: 'Tomakomai', country: 'Japan', lCity: 'tomakomai', lCountry: 'japan' },
  { city: 'Ontario', country: 'United States', lCity: 'ontario', lCountry: 'united states' },
  { city: 'Netanya', country: 'Israel', lCity: 'netanya', lCountry: 'israel' },
  { city: 'Tachikawa', country: 'Japan', lCity: 'tachikawa', lCountry: 'japan' },
  { city: 'Chiniot', country: 'Pakistan', lCity: 'chiniot', lCountry: 'pakistan' },
  {
    city: 'Cabo De Santo Agostinho',
    country: 'Brazil',
    lCity: 'cabo de santo agostinho',
    lCountry: 'brazil',
  },
  {
    city: 'Castellon De La Plana',
    country: 'Spain',
    lCity: 'castellon de la plana',
    lCountry: 'spain',
  },
  { city: 'Khandwa', country: 'India', lCity: 'khandwa', lCountry: 'india' },
  { city: 'Nakhodka', country: 'Russia', lCity: 'nakhodka', lCountry: 'russia' },
  {
    city: 'Pointe A Pitre',
    country: 'Guadeloupe',
    lCity: 'pointe a pitre',
    lCountry: 'guadeloupe',
  },
  { city: 'Beni Suef', country: 'Egypt', lCity: 'beni suef', lCountry: 'egypt' },
  { city: 'Namdinh', country: 'Viet Nam', lCity: 'namdinh', lCountry: 'viet nam' },
  { city: 'Bat Dambang', country: 'Cambodia', lCity: 'bat dambang', lCountry: 'cambodia' },
  { city: 'Alexandra', country: 'South Africa', lCity: 'alexandra', lCountry: 'south africa' },
  { city: 'Sakura', country: 'Japan', lCity: 'sakura', lCountry: 'japan' },
  { city: 'Kamakura', country: 'Japan', lCity: 'kamakura', lCountry: 'japan' },
  { city: 'Chimoio', country: 'Mozambique', lCity: 'chimoio', lCountry: 'mozambique' },
  { city: 'Iseyin', country: 'Nigeria', lCity: 'iseyin', lCountry: 'nigeria' },
  { city: 'Tarija', country: 'Bolivia', lCity: 'tarija', lCountry: 'bolivia' },
  { city: 'Iwata', country: 'Japan', lCity: 'iwata', lCountry: 'japan' },
  { city: 'Haldia', country: 'India', lCity: 'haldia', lCountry: 'india' },
  { city: 'Temirtau', country: 'Kazakhstan', lCity: 'temirtau', lCountry: 'kazakhstan' },
  { city: 'Francisco Morato', country: 'Brazil', lCity: 'francisco morato', lCountry: 'brazil' },
  { city: 'Obihiro', country: 'Japan', lCity: 'obihiro', lCountry: 'japan' },
  { city: 'Volgodonsk', country: 'Russia', lCity: 'volgodonsk', lCountry: 'russia' },
  { city: 'Mutare', country: 'Zimbabwe', lCity: 'mutare', lCountry: 'zimbabwe' },
  { city: 'Luque', country: 'Paraguay', lCity: 'luque', lCountry: 'paraguay' },
  { city: 'Sohag', country: 'Egypt', lCity: 'sohag', lCountry: 'egypt' },
  { city: 'Madiun', country: 'Indonesia', lCity: 'madiun', lCountry: 'indonesia' },
  { city: 'Kabwe', country: 'Zambia', lCity: 'kabwe', lCountry: 'zambia' },
  { city: 'Malappuram', country: 'India', lCity: 'malappuram', lCountry: 'india' },
  { city: 'Baharampur', country: 'India', lCity: 'baharampur', lCountry: 'india' },
  { city: 'Anjo', country: 'Japan', lCity: 'anjo', lCountry: 'japan' },
  { city: 'Pitesti', country: 'Romania', lCity: 'pitesti', lCountry: 'romania' },
  { city: 'Hugli Chinsurah', country: 'India', lCity: 'hugli chinsurah', lCountry: 'india' },
  { city: 'Parnamirim', country: 'Brazil', lCity: 'parnamirim', lCountry: 'brazil' },
  { city: 'Moratuwa', country: 'Sri Lanka', lCity: 'moratuwa', lCountry: 'sri lanka' },
  { city: 'Rondonopolis', country: 'Brazil', lCity: 'rondonopolis', lCountry: 'brazil' },
  { city: 'Breda', country: 'Netherlands', lCity: 'breda', lCountry: 'netherlands' },
  { city: 'Bhiwani', country: 'India', lCity: 'bhiwani', lCountry: 'india' },
  {
    city: 'Rancho Cucamonga',
    country: 'United States',
    lCity: 'rancho cucamonga',
    lCountry: 'united states',
  },
  { city: 'Rae Bareli', country: 'India', lCity: 'rae bareli', lCountry: 'india' },
  { city: 'Guarenas', country: 'Venezuela', lCity: 'guarenas', lCountry: 'venezuela' },
  { city: 'Ambato', country: 'Ecuador', lCity: 'ambato', lCountry: 'ecuador' },
  { city: 'Malayer', country: 'Iran', lCity: 'malayer', lCountry: 'iran' },
  { city: 'Guarapuava', country: 'Brazil', lCity: 'guarapuava', lCountry: 'brazil' },
  { city: 'El Aaiun', country: 'Western Sahara', lCity: 'el aaiun', lCountry: 'western sahara' },
  { city: 'Matsusaka', country: 'Japan', lCity: 'matsusaka', lCountry: 'japan' },
  {
    city: 'Aguas Lindas De Goias',
    country: 'Brazil',
    lCity: 'aguas lindas de goias',
    lCountry: 'brazil',
  },
  { city: 'Patrai', country: 'Greece', lCity: 'patrai', lCountry: 'greece' },
  { city: 'Lages', country: 'Brazil', lCity: 'lages', lCountry: 'brazil' },
  { city: 'Bahraich', country: 'India', lCity: 'bahraich', lCountry: 'india' },
  { city: 'Qaem Shahr', country: 'Iran', lCity: 'qaem shahr', lCountry: 'iran' },
  { city: 'Hadano', country: 'Japan', lCity: 'hadano', lCountry: 'japan' },
  { city: 'Pathankot', country: 'India', lCity: 'pathankot', lCountry: 'india' },
  {
    city: 'Santa Clarita',
    country: 'United States',
    lCity: 'santa clarita',
    lCountry: 'united states',
  },
  { city: 'Itajai', country: 'Brazil', lCity: 'itajai', lCountry: 'brazil' },
  { city: 'Ussuriisk', country: 'Russia', lCity: 'ussuriisk', lCountry: 'russia' },
  { city: 'Geoje', country: 'South Korea', lCity: 'geoje', lCountry: 'south korea' },
  { city: 'Ambala', country: 'India', lCity: 'ambala', lCountry: 'india' },
  { city: 'Arad', country: 'Romania', lCity: 'arad', lCountry: 'romania' },
  { city: 'Suva', country: 'Fiji', lCity: 'suva', lCountry: 'fiji' },
  { city: 'Coquimbo', country: 'Chile', lCity: 'coquimbo', lCountry: 'chile' },
  { city: 'Baranovichi', country: 'Belarus', lCity: 'baranovichi', lCountry: 'belarus' },
  { city: 'Bidhan Nagar', country: 'India', lCity: 'bidhan nagar', lCountry: 'india' },
  { city: 'Miass', country: 'Russia', lCity: 'miass', lCountry: 'russia' },
  { city: 'Takaoka', country: 'Japan', lCity: 'takaoka', lCountry: 'japan' },
  { city: 'Berezniki', country: 'Russia', lCity: 'berezniki', lCountry: 'russia' },
  {
    city: 'Brownsville',
    country: 'United States',
    lCity: 'brownsville',
    lCountry: 'united states',
  },
  {
    city: 'Fort Lauderdale',
    country: 'United States',
    lCity: 'fort lauderdale',
    lCountry: 'united states',
  },
  { city: 'Ishinomaki', country: 'Japan', lCity: 'ishinomaki', lCountry: 'japan' },
  { city: 'Coro', country: 'Venezuela', lCity: 'coro', lCountry: 'venezuela' },
  { city: 'Khoy', country: 'Iran', lCity: 'khoy', lCountry: 'iran' },
  { city: 'Chirala', country: 'India', lCity: 'chirala', lCountry: 'india' },
  { city: 'Wigan', country: 'United Kingdom', lCity: 'wigan', lCountry: 'united kingdom' },
  { city: 'Biratnagar', country: 'Nepal', lCity: 'biratnagar', lCountry: 'nepal' },
  { city: 'Holon', country: 'Israel', lCity: 'holon', lCountry: 'israel' },
  { city: 'Comilla', country: 'Bangladesh', lCity: 'comilla', lCountry: 'bangladesh' },
  { city: 'Huntsville', country: 'United States', lCity: 'huntsville', lCountry: 'united states' },
  { city: 'Oceanside', country: 'United States', lCity: 'oceanside', lCountry: 'united states' },
  { city: 'Mango', country: 'India', lCity: 'mango', lCountry: 'india' },
  {
    city: 'Garden Grove',
    country: 'United States',
    lCity: 'garden grove',
    lCountry: 'united states',
  },
  { city: 'Palangkaraya', country: 'Indonesia', lCity: 'palangkaraya', lCountry: 'indonesia' },
  { city: 'Hebron', country: 'Palestine', lCity: 'hebron', lCountry: 'palestine' },
  { city: 'Aktau', country: 'Kazakhstan', lCity: 'aktau', lCountry: 'kazakhstan' },
  { city: 'Amersfoort', country: 'Netherlands', lCity: 'amersfoort', lCountry: 'netherlands' },
  { city: 'Geelong', country: 'Australia', lCity: 'geelong', lCountry: 'australia' },
  { city: 'Al Khubar', country: 'Saudi Arabia', lCity: 'al khubar', lCountry: 'saudi arabia' },
  { city: 'Cabo Frio', country: 'Brazil', lCity: 'cabo frio', lCountry: 'brazil' },
  { city: 'Solingen', country: 'Germany', lCity: 'solingen', lCountry: 'germany' },
  { city: 'Qarchak', country: 'Iran', lCity: 'qarchak', lCountry: 'iran' },
  { city: 'Fianarantsoa', country: 'Madagascar', lCity: 'fianarantsoa', lCountry: 'madagascar' },
  { city: 'Tsu', country: 'Japan', lCity: 'tsu', lCountry: 'japan' },
  { city: 'Chingola', country: 'Zambia', lCity: 'chingola', lCountry: 'zambia' },
  { city: 'Proddatur', country: 'India', lCity: 'proddatur', lCountry: 'india' },
  { city: 'Amroha', country: 'India', lCity: 'amroha', lCountry: 'india' },
  {
    city: 'Overland Park',
    country: 'United States',
    lCity: 'overland park',
    lCountry: 'united states',
  },
  { city: 'Sherbrooke', country: 'Canada', lCity: 'sherbrooke', lCountry: 'canada' },
  { city: 'Alcorcon', country: 'Spain', lCity: 'alcorcon', lCountry: 'spain' },
  { city: 'Tongjiang', country: 'China', lCity: 'tongjiang', lCountry: 'china' },
  { city: 'Osnabruck', country: 'Germany', lCity: 'osnabruck', lCountry: 'germany' },
  { city: 'Alor Setar', country: 'Malaysia', lCity: 'alor setar', lCountry: 'malaysia' },
  { city: 'Corlu', country: 'Turkey', lCity: 'corlu', lCountry: 'turkey' },
  { city: 'Germiston', country: 'South Africa', lCity: 'germiston', lCountry: 'south africa' },
  { city: 'Szeged', country: 'Hungary', lCity: 'szeged', lCountry: 'hungary' },
  {
    city: 'Ciudad Delgado',
    country: 'El Salvador',
    lCity: 'ciudad delgado',
    lCountry: 'el salvador',
  },
  { city: 'Chardzhou', country: 'Turkmenistan', lCity: 'chardzhou', lCountry: 'turkmenistan' },
  { city: 'Tumaco', country: 'Colombia', lCity: 'tumaco', lCountry: 'colombia' },
  { city: 'Fontana', country: 'United States', lCity: 'fontana', lCountry: 'united states' },
  { city: 'Cotabato', country: 'Philippines', lCity: 'cotabato', lCountry: 'philippines' },
  {
    city: 'Ludwigshafen Am Rhein',
    country: 'Germany',
    lCity: 'ludwigshafen am rhein',
    lCountry: 'germany',
  },
  { city: 'Sultan Pur Majra', country: 'India', lCity: 'sultan pur majra', lCountry: 'india' },
  { city: 'Gombe', country: 'Nigeria', lCity: 'gombe', lCountry: 'nigeria' },
  { city: 'Abakan', country: 'Russia', lCity: 'abakan', lCountry: 'russia' },
  { city: 'Alborg', country: 'Denmark', lCity: 'alborg', lCountry: 'denmark' },
  { city: 'Qui Nhon', country: 'Viet Nam', lCity: 'qui nhon', lCountry: 'viet nam' },
  { city: 'Springs', country: 'South Africa', lCity: 'springs', lCountry: 'south africa' },
  { city: 'Hospet', country: 'India', lCity: 'hospet', lCountry: 'india' },
  { city: 'Rzeszow', country: 'Poland', lCity: 'rzeszow', lCountry: 'poland' },
  { city: 'Gondar', country: 'Ethiopia', lCity: 'gondar', lCountry: 'ethiopia' },
  { city: 'Bitung', country: 'Indonesia', lCity: 'bitung', lCountry: 'indonesia' },
  { city: 'Lalitpur', country: 'Nepal', lCity: 'lalitpur', lCountry: 'nepal' },
  { city: 'Plzen', country: 'Czech Republic', lCity: 'plzen', lCountry: 'czech republic' },
  { city: 'Mytishchi', country: 'Russia', lCity: 'mytishchi', lCountry: 'russia' },
  {
    city: 'Itapecerica Da Serra',
    country: 'Brazil',
    lCity: 'itapecerica da serra',
    lCountry: 'brazil',
  },
  { city: 'Chandan Nagar', country: 'India', lCity: 'chandan nagar', lCountry: 'india' },
  { city: 'Sudbury', country: 'Canada', lCity: 'sudbury', lCountry: 'canada' },
  {
    city: 'Puerto Princesa',
    country: 'Philippines',
    lCity: 'puerto princesa',
    lCountry: 'philippines',
  },
  { city: 'Abbotsford', country: 'Canada', lCity: 'abbotsford', lCountry: 'canada' },
  { city: 'Perugia', country: 'Italy', lCity: 'perugia', lCountry: 'italy' },
  { city: 'Ayacucho', country: 'Peru', lCity: 'ayacucho', lCountry: 'peru' },
  { city: 'Albacete', country: 'Spain', lCity: 'albacete', lCountry: 'spain' },
  { city: 'Roi Et', country: 'Thailand', lCity: 'roi et', lCountry: 'thailand' },
  { city: 'Tempe', country: 'United States', lCity: 'tempe', lCountry: 'united states' },
  { city: 'Adoni', country: 'India', lCity: 'adoni', lCountry: 'india' },
  { city: 'Leverkusen', country: 'Germany', lCity: 'leverkusen', lCountry: 'germany' },
  { city: 'Kumbakonam', country: 'India', lCity: 'kumbakonam', lCountry: 'india' },
  { city: 'Macae', country: 'Brazil', lCity: 'macae', lCountry: 'brazil' },
  { city: 'Salatiga', country: 'Indonesia', lCity: 'salatiga', lCountry: 'indonesia' },
  { city: 'Toulon', country: 'France', lCity: 'toulon', lCountry: 'france' },
  { city: 'Livorno', country: 'Italy', lCity: 'livorno', lCountry: 'italy' },
  { city: 'Ciudad Del Carmen', country: 'Mexico', lCity: 'ciudad del carmen', lCountry: 'mexico' },
  { city: 'Bhadravati', country: 'India', lCity: 'bhadravati', lCountry: 'india' },
  { city: 'Kota Kinabalu', country: 'Malaysia', lCity: 'kota kinabalu', lCountry: 'malaysia' },
  { city: 'Trondheim', country: 'Norway', lCity: 'trondheim', lCountry: 'norway' },
  { city: 'Oyama', country: 'Japan', lCity: 'oyama', lCountry: 'japan' },
  { city: 'Sirsa', country: 'India', lCity: 'sirsa', lCountry: 'india' },
  { city: 'Jaunpur', country: 'India', lCity: 'jaunpur', lCountry: 'india' },
  {
    city: 'S Hertogenbosch',
    country: 'Netherlands',
    lCity: 's hertogenbosch',
    lCountry: 'netherlands',
  },
  { city: 'Cagliari', country: 'Italy', lCity: 'cagliari', lCountry: 'italy' },
  { city: 'Ashikaga', country: 'Japan', lCity: 'ashikaga', lCountry: 'japan' },
  { city: 'Salamanca', country: 'Spain', lCity: 'salamanca', lCountry: 'spain' },
  { city: 'Kanchanaburi', country: 'Thailand', lCity: 'kanchanaburi', lCountry: 'thailand' },
  { city: 'Jiayuguan', country: 'China', lCity: 'jiayuguan', lCountry: 'china' },
  { city: 'Nijmegen', country: 'Netherlands', lCity: 'nijmegen', lCountry: 'netherlands' },
  { city: 'Kapra', country: 'India', lCity: 'kapra', lCountry: 'india' },
  { city: 'Melitopol', country: 'Ukraine', lCity: 'melitopol', lCountry: 'ukraine' },
  {
    city: 'Haldwani Cum Kathgodam',
    country: 'India',
    lCity: 'haldwani cum kathgodam',
    lCountry: 'india',
  },
  { city: 'Effon Alaiye', country: 'Nigeria', lCity: 'effon alaiye', lCountry: 'nigeria' },
  { city: 'Dayton', country: 'United States', lCity: 'dayton', lCountry: 'united states' },
  { city: 'Lyubertsy', country: 'Russia', lCity: 'lyubertsy', lCountry: 'russia' },
  { city: 'Narashino', country: 'Japan', lCity: 'narashino', lCountry: 'japan' },
  {
    city: 'West Lothian',
    country: 'United Kingdom',
    lCity: 'west lothian',
    lCountry: 'united kingdom',
  },
  { city: 'Iskenderun', country: 'Turkey', lCity: 'iskenderun', lCountry: 'turkey' },
  { city: 'El Djelfa', country: 'Algeria', lCity: 'el djelfa', lCountry: 'algeria' },
  { city: 'Reggio Nell Emilia', country: 'Italy', lCity: 'reggio nell emilia', lCountry: 'italy' },
  { city: 'Cuddalore', country: 'India', lCity: 'cuddalore', lCountry: 'india' },
  {
    city: 'Tallahassee',
    country: 'United States',
    lCity: 'tallahassee',
    lCountry: 'united states',
  },
  { city: 'Castanhal', country: 'Brazil', lCity: 'castanhal', lCountry: 'brazil' },
  { city: 'Pervouralsk', country: 'Russia', lCity: 'pervouralsk', lCountry: 'russia' },
  { city: 'Nacala', country: 'Mozambique', lCity: 'nacala', lCountry: 'mozambique' },
  {
    city: 'Warrington',
    country: 'United Kingdom',
    lCity: 'warrington',
    lCountry: 'united kingdom',
  },
  { city: 'Pangkal Pinang', country: 'Indonesia', lCity: 'pangkal pinang', lCountry: 'indonesia' },
  { city: 'Mansfield', country: 'United Kingdom', lCity: 'mansfield', lCountry: 'united kingdom' },
  { city: 'Sayama', country: 'Japan', lCity: 'sayama', lCountry: 'japan' },
  { city: 'Rubtsovsk', country: 'Russia', lCity: 'rubtsovsk', lCountry: 'russia' },
  { city: 'Dinajpur', country: 'Bangladesh', lCity: 'dinajpur', lCountry: 'bangladesh' },
  { city: 'Isparta', country: 'Turkey', lCity: 'isparta', lCountry: 'turkey' },
  { city: 'Veraval', country: 'India', lCity: 'veraval', lCountry: 'india' },
  { city: 'Kawanishi', country: 'Japan', lCity: 'kawanishi', lCountry: 'japan' },
  { city: 'Tunja', country: 'Colombia', lCity: 'tunja', lCountry: 'colombia' },
  { city: 'Zanzibar', country: 'Tanzania', lCity: 'zanzibar', lCountry: 'tanzania' },
  { city: 'Puri', country: 'India', lCity: 'puri', lCountry: 'india' },
  { city: 'Rousse', country: 'Bulgaria', lCity: 'rousse', lCountry: 'bulgaria' },
  { city: 'Genhe', country: 'China', lCity: 'genhe', lCountry: 'china' },
  { city: 'Owo', country: 'Nigeria', lCity: 'owo', lCountry: 'nigeria' },
  { city: 'Legasp', country: 'Philippines', lCity: 'legasp', lCountry: 'philippines' },
  { city: 'Robertson Pet', country: 'India', lCity: 'robertson pet', lCountry: 'india' },
  { city: 'Talcahuano', country: 'Chile', lCity: 'talcahuano', lCountry: 'chile' },
  { city: 'Ngaoundere', country: 'Cameroon', lCity: 'ngaoundere', lCountry: 'cameroon' },
  { city: 'Shebin El Kom', country: 'Egypt', lCity: 'shebin el kom', lCountry: 'egypt' },
  { city: 'Sandakan', country: 'Malaysia', lCity: 'sandakan', lCountry: 'malaysia' },
  { city: 'Salavat', country: 'Russia', lCity: 'salavat', lCountry: 'russia' },
  { city: 'Salalah', country: 'Oman', lCity: 'salalah', lCountry: 'oman' },
  { city: 'Portmore', country: 'Jamaica', lCity: 'portmore', lCountry: 'jamaica' },
  {
    city: 'Surendranagar Dudhrej',
    country: 'India',
    lCity: 'surendranagar dudhrej',
    lCountry: 'india',
  },
  { city: 'Pecs', country: 'Hungary', lCity: 'pecs', lCountry: 'hungary' },
  { city: 'Getafe', country: 'Spain', lCity: 'getafe', lCountry: 'spain' },
  { city: 'Pokhara', country: 'Nepal', lCity: 'pokhara', lCountry: 'nepal' },
  { city: 'Baleshwar', country: 'India', lCity: 'baleshwar', lCountry: 'india' },
  { city: 'Tlemcen', country: 'Algeria', lCity: 'tlemcen', lCountry: 'algeria' },
  { city: 'Nandyal', country: 'India', lCity: 'nandyal', lCountry: 'india' },
  { city: 'Ado Ekiti', country: 'Nigeria', lCity: 'ado ekiti', lCountry: 'nigeria' },
  { city: 'Itu', country: 'Brazil', lCity: 'itu', lCountry: 'brazil' },
  { city: 'Apeldoorn', country: 'Netherlands', lCity: 'apeldoorn', lCountry: 'netherlands' },
  { city: 'Madhyamgram', country: 'India', lCity: 'madhyamgram', lCountry: 'india' },
  { city: 'Swindon', country: 'United Kingdom', lCity: 'swindon', lCountry: 'united kingdom' },
  { city: 'Kena', country: 'Egypt', lCity: 'kena', lCountry: 'egypt' },
  { city: 'Maraqeh', country: 'Iran', lCity: 'maraqeh', lCountry: 'iran' },
  { city: 'Urayasu', country: 'Japan', lCity: 'urayasu', lCountry: 'japan' },
  { city: 'Ruili', country: 'China', lCity: 'ruili', lCountry: 'china' },
  { city: 'Gadag Betgeri', country: 'India', lCity: 'gadag betgeri', lCountry: 'india' },
  { city: 'Bogra', country: 'Bangladesh', lCity: 'bogra', lCountry: 'bangladesh' },
  {
    city: 'Chattanooga',
    country: 'United States',
    lCity: 'chattanooga',
    lCountry: 'united states',
  },
  { city: 'Pocos De Caldas', country: 'Brazil', lCity: 'pocos de caldas', lCountry: 'brazil' },
  { city: 'Capiata', country: 'Paraguay', lCity: 'capiata', lCountry: 'paraguay' },
  { city: 'Sibiu', country: 'Romania', lCity: 'sibiu', lCountry: 'romania' },
  { city: 'Enschede', country: 'Netherlands', lCity: 'enschede', lCountry: 'netherlands' },
  { city: 'Tebessa', country: 'Algeria', lCity: 'tebessa', lCountry: 'algeria' },
  { city: 'Ormoc', country: 'Philippines', lCity: 'ormoc', lCountry: 'philippines' },
  { city: 'San Carlos', country: 'Philippines', lCity: 'san carlos', lCountry: 'philippines' },
  { city: 'Oldenburg', country: 'Germany', lCity: 'oldenburg', lCountry: 'germany' },
  {
    city: 'Kirari Suleman Nagar',
    country: 'India',
    lCity: 'kirari suleman nagar',
    lCountry: 'india',
  },
  { city: 'Kerch', country: 'Ukraine', lCity: 'kerch', lCountry: 'ukraine' },
  { city: 'Pomona', country: 'United States', lCity: 'pomona', lCountry: 'united states' },
  { city: 'Bhind', country: 'India', lCity: 'bhind', lCountry: 'india' },
  { city: 'Luxer', country: 'Egypt', lCity: 'luxer', lCountry: 'egypt' },
  { city: 'Hitachinaka', country: 'Japan', lCity: 'hitachinaka', lCountry: 'japan' },
  { city: 'Chhindwara', country: 'India', lCity: 'chhindwara', lCountry: 'india' },
  { city: 'Foggia', country: 'Italy', lCity: 'foggia', lCountry: 'italy' },
  { city: 'Skikda', country: 'Algeria', lCity: 'skikda', lCountry: 'algeria' },
  { city: 'Grenoble', country: 'France', lCity: 'grenoble', lCountry: 'france' },
  { city: 'Mutrah', country: 'Oman', lCity: 'mutrah', lCountry: 'oman' },
  { city: 'Medinipur', country: 'India', lCity: 'medinipur', lCountry: 'india' },
  { city: 'Niiza', country: 'Japan', lCity: 'niiza', lCountry: 'japan' },
  { city: 'Santa Rosa', country: 'United States', lCity: 'santa rosa', lCountry: 'united states' },
  { city: 'Karur', country: 'India', lCity: 'karur', lCountry: 'india' },
  { city: 'Leskovac', country: 'Serbia', lCity: 'leskovac', lCountry: 'serbia' },
  { city: 'Chillan', country: 'Chile', lCity: 'chillan', lCountry: 'chile' },
  { city: 'Chittoor', country: 'India', lCity: 'chittoor', lCountry: 'india' },
  { city: 'Fukang', country: 'China', lCity: 'fukang', lCountry: 'china' },
  { city: 'Ongole', country: 'India', lCity: 'ongole', lCountry: 'india' },
  { city: 'Aydin', country: 'Turkey', lCity: 'aydin', lCountry: 'turkey' },
  { city: 'Rockford', country: 'United States', lCity: 'rockford', lCountry: 'united states' },
  { city: 'Mbeya', country: 'Tanzania', lCity: 'mbeya', lCountry: 'tanzania' },
  { city: 'Mahajanga', country: 'Madagascar', lCity: 'mahajanga', lCountry: 'madagascar' },
  { city: 'Nagareyama', country: 'Japan', lCity: 'nagareyama', lCountry: 'japan' },
  { city: 'Shunan', country: 'Japan', lCity: 'shunan', lCountry: 'japan' },
  { city: 'Kamoke', country: 'Pakistan', lCity: 'kamoke', lCountry: 'pakistan' },
  { city: 'Nawabganj', country: 'Bangladesh', lCity: 'nawabganj', lCountry: 'bangladesh' },
  { city: 'Quetzaltenango', country: 'Guatemala', lCity: 'quetzaltenango', lCountry: 'guatemala' },
  {
    city: 'Chicoutimi Jonquiere',
    country: 'Canada',
    lCity: 'chicoutimi jonquiere',
    lCountry: 'canada',
  },
  { city: 'Burewala', country: 'Pakistan', lCity: 'burewala', lCountry: 'pakistan' },
  { city: 'Enakievo', country: 'Ukraine', lCity: 'enakievo', lCountry: 'ukraine' },
  {
    city: 'Nong Bua Lam Phu',
    country: 'Thailand',
    lCity: 'nong bua lam phu',
    lCountry: 'thailand',
  },
  { city: 'Sitapur', country: 'India', lCity: 'sitapur', lCountry: 'india' },
  { city: 'Fatehpur', country: 'India', lCity: 'fatehpur', lCountry: 'india' },
  {
    city: 'Springfield',
    country: 'United States',
    lCity: 'springfield',
    lCountry: 'united states',
  },
  { city: 'Chaiyaphum', country: 'Thailand', lCity: 'chaiyaphum', lCountry: 'thailand' },
  { city: 'Makurdi', country: 'Nigeria', lCity: 'makurdi', lCountry: 'nigeria' },
  {
    city: 'Bhalswa Jahangir Pur',
    country: 'India',
    lCity: 'bhalswa jahangir pur',
    lCountry: 'india',
  },
  { city: 'Angers', country: 'France', lCity: 'angers', lCountry: 'france' },
  { city: 'Kovrov', country: 'Russia', lCity: 'kovrov', lCountry: 'russia' },
  { city: 'Noda', country: 'Japan', lCity: 'noda', lCountry: 'japan' },
  { city: 'Ogaki', country: 'Japan', lCity: 'ogaki', lCountry: 'japan' },
  { city: 'Teresopolis', country: 'Brazil', lCity: 'teresopolis', lCountry: 'brazil' },
  { city: 'Morena', country: 'India', lCity: 'morena', lCountry: 'india' },
  { city: 'Serilingampalle', country: 'India', lCity: 'serilingampalle', lCountry: 'india' },
  { city: 'Nilopolis', country: 'Brazil', lCity: 'nilopolis', lCountry: 'brazil' },
  { city: 'Bawshar', country: 'Oman', lCity: 'bawshar', lCountry: 'oman' },
  { city: 'Bushehr', country: 'Iran', lCity: 'bushehr', lCountry: 'iran' },
  {
    city: 'Pembroke Pines',
    country: 'United States',
    lCity: 'pembroke pines',
    lCountry: 'united states',
  },
  { city: 'Nangloi Jat', country: 'India', lCity: 'nangloi jat', lCountry: 'india' },
  { city: 'Camaragibe', country: 'Brazil', lCity: 'camaragibe', lCountry: 'brazil' },
  { city: 'Uttarpara Kotrung', country: 'India', lCity: 'uttarpara kotrung', lCountry: 'india' },
  { city: 'Dijon', country: 'France', lCity: 'dijon', lCountry: 'france' },
  { city: 'Quelimane', country: 'Mozambique', lCity: 'quelimane', lCountry: 'mozambique' },
  { city: 'Ravenna', country: 'Italy', lCity: 'ravenna', lCountry: 'italy' },
  { city: 'Sebha', country: 'Libya', lCity: 'sebha', lCountry: 'libya' },
  { city: 'Paterson', country: 'United States', lCity: 'paterson', lCountry: 'united states' },
  { city: 'Tenali', country: 'India', lCity: 'tenali', lCountry: 'india' },
  {
    city: 'Burnley Nelson',
    country: 'United Kingdom',
    lCity: 'burnley nelson',
    lCountry: 'united kingdom',
  },
  { city: 'Borisov', country: 'Belarus', lCity: 'borisov', lCountry: 'belarus' },
  { city: 'Kabankalan', country: 'Philippines', lCity: 'kabankalan', lCountry: 'philippines' },
  { city: 'Neuss', country: 'Germany', lCity: 'neuss', lCountry: 'germany' },
  { city: 'Margilan', country: 'Uzbekistan', lCity: 'margilan', lCountry: 'uzbekistan' },
  { city: 'Phetchaburi', country: 'Thailand', lCity: 'phetchaburi', lCountry: 'thailand' },
  { city: 'Hatay', country: 'Turkey', lCity: 'hatay', lCountry: 'turkey' },
  { city: 'Yonago', country: 'Japan', lCity: 'yonago', lCountry: 'japan' },
  { city: 'Las Tunas', country: 'Cuba', lCity: 'las tunas', lCountry: 'cuba' },
  { city: 'Corona', country: 'United States', lCity: 'corona', lCountry: 'united states' },
  { city: 'Potosi', country: 'Bolivia', lCity: 'potosi', lCountry: 'bolivia' },
  { city: 'Siverek', country: 'Turkey', lCity: 'siverek', lCountry: 'turkey' },
  { city: 'Tacheng', country: 'China', lCity: 'tacheng', lCountry: 'china' },
  { city: 'Townsville', country: 'Australia', lCity: 'townsville', lCountry: 'australia' },
  { city: 'Jequie', country: 'Brazil', lCity: 'jequie', lCountry: 'brazil' },
  { city: 'Balti', country: 'Moldova', lCity: 'balti', lCountry: 'moldova' },
  { city: 'Port Louis', country: 'Mauritius', lCity: 'port louis', lCountry: 'mauritius' },
  { city: 'Tiaert', country: 'Algeria', lCity: 'tiaert', lCountry: 'algeria' },
  { city: 'Krishnanagar', country: 'India', lCity: 'krishnanagar', lCountry: 'india' },
  { city: 'Iruma', country: 'Japan', lCity: 'iruma', lCountry: 'japan' },
  { city: 'Karawal Nagar', country: 'India', lCity: 'karawal nagar', lCountry: 'india' },
  { city: 'Salzburg', country: 'Austria', lCity: 'salzburg', lCountry: 'austria' },
  { city: 'Saqez', country: 'Iran', lCity: 'saqez', lCountry: 'iran' },
  { city: 'Hoshiarpur', country: 'India', lCity: 'hoshiarpur', lCountry: 'india' },
  { city: 'Budaun', country: 'India', lCity: 'budaun', lCountry: 'india' },
  { city: 'Leninakan', country: 'Armenia', lCity: 'leninakan', lCountry: 'armenia' },
  { city: 'Kolomna', country: 'Russia', lCity: 'kolomna', lCountry: 'russia' },
  { city: 'Saint Louis', country: 'Senegal', lCity: 'saint louis', lCountry: 'senegal' },
  { city: 'Paranagua', country: 'Brazil', lCity: 'paranagua', lCountry: 'brazil' },
  { city: 'Gimcheon', country: 'South Korea', lCity: 'gimcheon', lCountry: 'south korea' },
  {
    city: 'Dumfries Galloway',
    country: 'United Kingdom',
    lCity: 'dumfries galloway',
    lCountry: 'united kingdom',
  },
  { city: 'Batala', country: 'India', lCity: 'batala', lCountry: 'india' },
  { city: 'Formosa', country: 'Argentina', lCity: 'formosa', lCountry: 'argentina' },
  { city: 'Chanthaburi', country: 'Thailand', lCity: 'chanthaburi', lCountry: 'thailand' },
  { city: 'Moriguchi', country: 'Japan', lCity: 'moriguchi', lCountry: 'japan' },
  { city: 'Phetchabun', country: 'Thailand', lCity: 'phetchabun', lCountry: 'thailand' },
  { city: 'Jhelum', country: 'Pakistan', lCity: 'jhelum', lCountry: 'pakistan' },
  { city: 'Calbayog', country: 'Philippines', lCity: 'calbayog', lCountry: 'philippines' },
  { city: 'Komaki', country: 'Japan', lCity: 'komaki', lCountry: 'japan' },
  { city: 'Umuahia', country: 'Nigeria', lCity: 'umuahia', lCountry: 'nigeria' },
  { city: 'Logrono', country: 'Spain', lCity: 'logrono', lCountry: 'spain' },
  { city: 'Shivapuri', country: 'India', lCity: 'shivapuri', lCountry: 'india' },
  {
    city: 'Prachuap Khiri Khan',
    country: 'Thailand',
    lCity: 'prachuap khiri khan',
    lCountry: 'thailand',
  },
  { city: 'Pesterion', country: 'Greece', lCity: 'pesterion', lCountry: 'greece' },
  { city: 'Cartago', country: 'Costa Rica', lCity: 'cartago', lCountry: 'costa rica' },
  { city: 'Bene Beraq', country: 'Israel', lCity: 'bene beraq', lCountry: 'israel' },
  { city: 'Tirgu Mures', country: 'Romania', lCity: 'tirgu mures', lCountry: 'romania' },
  { city: 'Salinas', country: 'United States', lCity: 'salinas', lCountry: 'united states' },
  { city: 'Izumo', country: 'Japan', lCity: 'izumo', lCountry: 'japan' },
  { city: 'Florencia', country: 'Colombia', lCity: 'florencia', lCountry: 'colombia' },
  { city: 'Bayamo', country: 'Cuba', lCity: 'bayamo', lCountry: 'cuba' },
  { city: 'Subotica', country: 'Serbia', lCity: 'subotica', lCountry: 'serbia' },
  { city: 'Alandur', country: 'India', lCity: 'alandur', lCountry: 'india' },
  { city: 'Lauro De Freitas', country: 'Brazil', lCity: 'lauro de freitas', lCountry: 'brazil' },
  { city: 'Timon', country: 'Brazil', lCity: 'timon', lCountry: 'brazil' },
  { city: 'Elektrostal', country: 'Russia', lCity: 'elektrostal', lCountry: 'russia' },
  { city: 'Ondo', country: 'Nigeria', lCity: 'ondo', lCountry: 'nigeria' },
  { city: 'Le Mans', country: 'France', lCity: 'le mans', lCountry: 'france' },
  { city: 'Ruda Slaska', country: 'Poland', lCity: 'ruda slaska', lCountry: 'poland' },
  { city: 'Tama', country: 'Japan', lCity: 'tama', lCountry: 'japan' },
  {
    city: 'Zenica',
    country: 'Bosnia And Herzegovina',
    lCity: 'zenica',
    lCountry: 'bosnia and herzegovina',
  },
  { city: 'Gorontalo', country: 'Indonesia', lCity: 'gorontalo', lCountry: 'indonesia' },
  { city: 'Jinggangshan', country: 'China', lCity: 'jinggangshan', lCountry: 'china' },
  { city: 'Huelva', country: 'Spain', lCity: 'huelva', lCountry: 'spain' },
  { city: 'Usak', country: 'Turkey', lCity: 'usak', lCountry: 'turkey' },
  { city: 'Belovo', country: 'Russia', lCity: 'belovo', lCountry: 'russia' },
  { city: 'Dundee', country: 'United Kingdom', lCity: 'dundee', lCountry: 'united kingdom' },
  { city: 'Valsad', country: 'India', lCity: 'valsad', lCountry: 'india' },
  { city: 'Hollywood', country: 'United States', lCity: 'hollywood', lCountry: 'united states' },
  { city: 'Hampton', country: 'United States', lCity: 'hampton', lCountry: 'united states' },
  { city: 'Tanjung Balai', country: 'Indonesia', lCity: 'tanjung balai', lCountry: 'indonesia' },
  { city: 'Copiapo', country: 'Chile', lCity: 'copiapo', lCountry: 'chile' },
  { city: 'St Gallen', country: 'Switzerland', lCity: 'st gallen', lCountry: 'switzerland' },
  { city: 'Koga', country: 'Japan', lCity: 'koga', lCountry: 'japan' },
  { city: 'Arar', country: 'Saudi Arabia', lCity: 'arar', lCountry: 'saudi arabia' },
  { city: 'Falkirk', country: 'United Kingdom', lCity: 'falkirk', lCountry: 'united kingdom' },
  { city: 'Ranaghat', country: 'India', lCity: 'ranaghat', lCountry: 'india' },
  { city: 'Vungtau', country: 'Viet Nam', lCity: 'vungtau', lCountry: 'viet nam' },
  { city: 'Quilpue', country: 'Chile', lCity: 'quilpue', lCountry: 'chile' },
  { city: 'Ilopango', country: 'El Salvador', lCity: 'ilopango', lCountry: 'el salvador' },
  { city: 'Higashimurayama', country: 'Japan', lCity: 'higashimurayama', lCountry: 'japan' },
  { city: 'Unnao', country: 'India', lCity: 'unnao', lCountry: 'india' },
  { city: 'Calama', country: 'Chile', lCity: 'calama', lCountry: 'chile' },
  { city: 'Viransehir', country: 'Turkey', lCity: 'viransehir', lCountry: 'turkey' },
  { city: 'Parakou', country: 'Benin', lCity: 'parakou', lCountry: 'benin' },
  { city: 'Shimla', country: 'India', lCity: 'shimla', lCountry: 'india' },
  { city: 'Eugene', country: 'United States', lCity: 'eugene', lCountry: 'united states' },
  { city: 'Bejaia', country: 'Algeria', lCity: 'bejaia', lCountry: 'algeria' },
  { city: 'Sadiqabad', country: 'Pakistan', lCity: 'sadiqabad', lCountry: 'pakistan' },
  { city: 'Caxias', country: 'Brazil', lCity: 'caxias', lCountry: 'brazil' },
  {
    city: 'Grand Prairie',
    country: 'United States',
    lCity: 'grand prairie',
    lCountry: 'united states',
  },
  { city: 'Barrackpur', country: 'India', lCity: 'barrackpur', lCountry: 'india' },
  { city: 'Kakamigahara', country: 'Japan', lCity: 'kakamigahara', lCountry: 'japan' },
  { city: 'Angra Dos Reis', country: 'Brazil', lCity: 'angra dos reis', lCountry: 'brazil' },
  { city: 'Rijeka', country: 'Croatia', lCity: 'rijeka', lCountry: 'croatia' },
  { city: 'Isahaya', country: 'Japan', lCity: 'isahaya', lCountry: 'japan' },
  { city: 'Luanshya', country: 'Zambia', lCity: 'luanshya', lCountry: 'zambia' },
  { city: 'Pallavaram', country: 'India', lCity: 'pallavaram', lCountry: 'india' },
  { city: 'Pasadena', country: 'United States', lCity: 'pasadena', lCountry: 'united states' },
  { city: 'Badajoz', country: 'Spain', lCity: 'badajoz', lCountry: 'spain' },
  { city: 'Pindamonhangaba', country: 'Brazil', lCity: 'pindamonhangaba', lCountry: 'brazil' },
  { city: 'Rajnandgaon', country: 'India', lCity: 'rajnandgaon', lCountry: 'india' },
  { city: 'Jecheon', country: 'South Korea', lCity: 'jecheon', lCountry: 'south korea' },
  { city: 'Arnhem', country: 'Netherlands', lCity: 'arnhem', lCountry: 'netherlands' },
  { city: 'Parnaiba', country: 'Brazil', lCity: 'parnaiba', lCountry: 'brazil' },
  { city: 'Braganca Paulista', country: 'Brazil', lCity: 'braganca paulista', lCountry: 'brazil' },
  { city: 'Seosan', country: 'South Korea', lCity: 'seosan', lCountry: 'south korea' },
  { city: 'Itapetininga', country: 'Brazil', lCity: 'itapetininga', lCountry: 'brazil' },
  { city: 'Balurghat', country: 'India', lCity: 'balurghat', lCountry: 'india' },
  { city: 'Oxford', country: 'United Kingdom', lCity: 'oxford', lCountry: 'united kingdom' },
  { city: 'Caguas', country: 'Puerto Rico', lCity: 'caguas', lCountry: 'puerto rico' },
  { city: 'Huanuco', country: 'Peru', lCity: 'huanuco', lCountry: 'peru' },
  { city: 'Trois Rivieres', country: 'Canada', lCity: 'trois rivieres', lCountry: 'canada' },
  { city: 'Pagadian', country: 'Philippines', lCity: 'pagadian', lCountry: 'philippines' },
  { city: 'Khoramshahr', country: 'Iran', lCity: 'khoramshahr', lCountry: 'iran' },
  { city: 'Cienfuegos', country: 'Cuba', lCity: 'cienfuegos', lCountry: 'cuba' },
  { city: 'Tsuruoka', country: 'Japan', lCity: 'tsuruoka', lCountry: 'japan' },
  { city: 'Torrance', country: 'United States', lCity: 'torrance', lCountry: 'united states' },
  { city: 'Ede', country: 'Nigeria', lCity: 'ede', lCountry: 'nigeria' },
  { city: 'Ome', country: 'Japan', lCity: 'ome', lCountry: 'japan' },
  { city: 'Osorno', country: 'Chile', lCity: 'osorno', lCountry: 'chile' },
  { city: 'Otaru', country: 'Japan', lCity: 'otaru', lCountry: 'japan' },
  {
    city: 'San Cristobal De La Laguna',
    country: 'Spain',
    lCity: 'san cristobal de la laguna',
    lCountry: 'spain',
  },
  { city: 'Kariya', country: 'Japan', lCity: 'kariya', lCountry: 'japan' },
  { city: 'Bheemavaram', country: 'India', lCity: 'bheemavaram', lCountry: 'india' },
  { city: 'Yavatmal', country: 'India', lCity: 'yavatmal', lCountry: 'india' },
  { city: 'Cadiz', country: 'Philippines', lCity: 'cadiz', lCountry: 'philippines' },
  { city: 'Ilam', country: 'Iran', lCity: 'ilam', lCountry: 'iran' },
  { city: 'Damaturu', country: 'Nigeria', lCity: 'damaturu', lCountry: 'nigeria' },
  { city: 'Al Fasher', country: 'Sudan', lCity: 'al fasher', lCountry: 'sudan' },
  { city: 'Slough', country: 'United Kingdom', lCity: 'slough', lCountry: 'united kingdom' },
  { city: 'Almetievsk', country: 'Russia', lCity: 'almetievsk', lCountry: 'russia' },
  { city: 'Chirchik', country: 'Uzbekistan', lCity: 'chirchik', lCountry: 'uzbekistan' },
  { city: 'Jimeta', country: 'Nigeria', lCity: 'jimeta', lCountry: 'nigeria' },
  { city: 'Bago', country: 'Philippines', lCity: 'bago', lCountry: 'philippines' },
  { city: 'Syracuse', country: 'United States', lCity: 'syracuse', lCountry: 'united states' },
  { city: 'Ipswich', country: 'United Kingdom', lCity: 'ipswich', lCountry: 'united kingdom' },
  { city: 'Dessie', country: 'Ethiopia', lCity: 'dessie', lCountry: 'ethiopia' },
  { city: 'Naperville', country: 'United States', lCity: 'naperville', lCountry: 'united states' },
  { city: 'Moji Guacu', country: 'Brazil', lCity: 'moji guacu', lCountry: 'brazil' },
  { city: 'Stara Zagora', country: 'Bulgaria', lCity: 'stara zagora', lCountry: 'bulgaria' },
  { city: 'Aksaray', country: 'Turkey', lCity: 'aksaray', lCountry: 'turkey' },
]
