import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { MobileThemeProvider } from './components/theme/MobileThemeProvider'

import { MobileSettings } from './MobileSettings'
import { MobilePickLocation } from './MobilePickLocation'
import { AirDeliverySpotsProvider } from './containers/AirDeliverySpots'

export const Mobile = ({ match }) => {
  return (
    <MobileThemeProvider>
      <AirDeliverySpotsProvider>
        <Switch>
          <Route
            path={`${match.path}/settings`}
            render={props => (
              <MobileSettings {...props} pickLocation={`${match.url}/pick-location`} />
            )}
          />
          <Route
            path={`${match.path}/pick-location`}
            render={props => <MobilePickLocation {...props} settings={`${match.url}/settings`} />}
          />
          <Redirect to={`${match.path}/settings`} />
        </Switch>
      </AirDeliverySpotsProvider>
    </MobileThemeProvider>
  )
}
