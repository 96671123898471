import React from 'react'
import { orderBy, upperFirst } from 'lodash'
import { useSelector } from 'react-redux'
import { Page } from '../../components/app'
import {
  FormattedCoordinate,
  FormattedMinutes,
  FormattedOrderStatus,
  FormattedWeight,
} from '../../components/formatters'
import { Filter, Pagination, Table, TD, TH, TR } from '../../components/tables'
import { selectActiveFlightsByCity } from '../../containers/simulation/schedule/scheduleSelectors'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'
import { usePagination } from '../../containers/router'
import { linkToFlight } from './links/atcFlight'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

export const FlightManagement = ({ match, history, atcPath }) => {
  const city = getCitySlug(match)
  const { page, limit } = usePagination(12)
  const { name } = useSelector(selectCitySpec(city))
  const flights = useSelector(selectActiveFlightsByCity(city))
  const orderByKey = 'order.date'
  const orderedFlights = orderBy(flights, [orderByKey], ['desc'])
  return (
    <Page heading={`${name} - Flight Management`}>
      <Filter inline />
      <Table
        head={
          <TR>
            <TH>Drone ID</TH>
            <TH center>Hub</TH>
            <TH center>Gate</TH>
            <TH width="100">Action</TH>
            <TH>Speed</TH>
            <TH>Position</TH>
            <TH>Altitude</TH>
            <TH>Elapsed Time</TH>
            <TH>Time to Dest.</TH>
            <TH>Battery</TH>
            <TH>Payload</TH>
            <TH width="130">Status</TH>
          </TR>
        }
        page={page}
        limit={limit}
        items={orderedFlights}
        emptyMsg="No active flights"
      >
        {({ order, ...flight }) => (
          <TR key={order.id} onClick={() => history.push(atcPath, linkToFlight(flight))}>
            <TD>{flight.droneId}</TD>
            <TD center>{flight.flightPath.hubId}</TD>
            <TD center>{flight.flightPath.gateNo}</TD>
            <TD>{upperFirst(flight.flightPath.status)}</TD>
            <TD>{flight.flightPath.speed} mps</TD>
            <TD>
              <FormattedCoordinate value={flight.flightPath.position} />
            </TD>
            <TD>{flight.flightPath.position.altitude} m</TD>
            <TD>
              <FormattedMinutes value={flight.flightPath.elapsedDuration} seconds />
            </TD>
            <TD>
              <FormattedMinutes value={flight.flightPath.remainingDuration} seconds />
            </TD>
            <TD>{flight.flightPath.battery.toFixed(1)}%</TD>
            <TD>
              <FormattedWeight value={order.payload.weight} />
            </TD>
            <TD>
              <FormattedOrderStatus value={flight.schedule.status} />
            </TD>
          </TR>
        )}
      </Table>
      <Pagination total={orderedFlights.length} limit={limit} />
    </Page>
  )
}
