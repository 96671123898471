import React from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'

const ModalHead = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -${props => props.theme.spacing.xsmall}px;
  margin-right: -${props => props.theme.spacing.xsmall}px;
  margin-bottom: ${props => props.theme.spacing.small}px;
`

const Close = styled.i`
  margin-left: auto;
  color: ${props => props.theme.colors.dark4};
  font-size: ${props => props.theme.fontSizes.xlarge}px;
  cursor: pointer;
`

export const Modal = ({ isOpen, onRequestClose, children, ...props }) => (
  <ReactModal
    {...props}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    style={{
      overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '300px',
      },
    }}
  >
    <ModalHead>
      <Close className="far fa-times-circle" onClick={onRequestClose} />
    </ModalHead>
    {children}
  </ReactModal>
)
