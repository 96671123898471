import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useCurrentUser } from './auth'

export const PrivateRoute = props => {
  const user = useCurrentUser()
  const location = useLocation()
  return user ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/sign-in', state: { location } }} />
  )
}

export const PublicOnlyRoute = props => {
  const user = useCurrentUser()
  return user ? <Redirect to={{ pathname: '/' }} /> : <Route {...props} />
}

export const usePagination = limit => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const page = Number(query.get('page') ?? 1)
  return { page, limit }
}
