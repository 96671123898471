import { getDistance } from 'geolib'

export const calculateFlightDuration = ({ droneSpec }, wayPoints) => {
  const duration = Math.round(
    wayPoints.reduce(
      (result, wayPoint) => {
        result.duration +=
          result.position.altitude === wayPoint.altitude
            ? getDistance(result.position, wayPoint) / droneSpec.groundSpeed
            : (wayPoint.altitude - result.position.altitude) / droneSpec.verticalSpeed
        result.position = wayPoint
        return result
      },
      {
        duration: 0,
        position: wayPoints[0],
      },
    ).duration,
  )
  return duration < 60 ? 61 : duration
}
