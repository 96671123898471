import React from 'react'
import Media from 'react-media'
import { hot } from 'react-hot-loader/root'
import { Route, Switch } from 'react-router-dom'
import { PrivateRoute } from '../containers/router'
import { MainNavProvider } from '../components/app/MainNavProvider'

import { Home } from './Home'

import { Auth } from './auth'
import { Mobile } from './mobile'
import { Cities } from './cities'
import { Locations } from './locations'
import { Reports } from './reports'
import { UsersAndRoles } from './usersAndRoles'
import { MobileBlockingPage } from './mobile/MobileBlockingPage'

const navLinks = [
  { label: 'Active Cities', to: '/cities' },
  { label: 'Location Management', to: '/locations' },
  { label: 'Executive Reports', to: '/reports' },
  { label: 'User & Role Management', to: '/manage-access' },
]

const ManagementRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/cities" component={Cities} />
      <PrivateRoute path="/locations" component={Locations} />
      <PrivateRoute path="/reports" component={Reports} />
      <PrivateRoute path="/manage-access" component={UsersAndRoles} />
      <PrivateRoute path="/" render={props => <Home {...props} navLinks={navLinks} />} />
    </Switch>
  )
}

const RoutesBase = () => {
  return (
    <Media query={{ minWidth: 1025 }}>
      {matches =>
        matches ? (
          <MainNavProvider value={navLinks}>
            <Switch>
              <Route path="/sign-in" component={Auth} />
              <Route>
                <ManagementRoutes />
              </Route>
            </Switch>
          </MainNavProvider>
        ) : (
          <Switch>
            <Route path="/mobile" component={Mobile} />
            <Route>
              <MobileBlockingPage />
            </Route>
          </Switch>
        )
      }
    </Media>
  )
}

export const Routes = hot(RoutesBase)
