import { computeDestinationPoint, getDistance, getRhumbLineBearing } from 'geolib'
import produce from 'immer'

const coordinatesAreEqual = (a, b) =>
  a.latitude === b.latitude && a.longitude === b.longitude && a.altitude === b.altitude

export const calcMovedPosition = (seconds, drone, flightPath) => {
  const {
    position,
    nextWayPoint,
    bearing,
    distance,
    remainingDuration,
    elapsedDuration,
    battery,
    batteryPerSecond,
  } = flightPath
  const { verticalSpeed, groundSpeed } = drone

  const traveledHDistance = groundSpeed * seconds
  const traveledVDistance = verticalSpeed * seconds

  const destinationHDistance = getDistance(position, nextWayPoint)
  const destinationVDistance = Math.abs(nextWayPoint.altitude - position.altitude)

  const newPosition = !destinationHDistance
    ? { ...position }
    : traveledHDistance > destinationHDistance
    ? nextWayPoint
    : computeDestinationPoint(position, traveledHDistance, bearing)

  newPosition.altitude = Math.round(
    !destinationVDistance
      ? position.altitude
      : traveledVDistance > destinationVDistance ||
        position.altitude > nextWayPoint.altitude ||
        position.altitude < 0
      ? nextWayPoint.altitude
      : position.altitude + traveledVDistance,
  )

  return {
    position: newPosition,
    speed: traveledHDistance ? drone.groundSpeed : 0,
    distance: !traveledHDistance ? 0 : distance - getDistance(position, newPosition),
    remainingDuration: Math.max(0, remainingDuration - seconds),
    elapsedDuration: elapsedDuration + seconds,
    battery: Math.max(4, battery - batteryPerSecond * seconds),
    atWayPoint: coordinatesAreEqual(newPosition, nextWayPoint),
  }
}

export const updateFlightProgress = (time, seconds, droneSpec) => flight => ({
  ...flight,
  flightPath: produce(flight.flightPath, flightPath => {
    switch (flightPath.status) {
      default:
      case 'completed': {
        break
      }
      case 'queued': {
        flightPath.status = 'loading'
        break
      }
      case 'loading': {
        flightPath.loadingTime -= seconds
        if (flightPath.loadingTime <= 0) {
          flightPath.loadingTime = 0
          flightPath.status = 'outbound'
          flightPath.departedAt = new Date(time)
        }
        break
      }
      case 'outbound': {
        const {
          position,
          elapsedDuration,
          remainingDuration,
          distance,
          speed,
          battery,
          atWayPoint,
        } = calcMovedPosition(seconds, droneSpec, flightPath)
        flightPath.speed = speed
        flightPath.battery = battery
        flightPath.position = position
        flightPath.distance = distance
        flightPath.elapsedDuration = elapsedDuration
        flightPath.remainingDuration = remainingDuration
        flightPath.bearing = getRhumbLineBearing(position, flightPath.nextWayPoint)
        if (atWayPoint) {
          if (flightPath.wayPointsOut.length) {
            flightPath.nextWayPoint = flightPath.wayPointsOut.shift()
          } else {
            flightPath.nextWayPoint = flightPath.wayPointsIn.shift()
            flightPath.status = 'delivering'
          }
        }
        break
      }
      case 'delivering': {
        flightPath.deliveryTime -= seconds
        flightPath.elapsedDuration += seconds
        if (flightPath.deliveryTime <= 0) {
          flightPath.remainingDuration = flightPath.legDuration
          flightPath.deliveryTime = 0
          flightPath.status = 'inbound'
          flightPath.deliveredAt = new Date(time)
        }
        break
      }
      case 'inbound': {
        const {
          position,
          elapsedDuration,
          remainingDuration,
          distance,
          speed,
          battery,
          atWayPoint,
        } = calcMovedPosition(seconds, droneSpec, flightPath)
        flightPath.speed = speed
        flightPath.battery = battery
        flightPath.position = position
        flightPath.distance = distance
        flightPath.elapsedDuration = elapsedDuration
        flightPath.remainingDuration = remainingDuration
        flightPath.bearing = getRhumbLineBearing(position, flightPath.nextWayPoint)
        if (atWayPoint) {
          if (flightPath.wayPointsIn.length) {
            flightPath.nextWayPoint = flightPath.wayPointsIn.shift()
          } else {
            flightPath.nextWayPoint = null
            flightPath.bearing = 0
            flightPath.status = 'completed'
            break
          }
        }
        break
      }
    }
  }),
})
