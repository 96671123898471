import React from 'react'
import styled from 'styled-components'
import GoogleMap from 'google-map-react'
import { transparentize } from 'polished'
import { config } from '../../../../config'
import { ACCURACY_INTERVAL } from '../../containers/useGeoPosition'

const Circle = styled.div`
  position: absolute;
  height: ${props => props.radius * 2}px;
  width: ${props => props.radius * 2}px;
  left: -${props => props.radius}px;
  top: -${props => props.radius}px;
  background: ${props => (props.solid ? props.color : transparentize(0.7, props.color))};
  border: 1px solid ${props => props.borderColor || props.color};
  border-radius: 50%;
  transition: all ${ACCURACY_INTERVAL + 20}ms linear;
`

const mapDefaults = {
  defaultCenter: {
    latitude: 51.5074,
    longitude: -0.1278,
  },
  defaultZoom: 20,
}

export const PickLocationMap = ({ coords, accuracyMultiplier, setMaps }) => {
  return (
    <>
      <GoogleMap
        {...mapDefaults}
        options={getMapOptions}
        bootstrapURLKeys={config.googleMaps}
        center={{ lat: coords.latitude, lng: coords.longitude }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ maps }) => setMaps(maps)}
      >
        <Circle
          solid
          radius={10}
          lat={coords.latitude}
          lng={coords.longitude}
          borderColor="#fff"
          color="#0066d0"
        />
        <Circle
          radius={120 * accuracyMultiplier}
          lat={coords.latitude}
          lng={coords.longitude}
          color="#0066c0"
        />
      </GoogleMap>
    </>
  )
}

const getMapOptions = maps => ({
  streetViewControl: false,
  fullscreenControl: false,
  scaleControl: false,
  draggable: false,
  zoomControl: false,
  clickableIcons: false,
  disableDoubleClickZoom: true,
  mapTypeControl: false,
  mapTypeId: maps.MapTypeId.SATELLITE,
  maxZoom: 20,
  minZoom: 20,
})
