import { combineReducers } from 'redux'

import { citiesReducer as cities } from './simulation/cities/citiesReducer'
import { dronesReducer as drones } from './simulation/drones/dronesReducer'
import { ordersReducer as orders } from './simulation/orders/ordersReducer'
import { scheduleReducer as schedule } from './simulation/schedule/scheduleReducer'
import { usersReducer as users } from './users/usersReducer'

import { hubSetupReducer as hubSetup } from './locations/hubSetup'
import { createReducer as createCitiesUI } from '../pages/cities/reducers'

export const createReducer = injectedReducers =>
  combineReducers({
    cities,
    drones,
    orders,
    schedule,
    users,
    hubSetup,
    citiesUI: createCitiesUI(),

    ...injectedReducers,
  })
