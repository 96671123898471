import { camelCase } from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Row, Col } from 'styled-bootstrap-grid'
import { Page } from '../../components/app'
import { Heading } from '../../components/typography'
import { Switch } from '../../components/input/Switch'
import { DD, DL, DT, Table, TD, TH, TR } from '../../components/tables'
import {
  addUserCityRole,
  changeUserCityRole,
  selectUserById,
  toggleCityRole,
} from '../../containers/users/usersReducer'
import { roles } from '../../containers/users/roles'
import { Button } from '../../components/controls'
import { UserRoleForm } from './forms/UserRoleForm'
import { SelectInput } from '../../components/input'

const AddSection = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const UserManagement = ({ match, location: { state = {} } }) => {
  const [addForm, setAddForm] = useState(state.addForm ?? false)
  const dispatch = useDispatch()
  const user = useSelector(selectUserById(match.params.userId))
  if (!user) {
    return <Redirect to={match.url.substring(0, match.url.lastIndexOf('/'))} />
  }
  return (
    <Page heading="User Management">
      <Row>
        <Col sm={8}>
          <Form subscription={{}} onSubmit={values => dispatch(addUserCityRole(user.id, values))}>
            {({ handleSubmit, form }) => (
              <form
                onSubmit={async event => {
                  await handleSubmit(event)
                  form.getState().valid && form.reset()
                }}
              >
                <Table
                  head={
                    <TR>
                      <TH width="35%">City</TH>
                      <TH width="35%">Role</TH>
                      <TH width="30%">Hubs</TH>
                    </TR>
                  }
                  items={user.roles}
                  emptyMsg="No roles assigned"
                  footer={addForm && <UserRoleForm user={user} />}
                >
                  {(item, idx) => (
                    <TR key={item.id ?? idx}>
                      <TD>{item.cityName}</TD>
                      <TD>
                        <SelectInput
                          inline
                          value={item.role}
                          onChange={ev =>
                            dispatch(
                              changeUserCityRole(
                                user.id,
                                camelCase(item.cityName),
                                ev.target.value,
                              ),
                            )
                          }
                        >
                          {Object.values(roles).map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </SelectInput>
                      </TD>
                      <TD>
                        {Object.entries(item.hubs).map(([hubId, hubOn], hubIndex) => (
                          <Switch
                            key={hubId}
                            label={`Hub ${hubIndex + 1}`}
                            checked={hubOn}
                            onChange={() => dispatch(toggleCityRole(user.id, idx, hubId))}
                          />
                        ))}
                      </TD>
                    </TR>
                  )}
                </Table>

                <AddSection>
                  <Button block={false} onClick={() => setAddForm(!addForm)}>
                    {addForm ? 'Close form' : 'Add a role'}
                  </Button>
                </AddSection>
              </form>
            )}
          </Form>
        </Col>

        <Col sm={4}>
          <Heading margin="xsmall">{user.name}</Heading>
          <DL margin="large" isHorizontal>
            <DT>Employee No.</DT>
            <DD>{user.id}</DD>
            <DT>Phone</DT>
            <DD>{user.phone}</DD>
            <DT>Email</DT>
            <DD>{user.email}</DD>
          </DL>

          <Button>Suspend User</Button>
        </Col>
      </Row>
    </Page>
  )
}
