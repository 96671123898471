import React from 'react'
import { useSelector } from 'react-redux'
import { ExecutiveReports } from '../reports/ExecutiveReports'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

const summary = {
  sent: '4,367',
  delivered: '4,236',
  rate: '97%',
}

export const Reports = ({ match }) => {
  const city = getCitySlug(match)
  const { name } = useSelector(selectCitySpec(city))
  return <ExecutiveReports pageHeading={`${name} - Reports`} summary={summary} city={city} />
}
