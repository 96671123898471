import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Col } from 'styled-bootstrap-grid'
import { ButtonBlock } from './ButtonBlock'

const RowBlockList = styled(Row)`
  margin: 75px auto 90px;
  width: 900px;
`

export const ButtonBlockNavList = ({ items }) => {
  const offset = items.length < 5 ? 2 : 0
  return (
    <RowBlockList>
      {items.map(({ label, to }, index) => (
        <Col sm={4} smOffset={index % 2 === 0 ? offset : 0} key={to}>
          <Link to={to}>
            <ButtonBlock>{label}</ButtonBlock>
          </Link>
        </Col>
      ))}
    </RowBlockList>
  )
}
