import produce from 'immer'
import { isAfter } from 'date-fns'
import { batch } from 'react-redux'
import { groupBy, minBy, random } from 'lodash/fp'
import { DRONES_ADD_MANY } from '../drones/dronesReducer'
import { ORDERS_ADD_MANY, ORDERS_ADD_ONE } from '../orders/ordersReducer'
import { CITY_SET_HUB_ACTIVE } from '../cities/citiesReducer'
import { selectQueuedFlightsByCity } from './scheduleSelectors'

const SCHEDULE_FLIGHT_UPDATE = 'SCHEDULE_FLIGHT_UPDATE'
const SCHEDULE_CLEAR_QUEUES = 'SCHEDULE_CLEAR_QUEUES'
const SCHEDULE_ORDERS_READD_MANY = 'SCHEDULE_ORDERS_READD_MANY'

const shortestQueue = minBy(x => x.queuedDuration)

const optimalFlightPath = (flightPaths, hubsById) => {
  const optimalDroneQueues = flightPaths.map(flightPath => {
    const droneQueue = shortestQueue(Object.values(hubsById[flightPath.hubId].drones))
    return { flightPath, droneQueue, queuedDuration: droneQueue.queuedDuration }
  })
  const optimalActiveQueues = optimalDroneQueues.filter(q => hubsById[q.flightPath.hubId].active)
  return {
    optimalOverall: shortestQueue(optimalDroneQueues),
    optimalActive: shortestQueue(optimalActiveQueues),
  }
}

const initialState = {
  byCity: {},
}

const initialHubState = active => ({
  active,
  drones: {},
  completedFlights: [],
  cancelledFlights: [],
  delayedFlights: [],
})
const initialDroneQueueState = (hubId, droneId) => ({
  hubId,
  droneId,
  activeFlight: null,
  queuedFlights: [],
  queuedDuration: 0,
})
const initialFlightState = (orderId, optimalActive, optimalOverall, time, prevFlight) => {
  if (!optimalActive) {
    const { droneQueue, flightPath } = optimalOverall
    const departureTime = time + droneQueue.queuedDuration * 1000
    const scheduledDeparture = new Date(departureTime)
    const originalDeparture = prevFlight
      ? prevFlight.schedule.originalDeparture
      : scheduledDeparture
    return {
      orderId,
      droneId: droneQueue.droneId,
      flightPath,
      schedule: {
        status: 'onHold',
        originalDeparture,
        scheduledDeparture,
      },
    }
  }

  const { droneQueue, flightPath } = optimalActive
  const departureTime = time + droneQueue.queuedDuration * 1000
  const scheduledDeparture = new Date(departureTime)
  const originalDeparture = prevFlight ? prevFlight.schedule.originalDeparture : scheduledDeparture
  const status = isAfter(scheduledDeparture, originalDeparture)
    ? 'rescheduled'
    : random(0, 10) >= 9
    ? 'cancelled'
    : 'ok'
  return {
    orderId,
    droneId: droneQueue.droneId,
    flightPath,
    schedule: {
      status,
      originalDeparture,
      scheduledDeparture,
    },
  }
}

const addOrder = (city, order, time, prevFlight) => {
  const { optimalOverall, optimalActive } = optimalFlightPath(order.flightPaths, city)
  if (!optimalOverall) return
  const flight = initialFlightState(order.id, optimalActive, optimalOverall, time, prevFlight)
  if (flight.schedule.status === 'cancelled') {
    city[optimalOverall.flightPath.hubId].cancelledFlights.unshift(flight)
  } else if (flight.schedule.status === 'onHold') {
    city[optimalOverall.flightPath.hubId].delayedFlights.push(flight)
  } else {
    const { droneQueue, flightPath } = optimalActive
    droneQueue.queuedFlights.push(flight)
    droneQueue.queuedDuration += flightPath.duration
  }
}

export const scheduleReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DRONES_ADD_MANY: {
        const { cityId, cityHubs, drones } = action.payload
        const city = (draft.byCity[cityId] = draft.byCity[cityId] ?? {})

        Object.entries(groupBy('hub', drones)).forEach(([hubId, drones]) => {
          const hub = (city[hubId] = city[hubId] ?? initialHubState(cityHubs[hubId].active))
          drones.forEach(drone => {
            hub.drones[drone.id] = initialDroneQueueState(hubId, drone.id)
          })
        })
        break
      }

      case ORDERS_ADD_ONE: {
        const { cityId, order } = action.payload
        addOrder(draft.byCity[cityId], order, order.date.getTime(), order.prevFlight)
        break
      }

      case SCHEDULE_ORDERS_READD_MANY:
      case ORDERS_ADD_MANY: {
        const { cityId, orders } = action.payload
        const city = draft.byCity[cityId]
        orders.forEach(order => addOrder(city, order, order.date.getTime(), order.prevFlight))
        break
      }

      case SCHEDULE_FLIGHT_UPDATE: {
        const { cityId, droneSchedules, completedFlights } = action.payload
        const city = draft.byCity[cityId]
        droneSchedules.forEach(schedule => {
          city[schedule.hubId].drones[schedule.droneId] = schedule
        })
        completedFlights.forEach(flight => {
          city[flight.flightPath.hubId].completedFlights.push(flight)
        })
        break
      }

      case CITY_SET_HUB_ACTIVE: {
        const { cityId, hubId, active } = action.payload
        draft.byCity[cityId][hubId].active = active
        break
      }

      case SCHEDULE_CLEAR_QUEUES:
        const { cityId } = action.payload
        Object.values(draft.byCity[cityId]).forEach(hub => {
          hub.delayedFlights = []
          Object.values(hub.drones).forEach(drone => {
            drone.queuedFlights = []
            drone.queuedDuration = 0
          })
        })
        break
      // no default
    }
  })

export const flightUpdate = (cityId, droneSchedules, completedFlights) => ({
  type: SCHEDULE_FLIGHT_UPDATE,
  payload: { cityId, droneSchedules, completedFlights },
})

export const rescheduleAllOrders = cityId => (dispatch, getState) => {
  const flights = selectQueuedFlightsByCity(cityId)(getState())
  const orders = flights.map(flight => ({ ...flight.order, prevFlight: flight }))
  batch(() => {
    dispatch({
      type: SCHEDULE_CLEAR_QUEUES,
      payload: { cityId },
    })
    dispatch({
      type: SCHEDULE_ORDERS_READD_MANY,
      payload: { cityId, orders },
    })
  })
}
