import React, { useContext, useState } from 'react'
import { FORM_ERROR } from 'final-form'

let devUser

if (process.env.REACT_APP_SKIP_SIGNIN) {
  devUser = {
    name: 'Dev User',
    username: 'dev_user',
  }
}

const AuthContext = React.createContext(null)

const isJson = response => response.headers.get('Content-Type') === 'application/json'

const useAuthentication = () => {
  const [user, setUser] = useState(localStorage.getItem('user') || devUser)

  const signIn = credentials => {
    return fetch('/.netlify/functions/sign-in', {
      method: 'POST',
      body: JSON.stringify(credentials),
      headers: {
        'Content-Type': 'applications/json',
      },
    })
      .then(response =>
        (isJson(response) ? response.json() : response.text()).then(body =>
          response.ok ? body : Promise.reject(body),
        ),
      )
      .then(user => {
        setUser(user)
        localStorage.setItem('user', user)
      })
      .catch(error => ({
        ...error.errors,
        [FORM_ERROR]: error.message,
      }))
  }

  const signOut = () => {
    localStorage.removeItem('user')
    setUser(null)
  }

  return {
    signOut,
    signIn,
    user,
  }
}

export const AuthProvider = ({ children }) => {
  const auth = useAuthentication()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)

export const useCurrentUser = () => useContext(AuthContext).user
