import React from 'react'
import styled, { css } from 'styled-components'

const smallButton = css`
  padding: 5px ${props => props.theme.spacing.smedium / 2}px;
  margin: 0;
`

export const Button = styled(({ active, block, inline, small, medium, ...props }) => (
  <button {...props} />
)).attrs(props => ({
  type: props.type || 'button',
}))`
  width: ${props => (props.width ? props.width : props.block === false ? 'auto' : '100%')};
  border: none;
  border-radius: 5px;
  background: ${props => props.theme.colors.primary};
  padding: 10px ${props => props.theme.spacing.smedium}px;
  margin: 0 0 ${props => (props.inline ? 0 : props.theme.spacing.small)}px;
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.medium}px;
  color: ${props => props.theme.colors.white};
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  max-height: 36px;
  ${props => props.align && `align-self: ${props.align}`};

  ${props => props.small && smallButton};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`
