import React from 'react'
import { Map, mapCoordinate } from '../map'
import { DestinationMarker } from './Markers/DestinationMarker'
import { NoFlyZoneArea } from './Markers/NoFlyZoneArea'
import { DroneMarker } from './Markers/DroneMarker'
import { FlightPath } from './Markers/FlightPath'
import { HubMarker } from './Markers/HubMarker'
import { useSelector } from 'react-redux'

import { selectFlightsByCity } from '../../containers/simulation/schedule/scheduleSelectors'
import { FlightMapLegend } from './FlightMapLegend'

const shownStatuses = ['outbound', 'delivering', 'inbound']

const renderFlight = (chosenFlight, onChooseFlight) => flight => {
  const {
    orderId,
    droneId,
    flightPath: {
      status,
      destination,
      position,
      originalWayPoints: { wayPointsOut, wayPointsIn },
    },
  } = flight
  const showFlight = shownStatuses.includes(status)
  const showOutbound = status === 'outbound'
  const showInbound = status === 'inbound'
  return !showFlight
    ? null
    : [
        <DestinationMarker
          key={`${orderId}dest`}
          {...mapCoordinate(destination)}
          orderId={orderId}
          isChosen={orderId === chosenFlight.orderId}
          onClick={() => onChooseFlight(flight)}
        />,
        <DroneMarker
          key={`${orderId}drone`}
          {...mapCoordinate(position)}
          droneId={droneId}
          isChosen={droneId === chosenFlight.droneId}
          onClick={() => onChooseFlight(flight)}
        />,
        showOutbound && <FlightPath key={`${orderId}out`} path={wayPointsOut} />,
        showInbound && <FlightPath key={`${orderId}in`} path={wayPointsIn} />,
      ]
}

export const FlightMap = ({
  city,
  size,
  center,
  hubs,
  noFlyZones,
  chosenFlight,
  onChooseFlight,
}) => {
  const flights = useSelector(selectFlightsByCity(city))
  return (
    <div>
      <Map {...size} center={mapCoordinate(center)}>
        {hubs.map(({ id, center, radius, active }) => (
          <HubMarker
            key={id}
            radius={radius}
            center={center}
            active={active}
            {...mapCoordinate(center)}
          />
        ))}

        {flights.map(renderFlight(chosenFlight ?? {}, onChooseFlight))}

        {noFlyZones.map(({ name, center, radius }) => (
          <NoFlyZoneArea key={name} center={center} radius={radius} />
        ))}
      </Map>
      <FlightMapLegend />
    </div>
  )
}
