import produce from 'immer'
import { keyBy } from 'lodash/fp'
import { batch } from 'react-redux'
import { initialUsers } from './initialUsers'
import { camelCase, mapValues, update } from 'lodash'
import { selectCitySpec } from '../simulation/cities/citiesReducer'

export const USERS_ADD_ONE = 'USERS_ADD_ONE'
export const USERS_ADD_CITY_ROLE = 'USERS_ADD_CITY_ROLE'
export const USERS_CHANGE_CITY_ROLE = 'USERS_CHANGE_CITY_ROLE'
export const USERS_TOGGLE_CITY_ROLE = 'USERS_TOGGLE_CITY_ROLE'

const initialState = {
  byId: keyBy('id', initialUsers),
}

export const usersReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case USERS_ADD_ONE: {
        const { user } = action.payload
        draft.byId[user.id] = { ...user, roles: [] }
        break
      }

      case USERS_ADD_CITY_ROLE: {
        const { userId, cityRole } = action.payload
        draft.byId[userId].roles.push(cityRole)
        break
      }

      case USERS_CHANGE_CITY_ROLE: {
        const { userId, city, roleName } = action.payload
        const role = draft.byId[userId].roles.find(role => camelCase(role.cityName) === city)
        role.role = camelCase(roleName)
        break
      }

      case USERS_TOGGLE_CITY_ROLE: {
        const { userId, roleIdx, hubId } = action.payload
        update(draft, ['byId', userId, 'roles', roleIdx, 'hubs', hubId], value => !value)
        break
      }

      // no default
    }
  })

export const selectAllUsers = () => ({ users }) => Object.values(users.byId).reverse()

export const selectCityUsers = city => ({ users }) =>
  Object.values(users.byId)
    .reverse()
    .filter(user => user.roles.some(role => camelCase(role.cityName) === city))
    .map(user => ({
      ...user,
      role: user.roles.find(role => camelCase(role.cityName) === city),
      roleIdx: user.roles.findIndex(role => camelCase(role.cityName) === city),
    }))

export const selectUserById = id => ({ users }) => users.byId[id]

export const addUserCityRole = (userId, cityRole) => (dispatch, getState) => {
  const citySpec = selectCitySpec(camelCase(cityRole.cityName))(getState())
  dispatch({
    type: USERS_ADD_CITY_ROLE,
    payload: {
      userId,
      cityRole: {
        ...cityRole,
        hubs: mapValues(citySpec.hubs, () => false),
      },
    },
  })
}

export const addUserCityDefaultRole = (userId, city) => (dispatch, getState) => {
  const citySpec = selectCitySpec(city)(getState())
  dispatch(
    addUserCityRole(userId, {
      role: 'stockManager',
      cityName: citySpec.name,
    }),
  )
}

export const changeUserCityRole = (userId, city, newRole) => ({
  type: USERS_CHANGE_CITY_ROLE,
  payload: {
    userId,
    city,
    roleName: newRole,
  },
})

export const toggleCityRole = (userId, roleIdx, hubId) => ({
  type: USERS_TOGGLE_CITY_ROLE,
  payload: { userId, roleIdx, hubId },
})

export const addUser = user => ({
  type: USERS_ADD_ONE,
  payload: { user },
})

export const addUserWithInitialRole = (user, city) => dispatch => {
  batch(() => {
    dispatch(addUser(user))
    dispatch(addUserCityDefaultRole(user.id, city))
  })
}
