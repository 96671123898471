import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { selectCitySpec, setCityHubActive } from '../../containers/simulation/cities/citiesReducer'
import { rescheduleAllOrders } from '../../containers/simulation/schedule/scheduleReducer'
import { getCitySlug } from '../../containers/simulation/getCitySlug'
import { Switch } from '../input/Switch'

const Container = styled.div`
  margin-bottom: ${props => props.theme.spacing.xsmall}px;
`

export const Hubs = () => {
  const match = useRouteMatch()
  const city = getCitySlug(match)
  const { id: cityId, hubs } = useSelector(selectCitySpec(city))
  const dispatch = useDispatch()
  return (
    <Container>
      {Object.values(hubs).map(hub => (
        <Switch
          key={hub.id}
          justify="flex-end"
          label={`${hub.locality} hub`}
          checked={hub.active}
          onChange={active => {
            dispatch(setCityHubActive(cityId, hub.id, active))
            dispatch(rescheduleAllOrders(cityId))
          }}
        />
      ))}
    </Container>
  )
}
