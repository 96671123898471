import { random } from 'lodash'
import { getPossibleFlightPaths } from '../flights/getPossibleFlightPaths'
import { orderConfig } from '../config'

let lastId = 102382

const newOrderId = () => (++lastId).toString().padStart(9, '0')
const newSKU = () => random(150000000, 250000000).toString()

let ordersIndexes = {
  london: -1,
  losAngeles: -1,
  paris: -1,
}

const orderPromises = {
  london: fetch('/orders/london.json').then(response => response.json()),
  losAngeles: fetch('/orders/losAngeles.json').then(response => response.json()),
  paris: fetch('/orders/paris.json').then(response => response.json()),
}

const getNewOrder = async city => {
  const orders = await orderPromises[city]
  ordersIndexes[city] = (ordersIndexes[city] + 1) % orders.length
  return orders[ordersIndexes[city]]
}

export const generateOrderAsync = async (citySpec, timestamp = Date.now()) => {
  const { id: city, droneSpec } = citySpec
  const order = await getNewOrder(city)
  return {
    ...order,
    id: newOrderId(),
    city: citySpec.id,
    date: new Date(timestamp),
    payload: {
      sku: newSKU(),
      weight: random(orderConfig.weight.min, droneSpec.maxWeight),
    },
    flightPaths: getPossibleFlightPaths(citySpec, order.destination.location),
  }
}
