import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid'
import { ButtonBlock } from './ButtonBlock'

const ListRow = styled(Row)`
  margin-bottom: ${props => props.theme.spacing.small}px;
`

export const ButtonBlockList = ({ items, active, onClick }) => (
  <ListRow>
    {items.map(({ label, value }) => (
      <Col sm={3} key={value}>
        <ButtonBlock medium active={value === active} onClick={() => onClick(value)}>
          {label}
        </ButtonBlock>
      </Col>
    ))}
  </ListRow>
)
