import React from 'react'
import { Map, mapCoordinate } from '../map'
import { NoFlyZoneArea } from './Markers/NoFlyZoneArea'
import { HubSetupMarker } from './Markers/HubSetupMarker'
import { HubSetupMapLegend } from './HubSetupMapLegend'

export const HubSetupMap = ({ size, center, hubs, noFlyZones, setMaps }) => {
  return (
    <div>
      <Map
        {...size}
        defaultZoom={9}
        center={mapCoordinate(center)}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ maps }) => setMaps(maps)}
      >
        {hubs.map(({ id, center, radius, enabled }) => (
          <HubSetupMarker
            key={id}
            radius={radius}
            center={center}
            enabled={enabled}
            {...mapCoordinate(center)}
          />
        ))}

        {noFlyZones.map(({ name, center, radius }) => (
          <NoFlyZoneArea key={name} center={center} radius={radius} />
        ))}
      </Map>
      <HubSetupMapLegend />
    </div>
  )
}
