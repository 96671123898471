import React from 'react'
import styled from 'styled-components'
import { Clock } from './Clock'
import { Hubs } from './Hubs'
import { useSimulationControls } from '../../containers/simulation/useSimulationControls'

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: ${props => props.theme.spacing.smedium}px;
`

export const Simulation = () => {
  const { shown } = useSimulationControls()
  return !shown ? null : (
    <Controls>
      <Hubs />
      <Clock />
    </Controls>
  )
}
