import React from 'react'
import { useTheme } from 'styled-components'
import { MapCircle, PinMarker } from '../../map'
import { useFlightMapStyles } from './styles'

export const HubMarker = ({ center, radius, active }) => {
  const { hubCircleStyle, hubDisabledCircleStyle } = useFlightMapStyles()
  const theme = useTheme()
  return (
    <>
      <MapCircle
        center={center}
        radius={radius}
        style={active ? hubCircleStyle : hubDisabledCircleStyle}
      />
      <PinMarker color={active ? theme.colors.hubMarker : theme.colors.hubMarkerInactive} />
    </>
  )
}
