import React from 'react'
import styled from 'styled-components'

const Button = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing.small}px;
  margin-bottom: ${props => props.theme.spacing.xsmall}px;
  border: 1px solid ${props => props.theme.colors.light4};
  border-radius: 4px;
`

const Label = styled.div`
  color: ${props => props.theme.colors.foreground};
`

const Icon = styled.i`
  color: ${props => props.theme.colors.dark3};
  margin-top: 2px;
  margin-left: auto;
  flex: 0 0 auto;
`

export const AddAddressButton = ({ children }) => (
  <Button>
    <Label>{children}</Label>
    <Icon className="fas fa-chevron-right" />
  </Button>
)
