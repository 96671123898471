import { specs } from '../../drones/droneSpecs'

const droneSpec = specs[0]

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const operatingHours = days.reduce((acc, day) => {
  acc[`${day}-from`] = '8:00'
  acc[`${day}-to`] = '18:00'
  return acc
}, {})

const citySpec = {
  id: 'london',
  name: 'London',
  center: {
    latitude: 51.5074,
    longitude: -0.1278,
  },
  flightCeiling: 175,
  flightFloor: 150,
  flightSeparation: 18,
  takeoffSeparation: 20, // seconds
  operatingHours,
  weather: {
    temperature: 4, // Celsius
    precipitation: 6, // %
    humidity: 78, // %
    wind: 10, // mph
  },
  droneSpec,
  initialDrones: {
    '1': 50,
    '2': 32,
    '3': 68,
  },
  hubs: {
    '1': {
      id: '1',
      gates: 45,
      streetNumber: '201',
      route: 'Queen Street',
      locality: 'Watford',
      postalCode: 'WD17 2UB',
      formattedAddress: '201 Queen St, Watford, WD17 2UB',
      center: {
        latitude: 51.655835,
        longitude: -0.393235,
      },
      radius: droneSpec.radius,
      area: 73756, // ft2
      active: true,
      droneCounts: {
        active: 50,
        reserve: 6,
      },
    },
    '2': {
      id: '2',
      gates: 50,
      streetNumber: '14',
      route: 'Daniell Way',
      locality: 'Croydon',
      postalCode: 'CR0 4YJ',
      formattedAddress: '14 Daniell Way, Croydon, CR0 4YJ',
      center: {
        latitude: 51.380123,
        longitude: -0.123907,
      },
      radius: droneSpec.radius,
      area: 53819, // ft2
      active: true,
      droneCounts: {
        active: 32,
        reserve: 2,
      },
    },
    '3': {
      id: '3',
      gates: 60,
      streetNumber: '126',
      route: 'South Street',
      locality: 'Romford',
      postalCode: 'RM1 1PL',
      formattedAddress: '126 South St, Romford, RM1 1PL',
      center: {
        latitude: 51.574573,
        longitude: 0.183954,
      },
      radius: droneSpec.radius,
      area: 43229, // ft2
      active: true,
      droneCounts: {
        active: 68,
        reserve: 4,
      },
    },
  },
  noFlyZones: [
    // Tall buildings
    {
      name: 'The Shard',
      center: { latitude: 51.504674, longitude: -0.086533 },
      radius: 150,
    },

    // Airports
    {
      name: 'Gatwick Airport',
      center: { latitude: 51.157507, longitude: -0.160308 },
      radius: 5000,
    },
    {
      name: 'Heathrow Airport',
      center: { latitude: 51.470209, longitude: -0.45465 },
      radius: 5000,
    },
    {
      name: 'London City Airport',
      center: { latitude: 51.504482, longitude: 0.052496 },
      radius: 5000,
    },
    {
      name: 'Luton Airport',
      center: { latitude: 51.876039, longitude: -0.372423 },
      radius: 5000,
    },
    {
      name: 'Stansted Airport',
      center: { latitude: 51.885826, longitude: 0.237868 },
      radius: 5000,
    },
  ],
  droneWarehouses: {
    london: {
      name: 'London',
      drones: 142,
    },
    manchester: {
      name: 'Manchester',
      drones: 86,
    },
    birmingham: {
      name: 'Birmingham',
      drones: 156,
    },
  },
}

export default citySpec
