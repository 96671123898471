import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'styled-bootstrap-grid'
import { Page } from '../../components/app'
import { Pagination, Table, TD, TH, TR } from '../../components/tables'
import { addUser, selectAllUsers } from '../../containers/users/usersReducer'
import { usePagination } from '../../containers/router'
import { Heading } from '../../components/typography'
import { Input } from '../../components/input'
import { UserForm } from './forms/UserForm'

const ContentSection = styled.div`
  margin-bottom: ${props => props.theme.spacing.xlarge}px;
`

export const UsersList = ({ location, history }) => {
  const dispatch = useDispatch()
  const users = useSelector(selectAllUsers())
  const [userSearch, setUserSearch] = useState('')
  const { page, limit } = usePagination(10)
  const filteredUsers = users.filter(
    user => user.name.includes(userSearch) || user.email.includes(userSearch),
  )
  return (
    <Page heading="User Management">
      <Row>
        <Col sm={8}>
          <Table
            head={
              <TR>
                <TH>Employee No.</TH>
                <TH>Name</TH>
                <TH>Email</TH>
                <TH>Phone</TH>
              </TR>
            }
            items={filteredUsers}
            page={page}
            limit={limit}
            emptyMsg="No users"
          >
            {item => (
              <TR key={item.id} onClick={() => history.push(`${location.pathname}/${item.id}`)}>
                <TD>{item.id}</TD>
                <TD>{item.name}</TD>
                <TD>{item.email}</TD>
                <TD>{item.phone}</TD>
              </TR>
            )}
          </Table>
          <Pagination total={filteredUsers.length} limit={limit} />
        </Col>

        <Col sm={4}>
          <ContentSection>
            <Heading>Find a user account</Heading>
            <Input
              placeholder="Search users..."
              value={userSearch}
              onChange={ev => setUserSearch(ev.target.value)}
            />
          </ContentSection>

          <ContentSection>
            <Heading>Add a new user</Heading>
            <UserForm onSubmit={values => dispatch(addUser(values))} />
          </ContentSection>
        </Col>
      </Row>
    </Page>
  )
}
