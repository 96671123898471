import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { PublicOnlyRoute } from '../../containers/router'

import { SignIn } from './SignIn'
import { MultiFactorAuth } from './MultiFactorAuth'

export const Auth = ({ match }) => {
  return (
    <Switch>
      <PublicOnlyRoute exact path={`${match.path}/`} component={SignIn} />
      <Route path={`${match.path}/mfa`} component={MultiFactorAuth} />
      <Redirect to={`${match.path}/`} />
    </Switch>
  )
}
