import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import { Link, NavLink as RRNavLink } from 'react-router-dom'
import { Image } from '../images'
import logo from './logo.svg'
import { useMainNav } from './MainNavProvider'
import { useAuth } from '../../containers/auth'

const HeaderSection = styled.header`
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.45) 0 0 10px;
  background-color: ${props => props.theme.colors.contentBackground};
  margin-bottom: ${props => props.theme.spacing.smedium}px;
`

const HeaderContainer = styled(Container)`
  height: 60px;
  display: flex;
  align-items: center;
`

const NavLogo = styled(Image)`
  margin-right: ${props => props.theme.spacing.medium}px;
  width: 200px;
`

export const navLink = css`
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.medium}px;
  line-height: ${props => props.theme.lineHeight};
  color: ${props => props.theme.colors.foreground};
  margin: 0 ${props => props.theme.spacing.small}px;
  padding: 4px 0;
  border-bottom: 2px solid transparent;
`

export const navLinkActive = css`
  color: ${props => props.theme.colors.primary};
  border-bottom-color: ${props => props.theme.colors.primary};
`

export const NavLink = styled(RRNavLink).attrs({
  activeClassName: 'active',
})`
  ${navLink};
  &.active {
    ${navLinkActive};
  }
`

const SignOutLink = styled.a`
  ${navLink};
  margin-left: auto;
  color: ${props => props.theme.colors.dark4};
  font-size: ${props => props.theme.fontSizes.small}px;
`

export const Header = () => {
  const navItems = useMainNav()
  const { user, signOut } = useAuth()
  return (
    <HeaderSection>
      <HeaderContainer>
        <Link to="/">
          <NavLogo src={logo} alt="Parcel Fly" />
        </Link>

        {navItems.map(({ label, to }) => (
          <NavLink key={to} to={to}>
            {label}
          </NavLink>
        ))}

        {user && <SignOutLink onClick={signOut}>Sign out</SignOutLink>}
      </HeaderContainer>
    </HeaderSection>
  )
}
