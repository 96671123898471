import React, { useContext, useState } from 'react'

const SimulationContext = React.createContext({})

export const SimulationControlsProvider = ({ children }) => {
  const [shown, showSimulationControls] = useState(false)
  return (
    <SimulationContext.Provider value={{ shown, showSimulationControls }}>
      {children}
    </SimulationContext.Provider>
  )
}

export const useSimulationControls = () => useContext(SimulationContext)
