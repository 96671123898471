import React from 'react'
import styled from 'styled-components'

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0007;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Modal = styled.div`
  background-color: ${props => props.theme.colors.background};
  margin: 0 ${props => props.theme.spacing.smedium}px;
  padding: ${props => props.theme.spacing.small}px;
  box-shadow: #0006 2px 2px 12px;
  overflow-y: auto;
  max-width: 600px;
`

export const MobileModal = ({ show, children }) =>
  !show ? null : (
    <Backdrop>
      <Modal>{children}</Modal>
    </Backdrop>
  )
