import React, { useState } from 'react'
import styled from 'styled-components'
import { Heading } from '../../components/typography'
import { PageContent } from '../../components/app'
import { googleMapsJs } from '../../services/googleMapsJs'
import { Overlay } from './components/layout/Overlay'
import { BgImgPage } from './components/layout/BgImgPage'
import { MobilePrimaryButton } from './components/controls/MobileButton'
import { PickLocationMap } from './components/map/PickLocationMap'
import { useGeoPosition } from './containers/useGeoPosition'
import { useAirDeliverySpots } from './containers/AirDeliverySpots'

const Header = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.small}px;
  box-shadow: ${props => props.theme.colors.light4} 0 1px 4px;
`

const Icon = styled.i`
  flex: 0 0 ${props => props.theme.spacing.smedium}px;
  height: ${props => props.theme.fontSizes.large}px;
  font-size: ${props => props.theme.fontSizes.large}px;
  color: ${props => props.theme.colors.dark3};
  margin-top: 2px;
`

const HeaderHead = styled(Heading)`
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.large * 1.05}px;
  margin-bottom: 0;
`

const P = styled.p`
  color: ${props => props.theme.colors.dark3};
  margin-bottom: ${props => props.theme.spacing.smedium}px;
`

const MapContainer = styled.div`
  width: 100%;
  height: 360px;
  position: relative;
  margin-bottom: ${props => props.theme.spacing.medium}px;
`

export const MobilePickLocation = ({ settings, history }) => {
  const { coords, accuracyMultiplier } = useGeoPosition()
  const { addDeliverySpot } = useAirDeliverySpots()

  const [maps, setMaps] = useState(null)

  const onConfirm = async () => {
    googleMapsJs
      .reverseGeocode(maps, coords)
      .then(geoAddress => {
        addDeliverySpot({
          name: 'Delivery spot',
          latitude: coords.latitude,
          longitude: coords.longitude,
          formattedAddress: geoAddress.formattedAddress.replace(/^\d+ ?/i, ''),
        })
        history.replace({ pathname: settings, state: { success: true } })
      })
      .catch(error => window.alert(error.message))
  }

  return (
    <BgImgPage>
      <Overlay maxHeight="95vh">
        <Header onClick={history.goBack}>
          <Icon className="fas fa-chevron-left" />
          <HeaderHead>Add an Air Delivery Spot</HeaderHead>
        </Header>
        <Overlay.Content>
          <PageContent>
            <P>
              Proceed with your phone to the exact spot for delivery. When standing still for 3
              second, you will be able to confirm your delivery location.
            </P>

            <MapContainer>
              <PickLocationMap
                coords={coords}
                accuracyMultiplier={accuracyMultiplier}
                setMaps={setMaps}
              />
            </MapContainer>

            <MobilePrimaryButton onClick={onConfirm} disabled={accuracyMultiplier > 0.3}>
              Confirm Delivery Spot
            </MobilePrimaryButton>
          </PageContent>
        </Overlay.Content>
      </Overlay>
    </BgImgPage>
  )
}
