import React, { useState } from 'react'
import styled from 'styled-components'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'styled-bootstrap-grid'
import { Page } from '../../components/app'
import { Table, TD, TH, TR } from '../../components/tables'
import { Button } from '../../components/controls'
import { Heading } from '../../components/typography'
import { FormattedCoordinate } from '../../components/formatters'
import {
  addCityNoFlyZone,
  saveCitySetup,
  selectCitySpec,
} from '../../containers/simulation/cities/citiesReducer'
import { FlightLevelsForm, pickFlightLevels } from './forms/FlightLevelsForm'
import { OperatingHoursForm } from './forms/OperatingHoursForm'
import { CityNoFlyZoneForm } from './forms/CityNoFlyZoneForm'
import { DroneTypeForm } from './forms/DroneTypeForm'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

const FlexHeading = styled(Heading)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ContentSection = styled.div`
  margin-bottom: ${props => props.theme.spacing.xlarge}px;
  display: flex;
  flex-direction: column;
`

export const CitySetup = ({ match }) => {
  const location = getCitySlug(match)
  const dispatch = useDispatch()
  const citySpec = useSelector(selectCitySpec(location))
  const [addForm, setAddForm] = useState(!citySpec.noFlyZones.length)
  return (
    <Page heading={`${citySpec.name} - City Setup`}>
      <Form
        subscription={{ submitting: true }}
        onSubmit={values => dispatch(saveCitySetup(citySpec.id, values))}
        initialValues={{
          flightLevels: pickFlightLevels(citySpec),
          droneSpec: citySpec.droneSpec.id,
          operatingHours: citySpec.operatingHours,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col sm={3}>
                <ContentSection>
                  <Heading>Operational Hours</Heading>
                  <OperatingHoursForm />
                </ContentSection>
              </Col>
              <Col sm={3} smOffset={1}>
                <ContentSection>
                  <Heading>Drone Type</Heading>
                  <DroneTypeForm />
                </ContentSection>
              </Col>
              <Col sm={3} smOffset={1}>
                <ContentSection style={{ height: '100%' }}>
                  <Heading>Flight Levels</Heading>
                  <FlightLevelsForm />
                  <Button
                    block={false}
                    type="submit"
                    align="flex-end"
                    style={{ marginTop: 'auto' }}
                    disabled={submitting}
                  >
                    {submitting ? 'Submitting...' : 'Save Configuration'}
                  </Button>
                </ContentSection>
              </Col>
            </Row>
          </form>
        )}
      </Form>

      <br />
      <br />

      <Row>
        <Col sm={8}>
          <FlexHeading>
            No Fly Zones
            <Button small block={false} align="flex-end" onClick={() => setAddForm(!addForm)}>
              {addForm ? 'Close form' : 'Add a zone'}
            </Button>
          </FlexHeading>

          <Form
            subscription={{}}
            onSubmit={values => dispatch(addCityNoFlyZone(citySpec.id, values))}
          >
            {({ handleSubmit, form }) => (
              <form
                onSubmit={async event => {
                  await handleSubmit(event)
                  form.getState().valid && form.reset()
                }}
              >
                <Table
                  head={
                    <TR>
                      <TH width="35%">Zone description</TH>
                      <TH width="35%">Center</TH>
                      <TH width="30%">Radius</TH>
                    </TR>
                  }
                  items={citySpec.noFlyZones}
                  emptyMsg="No zones specified"
                  firstRow={addForm && <CityNoFlyZoneForm citySpec={citySpec} />}
                >
                  {(item, idx) => (
                    <TR key={item.id ?? idx}>
                      <TD>{item.name}</TD>
                      <TD>
                        <FormattedCoordinate value={item.center} />
                      </TD>
                      <TD>
                        {item.radius < 1000
                          ? `${item.radius} m`
                          : `${(item.radius / 1000).toFixed(0)} km`}
                      </TD>
                    </TR>
                  )}
                </Table>
              </form>
            )}
          </Form>
        </Col>
      </Row>
    </Page>
  )
}
