import React from 'react'
import styled from 'styled-components'
import { MobileButton } from '../controls/MobileButton'

const Block = styled.div`
  line-height: 1.45;
  padding: ${props => props.theme.spacing.xsmall}px;
  margin-bottom: ${props => props.theme.spacing.xsmall}px;
  border: 1px solid ${props => props.theme.colors.light4};
  border-radius: 4px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.theme.spacing.small}px;
`

export const AddressBlock = ({ edit, remove, children }) => (
  <Block>
    {children}
    <Buttons>
      {edit && <MobileButton>Edit</MobileButton>}
      <MobileButton onClick={remove}>Remove</MobileButton>
    </Buttons>
  </Block>
)
