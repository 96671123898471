import { specs } from '../../drones/droneSpecs'

const droneSpec = specs[2]

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const operatingHours = days.reduce((acc, day) => {
  acc[`${day}-from`] = '8:00'
  acc[`${day}-to`] = '18:00'
  return acc
}, {})

const citySpec = {
  id: 'losAngeles',
  name: 'Los Angeles',
  center: {
    latitude: 34.0522,
    longitude: -118.2437,
  },
  flightCeiling: 200,
  flightFloor: 150,
  flightSeparation: 20,
  takeoffSeparation: 20, // seconds
  operatingHours,
  weather: {
    temperature: 27, // Celsius
    precipitation: 0, // %
    humidity: 24, // %
    wind: 9, // mph
  },
  droneSpec,
  initialDrones: {
    '1': 39,
    '2': 41,
    '3': 46,
  },
  hubs: {
    '1': {
      id: '1',
      gates: 45,
      streetNumber: '5301',
      route: 'Bolsa Ave',
      locality: 'Huntington Beach',
      postalCode: '92647',
      formattedAddress: '5301 Bolsa Ave, Huntington Beach, 92647',
      center: {
        latitude: 33.747984,
        longitude: -118.039968,
      },
      radius: droneSpec.radius,
      area: 52784, // ft2
      active: true,
      droneCounts: {
        active: 39,
        reserve: 7,
      },
    },
    '2': {
      id: '2',
      gates: 52,
      streetNumber: '1250',
      route: 'E Walnut St',
      locality: 'Pasadena',
      postalCode: '91106',
      formattedAddress: '1250 E Walnut St, Pasadena, 91106',
      center: {
        latitude: 34.150005,
        longitude: -118.124883,
      },
      radius: droneSpec.radius,
      area: 64892, // ft2
      active: true,
      droneCounts: {
        active: 41,
        reserve: 2,
      },
    },
    '3': {
      id: '3',
      gates: 48,
      streetNumber: '8701',
      route: 'Canoga Ave',
      locality: 'Canoga Park',
      postalCode: '91304',
      formattedAddress: '8701 Canoga Ave, Canoga Park, 91304',
      center: {
        latitude: 34.228379,
        longitude: -118.597894,
      },
      radius: droneSpec.radius,
      area: 53822, // ft2
      active: true,
      droneCounts: {
        active: 46,
        reserve: 4,
      },
    },
  },
  noFlyZones: [
    // Airports
    {
      name: 'Los Angeles International Airport',
      center: { latitude: 33.940799, longitude: -118.408307 },
      radius: 5000,
    },
    {
      name: 'Hollywood Burbank Airport',
      center: { latitude: 34.19819, longitude: -118.357787 },
      radius: 5000,
    },
    {
      name: 'Santa Monica Airport',
      center: { latitude: 34.017428, longitude: -118.447526 },
      radius: 5000,
    },
    {
      name: 'Hawthorne Municipal Airport',
      center: { latitude: 33.922397, longitude: -118.333735 },
      radius: 2800,
    },
    {
      name: 'Long Beach Airport',
      center: { latitude: 33.817312, longitude: -118.152065 },
      radius: 5000,
    },
    {
      name: 'Compton/Woodley Airport',
      center: { latitude: 33.889463, longitude: -118.243702 },
      radius: 3000,
    },
    {
      name: 'San Gabriel Airport',
      center: { latitude: 34.08477, longitude: -118.033536 },
      radius: 5000,
    },
    // Forests
    // {
    //   name: 'Angeles National Forest 1',
    //   center: { latitude: 34.363298, longitude: -118.222043 },
    //   radius: 14000,
    // },
    // {
    //   name: 'Angeles National Forest 2',
    //   center: { latitude: 34.308468, longitude: -117.955862 },
    //   radius: 17000,
    // },
  ],
  droneWarehouses: {
    pasadena: {
      name: 'Pasadena',
      drones: 92,
    },
    westCovina: {
      name: 'West Covina',
      drones: 86,
    },
    santaAna: {
      name: 'Santa Ana',
      drones: 136,
    },
  },
}

export default citySpec
