import { set } from 'date-fns'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { EventTarget } from './EventTarget'
import { timeConfig } from '../config'

const tickInterval = 500

const TimeContext = React.createContext({})
const timeEventEmitter = new EventTarget()

const useTimeState = () => {
  const [multiplier, setMultiplier] = useState(timeConfig.initialMultiplier)
  const time = useRef(
    set(Date.now(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }).getTime(),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      time.current += tickInterval * multiplier
      timeEventEmitter.dispatchEvent(
        new CustomEvent('tick', {
          detail: {
            time: time.current,
            seconds: (tickInterval * multiplier) / 1000,
          },
        }),
      )
    }, tickInterval)

    return () => {
      clearInterval(interval)
    }
  }, [multiplier, time])

  const getTime = useCallback(() => time.current, [time])
  const speedUp = useCallback(() => setMultiplier(multiplier + 2), [multiplier, setMultiplier])
  const slowDown = useCallback(() => setMultiplier(Math.max(1, multiplier - 2)), [
    multiplier,
    setMultiplier,
  ])

  return {
    getTime,
    speedUp,
    slowDown,
    multiplier,
    setMultiplier,
    timeEventEmitter,
  }
}

export const useTime = () => useContext(TimeContext)

export const TimeProvider = ({ children }) => {
  const state = useTimeState()
  return <TimeContext.Provider value={state}>{children}</TimeContext.Provider>
}
