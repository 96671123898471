import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Page } from '../../components/app'
import { FlightMap } from '../../components/flightMap/FlightMap'
import { WeatherInfo, OrdersInfo, CityInfo, DroneInfo } from '../../components/map-info'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'
import { linkToFlight } from './links/atcFlight'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

const mapSize = { width: 850, height: 600 }

const Content = styled.div`
  display: flex;
`

const InfoCol = styled.div`
  flex-grow: 1;
  margin-left: ${props => props.theme.spacing.medium}px;
`

export const AirTrafficControl = ({ match, location, history }) => {
  const city = getCitySlug(match)
  const citySpec = useSelector(selectCitySpec(city))
  const [chosenFlight, setChosenFlight] = useState(location.state?.chosenFlight)
  useEffect(() => {
    if (location.state?.chosenFlight) {
      history.replace({ state: {} })
    }
  }, [history, location.state])
  return (
    <Page heading={`${citySpec.name} - Air Traffic Control`}>
      <Content>
        <FlightMap
          city={city}
          size={mapSize}
          center={citySpec.center}
          hubs={Object.values(citySpec.hubs)}
          noFlyZones={citySpec.noFlyZones}
          chosenFlight={chosenFlight}
          onChooseFlight={flight => setChosenFlight(linkToFlight(flight).chosenFlight)}
        />

        {chosenFlight ? (
          <InfoCol>
            <DroneInfo {...chosenFlight} city={city} onClose={() => setChosenFlight(null)} />
          </InfoCol>
        ) : (
          <InfoCol>
            <OrdersInfo city={city} />
            <WeatherInfo city={city} />
            <CityInfo city={city} />
          </InfoCol>
        )}
      </Content>
    </Page>
  )
}
