import { camelCase, keyBy, random } from 'lodash'

export const continents = [
  {
    name: 'Africa, Middle East, and India',
    countries: [
      {
        name: 'India',
        cities: ['Mumbai', 'New Delhi', 'Kolkata'],
      },
      {
        name: 'South Africa',
        cities: ['Cape Town', 'Johannesburg', 'Pretoria'],
      },
    ],
  },
  {
    name: 'Asia Pacific',
    countries: [
      {
        name: 'Japan',
        cities: ['Tokyo'],
      },
    ],
  },
  {
    name: 'Europe',
    defaultCountry: 0, // UK
    countries: [
      {
        name: 'United Kingdom',
        cities: ['Liverpool', 'London', 'Manchester'],
      },
      {
        name: 'France',
        cities: ['Paris'],
      },
      {
        name: 'Germany',
        cities: ['Berlin', 'Frankfurt', 'Munich'],
      },
      {
        name: 'Ireland',
        cities: ['Dublin'],
      },
    ],
  },
  {
    name: 'Latin America and the Caribbean',
    countries: [
      {
        name: 'Brazil',
        cities: ['Rio de Janeiro'],
      },
    ],
  },
  {
    name: 'The United States, Canada, and Puerto Rico',
    defaultCountry: 1, // US
    countries: [
      {
        name: 'Canada',
        cities: ['Calgary', 'Edmonton', 'Montreal', 'Ottawa', 'Quebec', 'Toronto', 'Vancouver'],
      },
      {
        name: 'United States',
        cities: ['Chicago', 'Dallas', 'Los Angeles', 'New York', 'San Diego'],
      },
    ],
  },
]

const hubsPresets = {
  Manchester: { '1': { id: '1' }, '2': { id: '2' } },
  Liverpool: { '1': { id: '1' }, '2': { id: '2' }, '3': { id: '3' } },
  Dublin: { '1': { id: '1' } },
}

export const byId = keyBy(
  continents.flatMap(continent =>
    continent.countries.flatMap(country =>
      country.cities.map(name => ({
        id: camelCase(name),
        name,
        hubs:
          hubsPresets[name] ??
          Array(random(1, 4))
            .fill(0)
            .reduce((acc, _, id) => {
              acc[id + 1] = { id: (id + 1).toString() }
              return acc
            }, {}),
      })),
    ),
  ),
  'id',
)
