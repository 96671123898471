import React from 'react'
import styled from 'styled-components'
import { PageContainer, PageContent } from '../../../../components/app'

import bgImg from './amazon-screen1.png'

const Background = styled.div`
  background: #ccc;
  width: 100%;
  height: 100%;
`

const MobilePageContainer = styled(PageContainer)`
  max-width: 414px;
  margin: 0 auto;
`

const BgImg = styled(PageContent)`
  background-image: linear-gradient(#0007, #0007), url(${bgImg});
  background-size: contain;
  background-repeat: no-repeat;
`

export const BgImgPage = ({ children }) => (
  <Background>
    <MobilePageContainer>
      <BgImg>{children}</BgImg>
    </MobilePageContainer>
  </Background>
)
