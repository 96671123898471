import styled, { css } from 'styled-components'
import { Button } from '../controls'

export const smallBlock = css`
  min-height: auto;
  background: ${props => props.theme.colors.dark4};
  padding-top: ${props => props.theme.spacing.small}px;
  padding-bottom: ${props => props.theme.spacing.small}px;
  margin-bottom: ${props => props.theme.spacing.small}px;
`

export const mediumBlock = css`
  min-height: auto;
  padding-top: ${props => props.theme.spacing.medium}px;
  padding-bottom: ${props => props.theme.spacing.medium}px;
`

export const activeBlock = css`
  background: ${props => props.theme.colors.dark1};
`

export const ButtonBlock = styled(Button)`
  font-size: ${props => props.theme.fontSizes.xlarge}px;
  font-weight: 600;
  white-space: normal;
  border-radius: 4px;
  min-height: 160px;
  max-height: none;

  ${props => props.small && smallBlock};
  ${props => props.medium && mediumBlock};
  ${props => props.active && activeBlock};
`
