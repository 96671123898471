import React from 'react'
import { useTheme } from 'styled-components'
import { PinMarkerSvg } from '../map/MapMarker/PinMarker'
import { useFlightMapStyles } from './Markers/styles'
import { Circle, Icon, Item, Legend } from './Legend'
import { DroneMarkerSvg } from './Markers/DroneMarker'

export const FlightMapLegend = ({ drone = true }) => {
  const theme = useTheme()
  const { hubCircleStyle, noFlyZonesStyle } = useFlightMapStyles()
  return (
    <Legend>
      {drone && (
        <Item>
          <Icon style={{ paddingTop: '3px' }}>
            <DroneMarkerSvg color={theme.colors.droneMarker} />
          </Icon>
          Drone
        </Item>
      )}
      <Item>
        <Icon>
          <PinMarkerSvg color={theme.colors.destinationMarker} />
        </Icon>
        Destination
      </Item>
      <Item>
        <Icon>
          <PinMarkerSvg color={theme.colors.hubMarker} />
        </Icon>
        Hub
      </Item>
      <Item>
        <Circle {...hubCircleStyle} />
        Delivery Radius
      </Item>
      <Item>
        <Circle {...noFlyZonesStyle} />
        No-fly Zone
      </Item>
    </Legend>
  )
}
