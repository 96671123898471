import React from 'react'
import styled from 'styled-components'
import { Field } from 'react-final-form'
import { Input } from '../../../components/input'

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.small}px;
`

const Label = styled.div`
  flex: 0 0 60px;
`

const Dash = styled.div`
  color: ${props => props.theme.colors.dark3};
  margin-right: ${props => props.theme.spacing.small}px;
`

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export const operatingHoursInit = days.reduce((acc, day) => {
  acc[`operatingHours.${day}-from`] = '8:00'
  acc[`operatingHours.${day}-to`] = '18:00'
  return acc
}, {})

export const OperatingHoursForm = () => {
  return (
    <>
      {days.map(day => (
        <Row key={day}>
          <Label>{day}</Label>
          <Field name={`operatingHours.${day}-from`} component={Input} inline />
          <Dash>-</Dash>
          <Field name={`operatingHours.${day}-to`} component={Input} inline />
        </Row>
      ))}
    </>
  )
}
