import { useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useTime } from '../time/useTime'
import { selectDroneSchedulesByCity } from './scheduleSelectors'
import { updateFlightProgress } from './updateFlightProgress'
import { flightUpdate } from './scheduleReducer'
import { selectCitySpec, selectIsCityInitialized } from '../cities/citiesReducer'

export const useFlightManagement = cityId => {
  const initialized = useSelector(selectIsCityInitialized(cityId))
  const { timeEventEmitter } = useTime()
  const store = useStore()

  useEffect(() => {
    const onTimeChange = ev => {
      if (!initialized) return

      const droneSchedules = selectDroneSchedulesByCity(cityId)(store.getState())
      const { droneSpec } = selectCitySpec(cityId)(store.getState())
      const updatedActiveFlight = updateFlightProgress(ev.detail.time, ev.detail.seconds, droneSpec)

      const completedFlights = []
      const schedules = droneSchedules.map(schedule => {
        if (schedule.activeFlight) {
          if (schedule.activeFlight.flightPath.status === 'completed') {
            completedFlights.push(schedule.activeFlight)
            return { ...schedule, activeFlight: null }
          }
          return { ...schedule, activeFlight: updatedActiveFlight(schedule.activeFlight) }
        }
        return {
          ...schedule,
          activeFlight: schedule.queuedFlights[0] ?? null,
          queuedFlights: schedule.queuedFlights.slice(1),
        }
      })

      store.dispatch(flightUpdate(cityId, schedules, completedFlights))
    }

    timeEventEmitter.addEventListener('tick', onTimeChange)
    return () => timeEventEmitter.removeEventListener('tick', onTimeChange)
  }, [timeEventEmitter, store, cityId, initialized])
}
