import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Heading } from '../../components/typography'
import { DD, DL, DT } from '../../components/tables'
import { FormattedCoordinate, FormattedMinutes, FormattedWeight } from '../formatters'
import { selectFlightByDroneId } from '../../containers/simulation/schedule/scheduleSelectors'

export const Icon = styled.i`
  flex: 0 0 ${props => props.theme.spacing.smedium}px;
  height: ${props => props.theme.fontSizes.large}px;
  font-size: ${props => props.theme.fontSizes.large}px;
  color: ${props => props.theme.colors.text};
  margin-right: 4px;
  cursor: pointer;
`

const compassDirections = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']
export const compassDirection = degrees =>
  compassDirections[Math.floor(((degrees + 22.5) % 360) / 45)]

export const DroneInfo = ({ city, hubId, droneId, onClose }) => {
  const { flight, order } = useSelector(selectFlightByDroneId(city, hubId, droneId))
  useEffect(() => {
    if (!flight) {
      onClose()
    }
  })
  if (!flight) {
    return null
  }
  const { flightPath } = flight
  const { destination, payload } = order
  return (
    <>
      <Heading margin="xsmall" onClick={onClose}>
        <Icon className="fas fa-arrow-left" /> Drone {droneId}
      </Heading>
      <DL isHorizontal dtPercentage={50}>
        <DT>Altitude</DT>
        <DD>{flightPath.position.altitude} m</DD>
        <DT>Ground speed</DT>
        <DD>{(flightPath.speed * 2.237).toFixed(1)} mph</DD>
        <DT>Throttle</DT>
        <DD>{flightPath.speed ? flightPath.throttle : 0}%</DD>
        <DT>Battery</DT>
        <DD>{flightPath.battery.toFixed(1)}%</DD>
      </DL>

      <Heading margin="xsmall">GPS</Heading>
      <DL isHorizontal dtPercentage={50}>
        <DT>
          Position
          <br />
          &nbsp;
        </DT>
        <DD>
          <FormattedCoordinate value={flightPath.position} sexagesimal />
        </DD>
        <DT>Bearing</DT>
        <DD>
          {flightPath.bearing.toFixed(0)}&deg; {compassDirection(flightPath.bearing)}
        </DD>
      </DL>

      <Heading margin="xsmall">Order</Heading>
      <DL isHorizontal dtPercentage={50}>
        <DT style={{ flexBasis: '100%' }}>
          {destination.streetNumber} {destination.route}
          <br />
          {destination.suburb}
          <br />
          {destination.postalCode}
        </DT>
        <DT>Order No</DT>
        <DD>{order.id}</DD>
        <DT>Payload</DT>
        <DD>
          <FormattedWeight value={payload.weight} />
        </DD>
        <DT>Time to destination</DT>
        <DD>
          <FormattedMinutes value={flight.flightPath.remainingDuration} seconds />
        </DD>
      </DL>
    </>
  )
}
