import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

const Container = styled.div`
  position: absolute;
  margin-left: 5px;
  padding-top: 5px;
`

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  opacity: 0.75;
  top: 0;
  border-width: 0 5px 5px;
  border-color: transparent transparent ${props => darken(0.1, props.theme.colors[props.bgColor])}
    transparent;
`

const Body = styled.div`
  background: ${props => darken(0.1, props.theme.colors[props.bgColor])};
  color: ${props => props.theme.colors.background};
  padding: ${props => props.theme.spacing.xxsmall}px;
  text-align: center;
  opacity: 0.75;
`

export const Tooltip = ({ bgColor, children }) => (
  <Container>
    <Arrow bgColor={bgColor} />
    <Body bgColor={bgColor}>{children}</Body>
  </Container>
)
