import { useContext, useEffect, useRef } from 'react'
import { MapContext } from '../MapContext'
import { mapCoordinate } from '../Map'

const defaultStyle = {
  strokeColor: 'black',
  strokeOpacity: 1,
  strokeWeight: 2,
}

const defaultIcons = () => undefined

export const MapPolyline = ({ path = [], geodesic = false, icons = defaultIcons, style }) => {
  const { maps, map } = useContext(MapContext)
  const polyline = useRef()

  useEffect(() => {
    if (map) {
      if (!polyline.current) {
        polyline.current = new maps.Polyline({
          ...defaultStyle,
          ...style,
          path: path.map(mapCoordinate),
          icons: icons(maps),
          geodesic,
          map,
        })
      } else {
        polyline.current.setOptions({
          ...defaultStyle,
          ...style,
          path: path.map(mapCoordinate),
          icons: icons(maps),
          geodesic,
          map,
        })
      }
    }
    return () => polyline.current && polyline.current.setMap(null)
  }, [path, geodesic, icons, style, map, maps])

  return null
}
