import React from 'react'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { configureStore } from './services/redux/store'
import { ThemeProvider } from './theme/ThemeProvider'
import { TimeProvider } from './containers/simulation/time/useTime'
import { AuthProvider } from './containers/auth'
import { Routes } from './pages/Routes'
import { SimulationControlsProvider } from './containers/simulation/useSimulationControls'

const store = configureStore()

const locale = process.env.NODE_ENV === 'development' ? 'en-GB' : 'en-US'

const App = () => {
  return (
    <IntlProvider locale={locale}>
      <BrowserRouter>
        <ThemeProvider>
          <AuthProvider>
            <TimeProvider>
              <SimulationControlsProvider>
                <ReduxProvider store={store}>
                  <Routes />
                </ReduxProvider>
              </SimulationControlsProvider>
            </TimeProvider>
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </IntlProvider>
  )
}

export default App
