import faker from 'faker'
import { lowerCase, random, times } from 'lodash'

const newUserId = () => 'PF' + random(15000, 99999)
const newRoles = () => [
  // {
  //   cityName: 'London',
  //   role: 'cityManager',
  //   hubs: { '1': false, '2': false, '3': true },
  // },
  {
    cityName: 'Manchester',
    role: 'flightController',
    hubs: { '1': true, '2': true },
  },
  {
    cityName: 'Dublin',
    role: 'flightController',
    hubs: { '1': true },
  },
]

export const initialUsers = [
  ...times(70, () => {
    const name = faker.name.findName()
    const email = `${name
      .split(' ')
      .map(lowerCase)
      .join('.')}@parcelfly.com`
    return {
      id: newUserId(),
      name,
      username: email,
      phone: faker.phone.phoneNumberFormat(0),
      email,
      roles: newRoles(),
    }
  }),
  {
    id: 'PF73844',
    name: 'Martin Warner',
    username: 'martin',
    phone: '808-555-5233',
    email: 'martin.warner@parcelfly.com',
    roles: [
      {
        cityName: 'London',
        role: 'cityManager',
        hubs: { '1': false, '2': true, '3': true },
      },
      {
        cityName: 'Paris',
        role: 'flightController',
        hubs: { '1': true, '2': true },
      },
      {
        cityName: 'Los Angeles',
        role: 'flightController',
        hubs: { '1': true, '2': false, '3': false },
      },
      {
        cityName: 'Dublin',
        role: 'flightController',
        hubs: { '1': true },
      },
    ],
  },
  {
    id: 'PF24378',
    name: 'Marnus Weststrate',
    username: 'marnusw',
    phone: '808-555-3829',
    email: 'marnusw@parcelfly.com',
    roles: [
      {
        cityName: 'London',
        role: 'cityManager',
        hubs: { '1': false, '2': true, '3': true },
      },
      {
        cityName: 'Paris',
        role: 'flightController',
        hubs: { '1': true, '2': true },
      },
      {
        cityName: 'Liverpool',
        role: 'flightController',
        hubs: { '1': true, '2': false, '3': false },
      },
      {
        cityName: 'Dublin',
        role: 'flightController',
        hubs: { '1': true },
      },
    ],
  },
]
