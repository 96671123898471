import styled from 'styled-components'
import { darken, transparentize } from 'polished'

export const MobileButton = styled.button`
  min-width: 110px;
  text-align: center;
  color: ${props => props.theme.colors.foreground};
  font-size: ${props => props.theme.fontSizes.xsmall}px;
  padding: ${props => props.theme.spacing.xsmall}px;
  background-image: linear-gradient(
    to bottom,
    ${props => props.theme.colors.light2},
    ${props => props.theme.colors.light3}
  );
  border: 1px solid ${props => props.theme.colors.dark5};
  border-radius: 4px;
`

export const MobilePrimaryButton = styled(MobileButton)`
  width: 100%;
  font-size: ${props => props.theme.fontSizes.medium}px;
  padding: ${props => props.theme.spacing.small}px;
  background-image: linear-gradient(
    to bottom,
    ${props => props.theme.colors.primary},
    ${props => darken(0.07, props.theme.colors.primary)}
  );

  :disabled {
    color: ${props => props.theme.colors.dark1};
    background-image: linear-gradient(
      to bottom,
      ${props => transparentize(0.3, props.theme.colors.primary)},
      ${props => transparentize(0.3, darken(0.07, props.theme.colors.primary))}
    );
  }
`
