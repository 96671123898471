import React from 'react'
import styled from 'styled-components'

const ExportText = styled.div`
  position: absolute;
  top: ${props => props.theme.fontSizes.xsmall}px;
  right: 15px;
  color: ${props => props.theme.colors.dark3};
  font-size: ${props => props.theme.fontSizes.medium}px;
  cursor: pointer;
`

export const ExportReportLink = () => (
  <ExportText>
    export report <i className="fas fa-file-download" />
  </ExportText>
)
