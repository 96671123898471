import styled from 'styled-components'

export const MapMarker = styled.div`
  position: absolute;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  left: -${props => props.width / 2}px;
  top: -${props => props.height / 2}px;
  overflow: visible;
  cursor: pointer;
`
