import { random } from 'lodash'
import { subDays } from 'date-fns/fp'

export const retiredDrones = [
  {
    id: 'DR04893',
    date: subDays(random(6, 60), new Date()),
    reason: 'Cracked frame',
  },
  {
    id: 'DR05093',
    date: subDays(random(6, 60), new Date()),
    reason: 'Broken arm',
  },
  {
    id: 'DR05022',
    date: subDays(random(6, 60), new Date()),
    reason: 'Crashed due to battery failure',
  },
]
