import React from 'react'
import { useHistory } from 'react-router-dom'
import { Field, Form } from 'react-final-form'
import { Button, ButtonLink } from '../../components/controls'
import { FormError, Input, required } from '../../components/input'
import { useAuth } from '../../containers/auth'
import { AuthPage } from './components/AuthPage'

export const SignIn = () => {
  const { signIn } = useAuth()
  const history = useHistory()
  const signInMFA = credentials =>
    signIn(credentials).then(errors => {
      if (!errors) {
        history.replace('/sign-in/mfa')
      }
      return errors
    })
  return (
    <AuthPage>
      <Form onSubmit={signInMFA}>
        {({ handleSubmit, submitting }) => (
          <form>
            <Field name="username" label="Employee No" validate={required} component={Input} />
            <Field
              name="password"
              label="Password"
              type="password"
              validate={required}
              component={Input}
            />
            <FormError />
            <Button type="submit" onClick={handleSubmit} disabled={submitting}>
              Sign in
            </Button>
            <ButtonLink disabled={submitting}>Reset Password</ButtonLink>
          </form>
        )}
      </Form>
    </AuthPage>
  )
}
