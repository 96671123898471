import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { Tooltip } from './Tooltip/Tooltip'
import { MapMarker } from '../../map'
import { Svg } from '../../images'

export const DroneMarker = ({ size = 20, droneId, isChosen, ...props }) => {
  const theme = useTheme()
  const [hover, setHover] = useState(false)
  return (
    <MapMarker
      {...props}
      height={size}
      width={size}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <DroneMarkerSvg color={theme.colors.droneMarker} size={size} />
      {(isChosen || hover) && <Tooltip bgColor="droneMarker">{droneId}</Tooltip>}
    </MapMarker>
  )
}

export const DroneMarkerSvg = ({ size, color }) => (
  <Svg viewBox="0 0 352 512" height={size} width={size}>
    <path
      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
      fill={color}
    />
  </Svg>
)
