import styled from 'styled-components'
import { transparentize } from 'polished'

export const Legend = styled.div`
  display: flex;
  padding-top: ${props => props.theme.spacing.xsmall}px;
  padding-left: ${props => props.theme.spacing.xxsmall}px;
`

export const Item = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-right: ${props => props.theme.spacing.smedium}px;
`

export const Icon = styled.div`
  width: 12px;
  padding-top: 2px;
  margin-right: ${props => props.theme.spacing.xsmall}px;
`

export const Circle = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 6px;
  border: 2px solid ${props => props.strokeColor};
  background: ${props =>
    props.fillColor && transparentize(1 - (props.fillOpacity || 0), props.fillColor)};
  margin-right: ${props => props.theme.spacing.xsmall}px;
`
