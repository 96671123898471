import { specs } from '../../drones/droneSpecs'

const droneSpec = specs[1]

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const operatingHours = days.reduce((acc, day) => {
  acc[`${day}-from`] = '8:00'
  acc[`${day}-to`] = '17:00'
  return acc
}, {})

const citySpec = {
  id: 'paris',
  name: 'Paris',
  center: {
    latitude: 48.8566,
    longitude: 2.3522,
  },
  flightCeiling: 185,
  flightFloor: 160,
  flightSeparation: 16,
  takeoffSeparation: 22, // seconds
  operatingHours,
  weather: {
    temperature: 12, // Celsius
    precipitation: 17, // %
    humidity: 52, // %
    wind: 8, // mph
  },
  droneSpec,
  initialDrones: {
    '1': 49,
    '2': 46,
  },
  hubs: {
    '1': {
      id: '1',
      gates: 43,
      streetNumber: '6',
      route: 'Rue Paul Eluard',
      locality: 'Saint-Denis',
      postalCode: '93200',
      formattedAddress: '6 Rue Paul Eluard, Saint-Denis, 93200',
      center: {
        latitude: 48.938997,
        longitude: 2.348001,
      },
      radius: droneSpec.radius,
      area: 62723, // ft2
      active: true,
      droneCounts: {
        active: 49,
        reserve: 8,
      },
    },
    '2': {
      id: '2',
      gates: 39,
      streetNumber: '2',
      route: 'Rue Jean Cocteau',
      locality: 'Corbeil-Essonnes',
      postalCode: '91100',
      formattedAddress: '2 Rue Jean Cocteau, Corbeil-Essonnes, 91100',
      center: {
        latitude: 48.608259,
        longitude: 2.448042,
      },
      radius: droneSpec.radius,
      area: 57388, // ft2
      active: true,
      droneCounts: {
        active: 46,
        reserve: 9,
      },
    },
  },
  noFlyZones: [
    // General areas
    {
      name: 'City center',
      center: { latitude: 48.8566, longitude: 2.3522 },
      radius: 2000,
    },

    // Tall buildings
    {
      name: 'Eiffel Tower',
      center: { latitude: 48.857927, longitude: 2.294972 },
      radius: 500,
    },

    // Airports
    {
      name: 'Charles De Gaulle Airport',
      center: { latitude: 49.011617, longitude: 2.547124 },
      radius: 5000,
    },
    {
      name: 'Orly Airport',
      center: { latitude: 48.72628, longitude: 2.360824 },
      radius: 5000,
    },
    {
      name: 'Aérodrome de Toussus-le-Noble',
      center: { latitude: 48.803867, longitude: 2.123642 },
      radius: 5000,
    },
    {
      name: 'Paris Airport-Le Bourget',
      center: { latitude: 48.960794, longitude: 2.436796 },
      radius: 3500,
    },
    {
      name: 'Airfield Lognes',
      center: { latitude: 48.821894, longitude: 2.622949 },
      radius: 3500,
    },
  ],
  droneWarehouses: {
    creteil: {
      name: 'Créteil',
      drones: 89,
    },
    saintDenis: {
      name: 'Saint-Denis',
      drones: 163,
    },
  },
}

export default citySpec
