import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTime } from '../time/useTime'
import { orderConfig } from '../config'
import { getIntervalWithVariation } from '../time/getIntervalWithVariation'
import { generateOrderAsync } from './generateOrder'
import { addOrder } from './ordersReducer'
import { selectIsCityInitialized } from '../cities/citiesReducer'

const { newOrderInterval } = orderConfig

export const useOrderGenerator = citySpec => {
  const cityInitialized = useSelector(selectIsCityInitialized(citySpec.id))
  const { multiplier, getTime } = useTime()
  const dispatch = useDispatch()

  useEffect(() => {
    let timeout

    const getInterval = () => getIntervalWithVariation(newOrderInterval, multiplier)

    const generateOrderAtIntervals = async () => {
      try {
        if (cityInitialized) {
          const order = await generateOrderAsync(citySpec, getTime())
          dispatch(addOrder(citySpec.id, order))
        }
        timeout = setTimeout(generateOrderAtIntervals, getInterval())
      } catch (error) {
        // Sometimes the route can't be resolved, but it's very seldom and doesn't matter
        // if we just skip it and wait for the next order to be added.
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    timeout = setTimeout(generateOrderAtIntervals, getInterval())

    return () => {
      clearTimeout(timeout)
    }
  }, [citySpec, getTime, multiplier, dispatch, cityInitialized])
}
