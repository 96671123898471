import React from 'react'
import { useSelector } from 'react-redux'
import { Field, useFormState } from 'react-final-form'
import { TD, TR } from '../../../components/tables'
import { Button } from '../../../components/controls'
import { Input, required, SelectInput } from '../../../components/input'
import { selectActiveCities } from '../../../containers/simulation/cities/citiesReducer'
import { roles } from '../../../containers/users/roles'

export const UserRoleForm = ({ user }) => {
  const cities = useSelector(selectActiveCities())
  const { submitting } = useFormState()
  const existingCities = user.roles.map(role => role.cityName)
  const availableCities = cities.filter(city => !existingCities.includes(city.name))
  return (
    <TR>
      <TD>
        <Field
          name="cityName"
          placeholder="City"
          validate={required}
          component={Input}
          as={SelectInput}
          inline
        >
          <option>Choose a city…</option>
          {availableCities.map(({ id, name }) => (
            <option key={id}>{name}</option>
          ))}
        </Field>
      </TD>
      <TD>
        <Field
          name="role"
          placeholder="Role"
          validate={required}
          component={Input}
          as={SelectInput}
          inline
        >
          <option>Choose a role…</option>
          {Object.values(roles).map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Field>
      </TD>
      <TD>
        <Button type="submit" disabled={submitting} inline>
          Add Role
        </Button>
      </TD>
    </TR>
  )
}
