import produce from 'immer'
import { sum, mapValues } from 'lodash'
import * as citySpecs from '../../../containers/simulation/cities/citySpecs'

export const CITY_INVENTORY_SUBMIT = 'CITY_INVENTORY_SUBMIT'
const CITY_INVENTORY_RESET = 'CITY_INVENTORY_RESET'

const initialState = mapValues(citySpecs, citySpec => ({
  availability: citySpec.droneWarehouses,
  orderSuccess: false,
}))

export const importInventoryReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CITY_INVENTORY_SUBMIT: {
        const { city, ordersByCity } = action.payload
        const { availability } = draft[city]
        Object.entries(ordersByCity).forEach(([warehouseCity, order]) => {
          availability[warehouseCity].drones -= sum(Object.values(order))
        })
        draft[city].orderSuccess = true
        break
      }

      case CITY_INVENTORY_RESET: {
        draft[action.payload.city].orderSuccess = false
        break
      }

      // no default
    }
  })

export const selectImportInventory = city => ({ citiesUI }) => citiesUI.importInventory[city]

export const submitOrder = (city, ordersByCity) => ({
  type: CITY_INVENTORY_SUBMIT,
  payload: { city, ordersByCity },
})

export const resetOrder = city => ({ type: CITY_INVENTORY_RESET, payload: { city } })
