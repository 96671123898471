import React, { useCallback } from 'react'
import delay from 'delay'
import { sum } from 'lodash'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'styled-bootstrap-grid'
import { Field, Form } from 'react-final-form'
import { Page } from '../../components/app'
import { Heading } from '../../components/typography'
import { DD, DL, DT, Table, TD, TH, TR } from '../../components/tables'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'
import { resetOrder, selectImportInventory, submitOrder } from './reducers/importInventory'
import { selectIndexedHubsByCity } from '../../containers/locations/hubSetup'
import { Input } from '../../components/input'
import { Button } from '../../components/controls'
import { Modal } from '../../components/modal/Modal'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

const HubOrderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${props => props.theme.spacing.xsmall}px;
`

const HubOrderLabel = styled.div`
  min-width: 170px;
  margin-right: ${props => props.theme.spacing.small}px;
`

const HubOrder = ({ name, locality }) => (
  <HubOrderDiv>
    <HubOrderLabel>{locality}&nbsp;&nbsp;Qty.</HubOrderLabel>
    <Field
      inline
      name={name}
      component={Input}
      style={{ width: '60px' }}
      parse={value => Number(value)}
      format={value => (value ? value.toString() : '')}
    />
  </HubOrderDiv>
)

const OrderSummarySection = styled(DL)`
  border-top: 2px solid ${props => props.theme.colors.light3};
  border-bottom: 2px solid ${props => props.theme.colors.light3};
  margin-bottom: ${props => props.theme.spacing.medium}px;
`

const OrderSummaryEmpty = styled.div`
  text-align: center;
  padding: ${props => props.theme.spacing.xlarge}px;
`

const HubOrderEmpty = () => (
  <OrderSummarySection isHorizontal>
    <OrderSummaryEmpty>Select drones to order from the table on the left</OrderSummaryEmpty>
  </OrderSummarySection>
)

const HubOrderSummary = ({ cityName, hubs, order }) => (
  <OrderSummarySection isHorizontal>
    <DT>Warehouse City</DT>
    <DD>{cityName}</DD>
    {Object.entries(order).map(([hubId, drones]) => (
      <React.Fragment key={hubId}>
        <DT compact>Order item</DT>
        <DD compact>
          {drones} Drones to {hubs[hubId].locality} hub
        </DD>
      </React.Fragment>
    ))}
  </OrderSummarySection>
)

export const ImportInventory = ({ match, cityId }) => {
  const city = getCitySlug(cityId || match)
  const { name } = useSelector(selectCitySpec(city))
  const hubs = useSelector(selectIndexedHubsByCity(city))
  const { availability, orderSuccess } = useSelector(selectImportInventory(city))
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async values => {
      await delay(1500)
      dispatch(submitOrder(city, values))
    },
    [dispatch, city],
  )

  return (
    <Page heading={`${name} - Import Inventory`}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, form }) => {
          const { values } = form.getState()
          const totalDrones = sum(
            Object.values(values).flatMap(order => (order ? Object.values(order) : [])),
          )
          return (
            <form
              onSubmit={async event => {
                await handleSubmit(event)
                form.reset()
              }}
            >
              <Row>
                <Col sm={7}>
                  <Table
                    head={
                      <TR>
                        <TH>Supplier City</TH>
                        <TH>Availability</TH>
                        <TH width="45%">Order</TH>
                      </TR>
                    }
                    items={Object.entries(availability)}
                  >
                    {([cityId, { name, drones }]) => (
                      <TR key={cityId}>
                        <TD vAlign="top">{name}</TD>
                        <TD vAlign="top">{drones} Drones</TD>
                        <TD>
                          {Object.keys(hubs).map(hubId => (
                            <HubOrder
                              key={hubId}
                              name={`${cityId}.${hubId}`}
                              locality={hubs[hubId].locality}
                            />
                          ))}
                        </TD>
                      </TR>
                    )}
                  </Table>
                </Col>

                <Col sm={4} smOffset={1}>
                  <Heading>Order Inventory</Heading>
                  {totalDrones === 0 ? (
                    <HubOrderEmpty />
                  ) : (
                    Object.entries(values).map(([city, cityOrder]) => (
                      <HubOrderSummary
                        key={city}
                        cityName={availability[city].name}
                        order={cityOrder}
                        hubs={hubs}
                      />
                    ))
                  )}
                  <Button type="submit" disabled={submitting || totalDrones === 0}>
                    {submitting ? 'Submitting your order...' : `Order ${totalDrones} Drones`}
                  </Button>

                  <br />
                  <br />

                  <Heading>Current Inventory</Heading>
                  <Table
                    head={
                      <TR>
                        <TH width="25%">Hub</TH>
                        <TH width="25%" center>
                          Active
                        </TH>
                        <TH width="25%" center>
                          Reserve
                        </TH>
                        <TH width="25%" center>
                          Ordered
                        </TH>
                      </TR>
                    }
                    items={Object.values(hubs)}
                  >
                    {({ id, locality, droneCounts }) => (
                      <TR key={id}>
                        <TD>{locality}</TD>
                        <TD center>{droneCounts.active}</TD>
                        <TD center>{droneCounts.reserve}</TD>
                        <TD center>{droneCounts.ordered || '-'}</TD>
                      </TR>
                    )}
                  </Table>
                </Col>
              </Row>
            </form>
          )
        }}
      </Form>

      <Modal isOpen={orderSuccess} onRequestClose={() => dispatch(resetOrder(city))}>
        <Heading>Thank you.</Heading>
        <p>Your order has been placed and you will be notified once it has been dispatched.</p>
      </Modal>
    </Page>
  )
}
