import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { MainNavProvider } from '../../components/app/MainNavProvider'

import { ActiveLocations } from './ActiveLocations'
import { AddLocation } from './AddLocation'
import { LocationHome } from './LocationHome'
import { CitySetup } from './CitySetup'
import { HubSetup } from './HubSetup'
import { UserSetup } from './UserSetup'
import { ImportInventory } from './ImportInventory'

const baseLinks = [
  { label: 'City Setup', to: '/city-setup' },
  { label: 'Hub Setup', to: '/hub-setup' },
  { label: 'Import Inventory', to: '/import-inventory' },
  { label: 'User Setup', to: '/user-setup' },
]

const Location = ({ match }) => {
  const navLinks = baseLinks.map(({ label, to }) => ({ label, to: match.url + to }))
  return (
    <MainNavProvider value={navLinks}>
      <Switch>
        <Route path={`${match.path}/city-setup`} component={CitySetup} />
        <Route path={`${match.path}/hub-setup`} component={HubSetup} />
        <Route path={`${match.path}/import-inventory`} component={ImportInventory} />
        <Route path={`${match.path}/user-setup`} component={UserSetup} />
        <Route render={props => <LocationHome {...props} navLinks={navLinks} />} />
      </Switch>
    </MainNavProvider>
  )
}

export const Locations = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/`} component={ActiveLocations} />
    <Route path={`${match.path}/add`} component={AddLocation} />
    <Route path={`${match.path}/:location`} component={Location} />
  </Switch>
)
