import React, { useState } from 'react'
import { format, subYears } from 'date-fns/fp'
import { useSelector } from 'react-redux'
import { FormattedDate } from 'react-intl'
import { MaxWidth, Page } from '../../components/app'
import { usePagination } from '../../containers/router'
import { selectCitySpec } from '../../containers/simulation/cities/citiesReducer'
import { ExportReportLink } from '../../components/misc/ExportReportLink'
import { Table, TD, TH, TR, Pagination } from '../../components/tables'
import { Dash, FilterBlock, FilterRow } from '../../components/tables/Filter'
import { DateInput, Input } from '../../components/input'
import { TabsList } from '../../components/nav'
import { drones } from './reducers/droneMaintenanceData'
import { retiredDrones } from './reducers/retiredDronesData'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

const formatDate = format('P')

const tabs = [
  { label: 'Service', value: 'service' },
  { label: 'Retirement', value: 'retirement' },
]

export const DroneMaintenance = ({ match }) => {
  const city = getCitySlug(match)
  const [tab, setTab] = useState(tabs[0].value)
  const { name } = useSelector(selectCitySpec(city))
  const { page, limit } = usePagination(15)
  return (
    <Page heading={`${name} - Drone Maintenance`}>
      <ExportReportLink />

      <MaxWidth width={900}>
        <TabsList items={tabs} active={tab} onClick={setTab} />

        {tab === 'service' ? (
          <>
            <FilterBlock isInline justify="flex-end">
              <FilterRow isInline style={{ marginRight: 0 }}>
                <Input
                  name="dateFrom"
                  as={DateInput}
                  input={{ defaultValue: formatDate(subYears(1, new Date())) }}
                  margin="xsmall"
                />
                <Dash>-</Dash>
                <Input
                  name="dateTo"
                  as={DateInput}
                  input={{ defaultValue: formatDate(new Date()) }}
                  margin="xsmall"
                />
              </FilterRow>
            </FilterBlock>

            <Table
              head={
                <TR>
                  <TH>Drone ID</TH>
                  <TH>Last Service</TH>
                  <TH center>Total Flight Time</TH>
                  <TH center>% of Max Flight Time</TH>
                  <TH center>Battery Cycles Used</TH>
                  <TH center>Status</TH>
                </TR>
              }
              items={drones}
              page={page}
              limit={limit}
              emptyMsg="No drone maintenance for the selected period"
            >
              {drone => (
                <TR key={drone.id}>
                  <TD>{drone.id}</TD>
                  <TD>
                    <FormattedDate value={drone.serviceDate} />
                  </TD>
                  <TD center>{drone.flightTimeHrs} hrs</TD>
                  <TD center>{((drone.flightTimeHrs / 700) * 100).toFixed(1)} %</TD>
                  <TD center>{drone.batteryCycle} %</TD>
                  <TD center>{drone.status}</TD>
                </TR>
              )}
            </Table>
            <Pagination total={drones.length} limit={limit} />
          </>
        ) : (
          <Table
            head={
              <TR>
                <TH>Drone ID</TH>
                <TH>Retired date</TH>
                <TH>Reason</TH>
              </TR>
            }
            items={retiredDrones}
          >
            {drone => (
              <TR key={drone.id}>
                <TD>{drone.id}</TD>
                <TD>
                  <FormattedDate value={drone.retiredDate} />
                </TD>
                <TD>{drone.reason}</TD>
              </TR>
            )}
          </Table>
        )}
      </MaxWidth>
    </Page>
  )
}
