import React from 'react'
import { TextInput } from './TextInput'
import { AddonIcon } from './AddonIcon'

export const DateInput = props => (
  <>
    <TextInput {...props} />
    <AddonIcon className="fas fa-calendar-alt" />
  </>
)
