import React from 'react'
import { pick } from 'lodash/fp'
import { Field } from 'react-final-form'
import { Input, required } from '../../../components/input'

export const pickFlightLevels = pick([
  'flightCeiling',
  'flightFloor',
  'flightSeparation',
  'takeoffSeparation',
])

export const FlightLevelsForm = () => {
  return (
    <>
      <Field
        name="flightLevels.flightCeiling"
        label="Flight ceiling (m)"
        validate={required}
        component={Input}
      />
      <Field
        name="flightLevels.flightFloor"
        label="Flight floor (m)"
        validate={required}
        component={Input}
      />
      <Field
        name="flightLevels.flightSeparation"
        label="Flight separation (m)"
        validate={required}
        component={Input}
      />
      <Field
        name="flightLevels.takeoffSeparation"
        label="Takeoff separation (s)"
        validate={required}
        component={Input}
      />
    </>
  )
}
