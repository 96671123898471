import React from 'react'
import { useTheme } from 'styled-components'
import { PinMarkerSvg } from '../map/MapMarker/PinMarker'
import { useFlightMapStyles } from './Markers/styles'
import { Circle, Icon, Item, Legend } from './Legend'

export const HubSetupMapLegend = () => {
  const theme = useTheme()
  const { hubSetupCircleStyle, noFlyZonesStyle } = useFlightMapStyles()
  return (
    <Legend>
      <Item>
        <Icon>
          <PinMarkerSvg color={theme.colors.hubMarkerInactive} />
        </Icon>
        Inactive Hub
      </Item>
      <Item>
        <Icon>
          <PinMarkerSvg color={theme.colors.hubMarker} />
        </Icon>
        Active Hub
      </Item>
      <Item>
        <Circle {...hubSetupCircleStyle} />
        Delivery Radius
      </Item>
      <Item>
        <Circle {...noFlyZonesStyle} />
        No-fly Zone
      </Item>
    </Legend>
  )
}
