import React from 'react'
import ReactSwitch from 'react-switch'
import styled, { useTheme } from 'styled-components'

const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justify || 'flex-start'};
  margin-bottom: ${props => props.theme.spacing.xxsmall}px;
`

const LabelText = styled.div`
  line-height: 22px;
  min-width: ${props => props.labelWidth || 45}px;
  margin-right: ${props => props.theme.spacing.xsmall}px;
`

export const Switch = ({ label, labelWidth, justify, ...props }) => {
  const theme = useTheme()
  return (
    <Label justify={justify}>
      {label && <LabelText labelWidth={labelWidth}>{label}</LabelText>}
      <ReactSwitch
        width={36}
        height={22}
        handleDiameter={18}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor={theme.colors.primaryLight}
        offColor={theme.colors.dark4}
        activeBoxShadow="0px 0px 1px 3px rgba(0, 0, 0, 0.2)"
        {...props}
      />
    </Label>
  )
}
