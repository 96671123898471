const defaultCity = 'london'

const validCities = ['london', 'paris', 'losAngeles']

export const getCitySlug = matchOrSlug => {
  const slug =
    typeof matchOrSlug === 'string'
      ? matchOrSlug
      : matchOrSlug.params.city ?? matchOrSlug.params.location
  return validCities.includes(slug) ? slug : defaultCity
}
