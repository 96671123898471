import { camelCase } from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Page } from '../../components/app'
import { HR } from '../../components/tables'
import { ButtonBlockList, ButtonBlockWideList, TabsList } from '../../components/nav'
import { selectContinents } from '../../containers/simulation/cities/citiesReducer'

export const ActiveCities = ({ history, match }) => {
  const continents = useSelector(selectContinents())

  const [continentName, setContinent] = useState('Europe')
  const continentNames = continents.map(c => ({ label: c.name, value: c.name }))
  const { countries, defaultCountry } =
    continents.find(c => c.name === continentName) ?? continents[0]

  const [countryName, setCountry] = useState((countries[defaultCountry] ?? countries[0]).name)
  const countryNames = countries.map(c => ({ label: c.name, value: c.name }))
  const { cities } = countries.find(c => c.name === countryName) ?? countries[0]

  const cityNames = cities.map(city => ({ label: city, value: `${match.url}/${camelCase(city)}` }))

  const setContinentAndCountry = newContinentName => {
    setContinent(newContinentName)
    const { countries, defaultCountry } =
      continents.find(c => c.name === newContinentName) ?? continents[0]
    setCountry((countries[defaultCountry] ?? countries[0]).name)
  }

  return (
    <Page heading="Active Cities">
      <TabsList items={continentNames} active={continentName} onClick={setContinentAndCountry} />
      <ButtonBlockList items={countryNames} active={countryName} onClick={setCountry} />
      <HR />
      <ButtonBlockWideList items={cityNames} small onClick={pathname => history.push(pathname)} />
    </Page>
  )
}
