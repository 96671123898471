import produce from 'immer'

export const ORDERS_ADD_MANY = 'ORDERS_ADD_MANY'
export const ORDERS_ADD_ONE = 'ORDERS_ADD_ONE'
export const ORDER_CANCELLED = 'ORDER_CANCELLED'

const initialState = {
  byId: {},
}

export const ordersReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ORDERS_ADD_MANY: {
        const { orders } = action.payload
        orders.forEach(order => (draft.byId[order.id] = order))
        break
      }

      case ORDERS_ADD_ONE: {
        const { order } = action.payload
        draft.byId[order.id] = order
        break
      }

      // no default
    }
  })

export const selectOrderById = orderId => ({ orders }) => orders.byId[orderId]

export const addOrders = (cityId, orders) => ({
  type: ORDERS_ADD_MANY,
  payload: { cityId, orders },
})

export const addOrder = (cityId, order) => ({
  type: ORDERS_ADD_ONE,
  payload: { cityId, order },
})

export const cancelOrder = (cityId, orderId) => ({
  type: ORDER_CANCELLED,
  payload: { cityId, orderId },
})
