import { useEffect } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { cityInitialized, selectCitySpec, selectIsCityInitialized } from './citiesReducer'
import { useSimulationControls } from '../useSimulationControls'
import { useFlightManagement } from '../schedule/useFlightManagement'
import { useOrderGenerator } from '../orders/useOrderGenerator'
import { initialOrdersAsync } from '../orders/initialOrders'
import { initialDrones } from '../drones/initialDrones'
import { addOrders } from '../orders/ordersReducer'
import { addDrones } from '../drones/dronesReducer'
import { useTime } from '../time/useTime'

export const useCitySimulation = city => {
  const { getTime } = useTime()
  const dispatch = useDispatch()
  const citySpec = useSelector(selectCitySpec(city))
  const initialized = useSelector(selectIsCityInitialized(city))

  useEffect(() => {
    if (!initialized) {
      const initializeSimulation = async () => {
        const orders = await initialOrdersAsync(citySpec, getTime())
        const drones = initialDrones(citySpec)
        batch(() => {
          dispatch(addDrones(citySpec.id, citySpec.hubs, drones))
          dispatch(addOrders(citySpec.id, orders))
          dispatch(cityInitialized(citySpec.id))
        })
      }
      initializeSimulation()
    }
  }, [getTime, citySpec, initialized, dispatch])

  const { showSimulationControls } = useSimulationControls()
  useEffect(() => {
    showSimulationControls(true)
    return () => showSimulationControls(false)
  }, [showSimulationControls])

  useOrderGenerator(citySpec)
  useFlightManagement(city)
}
