import { cloneDeep, random } from 'lodash'
import { getPathLength, getRhumbLineBearing } from 'geolib'
import { calculateFlightDuration } from './calculateFlightDuration'
import { calculateFlightPathWayPoints } from './calculateFlightPathWayPoints'
import { getIntervalWithVariation } from '../time/getIntervalWithVariation'
import { flightConfig } from '../config'

export const calculateFlightPath = (citySpec, hub, destination) => {
  const wayPoints = calculateFlightPathWayPoints(citySpec, hub.center, destination)
  const loadingTime = getIntervalWithVariation(flightConfig.loadingTimeInterval)
  const deliveryTime = getIntervalWithVariation(flightConfig.deliveryTimeInterval)
  const legDuration = calculateFlightDuration(citySpec, wayPoints.wayPointsOut)
  return {
    ...wayPoints,
    originalWayPoints: cloneDeep(wayPoints), // To render on the map
    destination,
    loadingTime, // seconds
    deliveryTime, // seconds
    status: 'queued',
    speed: 0,
    hubId: hub.id,
    gateNo: random(1, hub.gates),
    battery: random(90, 100),
    batteryPerSecond: citySpec.droneSpec.batteryPerSecond,
    throttle: random(85, 95),
    position: { ...hub.center, altitude: 0 },
    nextWayPoint: wayPoints.wayPointsOut.shift(),
    bearing: getRhumbLineBearing(hub.center, destination),
    distance: getPathLength(wayPoints.wayPointsOut) * 2,
    legDuration,
    duration: legDuration * 2,
    remainingDuration: legDuration,
    elapsedDuration: 0,
    departedAt: null,
    deliveredAt: null,
  }
}
