import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { MainNavProvider } from '../../components/app/MainNavProvider'

import { ActiveCities } from './ActiveCities'
import { CityHome } from './CityHome'
import { CustomerOrders } from './CustomerOrders'
import { CustomerOrdersMap } from './CustomerOrdersMap'
import { AirTrafficControl } from './AirTrafficControl'
import { DroneMaintenance } from './DroneMaintenance'
import { FlightManagement } from './FlightManagement'
import { ImportInventory } from './ImportInventory'
import { Reports } from './Reports'
import { useCitySimulation } from '../../containers/simulation/cities/useCitySimulation'
import { getCitySlug } from '../../containers/simulation/getCitySlug'

const baseLinks = [
  { label: 'Customer Orders', to: '/orders' },
  { label: 'Air Traffic Control', to: '/atc' },
  { label: 'Flight Management', to: '/flights' },
  { label: 'Drone Maintenance', to: '/drones' },
  { label: 'Import Inventory', to: '/inventory' },
  { label: 'Reports', to: '/reports' },
]

const City = ({ match }) => {
  useCitySimulation(getCitySlug(match))
  const navLinks = baseLinks.map(({ label, to }) => ({ label, to: match.url + to }))
  return (
    <MainNavProvider value={navLinks}>
      <Switch>
        <Route path={`${match.path}/orders/:orderId`} component={CustomerOrdersMap} />
        <Route path={`${match.path}/orders`} component={CustomerOrders} />
        <Route path={`${match.path}/atc`} component={AirTrafficControl} />
        <Route
          path={`${match.path}/flights`}
          render={props => <FlightManagement {...props} atcPath={`${match.url}/atc`} />}
        />
        <Route path={`${match.path}/drones`} component={DroneMaintenance} />
        <Route path={`${match.path}/inventory`} component={ImportInventory} />
        <Route path={`${match.path}/reports`} component={Reports} />
        <Route render={props => <CityHome {...props} navLinks={navLinks} />} />
      </Switch>
    </MainNavProvider>
  )
}

export const Cities = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/`} component={ActiveCities} />
    <Route path={`${match.path}/:city`} component={City} />
  </Switch>
)
